import styled from "styled-components";

export const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FramesChartWrapper = styled(ChartWrapper)`
  width: 50%;
  height: 265px;
`;

export const ExposureByLocationWrapper = styled(ChartWrapper)`
  width: 40%;
  height: 265px;
`;

export const DeviceActivityWrapper = styled(ChartWrapper)`
  width: 35%;
  height: 265px;
`;

export const GaugeChartWrapper = styled(ChartWrapper)`
  width: 50%;
  align-items: center;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 33.33%;
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 25%;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
    width: 20%;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.xxl}) {
    width: 20%;
  }
`;

export const GroupActivityChartWrapper = styled(ChartWrapper)`
  width: 25%;
  height: 265px;
`;

export const ScanPerLocationChartWrapper = styled(ChartWrapper)`
  width: 50%;
  height: 265px;
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
