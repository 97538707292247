export const defaultOptions = [
  {
    id: 10,
    value: "Dropdown Labels",
  },
  {
    id: 20,
    value: "Twenty",
  },
  {
    id: 30,
    value: "Thirty",
  },
];
