import React, { useState } from "react";
import SetupNavBar from "../../../Components/nav/setup-nav/SetupNavBar";
import { FiDownload } from "react-icons/fi";
import { FaArrowsAlt } from "react-icons/fa";
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";
import { IoBook } from "react-icons/io5";
import DeployedDevices from "./DeployedDevices";
import SetupGuide from "./SetupGuide";
import setupGuideDataList from "../../../data/setupGuide";
import UndeployDevices from "./UndeployDevices";

interface DeviceSetupType {
  title: string;
  subTitle: string;
  barIcon: JSX.Element;
}

const setupNavBarList: DeviceSetupType[] = [
  {
    title: "Get Firmware",
    subTitle: "Download the latest firmware for your devices",
    barIcon: <FiDownload />,
  },
  {
    title: "Deploy Devices",
    subTitle:
      "Undeployed devices with their firmware installed can be accessed and deployed from this portal",
    barIcon: <FaArrowsAlt />,
  },
  {
    title: "Undeploy Devices",
    subTitle:
      "Deployed devices with their firmware installed can be accessed and undeployed from this portal",
    barIcon: <MdOutlineDoNotDisturbAlt />,
  },
  {
    title: "Setup Guide",
    subTitle: "For more information in setting up your device click here",
    barIcon: <IoBook />,
  },
];

export const DeviceSetup = () => {
  const [selectedTitle, setSelectedTitle] = useState("");
  const onSelectedTitle = (title: string) => {
    if (title === "Get Firmware") {
      const swrmGithubUrl = "https://github.com/SWRMAI";
      // Open the link in a new tab
      window.open(swrmGithubUrl, "_blank");
    } else {
      setSelectedTitle(title);
    }
  };
  return (
    <>
      {selectedTitle ? (
        // selectedTitle === "Get Firmware" ? (
        //   <GetFirmware />
        // ) :
        selectedTitle === "Deploy Devices" ? (
          <DeployedDevices />
        ) : selectedTitle === "Undeploy Devices" ? (
          <UndeployDevices />
        ) : selectedTitle === "Setup Guide" ? (
          <SetupGuide data={setupGuideDataList} />
        ) : null
      ) : (
        setupNavBarList.map((setupNavBar, index) => (
          <SetupNavBar
            key={index}
            title={setupNavBar.title}
            subTitle={setupNavBar.subTitle}
            barIcon={setupNavBar.barIcon}
            onClick={onSelectedTitle}
          />
        ))
      )}
    </>
  );
};

export default DeviceSetup;
