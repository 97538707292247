import { BiInfoCircle } from "react-icons/bi";
import { IoMdDownload } from "react-icons/io";
import { BiSolidArchiveIn } from "react-icons/bi";
import { BiSolidArchiveOut } from "react-icons/bi";
import styled from "styled-components";

export const ImageCardWrapper = styled.div`
  aspect-ratio: 1/1;
  border-radius: 5px;
  border: 1px solid #707070;
  cursor: pointer;

  &:hover {
    border: ${(props) => props.theme.border["secondary-md"]};
    border-radius: 5px;
  }
`;

export const CardImageWrapper = styled.div`
  display: flex;
  height: 80%;
  position: relative;
`;

export const CardIconsWrapper = styled.div`
  display: flex;
  height: 20%;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
`;

export const VerticalLineWrapper = styled.span`
  font-size: ${(props) => props.theme.fontSizes["xl"]};
  font-weight: 100;
  color: #707070;
`;

export const StyledBiSolidArchiveIn = styled(BiSolidArchiveIn)`
  font-size: ${(props) => props.theme.fontSizes["xl"]};
  width: 33%;
  outline: none;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors["primary"]};
  }
`;

export const StyledBiSolidArchiveOut = styled(BiSolidArchiveOut)`
  font-size: ${(props) => props.theme.fontSizes["xl"]};
  width: 33%;
  outline: none;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors["primary"]};
  }
`;

export const StyledIoMdDownload = styled(IoMdDownload)`
  font-size: ${(props) => props.theme.fontSizes["xl"]};
  width: 33%;
  outline: none;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors["primary"]};
  }
`;

export const StyledBiInfoCircle = styled(BiInfoCircle)`
  font-size: ${(props) => props.theme.fontSizes["xl"]};
  width: 33%;
  outline: none;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors["primary"]};
  }
`;
