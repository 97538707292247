import styled from "styled-components";

export const EditorWrapper = styled.div`
  padding: 0 20px;

  .tox .tox-editor-container {
    border: ${(props) => props.theme.border["primary-sm"]};
    border-radius: 5px;
  }

  .tox
    .tox-toolbar-overlord
    .tox-toolbar:not(.tox-toolbar--scrolling):first-child,
  .tox .tox-toolbar-overlord .tox-toolbar__primary {
    border-bottom: ${(props) => props.theme.border["primary-sm"]};
  }

  .tox:not([dir="rtl"]) .tox-toolbar__group:not(:last-of-type) {
    border-right: ${(props) => props.theme.border["primary-sm"]};
  }

  .tox .tox-toolbar,
  .tox .tox-toolbar__overflow,
  .tox .tox-toolbar__primary {
    background-color: #020a01 !important;
  }
`;
