import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { companyService } from "../../Services/Company/company.service";
import { ResponseObject } from "../../Interfaces/Response";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../Components/notification/Notification";
import Loader from "../../Components/loader/Loader";

const AcceptInvitation: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const encodedData = queryParams.get("data");
  const navigate = useNavigate();
  useEffect(() => {
    if (encodedData) {
      verifyToken();
    }
  }, [encodedData]);

  const verifyToken = async () => {
    try {
      const response: ResponseObject<string> =
        await companyService.verifyInvitation(encodedData);
      if (response.IsError) {
        OpenErrorNotification(response.ErrorMessage);
      } else {
        OpenSuccessNotification(response.Result);
      }
    } catch (error) {
      console.error("Error inviting user:", error);
    } finally {
      navigate("/"); //redirect to login screen
    }
  };

  return (
    <Loader
      isLoadingScreen
      loadingText="Please wait while we validating request!!!"
    />
  );
};

export default AcceptInvitation;
