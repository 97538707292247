import React, { useEffect, useState } from "react";
import { UsersWrapper } from "./Users.style";
import { StyledTable } from "../../tables/Table.style";
import { UserCompany, UserRoles } from "../../../Interfaces/User";
import {
  companyService,
  getCompanyUsers,
} from "../../../Services/Company/company.service";
import InviteUserBtn from "./InviteUserBtn";

import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../notification/Notification";
import ConfirmationModal from "../../modals/confirmation-modal/ConfirmationModal";
import { Typography } from "../../typrography/Typography";
import DeleteIcon from "../../icons/DeleteIcon";
import EditIcon from "../../icons/EditIcon";
import EditUserRoleModal from "../../modals/edit-user-role-modal/EditUserRoleModal";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { RootState } from "../../../Store/store";
import {
  removeUser,
  updateUserRole,
} from "../../../Features/Slice/users/usersSlice";
const columns = ["First Name", "Last Name", "Email", "Role", "Actions"];
export default function Users() {
  const { user } = useAuth0();
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state: RootState) => state.users);
  const [confirmMsg, setConfirmMsg] = useState<string>("");
  const [userDataToDelete, setUserDataToDelete] = useState<number>(0);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [userDataToEdit, setUserDataToEdit] = useState<UserCompany | null>(
    null,
  );
  useEffect(() => {
    dispatch(getCompanyUsers());
  }, []);
  const handleDeleteClick = (id: number) => {
    const msg = `Are you sure you'd like to remove the user from company? This cannot be undone.`;
    setConfirmMsg(msg);
    setUserDataToDelete(id);
    setDeleteModalOpen(true);
  };
  const handleConfirmDelete = async () => {
    const response: any = await companyService.removeUserFromCompany(
      userDataToDelete,
    );
    if (response.ErrorCode !== 200) {
      OpenErrorNotification(response.ErrorMessage);
    } else {
      OpenSuccessNotification(response.Result);
      dispatch(removeUser(userDataToDelete));
    }
    handleConfirmModalClose();
  };
  const handleConfirmModalClose = () => {
    setDeleteModalOpen(false);
    setUserDataToDelete(0);
  };
  const handleEditClick = (data: UserCompany) => {
    setEditModalOpen(true);
    setUserDataToEdit(data);
  };
  const handleEditModalClose = () => {
    setEditModalOpen(false);
    setUserDataToEdit(null);
  };
  return (
    <UsersWrapper>
      <InviteUserBtn />
      <StyledTable style={{ paddingTop: "0.5rem" }}>
        <thead>
          <tr>
            {columns.map((column, index) => {
              return <th key={index}>{column}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((item: UserCompany) => (
            <tr key={item.Id}>
              <td>{item.User.FirstName}</td>
              <td>{item.User.LastName}</td>
              <td>{item.User.Email}</td>
              <td>{UserRoles[item.RoleId]}</td>
              <td>
                {user?.sub &&
                user.sub.toLocaleLowerCase() ===
                  item.UserId.toLocaleLowerCase() ? (
                  <div style={{ display: "flex", gap: "0.5rem" }}>
                    <Typography variant="md" color="white" weight="bold">
                      <EditIcon
                        handleEditOnClick={() => undefined}
                        size="1.33rem"
                        tooltip="Not Allowed"
                        disabled
                      />
                    </Typography>
                    <Typography variant="md" color="white" weight="bold">
                      <DeleteIcon
                        handleDeleteOnClick={() => undefined}
                        size="1.33rem"
                        tooltip="Not Allowed"
                        disabled
                      />
                    </Typography>
                  </div>
                ) : (
                  <div style={{ display: "flex", gap: "0.5rem" }}>
                    <Typography variant="md" color="white" weight="bold">
                      <EditIcon
                        handleEditOnClick={() => handleEditClick(item)}
                        size="1.33rem"
                      />
                    </Typography>
                    <Typography variant="md" color="white" weight="bold">
                      <DeleteIcon
                        handleDeleteOnClick={() => handleDeleteClick(item.Id)}
                        size="1.33rem"
                      />
                    </Typography>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </StyledTable>

      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setDeleteModalOpen}
        onConfirm={handleConfirmDelete}
        headerTitle={"Remove User"}
        confirmMsg={confirmMsg}
        onClose={handleConfirmModalClose}
        modalWidth="30vw"
        minWidth="450px"
      />
      {userDataToEdit && (
        <EditUserRoleModal
          data={userDataToEdit}
          isOpenModel={isEditModalOpen}
          onClose={handleEditModalClose}
        />
      )}
    </UsersWrapper>
  );
}
