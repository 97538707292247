import { FilterOption } from "../Components/dropdown/filter-dropdown/FilterDropdown";

// generic method to build and return a string array of names
// Pre-conditions: getAllLocations, getAllDevices and getAllAIModels contain a 'Name' column
/* 
    This same method has other implementations under a different name
    They exist in their own respective files. This utility method is created 
    so that we follow the DRY(Don't Repeat Yourself) Principle. It is important
    to remove those method instances and utilize this utility method instead.
    - Reminder for Matthew
*/
export const buildDropdownOptionsObj = (
  list: any,
  isListOfStrings?: boolean | null,
) => {
  const options: FilterOption[] = [];

  // loop through all objects in payload and only build dropdown
  // with entries that have values in the 'Name' columns
  if (list) {
    if (!isListOfStrings) {
      options.push(
        ...list
          .filter(
            (stateArray: any) => stateArray.Name && stateArray.Name !== "",
          )
          .map((stateArray: any) => ({
            label: stateArray.Name,
            value: stateArray.Id,
          })),
      );
    } else {
      let index = 0;
      options.push(
        ...list.map((item: string) => ({
          label: item,
          value: index++,
        })),
      );
    }
  }
  return options;
};
