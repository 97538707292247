import styled from "styled-components";
import { HelpDropdownProps } from "./HelpDropdownList";

export const HelpDropdownListWrapper = styled.div<
  Pick<HelpDropdownProps, "padding">
>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ padding }) => (padding ? padding : "0 2vw")};
`;
