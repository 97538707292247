import React, { useState } from "react";
import { Typography } from "../../typrography/Typography";
import ApiUptimeBarChartList from "../../bar-chart/ApiUptimeBarChartList";
import { Tabs } from "../../../Components/tab/tabs/Tabs";
import SystemMetricsLineChart from "../../line-chart/SystemMetricsLineChart";
import { ITab } from "../../../Types/page-tabs/pageTabs";
import { DefaultThemeColor } from "../../../app/Theme";

type Props = {};
const tabs: ITab[] = [
  {
    label: "Day",
    index: 1,
    Component: SystemMetricsLineChart,
  },
  {
    label: "Week",
    index: 2,
    Component: SystemMetricsLineChart,
  },
  {
    label: "Month",
    index: 3,
    Component: SystemMetricsLineChart,
  },
];
const Status = (props: Props) => {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index);
  const handleEvent = (e: number) => {
    setSelectedTab(e);
  };

  return (
    <>
      <Typography
        style={{
          fontSize: "20px",
          padding: "10px",
          background: DefaultThemeColor.secondary,
          width: "100%",
        }}
      >
        All Systems Online
      </Typography>
      <ApiUptimeBarChartList />
      <Typography style={{ fontSize: "22px", paddingTop: "3rem" }}>
        System Metrics
      </Typography>
      <Tabs
        selectedTab={selectedTab}
        onClick={handleEvent}
        tabs={tabs}
        justifyContent="flex-end"
        style={{ fontSize: "16px" }}
        variant="chartTab"
        padding="0"
      />
    </>
  );
};

export default Status;
