import styled from "styled-components";
import { TitlesProps } from "./Titles";
import { DropdownTitleProps } from "./dropdown-title/DropdownTitle";

export const TitleWrapper = styled.div<
  Pick<TitlesProps, "variant" | "marginTop" | "flex">
>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ variant }) =>
    variant === "normal" ? "1rem" : "1rem 1rem 3rem"};
  margin-top: ${({ marginTop }) =>
    marginTop !== undefined ? marginTop : "15vh"};
  flex: ${({ flex }) => flex && flex};
`;

export const HeadingStyle = styled.div<Pick<TitlesProps, "variant">>`
  background: #020a01;
  text-align: ${({ variant }) => (variant === "normal" ? "left" : "center")};
  font: normal normal 900 ${(props) => props.theme.fontSizes["7xl"]} /
    ${(props) => props.theme.fontSizes["8xl"]} Avenir LT Std;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
`;

export const SmallHeadingStyle = styled(HeadingStyle)`
  font: normal normal 700 50px / 65px Avenir LT Std;
`;

export const SubheadingStyle = styled.div<Pick<TitlesProps, "variant">>`
  background: #020a01;
  text-align: ${({ variant }) => (variant === "normal" ? "left" : "center")};
  font-size: ${(props) => props.theme.fontSizes["lg"]};
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
`;

export const SubTitle = styled.div`
  text-align: left;
  font-size: ${(props) => props.theme.fontSizes["lg"]};
  letter-spacing: 0px;
  color: #ffffff;
`;

export const DropdownTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ApiMethodWrapper = styled.div<
  Pick<DropdownTitleProps, "methodBgColor">
>`
  background: ${({ methodBgColor }) => methodBgColor};
  padding: 5px 10px;
  border-radius: 3px;
  color: #111817;
  font-size: ${(props) => props.theme.fontSizes["sm"]};
  font-weight: 900;
`;

export const ApiUrlWrapper = styled.div`
  padding: 5px 10px;
`;
