import styled from "styled-components";

interface ToggleSwitchProps {
  checked?: boolean;
  isPending?: boolean;
  margin?: string;
}

export const CustomToggleSwitch = styled.label<ToggleSwitchProps>`
  cursor: ${({ isPending }) =>
    isPending ? "wait" : "pointer"}; // Change cursor to indicate loading
  text-indent: -9999px;
  width: 38px;
  height: 19px;
  background: ${({ checked, isPending }) =>
    isPending ? "#cccccc"
    : checked ? "#80D39B"
    : "#4B4B4B"}; // Dim the color when pending
  display: block;
  border-radius: 100px;
  position: relative;
  margin: ${({ margin }) => (margin ? margin : "20px")};
  opacity: ${({ isPending }) =>
    isPending ? 0.7 : 1}; // Reduce opacity when pending
  transition: background 0.3s ease;

  &:after {
    content: "";
    position: absolute;
    left: ${({ checked }) => (checked ? "calc(57%)" : "0px")};
    top: 2px;
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 90px;
    transition: left 0.3s;
  }
`;
