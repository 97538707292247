import axios from "axios";
import { Sensor } from "../../Interfaces/Device/Sensor";
import { OpenErrorNotification } from "../../Components/notification/Notification";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

const searchSensor = async (deviceId: string, id: number, active: boolean) => {
  const searchString = `?deviceId${
    deviceId.length > 0 ? "=" + deviceId : ""
  }&id${id > 0 ? "=" + id : ""}&active${active ? "=" + active : ""}`;
  let response = await axios
    .get(`${API_URL}/Sensor/filter${searchString}`)
    .then(async (response) => {
      await console.log(response.data);
      return response.data;
    })
    .catch((err: any) => {
      OpenErrorNotification(err.response.data.ErrorMessage);
      return err.response.data;
    });

  return response;
};

const patchSensor = async (sensor: Sensor) => {
  let response = await axios
    .patch(`${API_URL}/sensor/active`, sensor)
    .then(async (response) => {
      await console.log(response.data);
      return response.data;
    })
    .catch((err: any) => {
      OpenErrorNotification(err.response.data.ErrorMessage);
      return err.response.data;
    });

  return response;
};
const sensorService = {
  searchSensor,
  patchSensor,
};

export default sensorService;
