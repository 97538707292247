import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { RootState } from "../../../../Store/store";
import { Typography } from "../../../../Components/typrography/Typography";
import { FlexContainer } from "../../../../Components/flex-container/FlexContainer";
import { ScaledButtonWrapper } from "../../../../Components/modals/CustomModal.style";
import * as Yup from "yup";
import CustomModal from "../../../../Components/modals/CustomModal";
import { Formik } from "formik";
import { StyledFormikForm } from "../../../../Components/tab/ai-model-manager/Upload.style";
import Input from "../../../../Components/input/Input";
import CountryCodeDropdown from "../../../../Components/select/CountryCodeDropdown";
import { DeviceConfigurationWifi } from "../../../../Interfaces/Device/DeviceConfigurationWifi";
import { SwrmDevices } from "../../../../Interfaces/Device/SwrmDevice";
import { encodeString } from "../../../../Utils/encodingUtils";
import deviceConfigService from "../../../../Services/DeviceManagement/deviceConfig.service";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../../Components/notification/Notification";
import { Option } from "../../../../Interfaces/Country";
import { SelectWrapper } from "../../../../Components/select/SelectDropdown.style";
import { ResponseObject } from "../../../../Interfaces/Response";
import Loader from "../../../../Components/loader/Loader";
import { setDeviceWifiStatusConnected } from "../../../../Features/Slice/devices/devicesSlice";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  device: SwrmDevices;
}

interface WifiConnectionResultPayload {
  WifiConnectionResult: boolean;
}

const DeviceWifiSettingsModal = (props: Props) => {
  const { isOpen, onClose, device } = props;
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  interface FormValues {
    ssid: string;
    password: string;
    country: Option | null;
  }

  const initialValues: FormValues = {
    ssid: "",
    password: "",
    country: null,
  };

  const validationSchema = Yup.object({
    ssid: Yup.string()
      .max(32, "SSID maximum of 32 characters")
      .required("SSID is required"),
    password: Yup.string().required("Password is required"),
    country: Yup.object().required("Country is required"),
  });

  const cardHeader = () => {
    return (
      <Typography
        variant="lg"
        direction="flex-start"
        style={{ padding: "0 20px", fontWeight: "700" }}
      >
        Wi-Fi Settings
      </Typography>
    );
  };
  const cardFooter = () => {
    return (
      <FlexContainer gap="1rem" justifyContent="center" alignItems="center">
        <ScaledButtonWrapper buttonPadding={"10px"}>
          <button type="submit" form="integration-authentication">
            {isLoading ? "Connecting..." : "Connect"}
          </button>
        </ScaledButtonWrapper>
      </FlexContainer>
    );
  };

  const waitForConnectionResult = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleSubmit = async (values: FormValues) => {
    try {
      setIsLoading(true);

      // Encode WiFi password
      const encodedPassword = encodeString(values.password);
      const payload: DeviceConfigurationWifi = {
        Id: 0,
        DeviceId: device.Id,
        WifiSSID: values.ssid,
        WifiWPA: encodedPassword,
        WifiCountry: values.country?.value!,
      };

      // Send off credentials to device
      const response = await deviceConfigService.patchDeviceConfigWifi(payload);
      if (response.IsError) {
        OpenErrorNotification(response.ErrorMessage);
        onClose();
        setIsLoading(false);
        return;
      }

      // Get Wi-Fi connection result
      await waitForConnectionResult(10000);
      const connectionResponse: ResponseObject<WifiConnectionResultPayload> =
        await deviceConfigService.getWifiConnectionResult(device.Id);

      setIsLoading(false);

      if (!connectionResponse.Result.WifiConnectionResult) {
        OpenErrorNotification("Failed to send Wi-Fi credentials");
        onClose();
        return;
      }

      dispatch(setDeviceWifiStatusConnected(device.Id));
      OpenSuccessNotification("Wi-Fi credentials sent to device");
      onClose();

      return;
    } catch (error) {
      throw error;
    }
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      closeBtnTopPosition="0.188rem"
      padding="10px 0"
      width={"35%"}
      header={cardHeader()}
      footer={cardFooter()}
    >
      {isLoading && (
        <Loader loadingText="Sending Wi-Fi credentials..." isLoadingScreen />
      )}
      <FlexContainer
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="1.3rem"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleBlur, handleChange, errors }: any) => (
            <StyledFormikForm id="integration-authentication">
              <FlexContainer
                flexDirection="column"
                gap="0.5rem"
                margin="1rem 0 0"
              >
                <div style={{ width: "100%", position: "relative" }}>
                  <Input
                    type="text"
                    name="ssid"
                    placeHolder="Wi-Fi SSID"
                    label={""}
                    width="18rem"
                    margin="0 auto 22px"
                    padding="0 10px 0"
                    value={values.ssid}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>

                <div style={{ width: "100%", position: "relative" }}>
                  <Input
                    type="password"
                    name="password"
                    placeHolder="Wi-Fi Password"
                    label={""}
                    width="18rem"
                    margin="0 0 22px"
                    padding="0 10px 0"
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>

                <SelectWrapper padding="0 10px 28px" style={{ width: "100%" }}>
                  <CountryCodeDropdown name="country" />
                </SelectWrapper>
              </FlexContainer>
            </StyledFormikForm>
          )}
        </Formik>
      </FlexContainer>
    </CustomModal>
  );
};

export default DeviceWifiSettingsModal;
