import React, { useState } from "react";
import { HelpDropdownListWrapper } from "./HelpDropdownList.style";
import { IDropdown } from "../../../data/faqAndHelpDropdowns";
import { Accordion } from "../../accordion/Accordion";

export interface HelpDropdownProps {
  data: IDropdown[];
  width?: string;
  padding?: string;
  margin?: string;
}

const HelpDropdownList = ({
  data,
  padding,
  width,
  margin,
}: HelpDropdownProps) => {
  const [activeId, setActiveId] = useState<number | null>(null);

  const handleToggle = (id: number) => {
    if (activeId === id) {
      setActiveId(null); // Close the accordion if it is already open
    } else {
      setActiveId(id); // Open the clicked accordion and close others
    }
  };

  return (
    <HelpDropdownListWrapper padding={padding}>
      {data.map((item, index) => (
        <Accordion
          key={index}
          id={item.id}
          title={item.question}
          description={item.answer}
          width={width || ""}
          alignItems="flex-start"
          margin={margin || ""}
          label=""
          color=""
          display=""
          verticalLine={false}
          backgroundColor=""
          isActive={activeId === item.id}
          handleToggle={() => handleToggle(item.id)}
        ></Accordion>
      ))}
    </HelpDropdownListWrapper>
  );
};

export default HelpDropdownList;
