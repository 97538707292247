import React from "react";
import {
  StyledBRiVisaLine,
  StyledRiMastercardFill,
  StyledSiAmericanexpress,
  StyledSiGooglepay,
  VisaCardWrapper,
} from "./PaymentCard.style";

interface Props {
  cardType: "Visa" | "Master" | "AmericanExpress" | "GooglePay";
}

const VisaCard = ({ cardType }: Props) => {
  return (
    <VisaCardWrapper>
      {cardType === "Visa" ? (
        <StyledBRiVisaLine />
      ) : cardType === "Master" ? (
        <StyledRiMastercardFill />
      ) : cardType === "AmericanExpress" ? (
        <StyledSiAmericanexpress />
      ) : cardType === "GooglePay" ? (
        <StyledSiGooglepay />
      ) : (
        ""
      )}
    </VisaCardWrapper>
  );
};

export default VisaCard;
