import CustomModal from "../CustomModal";
import { Typography } from "../../typrography/Typography";
import { ModalProvider } from "styled-react-modal";
import { ScaledButtonWrapper } from "../CustomModal.style";
import { RoleOptions, UserCompany, UserRoles } from "../../../Interfaces/User";
import { useState } from "react";
import { FormControl } from "../../form-field/form-control/FormControl";
import { FormLabel } from "../../form-field/form-label/FormLabel";
import { DropdownMenuContainer } from "../../form-field/drop-down/Dropdown.style";
import { CustomDropdown } from "../../form-field/drop-down/CustomDropdown";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
  OpenWarningNotification,
} from "../../notification/Notification";
import { ResponseObject } from "../../../Interfaces/Response";
import { companyService } from "../../../Services/Company/company.service";
import { UserData } from "aws-sdk/clients/ec2";
import { updateUserRole } from "../../../Features/Slice/users/usersSlice";
import { useAppDispatch } from "../../../Store/hooks";
import {
  FilterDropdown,
  FilterOption,
} from "../../dropdown/filter-dropdown/FilterDropdown";
interface Props {
  isOpenModel: boolean;
  onClose: () => void;
  data: UserCompany;
}

const EditUserRoleModal = ({ data, isOpenModel, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const [isWaitingForResponse, setIsWaitingForResponse] =
    useState<boolean>(false);
  const currentUserRole = RoleOptions.find((item) => {
    return item.value == data.RoleId;
  });
  const [userRole, setUserRole] = useState<FilterOption>(
    currentUserRole || { label: "", value: 0 },
  );
  const handleRoleChange = (value: any) => {
    setUserRole(value);
  };
  const cardHeader = () => {
    return (
      <Typography
        variant="lg"
        direction="flex-start"
        style={{ padding: "0.725rem", fontWeight: "700" }}
      >
        Edit User Role
      </Typography>
    );
  };
  const cardFooter = () => {
    return (
      <ScaledButtonWrapper
        buttonMargin="0.5rem"
        style={{ justifyContent: "end" }}
      >
        <button type="submit" form="update-user-role" onClick={handleSubmit}>
          Save
        </button>
      </ScaledButtonWrapper>
    );
  };
  const handleSubmit = async () => {
    try {
      if (!userRole) {
        OpenWarningNotification("Please select user role!");
        return;
      }
      // If currently inviting user, prevent multiple attempt to invite user
      if (isWaitingForResponse) return;

      setIsWaitingForResponse(true);

      // service call
      const payload = {
        ...data,
        RoleId: Number(userRole.value),
      };
      const response: ResponseObject<string> =
        await companyService.updateUserRole(payload);

      setIsWaitingForResponse(false);

      if (response.IsError) {
        OpenErrorNotification(response.ErrorMessage);
        return;
      }

      OpenSuccessNotification(response.Result);
      dispatch(updateUserRole(payload));
      onClose();
    } catch (error) {
      setIsWaitingForResponse(false);
      console.error("Error inviting user:", error);
    }
  };
  return (
    <ModalProvider>
      <CustomModal
        width="25rem"
        isOpen={isOpenModel}
        onClose={onClose}
        header={cardHeader()}
        footer={cardFooter()}
      >
        <div style={{ padding: "1rem" }}>
          <FormControl>
            <FormLabel
              htmlFor="Role"
              fontSize="1.25vw"
              fontWeight="700"
              style={{ color: "white" }}
            >
              Role
            </FormLabel>
            <div style={{ width: "100%" }}>
              <FilterDropdown
                defaultValue={userRole}
                title="Role"
                options={RoleOptions}
                isFullSize
                onChange={(value: FilterOption) => {
                  handleRoleChange(value);
                }}
              />
            </div>
          </FormControl>
        </div>
      </CustomModal>
    </ModalProvider>
  );
};

export default EditUserRoleModal;
