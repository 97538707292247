import { useState } from "react";
import { Typography } from "../../../../Components/typrography/Typography";
import { IconWrapper } from "../../../deviceManager/manage/info-card-device-manager/DeviceName.style";
import EditLocationInfoModal from "../../../../Components/modals/edit-location-name-modal/EditLocationInfoModal";
import EditIcon from "../../../../Components/icons/EditIcon";

type Props = {
  locationId: number;
  locationName: string;
  editLocationAddress?: boolean | null;
};

const LocationEditToolTip = ({
  locationName,
  locationId,
  editLocationAddress,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectRelationModalOpen = () => {
    setIsOpen(true);
  };

  const handleSelectRelationModalClose = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto"; // enable scrolling
  };

  return (
    <>
      <Typography variant="md" color="white" weight="bold">
        <IconWrapper>
          {/* <StyledFontAwesomeIcon icon={faEdit} style={{ marginLeft: '10px' }}/> */}
          <EditIcon
            handleEditOnClick={handleSelectRelationModalOpen}
            size="26px"
          />
        </IconWrapper>
      </Typography>
      <EditLocationInfoModal
        isOpenModel={isOpen}
        locationName={locationName}
        locationId={locationId}
        onClose={handleSelectRelationModalClose}
        editLocationAddress={editLocationAddress}
      />
    </>
  );
};

export default LocationEditToolTip;
