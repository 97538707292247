import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../Store/store";
import { toggleButtonList } from "../../../data/toggleButtonList";

export const tabListSlice = createSlice({
  name: "tabs",
  initialState: toggleButtonList,
  reducers: {
    toggleSwitchButton: (state, action: PayloadAction<number>) => {
      const switchButtonId = action.payload;
      state.forEach((switchBtn: { id: number; isActive: boolean }) => {
        if (switchBtn.id === switchButtonId) {
          return (switchBtn.isActive = !switchBtn.isActive);
        }
      });
    },
  },
});

export const selectGeneralNotificationToggleBtns = (state: RootState) =>
  state.tabList.filter((tab) => tab.tabName === "General Notifications");
export const selectDeviceNotificationToggleBtns = (state: RootState) =>
  state.tabList.filter((tab) => tab.tabName === "Device Notifications");
export const selectAINotificationToggleBtns = (state: RootState) =>
  state.tabList.filter((tab) => tab.tabName === "AI Notifications");
export const selectLocationNotificationToggleBtns = (state: RootState) =>
  state.tabList.filter((tab) => tab.tabName === "Location Notifications");

export const { toggleSwitchButton } = tabListSlice.actions;
export default tabListSlice.reducer;
