import Select, { MenuPlacement } from "react-select";
import {
  CustomDropdownWrapper,
  customDropdownStyle,
  customDropdownStyleFull,
} from "./CustomDropdown.style";

export interface FilterOption {
  label: string;
  value: string | number;
}
export interface CustomDropdownProps {
  defaultValue?: FilterOption | undefined;
  options: FilterOption[] | undefined;
  title: string;
  isMulti?: boolean;
  menuPlacement?: MenuPlacement;
  isFullSize?: boolean;
  onChange?: ({ label, value }: FilterOption) => void;
}

export const CustomDropdown = ({
  defaultValue,
  options,
  title,
  menuPlacement,
  isFullSize,
  onChange,
}: CustomDropdownProps) => {
  const handleOptionClick = (option: FilterOption | null) => {
    const selectedOption: FilterOption = {
      label: option?.label!,
      value: option?.value!,
    };

    if (onChange) onChange(selectedOption);
  };

  const validateDefaultValue =
    defaultValue == null ||
    defaultValue.label == undefined ||
    defaultValue.label == ""
      ? null
      : defaultValue;
  return (
    <CustomDropdownWrapper>
      <Select
        name={title}
        defaultValue={validateDefaultValue}
        options={options}
        onChange={(option: FilterOption | null) => handleOptionClick(option)}
        placeholder={title}
        menuPlacement={menuPlacement || "bottom"}
        menuPosition="fixed"
        maxMenuHeight={250}
        styles={isFullSize ? customDropdownStyleFull : customDropdownStyle}
      />
    </CustomDropdownWrapper>
  );
};
