import React from "react";
import { CustomChartTabLinks } from "./TabLinks.style";

export interface ChartTabLinksProps {
  className: string;
  onClick: (index: number) => void;
  tabIndex: number;
  children: string;
  active: boolean;
  style?: any;
}
export const ChartTabLinks: React.FC<ChartTabLinksProps> = ({
  children,
  className,
  onClick,
  tabIndex,
  active,
  style,
}) => {
  return (
    <CustomChartTabLinks
      onClick={() => onClick(tabIndex)}
      className={className}
      tabIndex={tabIndex}
      active={active}
      style={style}
    >
      {children}
    </CustomChartTabLinks>
  );
};
