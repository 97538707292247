import axios from "axios";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../Components/notification/Notification";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IntegrationTokens } from "../../Interfaces/Integration/IntegrationTokens";
import authService from "../Auth/auth.service";

const SWRM_BE_API_URL =
  process.env.REACT_APP_SWRMBE_URL + "api/v2/IntegrationCompany";
const SOIL_SCOUT_API_URL =
  process.env.REACT_APP_SWRMBE_URL + "api/v2/Integration";

export interface AuthenticatePayload {
  Username: string;
  Password: string;
  IntegratedWith: string;
}

export interface CredentialsPayload {
  Username: string;
  Password: string;
}

export interface UpdateIntegrationStatusPayload {
  companyId: string;
  integrationId: number;
  status: boolean;
}

// SWRM Service Methods
const getIntegrations = createAsyncThunk(
  "integration/getIntegrations",
  async () => {
    try {
      let response = await axios
        .get(`${SWRM_BE_API_URL}`)
        .then(async (response) => {
          await console.log(response.data);
          return response.data;
        })
        .catch((err: any) => {
          OpenErrorNotification(err.response.data.ErrorMessage);
          return err.response.data;
        });
      return response;
    } catch (error: unknown) {
      return error;
    }
  },
);

const updateIntegrationStatus = createAsyncThunk(
  "integration/updateIntegrationStatus",
  async (payload: UpdateIntegrationStatusPayload) => {
    try {
      let response = await axios
        .patch(
          `${SWRM_BE_API_URL}/updateStatus?companyId=${payload.companyId}&integrationId=${payload.integrationId}&status=${payload.status}`,
        )
        .then(async (response) => {
          OpenSuccessNotification(response.data.Result);
          return response.data;
        })
        .catch((err: any) => {
          OpenErrorNotification(err.response.data.ErrorMessage);
          return err.response.data;
        });
      return response;
    } catch (error: unknown) {
      return error;
    }
  },
);

// Soil Scout Service Methods
const authenticate = createAsyncThunk(
  "integration/authenticate",
  async (payload: AuthenticatePayload) => {
    try {
      const credentialsPayload: CredentialsPayload = {
        Username: payload.Username,
        Password: payload.Password,
      };

      let response = await axios
        .post(`${SOIL_SCOUT_API_URL}/authenticate`, credentialsPayload)
        .then(async (response) => {
          const { refresh, access }: IntegrationTokens = response.data.Result;
          authService.setIntegrationAuthTokenToHeader(access);
          authService.setIntegrationRefreshTokenToHeader(refresh);
          OpenSuccessNotification(`Signed in to ${payload.IntegratedWith}`);
          return response.data;
        })
        .catch((err: any) => {
          OpenErrorNotification(err.response.data.ErrorMessage);
          return err.response.data;
        });
      return response;
    } catch (error: unknown) {
      return error;
    }
  },
);

const refreshSession = createAsyncThunk(
  "integration/refreshSession",
  async () => {
    try {
      let response = await axios
        .post(`${SOIL_SCOUT_API_URL}/refreshSession`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(async (response) => {
          const { refresh, access }: IntegrationTokens = response.data.Result;
          authService.setIntegrationAuthTokenToHeader(access);
          authService.setIntegrationRefreshTokenToHeader(refresh);
          return response.data;
        })
        .catch((err: any) => {
          OpenErrorNotification(err.response.data.ErrorMessage);
          return err.response.data;
        });
      return response;
    } catch (error: unknown) {
      return error;
    }
  },
);

const getLatestSensorData = async (
  deviceId?: string | undefined | null,
  locationId?: number | undefined | null,
) => {
  try {
    const formattedLocationId = locationId ?? 0;
    let response = await axios
      .get(
        `${SOIL_SCOUT_API_URL}/latestSensorData?deviceId=${deviceId}&locationId=${formattedLocationId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
      .then(async (response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((err: any) => {
        OpenErrorNotification(err.response.data.ErrorMessage);
        return err.response.data;
      });
    return response;
  } catch (error: unknown) {
    return error;
  }
};

const getWeeklySensorData = async (
  deviceId: string | undefined | null,
  locationId: number | undefined | null,
) => {
  try {
    const formattedLocationId = locationId ?? 0;
    let response = await axios
      .get(
        `${SOIL_SCOUT_API_URL}/weeklySensorData?deviceId=${deviceId}&locationId=${formattedLocationId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
      .then(async (response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((err: any) => {
        OpenErrorNotification(err.response.data.ErrorMessage);
        return err.response.data;
      });
    return response;
  } catch (error: unknown) {
    return error;
  }
};

export const integrationService = {
  getIntegrations,
  updateIntegrationStatus,
  authenticate,
  refreshSession,
  getLatestSensorData,
  getWeeklySensorData,
};
