import { BsBuilding } from "react-icons/bs";
import Titles from "../../Components/title/Titles";
import { Typography } from "../../Components/typrography/Typography";
import { CompanyOptionsCard } from "./styles/card/CompanyOptionsCard.style";
import {
  CompanyOptionsCardContainer,
  CompanyOptionsContainer,
  CompanyOptionsTextContainer,
} from "./styles/container/CompanyOptionsContainer.style";
import { IoMailOpenOutline } from "react-icons/io5";
import { useState } from "react";
import CompanyOptionsCreationModal from "./modals/CompanyOptionsCreationModal";
import { useAuth0 } from "@auth0/auth0-react";
import InviteModal from "./modals/InviteModal";
import {
  AvatarWrapper,
  DropdownWrapper,
  NavContainer,
  ProfileDropDown,
  TextWrapper,
} from "../../Components/nav/top-nav/TopNavBar.style";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoMdLogOut } from "react-icons/io";
import { DefaultThemeFontSize } from "../../app/Theme";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../Store/hooks";
import { userLogout } from "../../Features/Slice/auth/authSlice";
import { resetNavLink } from "../../Components/nav/side-nav/sideNavBarSlice";
import { setRouteOption } from "../../Features/Slice/routes/routesSlice";
import { Image } from "../../Components/image/Image";
import { awsUtils } from "../../Utils/awsUtils";

const CompanyOptions = () => {
  const [createModelOpen, setCreateModalOpen] = useState(false);
  const [inviteModelOpen, setInviteModalOpen] = useState(false);
  const { user, logout } = useAuth0();
  const dispatch = useDispatch();
  const auth = useAppSelector((state) => state.persisted.auth);
  const handleLogout = () => {
    logout();
    dispatch(userLogout());
    dispatch(resetNavLink());
    dispatch(setRouteOption(0));
  };

  return (
    <CompanyOptionsContainer>
      {/* Add minimal profile for logout button */}
      <NavContainer>
        <ProfileDropDown>
          <DropdownWrapper>
            <AvatarWrapper>
              <Image
                src={awsUtils.getSignedImageURL(
                  auth?.userInfo.MediaURL || user?.picture || "",
                )}
                alt="User Image"
                height="50px"
                width="50px"
              />
            </AvatarWrapper>
            <TextWrapper>
              {auth.userInfo.FirstName !== null &&
              auth.userInfo.LastName !== null
                ? `${auth.userInfo.FirstName} ${auth.userInfo.LastName}`
                : user?.email!}
              <br />
              {auth.userRole}
            </TextWrapper>
            <div className="arrow-icon">
              <MdKeyboardArrowDown size={"36px"} />
            </div>
          </DropdownWrapper>
          <nav style={{ minWidth: "15vw" }}>
            <a href="/" onClick={handleLogout} style={{ width: "100%" }}>
              <IoMdLogOut
                size={DefaultThemeFontSize.lg}
                style={{ marginRight: "5px" }}
              />
              <Typography variant="sm"> LOG OUT</Typography>
            </a>
          </nav>
        </ProfileDropDown>
      </NavContainer>
      <Titles
        heading="Welcome to SWRM"
        subheading="How would you like to get started?"
      />

      <CompanyOptionsCardContainer>
        <CompanyOptionsCard onClick={() => setCreateModalOpen(true)}>
          <BsBuilding color="#FFF" size={90} />
          <CompanyOptionsTextContainer>
            <Typography color="primary" variant="xl" weight="bold">
              Create a Company
            </Typography>
            <Typography>
              Create a company from scratch and start building your
              infrastructure.
            </Typography>
          </CompanyOptionsTextContainer>
        </CompanyOptionsCard>

        <CompanyOptionsCard onClick={() => setInviteModalOpen(true)}>
          <IoMailOpenOutline color="#FFF" size={90} />
          <CompanyOptionsTextContainer>
            <Typography color="primary" variant="xl" weight="bold">
              Get an Invite
            </Typography>
            <Typography>
              Get a company invite and dabble in what's already setup.
            </Typography>
          </CompanyOptionsTextContainer>
        </CompanyOptionsCard>
      </CompanyOptionsCardContainer>
      <CompanyOptionsCreationModal
        isOpen={createModelOpen}
        onClose={() => setCreateModalOpen(false)}
        title="Create a Company"
        buttonText="Create Company"
        companyOwnerId={user?.sub!}
      />
      <InviteModal
        isOpen={inviteModelOpen}
        onClose={() => setInviteModalOpen(false)}
        title="Get an Invite"
      />
    </CompanyOptionsContainer>
  );
};

export default CompanyOptions;
