import React, { useState } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { chargePayment } from "../../../Services/Payment/payment.service";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../Components/notification/Notification";
import {
  increaseSubscriptionPurchaseCount,
  resetSelectedSubscription,
} from "../../../Features/Slice/subscriptions/subscriptionsSlice";
import {
  PaymentCVCExpiryInputGroup,
  PaymentFormGroup,
  PaymentInputLabel,
  StyledPaymentForm,
} from "./PaymentForm.style";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import {
  FormWrapper,
  ScaledButtonWrapper,
} from "../../../Components/modals/CustomModal.style";
import VisaCard from "../../../Components/card/payment-card/VisaCard";
import MastercardIcon from "../../../Components/icons/MastercardIcon";
import AmericanExpress from "../../../Components/icons/AmericanExpress";
import {
  CurrentCardWrapper,
  CurrentPaymentMethodWrapper,
  StyledBsCreditCardFill,
} from "../../../Components/card/payment-card/PaymentCard.style";
import CardBrand from "../Billing/CardBrand";
import PaymentCard from "../../../Components/card/payment-card/PaymentCard";
import Loader from "../../../Components/loader/Loader";

const PaymentElementOptions = {
  style: {
    base: {
      fontWeight: "600",
      fontSize: "16px",
      color: "#fff",
    },
  },
};

const PaymentForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const auth = useAppSelector((state) => state.persisted.auth);
  const selectedSubscription = useAppSelector(
    (state) => state.subscriptions.selectedSubscription,
  );
  const [isPaymentInProgress, setIsPaymentInProgress] =
    useState<boolean>(false);
  const [cardBrand, setCardBrand] = useState("");

  const handleCardNumberChange = (event: any) => {
    // Update cardBrand state based on the card brand detected by Stripe
    setCardBrand(event.brand);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    try {
      setIsLoading(true);
      const cardNumberElement = elements.getElement(CardNumberElement);
      const cardExpiryElement = elements.getElement(CardExpiryElement);
      const cardCvcElement = elements.getElement(CardCvcElement);

      if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
        throw new Error("Card information incomplete");
      }

      const { token, error } = await stripe.createToken(cardNumberElement);

      if (error) {
        throw new Error(error.message);
      }

      // Now you can use the token to make a charge or save it for later use
      const payload = {
        email: /*auth?.user?.email ||*/ "",
        token: token?.id,
        productId: selectedSubscription?.Id,
        description: "SWRM Dashboard Subscription",
      };

      const response = await chargePayment(payload);

      console.log("Payment successful:", response.Value);
      console.log("Payment Method:", response.Value.Result);
      OpenSuccessNotification(response?.Value?.Result);
      dispatch(increaseSubscriptionPurchaseCount());
      dispatch(resetSelectedSubscription());
    } catch (error: any) {
      // Handle errors and show error notification
      setPaymentError(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const setPaymentError = (msg: string | undefined) => {
    OpenErrorNotification(
      msg ?? "An error occurred while processing your payment.",
    );
  };

  return (
    <>
      {isLoading && (
        <Loader
          isLoadingScreen
          loadingText="Please wait while payment is in progress..."
        />
      )}
      <CurrentCardWrapper>
        <PaymentCard
          cardName={"Card"}
          cardIcon={StyledBsCreditCardFill}
          isActive={true}
          isCardList={false}
        />
        <CardBrand Brand={cardBrand} />
      </CurrentCardWrapper>
      <StyledPaymentForm onSubmit={handleSubmit} className="payment-form">
        <FormWrapper>
          <PaymentFormGroup>
            <PaymentInputLabel htmlFor="card-number">
              Card Number
            </PaymentInputLabel>
            <CardNumberElement
              id="card-number"
              className="card-element"
              options={PaymentElementOptions}
              onChange={handleCardNumberChange}
            />
          </PaymentFormGroup>
        </FormWrapper>
        <FormWrapper>
          <PaymentCVCExpiryInputGroup>
            <PaymentInputLabel htmlFor="card-expiry">
              Expiration
            </PaymentInputLabel>
            <CardExpiryElement
              id="card-expiry"
              className="card-element"
              options={PaymentElementOptions}
            />
          </PaymentCVCExpiryInputGroup>
          <PaymentCVCExpiryInputGroup>
            <PaymentInputLabel htmlFor="card-cvc">CVC</PaymentInputLabel>
            <CardCvcElement
              id="card-cvc"
              className="card-element"
              options={PaymentElementOptions}
            />
          </PaymentCVCExpiryInputGroup>
        </FormWrapper>
        <ScaledButtonWrapper buttonPadding={"5px"}>
          <button className="pay-button" type="submit" disabled={!stripe}>
            Pay
          </button>
        </ScaledButtonWrapper>
      </StyledPaymentForm>
    </>
  );
};

export default PaymentForm;
