import React, { useState } from "react";
import { Typography } from "../../../../Components/typrography/Typography";
import ArchiveIcon from "../../../../Components/icons/ArchiveIcon";
import ConfirmationModal from "../../../../Components/modals/confirmation-modal/ConfirmationModal";
import { patchDeviceArchive } from "../../../../Services/DeviceManagement/devicePatch.service";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../../Components/notification/Notification";
import { useAppDispatch } from "../../../../Store/hooks";
import { removeArchiveDevice } from "../../../../Features/Slice/devices/devicesSlice";

type Props = {
  deviceId: string;
  deviceName: string;
};
export default function DeviceArchive({ deviceName, deviceId }: Props) {
  const dispatch = useAppDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState<string>("");
  const handleArchiveOnClick = () => {
    const msg = `Are you sure you'd like to archive the ${deviceName || ""}?`;
    setConfirmMsg(msg);
    setIsConfirmModalOpen(true);
  };

  const handleArchiveDevice = async () => {
    // Archive device and update device states
    const payload = {
      id: deviceId,
      archive: true,
    };
    try {
      const response = await patchDeviceArchive(payload);
      if (response && response.IsError) {
        OpenErrorNotification(response.Result);
      } else {
        OpenSuccessNotification(response.Result);
        setIsConfirmModalOpen(false);
        dispatch(removeArchiveDevice(deviceId));
      }
    } catch (e: any) {
      OpenErrorNotification(e.message);
    }
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  return (
    <>
      <Typography
        variant="md"
        color="white"
        weight="bold"
        padding="1rem 0.2rem"
      >
        <div className="d-flex">
          <ArchiveIcon
            size="1.25rem"
            handleArchiveOnClick={handleArchiveOnClick}
          />
        </div>
      </Typography>

      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        onConfirm={handleArchiveDevice}
        headerTitle={"Archive a Device"}
        confirmMsg={confirmMsg}
        onClose={handleConfirmModalClose}
        modalWidth="30vw"
        minWidth="450px"
      />
    </>
  );
}
