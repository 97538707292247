import React, { useState } from "react";
import { IoSettingsSharp } from "react-icons/io5";
import { Typography } from "../../typrography/Typography";
import CustomModal from "../CustomModal";
import { HorizontalLine } from "../CustomModal.style";
import data from "../../../data/notificationList";
import NotificationList from "../../notification-bar/NotificationList";

export interface NotificationModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  dropdownLabel: string;
  editorLabel: string;
  buttonText: string;
  buttonPadding?: string;
  editorHeight?: number;
  formPadding?: string;
  description: string;
}

const NotificationModal = (props: NotificationModalProps) => {
  const { isOpen, onClose, title } = props;

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      padding="10px 0"
      width="32vw"
      height="88vh"
      justifyContent="flex-end"
      alignItems="flex-end"
      border="0"
    >
      <Typography
        variant="lg"
        direction="flex-start"
        style={{ padding: "0 20px", fontWeight: "700" }}
      >
        {title}
      </Typography>
      <HorizontalLine />
      <NotificationList data={data} />
    </CustomModal>
  );
};

export default NotificationModal;
