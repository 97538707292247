import React, { useState } from "react";
import Input from "../../input/Input";
import TextEditor from "../../rich-text-editor/TextEditor";
import { EditorWrapper } from "../../rich-text-editor/TextEditor.style";
import { Typography } from "../../typrography/Typography";
import CustomModal from "../CustomModal";
import { FormWrapper, ScaledButtonWrapper } from "../CustomModal.style";
import { Formik } from "formik";
import * as Yup from "yup";
import { StyledFormikForm } from "../../tab/ai-model-manager/Upload.style";
import UploadInput from "../../input/upload-input/UploadInput";

export interface ReportBugModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  dropdownLabel: string;
  editorLabel: string;
  buttonText: string;
  buttonPadding?: string;
  editorHeight?: number;
  formPadding?: string;
  description: string;
}

interface FormValues {
  name: string;
  email: string;
  description: string;
}

//We use Yup for object schema validation
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Must be at least 2 characters")
    .max(50, "Must be up to 50 characters")
    .required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const ReportBugModal = (props: ReportBugModalProps) => {
  const {
    isOpen,
    onClose,
    description,
    title,
    buttonText,
    buttonPadding,
    editorLabel,
  } = props;
  const [file, setFile] = useState<File | null>(null);
  const initialValues = {
    name: "",
    email: "",
    description: "",
  };
  const handelSubmit = (values: FormValues, { setSubmitting }: any) => {};

  const handleFileUpload = (uploadedFile: File) => {
    setFile(uploadedFile);
  };
  const cardHeader = () => {
    return (
      <Typography
        variant="lg"
        direction="flex-start"
        style={{ padding: "0 20px", fontWeight: "700" }}
      >
        {title}
      </Typography>
    );
  };
  const cardFooter = () => {
    return (
      <ScaledButtonWrapper buttonPadding={buttonPadding}>
        <button type="submit" form="report-bug-form">
          {buttonText}
        </button>
      </ScaledButtonWrapper>
    );
  };
  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      padding="10px 0"
      width="40vw"
      height="70vh"
      header={cardHeader()}
      footer={cardFooter()}
    >
      {description ? (
        <Typography style={{ padding: "0 20px", fontSize: "0.85vw" }}>
          {description}
        </Typography>
      ) : null}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handelSubmit}
      >
        {({ isSubmitting }: any) => (
          <StyledFormikForm id="report-bug-form">
            <FormWrapper formPadding="0 20px">
              <Input
                name={"name"}
                placeHolder={"Name"}
                label={"Name"}
                width={"100%"}
                height="4.5vh"
                fontSize="1vw"
                inputWrapperWidth="45%"
              />
              <Input
                name={"email"}
                placeHolder={"Email"}
                label={"Email"}
                width={"100%"}
                height="4.5vh"
                fontSize="1vw"
                inputWrapperWidth="45%"
              />
            </FormWrapper>
            <FormWrapper formPadding="0 20px">
              <Input
                name={"browser"}
                placeHolder={"Browser"}
                label={"Browser"}
                width={"100%"}
                height="4.5vh"
                padding="10px 0"
                fontSize="1vw"
                inputWrapperWidth="45%"
              />
              <Input
                name={"system"}
                placeHolder={"System"}
                label={"System"}
                width={"100%"}
                height="4.5vh"
                padding="10px 0"
                fontSize="1vw"
                inputWrapperWidth="45%"
              />
            </FormWrapper>
            <Typography
              variant="lg"
              direction="flex-start"
              style={{ padding: "0 20px", fontWeight: "700", fontSize: "1vw" }}
            >
              {editorLabel}
            </Typography>
            <EditorWrapper>
              <TextEditor />
            </EditorWrapper>
            <UploadInput
              onFileUpload={handleFileUpload}
              variant="input"
              placeHolder={"Upload Image File"}
              label={"Upload Image(Optional)"}
              width={"100%"}
              height={"4.5vh"}
              padding="10px 20px 0"
              fontSize="1vw"
            />
          </StyledFormikForm>
        )}
      </Formik>
    </CustomModal>
  );
};

export default ReportBugModal;
