import { AiFillDelete } from "react-icons/ai";
import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";
import { IoMdDownload } from "react-icons/io";
import { BiSolidArchiveIn, BiSolidArchiveOut } from "react-icons/bi";
import styled from "styled-components";

interface IconProps {
  currentpage?: number;
  lastpage?: number;
  dataLength?: number;
}

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  width: 100%;
`;

export const ButtonIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

export const CheckboxWrapper = styled.div<{ gap?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : "5px")};
  position: relative;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;

  & ul {
    width: 100%;
  }

  & li:hover {
    background-color: #444444;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const AddKeyButtonWrapper = styled(ButtonWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 20px 0px;
`;

export const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const PageIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const StyledBiSolidArchiveIn = styled(BiSolidArchiveIn)`
  font-size: ${(props) => props.theme.fontSizes["lg"]};
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors["primary"]};
  }
`;

export const StyledBiSolidArchiveOut = styled(BiSolidArchiveOut)`
  font-size: ${(props) => props.theme.fontSizes["lg"]};
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors["primary"]};
  }
`;

export const StyledIoMdDownload = styled(IoMdDownload)`
  font-size: ${(props) => props.theme.fontSizes["lg"]};
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors["primary"]};
  }
`;
export const StyledAiFillDelete = styled(AiFillDelete)`
  font-size: ${(props) => props.theme.fontSizes["lg"]};
  &:hover {
    cursor: pointer;
    color: #d64550;
  }
`;

export const IconButton = styled.button`
  padding: 0;
  border: none;
  color: #fff;
  background-color: transparent;
  cursor: pointer;
`;

export const LeftArrowIconWragger = styled.div<IconProps>`
  cursor: ${(props) => (props.currentpage === 1 ? "not-allowed" : "pointer")};
`;

export const RightArrowIconWragger = styled.div<IconProps>`
  cursor: ${(props) =>
    props.lastpage === props.currentpage || props.dataLength == 0
      ? "not-allowed"
      : "pointer"};
`;

export const StyledBsArrowLeftSquare = styled(BsArrowLeftSquare)<IconProps>`
  font-size: ${(props) => props.theme.fontSizes["3xl"]};
  color: #707070;
  pointer-events: ${(props) => (props.currentpage === 1 ? "none" : "auto")};
  &:hover {
    color: ${(props) => props.theme.colors["primary"]};
  }
`;

export const StyledBsArrowRightSquare = styled(BsArrowRightSquare)<IconProps>`
  font-size: ${(props) => props.theme.fontSizes["3xl"]};
  color: #707070;
  pointer-events: ${(props) =>
    props.lastpage === props.currentpage || props.dataLength == 0
      ? "none"
      : "auto"};
  &:hover {
    color: ${(props) => props.theme.colors["primary"]};
  }
`;
