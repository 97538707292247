import React from "react";
import { CustomNavigationContainer } from "./NavigationContainer.style";

export interface NavigationContainerProps {
  children: React.ReactNode;
  direction: "left" | "right";
}

export const NavigationContainer: React.FC<NavigationContainerProps> = ({
  children,
  direction = "left",
}) => {
  return (
    <CustomNavigationContainer direction={direction}>
      {children}
    </CustomNavigationContainer>
  );
};
