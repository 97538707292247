import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { FetchParamsForDevices } from "../../Features/Slice/fetchParameters/fetchParametersSlice";
import qs from "qs";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

export const getAllDevices = createAsyncThunk(
  "device/getAllDevices",
  async (thunkapi) => {
    try {
      const response = await axios
        .get(`${API_URL}/Device`)
        .then(async (response) => {
          await console.log(response.data);
          return response.data;
        });
      return response;
    } catch (error: unknown) {
      return error;
    }
  },
);

export const getDevice = createAsyncThunk(
  "device/getDevice",
  async (deviceId: string) => {
    try {
      let response = await axios
        .get(`${API_URL}/Device/getById?id=${deviceId}`)
        .then(async (response) => {
          await console.log(response.data);
          return response.data;
        });
      return response;
    } catch (error: unknown) {
      return error;
    }
  },
);

export const filterDevices = createAsyncThunk(
  "device/filterDevices",
  async (params: FetchParamsForDevices) => {
    try {
      let response = await axios
        .get(`${API_URL}/Device/filter`, {
          params: params,
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
        })
        .then(async (response) => {
          await console.log("response.data of device : ", response.data);
          return response.data;
        });
      return response;
    } catch (error: unknown) {
      return error;
    }
  },
);

const deviceManService = {
  getAllDevices,
  getDevice,
  filterDevices,
};

export default deviceManService;
