import styled from "styled-components";
import { DefaultThemeFontSize } from "../../app/Theme";

export const PhoneInputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.313rem;
  padding: 1rem 0 1.25rem;

  @media (max-width: 900px) {
    padding: 1rem 0 2rem;
  }
`;

export const PhoneInputLabel = styled.label`
  font-size: ${DefaultThemeFontSize.lg};
  font-weight: bold;
  color: #fff;
`;
