import { toast, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TypeOptions } from "react-toastify";

export interface NotificationProps {
  description: string;
  type?: TypeOptions;
}

const defaultToastProps: ToastOptions = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  draggable: true,
  progress: undefined,
  style: {
    background: "#000",
  },
};

export const showToast = (notificationProps: NotificationProps) => {
  const { description, type } = notificationProps;

  toast(description, {
    ...defaultToastProps,
    type,
  });
};

export const OpenErrorNotification = (description: string) => {
  showToast({ description, type: "error" });
};

export const OpenSuccessNotification = (description: string) => {
  showToast({ description, type: "success" });
};

export const OpenInfoNotification = (description: string) => {
  showToast({ description, type: "info" });
};

export const OpenWarningNotification = (description: string) => {
  showToast({ description, type: "warning" });
};
