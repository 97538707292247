import React from "react";
import { CustomNavigationButton } from "./NavigationButton.style";

export interface NavigationButtonProps {
  children: string | React.ReactNode | React.ReactNode[];
  onClick?: () => void;
  onMouseOver?: () => void;
}

export const NavigationButton: React.FC<NavigationButtonProps> = ({
  children,
  onClick,
  onMouseOver,
}) => {
  return (
    <CustomNavigationButton onClick={onClick}>
      {children}
    </CustomNavigationButton>
  );
};
