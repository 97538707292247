import { IconContext } from "react-icons";
import React from "react";

interface IconContextProps {
  children: React.ReactNode[] | React.ReactNode;
  color?: string;
}

const ConfigIcon: React.FC<IconContextProps> = ({ children, color }) => {
  return (
    <>
      <IconContext.Provider
        value={{ size: "1rem", color: color ? color : "707070" }}
      >
        {children}
      </IconContext.Provider>
    </>
  );
};

export default ConfigIcon;
