import React, { useEffect } from "react";
import {
  IconSection,
  SortbyIcon,
  StyledFaSortAmountDownAlt,
  StyledFaSortAmountUp,
} from "./SortBy.style";
import { SubTitle } from "../title/Titles.styles";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import {
  resetSortByState,
  selectAllSortByIcons,
  setSortBysForCaptures,
  switchSortBy,
} from "./sortBySlice";
import { DefaultThemeColor } from "../../app/Theme";
import {
  StyledBiSolidMoviePlay,
  StyledFaImage,
} from "../filter-options/FilterOptions.style";
import { Tooltip } from "react-tooltip";
import { resetAllCheckedStatus } from "../../Features/Slice/captures/captureSlice";

export interface SortByProps {
  onChange?: Function | null;
  page?: "captures";
  isForCaptureTypes?: boolean | null;
}

const SortBy: React.FC<SortByProps> = ({
  onChange,
  page,
  isForCaptureTypes,
}: SortByProps) => {
  const sortByIcons = useAppSelector(selectAllSortByIcons);
  const dispatch = useAppDispatch();

  const setSortByTitle = () => {
    if (page === "captures" && !isForCaptureTypes) {
      return "Sort By Date";
    } else if (page === "captures" && isForCaptureTypes) {
      return "Sort By Type";
    } else {
      return "Sort By";
    }
  };

  const handleBtnClick = (sortByItem: any) => {
    if (sortByItem.id === 3 || sortByItem.id === 4) {
      dispatch(resetAllCheckedStatus());
    }
    dispatch(switchSortBy(sortByItem.id));
    onChange && onChange(sortByItem);
  };

  useEffect(() => {
    dispatch(resetSortByState());
    if (page === "captures") dispatch(setSortBysForCaptures());
  }, []);

  return (
    <>
      <SubTitle>{setSortByTitle()}</SubTitle>
      <IconSection>
        {sortByIcons.map(
          (sortByItem: { id: number; name: string; isActive: boolean }) =>
            sortByItem.name === "descending" && !isForCaptureTypes ? (
              <SortbyIcon
                key={sortByItem.id}
                isActive={sortByItem.isActive}
                onClick={() =>
                  !sortByItem.isActive && handleBtnClick(sortByItem)
                }
              >
                <StyledFaSortAmountDownAlt
                  size="1.25rem"
                  color={
                    sortByItem.isActive ? DefaultThemeColor["primary"] : ""
                  }
                  data-tooltip-id="asc-tooltip"
                  data-tooltip-content={
                    page == "captures" && !isForCaptureTypes
                      ? "Newest to Oldest"
                      : "Z-A"
                  }
                  data-tooltip-place="top"
                />
                <Tooltip id="desc-tooltip" />
              </SortbyIcon>
            ) : sortByItem.name === "ascending" && !isForCaptureTypes ? (
              <SortbyIcon
                key={sortByItem.id}
                isActive={sortByItem.isActive}
                onClick={() =>
                  !sortByItem.isActive && handleBtnClick(sortByItem)
                }
              >
                <StyledFaSortAmountUp
                  size="1.25rem"
                  color={
                    sortByItem.isActive ? DefaultThemeColor["primary"] : ""
                  }
                  data-tooltip-id="asc-tooltip"
                  data-tooltip-content={
                    page == "captures" && !isForCaptureTypes
                      ? "Oldest to Newest"
                      : "A-Z"
                  }
                  data-tooltip-place="top"
                />
                <Tooltip id="asc-tooltip" />
              </SortbyIcon>
            ) : sortByItem.name === "image" && isForCaptureTypes ? (
              <SortbyIcon
                key={sortByItem.id}
                isActive={sortByItem.isActive}
                onClick={() =>
                  !sortByItem.isActive && handleBtnClick(sortByItem)
                }
              >
                <StyledFaImage
                  size="1.25rem"
                  color={
                    sortByItem.isActive ? DefaultThemeColor["primary"] : ""
                  }
                  data-tooltip-id="byImage-tooltip"
                  data-tooltip-content="Sort By Image"
                  data-tooltip-place="top"
                />
                <Tooltip id="byImage-tooltip" />
              </SortbyIcon>
            ) : (
              sortByItem.name === "video" &&
              isForCaptureTypes && (
                <SortbyIcon
                  key={sortByItem.id}
                  isActive={sortByItem.isActive}
                  onClick={() =>
                    !sortByItem.isActive && handleBtnClick(sortByItem)
                  }
                >
                  <StyledBiSolidMoviePlay
                    size="1.25rem"
                    color={
                      sortByItem.isActive ? DefaultThemeColor["primary"] : ""
                    }
                    data-tooltip-id="byImage-tooltip"
                    data-tooltip-content="Sort By Videos"
                    data-tooltip-place="top"
                  />
                  <Tooltip id="byVideo-tooltip" />
                </SortbyIcon>
              )
            ),
        )}
      </IconSection>
    </>
  );
};

export default SortBy;
