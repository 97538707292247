import React from "react";
import { DeviceStatisticsWrapper } from "../../../../Components/device-manager-elements/DeviceManagerMain.style";
import { useAppSelector } from "../../../../Store/hooks";
import { Typography } from "../../../../Components/typrography/Typography";
import DeviceName from "../../../../Pages/deviceManager/manage/info-card-device-manager/DeviceName";

export const Boards = () => {
  const { selectedDevice } = useAppSelector((state) => state.devices);
  return !!selectedDevice.ChildDevices?.length ? (
    <DeviceStatisticsWrapper>
      <Typography variant="lg" weight="bold" color="yellow-100">
        Connected Sensor Board(s)
      </Typography>
      <div style={{ padding: "1rem 2rem" }}>
        {selectedDevice.ChildDevices.map((d) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="md" weight="light">
              {d.Name} &nbsp;
            </Typography>
            <span>({d.PiSerial})</span>
            <DeviceName
              deviceName={d.Name}
              deviceId={d.Id}
              masterDeviceId={selectedDevice.Id || ""}
            />
          </div>
        ))}
      </div>
    </DeviceStatisticsWrapper>
  ) : (
    <Typography color="red-100" variant="lg">
      No sensor board connected
    </Typography>
  );
};
