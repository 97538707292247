import React from "react";
import { FlexContainer } from "../../flex-container/FlexContainer";
import { Image } from "../../image/Image";
import {
  CardContent,
  CustomImageCard,
  Header,
  SecondaryHeader,
} from "./ThumbnailCard.style";
import { BsCheck2Circle } from "react-icons/bs";
import { BiSignal5 } from "react-icons/bi";
import { Typography } from "../../typrography/Typography";
import { IDeviceCard } from "../../../Types/captures/captures";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleXmark,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { DefaultThemeColor } from "../../../app/Theme";

export interface Props {
  children?: React.ReactNode[];
  onMouseEnter?: () => void;
  data: IDeviceCard;
  onClick?: any;
}

export const ThumbnailCard: React.FC<Props> = ({
  onMouseEnter,
  data,
  onClick,
}) => {
  return (
    <CustomImageCard onMouseEnter={onMouseEnter} onClick={onClick}>
      {/* image */}
      <Image
        src={data.image}
        alt="device_image"
        height={"60%"}
        width={"100%"}
      />
      {/* card content */}
      <CardContent>
        <FlexContainer
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="space-between"
          height="100%"
        >
          <div>
            {/* card Header */}
            <Header>{data.header}</Header>
            <Typography variant="xs" padding="0.25rem 0">
              {data.dataId && <div>ID: {data.dataId}</div>}
            </Typography>
          </div>
          <FlexContainer width="100%" justifyContent="space-between">
            <SecondaryHeader>
              <Typography variant="xs" display="flex">
                {data.connectionStatus} &nbsp;
                <FontAwesomeIcon
                  icon={
                    data.connectionStatus === "Connected"
                      ? faCheckCircle
                      : data.connectionStatus === "Out of Date"
                      ? faExclamationCircle
                      : faCircleXmark
                  }
                  color={
                    data.connectionStatus === "Connected"
                      ? "#80D39B"
                      : data.connectionStatus === "Out of Date"
                      ? DefaultThemeColor["primary"]
                      : "#D64550"
                  }
                />
              </Typography>
            </SecondaryHeader>
          </FlexContainer>

          {/* card footer */}
        </FlexContainer>
      </CardContent>
      {/* end card content */}
    </CustomImageCard>
  );
};
