import React from "react";
import { CustomNavigationBackground } from "./NavigationBackground.style";

export interface navigationBackgroundProps {
  children: React.ReactNode | React.ReactNode[];
  direction: "left" | "right";
  onMouseOver: () => void;
  onMouseOut: () => void;
}

export const NavigationBackground: React.FC<navigationBackgroundProps> = ({
  children,
  direction,
  onMouseOver,
  onMouseOut,
}) => {
  return (
    <CustomNavigationBackground
      direction={direction}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      {children}
    </CustomNavigationBackground>
  );
};
