import styled from "styled-components";

export const ControlButtons = styled.div`
  display: flex;
  gap: 4px;
  max-width: 100%;
  font-size: 0.65vw;
`;
export const DeviceDate = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
`;
