import { useState } from "react";
import { Typography } from "../../../../Components/typrography/Typography";
import EditLocationInfoModal from "../../../../Components/modals/edit-location-name-modal/EditLocationInfoModal";
import DeleteIcon from "../../../../Components/icons/DeleteIcon";
import ArchiveIcon from "../../../../Components/icons/ArchiveIcon";
import DeleteLocationModal from "../../../../Components/modals/delete-location-modal/DeleteLocationModal";

type Props = {
  locationId: number;
};

const LocationToolTipGroup = ({ locationId }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isArchiveLocation, setIsArchiveLocation] = useState(false);

  const handleSelectRelationModalOpen = () => {
    setIsOpen(true);
  };

  const handleSelectRelationModalClose = () => {
    setIsOpen(false);
    setIsArchiveLocation(false);
    document.body.style.overflow = "auto"; // enable scrolling
  };

  return (
    <>
      <Typography variant="md" color="white" weight="bold">
        <div>
          {/* <StyledFontAwesomeIcon icon={faEdit} style={{ marginLeft: '10px' }}/> */}
          <ArchiveIcon
            handleArchiveOnClick={() => {
              setIsArchiveLocation(true);
              handleSelectRelationModalOpen();
            }}
            size="26px"
          />
        </div>
      </Typography>
      <Typography variant="md" color="white" weight="bold">
        <div>
          {/* <StyledFontAwesomeIcon icon={faEdit} style={{ marginLeft: '10px' }}/> */}
          <DeleteIcon
            handleDeleteOnClick={handleSelectRelationModalOpen}
            size="26px"
          />
        </div>
      </Typography>
      <DeleteLocationModal
        isOpenModel={isOpen}
        locationId={locationId}
        onClose={handleSelectRelationModalClose}
        archiveLocation={isArchiveLocation}
      />
    </>
  );
};

export default LocationToolTipGroup;
