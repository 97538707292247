import React from "react";
import { CustomButton } from "./Button.style";
//test
export interface ButtonProps {
  children: string | React.ReactNode;
  disabled?: boolean;
  size?: "xs" | "small" | "medium" | "large";
  endIcon?: React.ReactNode;
  fullWidth?: boolean;
  color?: "primary" | "secondary" | "danger" | "success";
  variant?: "outlined" | "filled" | "text";
  onClick?: () => void;
  scaled?: boolean;
  borderRadius?: string;
  width?: string;
  height?: string;
  style?: any;
  hoverColor?: string;
  type?: "button" | "submit" | "reset";
}

export const Button: React.FC<ButtonProps> = ({
  children,
  fullWidth = false,
  color = "primary",
  variant = "filled",
  size = "medium",
  scaled,
  borderRadius,
  width,
  height,
  style,
  hoverColor,
  onClick,
  type,
}) => {
  return (
    <CustomButton
      fullWidth={fullWidth}
      color={color}
      variant={variant}
      size={size}
      scaled={scaled}
      borderRadius={borderRadius}
      width={width}
      height={height}
      style={style}
      hoverColor={hoverColor}
      onClick={onClick}
    >
      {children}
    </CustomButton>
  );
};
