import React from "react";

export interface IDropdown {
  id: number;
  menuType: string;
  question: string;
  answer: JSX.Element | string;
}

const helpDataList: IDropdown[] = [
  // {
  //   id: 1,
  //   menuType: "general",
  //   question: "What is this SWRM dashboard about?",
  //   answer: "",
  // },
  // {
  //   id: 2,
  //   menuType: "general",
  //   question: "How do you monitor and protect my data?",
  //   answer: "",
  // },
  {
    id: 3,
    menuType: "general",
    question: "Do you have app integrations?",
    answer:
      "Yes, you can access integrations through the left navigation menu. Integrations are near the bottom of the list.",
  },
  {
    id: 4,
    menuType: "general",
    question: "How do I reset my password and account information?",
    answer:
      "On the drop down menu of your profile image, navigate to 'Account'. Here you can modify your account information.",
  },
  {
    id: 5,
    menuType: "general",
    question:
      "I submitted a request for support, when can I expect to contacted",
    answer: "Support requests can take up to 3 business days",
  },
  {
    id: 6,
    menuType: "general",
    question:
      "I have questions that are not covered on this page, who can I contact?",
    answer: (
      <span>
        Please send an email to{" "}
        <a href="mailto:support@swrm.ai" target="_blank" rel="support@swrm.ai">
          support@swrm.ai
        </a>
        .
      </span>
    ),
  },
  {
    id: 7,
    menuType: "general",
    question: "What subscription options do you offer?",
    answer: (
      <span>
        We offer various subscription tiers, you can view information about
        them. If they do not provide what you are looking for, please contact
        our sales team at{" "}
        <a href="mailto:sales@bsdxr.com" target="_blank" rel="sales@bsdxr.com">
          sales@bsdxr.com
        </a>
        .
      </span>
    ),
  },
  {
    id: 8,
    menuType: "general",
    question: "What payment methods do you offer?",
    answer:
      "We process payments through stripe which accepts all major credit cards, wallets and debit transactions.",
  },
  {
    id: 9,
    menuType: "general",
    question: "How do I renew my subscription or add my billing information?",
    answer:
      "On the drop down menu of your profile image, navigate to 'Settings'. Here you can modify your account information, notifications, security and billing. ",
  },
  {
    id: 10,
    menuType: "general",
    question: "How do I transfer or delete my account?",
    answer: (
      <span>
        To remove or transfer ownership of your account please contact{" "}
        <a href="mailto:support@swrm.ai" target="_blank" rel="support@swrm.ai">
          support@swrm.ai
        </a>
        . It can take up to 3 business days to update.
      </span>
    ),
  },
  {
    id: 11,
    menuType: "general",
    question: "How do I report a bug?",
    answer:
      "You can report a bug by using the report a bug feature in the left navigation menu.",
  },
  {
    id: 12,
    menuType: "deviceIssues",
    question: "What's the difference between Pairing and Groups?",
    answer: "",
  },
  {
    id: 13,
    menuType: "deviceIssues",
    question: "How can I configure my device after deployment?",
    answer: "",
  },
  {
    id: 14,
    menuType: "captureIssues",
    question: "What is the purpose of the Captures Page?",
    answer:
      "Captures is where you can view, archive, and download your devices captured frames and videos.",
  },
  {
    id: 15,
    menuType: "captureIssues",
    question: "Where do my archived Frames go?",
    answer:
      "Your archived frames get stored into a separate tab where you can organize any important captures your devices have taken.",
  },
  {
    id: 16,
    menuType: "captureIssues",
    question: "What do the yellow and red outlines indicate?",
    answer:
      "Yellow indicates a concern for the highlighted frame. Concern is raised when the capture is either of moderate to low confidence or moderate to high confidence (Depending on the model and settings by the user). For example, a high confidence on a frame using an AI model to capture pests at your job site could be highlighted in yellow, but an AI model meant to track people could also be highlighted yellow if it's confidence is low. In both situations the confidence value is important depending on what it implies.",
  },
  {
    id: 17,
    menuType: "captureIssues",
    question: "How can I find out where this frame was taken?",
    answer:
      "Attached to every frame is data pertaining to what device captured it, what job site it was taken at, what time of day it was, as well as what model captured it.",
  },
  {
    id: 18,
    menuType: "captureIssues",
    question: "What is AI Confidence?",
    answer:
      "AI Confidence is the degree to which an AI determines how likely it is, that it captured what it was trained to capture. It is up to the user to determine whether a high confidence is of concern, or a low confidence is.",
  },
  {
    id: 19,
    menuType: "locationIssues",
    question: "What is the Location page?",
    answer:
      "The Location page is where you can create and manage locations within your company. Through this page you can add upcoming job sites, or remove projects that have been completed. Additionally, you can create groups for your locations that allow you to create complex communication networks between your devices.",
  },
  {
    id: 20,
    menuType: "locationIssues",
    question: "How do I add a location?",
    answer:
      "To add a location navigate to the Create Tab on the Locations page. It will pop open a window for you to name, describe, and upload a thumbnail of your location. From there you can go to the Manage Tab, and begin applying groups, and organizing your work site as you deem fit.",
  },
  {
    id: 21,
    menuType: "locationIssues",
    question: "What is a group?",
    answer:
      "A group is how we create our complex communication networks between devices. Groups can be set to be either Universal or Unique. ",
  },
  {
    id: 22,
    menuType: "locationIssues",
    question: "What is the difference between unique and universal?",
    answer:
      "A Unique group, means that the group is only found at this one location. Any devices paired with this group will only receive information from devices at this location, within this group. A Universal group means that the group can be found at any or all of your locations. This will allow devices to communicate with other devices from different job sites that share this group.",
  },
  {
    id: 23,
    menuType: "locationIssues",
    question: "Can I edit a location after it's been created?",
    answer:
      "Yes you can change a locations name, location type, and thumbnail after it's creation if needed.",
  },
  {
    id: 24,
    menuType: "locationIssues",
    question: "Can I remove a location after it's been created?",
    answer:
      "Yes, when a location no longer exists, or the work has been completed you can remove a location from your account.",
  },
  {
    id: 25,
    menuType: "locationIssues",
    question: "Can I edit a group after it's been created?",
    answer: "No, a group cannot be edited after being created at this time.",
  },
  {
    id: 26,
    menuType: "locationIssues",
    question: "Can I remove a group after it's been created?",
    answer:
      "You cannot delete a group, but you can remove a group from a location if it's been applied to one. To do this, click on the group of choice, and in the area where it says “Select Group” set it to none.",
  },
  {
    id: 27,
    menuType: "permissionIssues",
    question: "What is the Permissions page for?",
    answer:
      "The Permissions page is where a team manager can delegate permissions to it's employees to operate the IOT Dashboard and manage their company devices. There are a plethora of permission options that allow a manager to have precise control over what their employees can and cannot do.",
  },
  {
    id: 28,
    menuType: "permissionIssues",
    question: "How do I add or remove a new team member?",
    answer:
      "A team member can be added to your team by creating a new unique account and specifying the company name in the Company input field. To remove a team member, navigate to the permissions tab within the permissions page, and turn off all permissions and delete the team members account via internal management.",
  },
  {
    id: 29,
    menuType: "integrationIssues",
    question: "What is Integration?",
    answer:
      "The Integration page is where we provide documentation for our custom API's to integrate into your own proprietary tools. This way you can have access to all of the power and functionality that is provided by SWRM.",
  },
  {
    id: 30,
    menuType: "integrationIssues",
    question: "Where can I check the status of SWRM's API?",
    answer:
      "You can check to see the live status of our API's within the Status Tab of the Integration page. Here you will find information regarding our API's historic uptime and any current service outages.",
  },
  {
    id: 31,
    menuType: "integrationIssues",
    question: "What is Token Generation?",
    answer:
      "Token Generation is where you can create unique access tokens for your devices when registering them to our service",
  },
  {
    id: 32,
    menuType: "aiModelIssues",
    question: "Why is my custom model not uploading?",
    answer: (
      <span>
        Your custom model may not be uploading due to having the incorrect file
        types within its .zip folder.The supported file types for a model are:{" "}
        <ul>
          <li>.txt</li>
          <li>.uff</li>
          <li>.eng</li>
          <li>.SHA</li>
        </ul>
      </span>
    ),
  },
  {
    id: 33,
    menuType: "aiModelIssues",
    question: "Can I retrain a duplicated model?",
    answer:
      "Yes, you can duplicate an AI and retrain it into a different permutation of its parent.",
  },
  {
    id: 34,
    menuType: "aiModelIssues",
    question: "Can I duplicate a duplicate?",
    answer: "Yes, you can duplicate a duplicate as many times as you want!",
  },
  {
    id: 35,
    menuType: "aiModelIssues",
    question: "How does retraining my AI work?",
    answer:
      "Retraining your AI allows the AI to run through a new set of imagery and instructions to increase its precision and confidence when capturing its frames.",
  },
  {
    id: 36,
    menuType: "aiModelIssues",
    question: "What do I need to retrain my AI?",
    answer: (
      <span>
        To retrain you will need a zip file that contains the following file
        formats:{" "}
        <ul>
          <li>.txt Labels File</li>
          <li>.uff File</li>
          <li>.eng File</li>
          <li>.SHA File</li>
        </ul>
      </span>
    ),
  },
  {
    id: 37,
    menuType: "legal",
    question: "Terms of Use",
    answer: "Our terms of use",
  },
  {
    id: 38,
    menuType: "legal",
    question: "Privacy Policy",
    answer: "Our privacy Policy",
  },
];

export default helpDataList;
