import React from "react";
import { RadialBarChart, RadialBar, ResponsiveContainer } from "recharts";

export interface CircleProgressBarProps {
  name: string;
  uv: number;
  fill: string;
  size: number;
  pct: number;
  fontSize?: string;
}

const CircleProgressBar = ({
  name,
  uv,
  fill,
  size,
  pct,
}: CircleProgressBarProps) => {
  const data = [
    {
      name: name,
      uv: uv,
      fill: fill,
    },
  ];
  return (
    <ResponsiveContainer
      width={size}
      height={"100%"} //percentage
    >
      <RadialBarChart
        innerRadius={size / 3}
        outerRadius={size / 2}
        barSize={size / 4}
        data={data}
        startAngle={0}
        endAngle={360 * pct}
      >
        <RadialBar
          label={{ position: "insideRight", fill: "#fff" }}
          background
          dataKey="uv"
        />
        <text
          x={size / 2}
          y={size / 2}
          textAnchor="middle"
          dominantBaseline="middle"
          className="progress-label"
          fontSize="0.8rem"
          fill="white"
        >
          {name}
        </text>
      </RadialBarChart>
    </ResponsiveContainer>
  );
};

export default CircleProgressBar;
