import styled from "styled-components";
import { DefaultThemeBorder, DefaultThemeFontSize } from "../../app/Theme";

interface SelectWrapperProps {
  padding?: string;
}

export const SelectWrapper = styled.div<SelectWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.313rem;
  padding: ${(props) => (props.padding ? props.padding : "1.25rem 0 1.625rem")};

  @media (max-width: 900px) {
    padding: 1.25rem 0 2rem;
  }
`;

export const SelectLabel = styled.label`
  font-size: ${DefaultThemeFontSize.lg};
  font-weight: bold;
  color: #fff;
`;

export const SelectErrorMsg = styled.div`
  position: absolute;
  bottom: 0.37rem;
  color: #d64550;
  font-size: ${(props) => props.theme.fontSizes["md"]};
  font-weight: 400;

  @media (max-width: 1920px) {
    bottom: 0.28rem;
  }

  @media (max-width: 1365px) {
    bottom: 0.1rem;
  }

  @media (max-width: 1025px) {
    bottom: 0.05rem;
  }

  @media (max-width: 900px) {
    bottom: 0.32rem;
  }

  @media (max-width: 765px) {
    bottom: 0.05rem;
  }
`;

// Select component from react-select package styling
export const selectStyle = {
  control: (provided: any, state: any) => ({
    ...provided,
    height: "2.25rem",
    backgroundColor: "#020A01",
    border: DefaultThemeBorder["border-sm-yellow"],
    borderRadius: "5px",
    fontSize: DefaultThemeFontSize.sm,
    boxShadow: "none",
    cursor: "pointer",

    "&:hover": {
      borderColor: "#00916E",
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    width: "75%",
    backgroundColor: "#020A01",
    border: DefaultThemeBorder["border-sm-yellow"],
    borderRadius: "5px",
    outline: "none",
  }),
  input: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? "#000" : "#FFF",
    cursor: "pointer",
    backgroundColor: state.isSelected && "#EDAE49",

    "&:hover": {
      backgroundColor: "#EDAE49",
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#B5B5B5",
  }),
};
