import React from "react";
import StyledLink from "./Link.style";

export interface LinkProps {
  children: any;
  href: string;
  color?: string;
  hoverColor?: string;
  target?: string;
}

const Link = ({ color, hoverColor, children, href, target }: LinkProps) => {
  return (
    <StyledLink
      color={color}
      hoverColor={hoverColor}
      href={href}
      target={target}
    >
      {children}
    </StyledLink>
  );
};

export default Link;
