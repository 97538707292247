import styled from "styled-components";
import { RelayBoxBlock, RelayEdit, RelayTitle } from "../relays/RelayBox.style";

export const GroupBox = styled(RelayBoxBlock)`
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
export const GroupDelete = styled(RelayEdit)`
  position: absolute;
  top: 0.725rem;
  right: 1rem;
`;
export const GroupTitle = styled(RelayTitle)``;
