import React, { useEffect, useState } from "react";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "../../../../Components/typrography/Typography";
import { StyledFontAwesomeIcon } from "../../../../Components/device-manager-card/card-header/CardHeader.style";
import DeviceNameModal from "../../../../Components/modals/edit-device-name-modal/EditDeviceNameModal";
import { IconWrapper } from "./DeviceName.style";

type Props = {
  masterDeviceId?: string;
  deviceId: string;
  deviceName: string;
};

export default function DeviceName({
  masterDeviceId,
  deviceName,
  deviceId,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectRelationModalOpen = () => {
    setIsOpen(true);
  };

  const handleSelectRelationModalClose = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto"; // enable scrolling
  };

  return (
    <>
      <Typography
        variant="md"
        color="white"
        weight="bold"
        // onClick={onClick ? () => onClick(index) : () => {}}
        onClick={() => handleSelectRelationModalOpen()}
        // onMouseOver={onMouseOver ? () => onMouseOver() : () => {}}
      >
        <IconWrapper>
          {/* <StyledFontAwesomeIcon icon={faEdit} style={{ marginLeft: '10px' }}/> */}
          <StyledFontAwesomeIcon icon={faEdit} cursor="pointer" />
        </IconWrapper>
      </Typography>
      <DeviceNameModal
        isOpenModel={isOpen}
        deviceName={deviceName}
        deviceId={deviceId}
        masterDeviceId={masterDeviceId || ""}
        onClose={handleSelectRelationModalClose}
      />
    </>
  );
}
