import React from "react";
import { Typography } from "../../typrography/Typography";
import { ApiDropdownListWrapper } from "./ApiDropdownList.style";
import { IApiData } from "../../../data/integrationApiList";
import { DefaultThemeColor, DefaultThemeBorder } from "../../../app/Theme";
import { CustomDropdown } from "../../form-field/drop-down/CustomDropdown";

interface ApiDropdownProps {
  apiData: IApiData;
}

const ApiDropdown = ({ apiData }: ApiDropdownProps) => {
  const {
    id,
    name,
    description,
    getApi,
    postApi,
    patchApi,
    putApi,
    deleteApi,
  } = apiData;
  return (
    <ApiDropdownListWrapper>
      <Typography
        variant="lg"
        display="flex"
        weight="black"
        direction="flex-start"
      >
        {name}{" "}
      </Typography>
      <Typography
        variant="md"
        display="flex"
        color="primary"
        direction="flex-start"
      >
        {description}
      </Typography>
      {postApi.map((item, index) => (
        <CustomDropdown
          key={index}
          width="80%"
          alignItems="flex-start"
          verticalLine={true}
          backgroundColor="#80D39B21"
          borderTheme="1px solid #80D39B"
          iconColor="white"
          label={item.apiName}
          options={[]}
        />
      ))}
      {getApi.map((item, index) => (
        <CustomDropdown
          key={index}
          width="80%"
          alignItems="flex-start"
          verticalLine={true}
          backgroundColor="#428CEF33"
          borderTheme="1px solid #428CEF"
          iconColor="white"
          label={item.apiName}
          options={[]}
          // methodBgColor={"#428CEF"}
        />
      ))}
      {putApi.map((item, index) => (
        <CustomDropdown
          key={index}
          width="80%"
          alignItems="flex-start"
          verticalLine={true}
          backgroundColor={`${DefaultThemeColor["primary"]}33`}
          borderTheme={DefaultThemeBorder["primary-sm"]}
          iconColor="white"
          label={item.apiName}
          options={[]}
        />
      ))}
      {patchApi.map((item, index) => (
        <CustomDropdown
          key={index}
          width="80%"
          alignItems="flex-start"
          verticalLine={true}
          backgroundColor="#CF7FE200"
          borderTheme="1px solid #A248A6"
          iconColor="white"
          label={item.apiName}
          options={[]}
        />
      ))}
      {deleteApi.map((item, index) => (
        <CustomDropdown
          key={index}
          width="80%"
          alignItems="flex-start"
          verticalLine={true}
          backgroundColor="#D6455033"
          borderTheme="1px solid #D64550"
          iconColor="white"
          label={item.apiName}
          options={[]}
        />
      ))}
    </ApiDropdownListWrapper>
  );
};

export default ApiDropdown;
