import { MdEdit } from "react-icons/md";
import styled from "styled-components";

export const AccountWrapper = styled.div`
  display: flex;
  padding: 0 2vw;
  flex-direction: column;
  width: 100%;
`;

export const PhotoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AvatarWrapper = styled.div`
  position: relative;
  display: flex;
  height: 100px;
  width: 100px;
  border-radius: 100px;
  cursor: pointer;
  img {
    object-fit: cover;
    border-radius: 50px;
  }
  & div {
    position: absolute;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background: ${(props) => props.theme.colors["primary"]};
    bottom: 0;
    right: 5px;
    font-size: ${(props) => props.theme.fontSizes["xs"]};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & div:hover {
    background: ${(props) => props.theme.colors["green-100"]};
  }
`;

export const StyledMdEdit = styled(MdEdit)`
  font-size: ${(props) => props.theme.fontSizes["lg"]};
  margin: 0.3rem;
  color: ${(props) => props.theme.colors["grey-800"]};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2vw 0;
`;

export const InputButtonWrapper = styled.div`
  display: flex;
  width: 45%;
  position: relative;
  height: fit-content;
`;
