import styled from "styled-components";
import { ContainerProps } from "./Container";

export const CustomContainer = styled.div<ContainerProps>`
  display: flex;
  background: ${(props) =>
    props.noBackground ? "#020A01" : props.theme.colors["primary"]};
  min-height: 100vh;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100vw;
`;
