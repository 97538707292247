import React from "react";
import { StyledCheckbox } from "./Checkbox.style";
import { Tooltip } from "react-tooltip";

interface CheckboxProps {
  isChecked: boolean;
  isDisabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: number | string;
  width?: string;
  height?: string;
  tooltipId?: string;
  tooltipContent?: string;
}

const Checkbox = ({
  isChecked,
  isDisabled,
  onChange,
  value,
  width,
  height,
  tooltipId,
  tooltipContent,
}: CheckboxProps) => {
  return (
    <>
      <StyledCheckbox
        checked={isChecked}
        disabled={isDisabled}
        value={value}
        onChange={onChange}
        width={width}
        height={height}
        data-tooltip-id={tooltipId && tooltipId}
        data-tooltip-content={tooltipContent && tooltipContent}
        data-tooltip-place="top"
      />

      {tooltipId && tooltipContent && <Tooltip id={tooltipId} />}
    </>
  );
};

export default Checkbox;
