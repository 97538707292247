import React from "react";
import VisaCard from "../../../Components/card/payment-card/VisaCard";
import MastercardIcon from "../../../Components/icons/MastercardIcon";
import AmericanExpress from "../../../Components/icons/AmericanExpress";
import JCBIcon from "../../../Components/icons/JCBIcon";
import UnionPayIcon from "../../../Components/icons/UnionPayIcon";
import DiscoverIcon from "../../../Components/icons/DiscoverIcon";
import DinersClubIcon from "../../../Components/icons/DinersClubIcon";
interface Props {
  Brand: string;
}
export default function CardBrand({ Brand }: Props) {
  return (
    <>
      {Brand.toLocaleLowerCase() === "visa" && <VisaCard cardType={"Visa"} />}
      {Brand.toLocaleLowerCase() === "mastercard" && <MastercardIcon />}
      {["amex", "american express"].includes(Brand.toLocaleLowerCase()) && (
        <AmericanExpress />
      )}
      {Brand.toLocaleLowerCase() === "jcb" && <JCBIcon />}
      {Brand.toLocaleLowerCase() === "unionpay" && <UnionPayIcon />}
      {Brand.toLocaleLowerCase() === "discover" && <DiscoverIcon />}
      {Brand.toLocaleLowerCase() === "diners" && <DinersClubIcon />}
    </>
  );
}
