import React, { useState } from "react";
import Checkbox from "../checkbox/Checkbox";
import { StyledIoMdDownload, StyledTable } from "./Table.style";
import {
  IconButton,
  StyledAiFillDelete,
  StyledBiSolidArchiveIn,
  StyledBiSolidArchiveOut,
} from "./table-button/TableButton.style";
import { ICaptureImageCard } from "../../Types/captures/captures";
import ConfirmationModal from "../modals/confirmation-modal/ConfirmationModal";
import {
  archiveCapture,
  handleDownloadCapture,
  softDeleteCapture,
} from "../../Services/Captures/capture.service";
import { useAppDispatch } from "../../Store/hooks";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Tooltip } from "react-tooltip";

interface TableProps {
  columns: string[];
  isArchivedData?: boolean;
  data: ICaptureImageCard[];
  handleItemCheckChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CaptureImageVideoTable = ({
  columns,
  isArchivedData,
  data,
  handleItemCheckChange,
}: TableProps) => {
  const options: any = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const dispatch = useAppDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState<string>("");
  const [confirmTitle, setConfirmTitle] = useState<string>("");
  const [actionType, setActionType] = useState<
    "archive" | "delete" | "unarchive"
  >();
  const [selectedCapture, setSelectedCapture] = useState<ICaptureImageCard>();

  const handleConfirmationModalOpen = (
    captureId: number,
    actionType: "archive" | "delete" | "unarchive",
  ) => {
    const selectedCapture = data.find((capture) => capture.id === captureId);
    setSelectedCapture(selectedCapture);
    setActionType(actionType);

    switch (actionType) {
      case "archive":
        setConfirmTitle("Archive a Capture");
        break;
      case "unarchive":
        setConfirmTitle("Unarchive a Capture");
        break;
      case "delete":
        setConfirmTitle("Delete a Capture");
    }

    const msg = `Are you sure you'd like to ${actionType} the capture that capture ID is ${captureId}?`;
    setConfirmMsg(msg);
    setIsConfirmModalOpen(true);
  };

  const handleArchiveCapture = () => {
    //Archive or Unarchive capture and update capture states
    dispatch(
      archiveCapture({
        Id: selectedCapture?.id!,
        ModelId: selectedCapture?.modelId!,
        DeviceId: selectedCapture?.deviceId!,
        Archive: isArchivedData ? false : true,
      }),
    )
      .then(() => {
        setIsConfirmModalOpen(false);
      })
      .catch((error) => {
        console.error("Error during archiving the capture:", error);
      });
  };

  const handleSoftDeleteCapture = () => {
    dispatch(
      softDeleteCapture({
        Id: selectedCapture?.id!,
        ModelId: selectedCapture?.modelId!,
        DeviceId: selectedCapture?.deviceId!,
        Deleted: true,
      }),
    )
      .then(() => {
        setIsConfirmModalOpen(false);
      })
      .catch((error: string) => {
        console.error("Error during deleting the capture:", error);
      });
  };

  const onConfirm = () => {
    if (actionType === "archive" || actionType == "unarchive")
      handleArchiveCapture();
    else if (actionType === "delete") handleSoftDeleteCapture();
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  return (
    <>
      <StyledTable>
        <thead>
          <tr>
            <th></th>
            {columns.map((column, index) => {
              return <th key={index}>{column}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <tr key={index}>
                <td>
                  <Checkbox
                    isChecked={item.checked}
                    value={item.id}
                    onChange={handleItemCheckChange}
                  />
                </td>
                <td>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <svg
                      width={80}
                      height={60}
                      style={{ margin: "0 0.5em 0 0" }}
                    >
                      <LazyLoadImage
                        key={item.thumbnail}
                        src={item.thumbnail!}
                        alt="Capture Image"
                        effect="blur"
                        height="100%"
                        style={{ objectFit: "cover" }}
                      />
                    </svg>
                    <span>{item.model}</span>
                  </div>
                </td>
                <td>
                  {isArchivedData ? (
                    <>
                      <Tooltip id="unarchive-tooltip" />
                      <IconButton
                        data-tooltip-id="unarchive-tooltip"
                        data-tooltip-content="Unarchive"
                        data-tooltip-place="top"
                      >
                        <StyledBiSolidArchiveOut
                          onClick={() =>
                            handleConfirmationModalOpen(item.id, "unarchive")
                          }
                        />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <Tooltip id="archive-tooltip" />
                      <IconButton
                        data-tooltip-id="archive-tooltip"
                        data-tooltip-content="Archive"
                        data-tooltip-place="top"
                      >
                        <StyledBiSolidArchiveIn
                          onClick={() =>
                            handleConfirmationModalOpen(item.id, "archive")
                          }
                        />
                      </IconButton>
                    </>
                  )}

                  <Tooltip id="download-tooltip" />
                  <IconButton
                    data-tooltip-id="download-tooltip"
                    data-tooltip-content="Download"
                    data-tooltip-place="top"
                  >
                    <StyledIoMdDownload
                      onClick={() => handleDownloadCapture(item.mediaPath!)}
                    />
                  </IconButton>

                  <Tooltip id="delete-tooltip" />
                  <IconButton
                    data-tooltip-id="delete-tooltip"
                    data-tooltip-content="Delete"
                    data-tooltip-place="top"
                  >
                    <StyledAiFillDelete
                      onClick={() =>
                        handleConfirmationModalOpen(item.id, "delete")
                      }
                    />
                  </IconButton>
                </td>
                <td>{Math.round(item.aiConfidence * 100)}%</td>
                <td>{Math.round(item.cpuUsage * 100)}%</td>
                <td>{Math.round(item.gpuUsage * 100)}%</td>
                <td>{item.frame}</td>
                <td>
                  {item.snapshortTakenTime.toLocaleString("en-CA", options)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        onConfirm={onConfirm}
        headerTitle={confirmTitle}
        confirmMsg={confirmMsg}
        onClose={handleConfirmModalClose}
        modalWidth="30vw"
        minWidth="450px"
      />
    </>
  );
};

export default CaptureImageVideoTable;
