import styled from "styled-components";

export const CustomNavigationButton = styled.button`
  background-color: transparent;
  border: ${(props) => props.theme.border["border-sm-white"]};
  border-radius: 4px;
  color: ${(props) => props.theme.colors["grey-500"]};
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: ${(props) => props.theme.fontSizes["xl"]};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors["primary"]};
    border-color: ${(props) => props.theme.colors["primary"]};
  }
`;
