import { DefaultThemeBorder, DefaultThemeFontSize } from "../../../app/Theme";

export const multiSelectDropdownStyle = {
  control: (provided: any, state: any) => ({
    ...provided,
    maxHeight: "1.5rem",
    width: "100%",
    overflowY: "scroll",
    overflowX: "none",
    backgroundColor: "#020A01",
    border: DefaultThemeBorder["border-sm-yellow"],
    borderRadius: "5px",
    fontSize: DefaultThemeFontSize.sm,
    boxShadow: "none",
    cursor: "pointer",

    "&:hover": {
      borderColor: "#00916E",
    },
    "&::-webkit-scrollbar": {
      display: "block",
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#444444",
      borderRadius: "2px",
    },

    "&::-webkit-scrollbar-thumb:hover": {
      background: "#707070",
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    width: "100%",
    backgroundColor: "#020A01",
    border: DefaultThemeBorder["border-sm-yellow"],
    borderRadius: "5px",
    outline: "none",
  }),
  input: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? "#000" : "#FFF",
    cursor: "pointer",
    backgroundColor: state.isSelected && "#EDAE49",

    "&:hover": {
      backgroundColor: "#EDAE49",
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
  multiValue: (provided: any) => ({
    ...provided,
    display: "flex",
    backgroundColor: "#EDAE49",
    color: "#000",
    paddingInline: "0.5rem",
    borderRadius: "25px",
  }),
  valueContainer: (base: any) => ({
    ...base,
    display: "flex",
    flexWrap: "wrap",
    overflow: "hidden",
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: "#000",
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: "#000",
    backgroundColor: "#EDAE49",
    "&:hover": {
      backgroundColor: "#00916E",
      color: "#FFF",
    },
  }),
};

export const multiSelectDropdownStyleDisabled = {
  control: (provided: any, state: any) => ({
    ...provided,
    maxHeight: "1.5rem",
    width: "100%",
    backgroundColor: "#020A01",
    border: DefaultThemeBorder["border-sm-gray"],
    borderRadius: "5px",
    fontSize: DefaultThemeFontSize.sm,
    boxShadow: "none",
    PointerEvent: "auto",
    cursor: "not-allowed",
    "&::-webkit-scrollbar": {
      display: "block",
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#444444",
      borderRadius: "2px",
    },

    "&::-webkit-scrollbar-thumb:hover": {
      background: "#707070",
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    width: "100%",
    backgroundColor: "#020A01",
    border: DefaultThemeBorder["border-sm-yellow"],
    borderRadius: "5px",
    outline: "none",
  }),
  input: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? "#000" : "#FFF",
    backgroundColor: state.isSelected && "#EDAE49",

    "&:hover": {
      backgroundColor: "#EDAE49",
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#FFF",
  }),
  multiValue: (provided: any) => ({
    ...provided,
    display: "flex",
    backgroundColor: "#EDAE49",
    color: "#000",
    paddingInline: "0.5rem",
    borderRadius: "25px",
  }),
  valueContainer: (base: any) => ({
    ...base,
    display: "flex",
    flexWrap: "wrap",
    overflow: "hidden",
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: "#000",
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: "#000",
    backgroundColor: "#EDAE49",
    "&:hover": {
      backgroundColor: "#00916E",
      color: "#FFF",
    },
  }),
};
