import CustomModal from "../CustomModal";
import { Typography } from "../../typrography/Typography";
import { ModalProvider } from "styled-react-modal";
import { ScaledButtonWrapper } from "../CustomModal.style";
import { DeviceInfoWrapper } from "../deploy-device-modal/DeployDeviceModal.styled";
import { useAppDispatch } from "../../../Store/hooks";
import {
  locationArchive,
  locationSoftDelete,
} from "../../../Services/Locations/Location.service";
import { setActiveLocationImageCard } from "../../../Features/Slice/locations/locationsSlice";

interface Props {
  isOpenModel: boolean;
  locationId?: number | null;
  archiveLocation?: boolean | null;
  onClose: () => void;
}

const DeleteLocationModal = ({
  isOpenModel,
  locationId,
  onClose,
  archiveLocation,
}: Props) => {
  const dispatch = useAppDispatch();

  const cardHeader = () => {
    return (
      <Typography
        variant="lg"
        direction="flex-start"
        style={{ padding: "0.725rem", fontWeight: "700" }}
      >
        {archiveLocation ? "Archive Location" : "Delete location"}
      </Typography>
    );
  };

  const handleDelete = async (id: number) => {
    if (archiveLocation) {
      await dispatch(locationArchive(id));
    } else {
      await dispatch(locationSoftDelete(id));
    }

    onClose();
    dispatch(setActiveLocationImageCard(0));
  };

  const cardFooter = () => {
    return (
      <ScaledButtonWrapper
        buttonMargin="0.5rem"
        style={{ justifyContent: "end" }}
      >
        <button onClick={onClose}>Cancel</button>
        <button onClick={() => handleDelete(locationId!)}>Confirm</button>
      </ScaledButtonWrapper>
    );
  };

  return (
    <ModalProvider>
      <CustomModal
        isOpen={isOpenModel}
        onClose={onClose}
        header={cardHeader()}
        footer={cardFooter()}
      >
        <DeviceInfoWrapper>
          You are about to {archiveLocation ? "archive " : "delete "}
          this location. Are you sure about this?
        </DeviceInfoWrapper>
      </CustomModal>
    </ModalProvider>
  );
};

export default DeleteLocationModal;
