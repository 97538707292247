import {
  DropdownSection,
  DropdownWrapper,
  FilterOptionContainer,
  SortBySection,
  StatusAndSortContainer,
} from "./FilterOptions.style";
import { SubTitle } from "../title/Titles.styles";
import SortBy from "../sort-by/SortBy";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import {
  FetchParamsForAIModels,
  FilterParam,
  resetSearchParams,
  setAIModelParameters,
  updateAIModelParameter,
} from "../../Features/Slice/fetchParameters/fetchParametersSlice";
import { getAllAIModels } from "../../Services/AIModels/aimodel.service";
import { RootState } from "../../Store/store";
import { Fragment, useEffect, useState } from "react";
import {
  DropdownItem,
  getAIModelFilterDropdown,
} from "../../Features/Slice/dropdownOptions/dropdownListSlice";
import {
  CustomDropdown,
  IOption,
} from "../form-field/drop-down/CustomDropdown";
import { Button } from "../button/Button";
import {
  FilterDropdown,
  FilterOption,
} from "../dropdown/filter-dropdown/FilterDropdown";

const AIModelFilterOptions = () => {
  const dispatch = useAppDispatch();
  const filterDropdowns = useAppSelector(getAIModelFilterDropdown);
  const searchParams = useAppSelector(
    (state: RootState) => state.fetchParams.AIModelSearchParams,
  );
  const [keyToResetDropdown, setKeyToResetDropdown] = useState<number>(0); // to reset dropdown selection on clear

  const handleDropdownOptionClick = (
    dropdownId: DropdownItem,
    value: number | string,
  ) => {
    switch (dropdownId) {
      case DropdownItem.AICreatedDate:
        const aiModelDateCreatedParam: FilterParam = {
          paramName: "dateCreated",
          paramValue: value,
        };
        dispatch(updateAIModelParameter(aiModelDateCreatedParam));
        break;
      case DropdownItem.ActiveDevices:
        const aiModelActiveDevices: FilterParam = {
          paramName: "activeDevice",
          paramValue: value,
        };
        dispatch(updateAIModelParameter(aiModelActiveDevices));
        break;
    }
  };

  const resetFilterParams = () => {
    dispatch(resetSearchParams({ page: "AIModels" }));
    dispatch(getAllAIModels());
    setKeyToResetDropdown((prev) => prev + 1);
  };

  const onSortByChange = (sortByItem: any) => {
    const updatedParams: FetchParamsForAIModels = {
      ...searchParams,
      orderByDesc: sortByItem.name === "descending",
    };
    dispatch(setAIModelParameters(updatedParams));
  };

  useEffect(() => {
    resetFilterParams();
  }, []);

  return (
    <FilterOptionContainer>
      <DropdownSection>
        <SubTitle style={{ marginBottom: "4px" }}>Filter Options</SubTitle>
        <DropdownWrapper>
          <Fragment key={keyToResetDropdown}>
            {filterDropdowns.map((dropdown: any, index) => (
              <FilterDropdown
                title={dropdown.title}
                options={dropdown.options}
                onChange={({ label, value }: FilterOption) => {
                  handleDropdownOptionClick(
                    dropdown.id,
                    dropdown.title == "Active Devices" ? value : label,
                  );
                }}
              />
            ))}
          </Fragment>
          <Button color="primary" variant="filled" onClick={resetFilterParams}>
            Clear Filters
          </Button>
        </DropdownWrapper>
      </DropdownSection>
      <StatusAndSortContainer>
        <SortBySection>
          <SortBy onChange={onSortByChange} />
        </SortBySection>
      </StatusAndSortContainer>
    </FilterOptionContainer>
  );
};

export default AIModelFilterOptions;
