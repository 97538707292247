import axios from "axios";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

export const postGroup = async ({
  Name,
  Description,
}: {
  Name: string;
  Description: string;
}) => {
  try {
    const response = await axios.post(`${API_URL}/Group`, {
      Name,
      Description,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const removeLocationFromGroup = async (id: number) => {
  try {
    const response = await axios.patch(
      `${API_URL}/Group/RemoveLocation?id=${id}`,
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getGroups = async ({
  id,
  name,
  locationId,
}: {
  id?: number;
  name?: string;
  locationId?: number;
}) => {
  try {
    const response = await axios.get(`${API_URL}/Group`, {
      params: {
        id,
        name,
        locationId,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateLocationType = async ({
  Id,
  LocationTypeId,
}: {
  Id: number;
  LocationTypeId: number;
}) => {
  try {
    const response = await axios.patch(`${API_URL}/Location/locationType`, {
      Id,
      LocationTypeId,
    });
    return response.data;
  } catch (error) {
    console.error("There was an error updating the location type:", error);
    return error;
  }
};

const GroupService = {
  postGroup,
  getGroups,
  updateLocationType,
};

export default GroupService;
