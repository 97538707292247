import React from "react";
import { StyledAbsoluteCheckbox } from "./Checkbox.style";

interface AbsoluteCheckboxProps {
  isChecked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: number | string;
}

const AbsoluteCheckbox = ({
  isChecked,
  onChange,
  value,
}: AbsoluteCheckboxProps) => {
  return (
    <StyledAbsoluteCheckbox
      checked={isChecked}
      value={value}
      onChange={onChange}
    />
  );
};

export default AbsoluteCheckbox;
