import React from "react";
import { Gauge } from "@ant-design/plots";

export interface GaugePlotBarProps {
  percent: number;
}

const GaugePlotBar = ({ percent = 0.43 }: GaugePlotBarProps) => {
  const config = {
    percent: percent + 0.4,
    autofit: true,
    range: {
      ticks: [0, 1],
      color: ["l(0) 0:#5596F8 1:#F18344"],
    },
    indicator: {
      pointer: {
        style: {
          stroke: "#D0D0D0",
        },
      },
      pin: {
        style: {
          stroke: "#D0D0D0",
        },
      },
    },
    axis: {
      label: {
        formatter(v: any) {
          return Number(v) * 100 - 40;
        },
      },
      subTickLine: {
        count: 3,
      },
    },
    statistic: {
      content: {
        style: {
          fontSize: "1rem",
          color: "white",
          lineHeight: "2rem",
        },
        formatter: () => `Temperature ${(Number(percent) * 100).toFixed(1)} ℃`,
      },
    },
  };
  return (
    <div
      style={{
        width: (window.innerHeight / 100) * 23,
        height: (window.innerHeight / 100) * 23,
      }}
    >
      <Gauge {...config} />
    </div>
  );
};

export default GaugePlotBar;
