import React, { useState, useEffect, useRef } from "react";

import { CustomImageCarousel } from "./ImageCarousel.style";
import { NavigationButton } from "../button/navigation-button/NavigationButton";

// Third Party
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { NavigationContainer } from "./navigation-container/NavigationContainer";
import { NavigationBackground } from "./navigation-background/NavigationBackground";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { ImageCardContainer } from "../image-card/image-card-container/ImageCardContainer";

interface Props {
  currentIndex: number;
  children: any;
  dataLength: number;
}

export const ImageCarousel: React.FC<Props> = ({
  currentIndex,
  children,
  dataLength,
}) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [displayLeftNav, setDisplayLeftNav] = useState(false);
  const [displayRightNav, setDisplayRightNav] = useState(false);

  const handleLeftButtonClick = () => {
    if (carouselRef.current && currentIndex >= -1) {
      carouselRef.current.scrollLeft -= carouselRef.current.offsetWidth;
    }
  };

  const handleRightButtonClick = () => {
    if (carouselRef.current && currentIndex < dataLength - 1) {
      carouselRef.current.scrollLeft += carouselRef.current.offsetWidth;
    }
  };

  useEffect(() => {
    if (carouselRef.current) {
    }
  }, [carouselRef, currentIndex, dataLength]);

  const hoverNavigationLeft = () => {
    setDisplayLeftNav(true);
  };

  const hoverNavigationRight = () => {
    setDisplayRightNav(true);
  };
  const unhoverNavigationLeft = () => {
    setDisplayLeftNav(false);
  };
  const unhoverNavigationRight = () => {
    setDisplayRightNav(false);
  };

  return (
    <CustomImageCarousel>
      <NavigationBackground
        direction="left"
        onMouseOver={hoverNavigationLeft}
        onMouseOut={unhoverNavigationLeft}
      >
        {displayLeftNav && (
          <NavigationContainer direction="left">
            <NavigationButton onClick={handleLeftButtonClick}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </NavigationButton>
          </NavigationContainer>
        )}
      </NavigationBackground>

      <NavigationBackground
        direction="right"
        onMouseOver={hoverNavigationRight}
        onMouseOut={unhoverNavigationRight}
      >
        {displayRightNav && (
          <NavigationContainer direction="right">
            <NavigationButton onClick={handleRightButtonClick}>
              <FontAwesomeIcon icon={faChevronRight} />
            </NavigationButton>
          </NavigationContainer>
        )}
      </NavigationBackground>

      <ImageCardContainer id="carousel" ref={carouselRef}>
        {children}
      </ImageCardContainer>
    </CustomImageCarousel>
  );
};
