import styled from "styled-components";

export const MapWindow = styled.div`
  height: 27.068rem;
  width: 100%;
`;

export const PopupContainer = styled.div`
  width: 50rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const PopupTextGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PopupTitle = styled.span`
  font-size: 1rem;
  font-weight: bold;
`;

export const PopupHeading = styled.span`
  font-weight: bold;
`;
