import styled from "styled-components";
import { FilterOptionsProps } from "./FilterOptions";
import { FaImage } from "react-icons/fa6";
import { BiSolidMoviePlay } from "react-icons/bi";

export const FilterOptionContainer = styled.div<
  Pick<FilterOptionsProps, "filterOptionMargin" | "background">
>`
  display: flex;
  justify-content: space-between;
  height: auto;
  min-height: 130px;
  background: ${({ background }) => (background ? background : "#020A01")};
  color: white;
  border: ${(props) => props.theme.border["primary-sm"]};
  border-radius: 5px;
  padding: 1.2rem;
  margin: ${({ filterOptionMargin }) => filterOptionMargin || "2rem 0"};
  width: 100%;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const DropdownSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  gap: 12px;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px;
  flex-wrap: wrap;
`;

export const StatusAndSortContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DeviceStatusSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 120px;
`;

export const SortBySection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 15px;
`;

export const VerticalLine = styled.div`
  width: 20px;
  height: 100%;
  border-right: ${(props) => props.theme.border["primary-md"]};
`;

export const StyledFaImage = styled(FaImage)`
  font-size: ${(props) => props.theme.fontSizes["lg"]};
  color: #707070;
  &:hover {
    cursor: pointer;
    color: #d64550;
  }
`;

export const StyledBiSolidMoviePlay = styled(BiSolidMoviePlay)`
  font-size: ${(props) => props.theme.fontSizes["lg"]};
  color: #707070;
  &:hover {
    cursor: pointer;
    color: #d64550;
  }
`;
