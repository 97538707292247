import styled from "styled-components";
import { TabPanelProps } from "./TabPanel";

export const CustomTabPanel = styled.div<
  Pick<TabPanelProps, "tabPanelPadding">
>`
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
  color: ${(props) => props.theme.colors.white};
  padding: ${({ tabPanelPadding }) =>
    tabPanelPadding ? tabPanelPadding : "2rem 0 0"};
  @media only screen and (max-width: 1350px) {
    justify-content: center;
    gap: 5%;
    row-gap: 20px;
  }
`;
//refactor width/height for media query to change with screen size
