import React, { useEffect, useState } from "react";
import CustomModal from "../CustomModal";
import { Typography } from "../../typrography/Typography";
import { ModalProvider } from "styled-react-modal";
import { ScaledButtonWrapper } from "../CustomModal.style";
import * as Yup from "yup";
import {
  DeployDeviceContent,
  DeviceInfoWrapper,
} from "./DeployDeviceModal.styled";
import { Formik, FormikHelpers, ErrorMessage } from "formik";
import {
  StyledFormikForm,
  UploadWrapper,
} from "../../tab/ai-model-manager/Upload.style";
import { FormControl } from "../../form-field/form-control/FormControl";
import Input from "../../input/Input";
import DragUpload from "../../input/upload-input/DragUpload";
import { DeleteMedia, UploadMedia } from "../../../Services/Media/Media.Upload";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { getLocationsDropdown } from "../../../Features/Slice/dropdownOptions/dropdownListSlice";
import { CustomDropdown } from "../../form-field/drop-down/CustomDropdown";
import { getAllLocations } from "../../../Services/Locations/Location.service";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../notification/Notification";
import devicePatchService, {
  DeviceDeployPayload,
} from "../../../Services/DeviceManagement/devicePatch.service";
import { useNavigate } from "react-router-dom";
import { DefaultThemeColor } from "../../../app/Theme";
import { SwrmDevices } from "../../../Interfaces/Device/SwrmDevice";
import {
  FilterDropdown,
  FilterOption,
} from "../../dropdown/filter-dropdown/FilterDropdown";

interface Props {
  isOpenModel: boolean;
  device: SwrmDevices | undefined;
  onClose: () => void;
}

interface FormValues {
  name: string;
}
const validationSchema = Yup.object({
  name: Yup.string()
    .required("Device Name is required")
    .min(1, "Name must be at least 1 character"),
});

export default function DeployDeviceModal({
  isOpenModel,
  device,
  onClose,
}: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const initialValues = {
    name:
      device?.Name &&
      !device.Name.toLocaleLowerCase().includes("unnamed device")
        ? device.Name
        : "",
  };
  const [mediaId, setMediaId] = useState<null | number>(null);
  const [locationId, setLocationId] = useState<null | number>(null);
  const locationDropdown = useAppSelector(getLocationsDropdown);

  // Fetch all locations, devices, and AI models on initial load of the page
  useEffect(() => {
    dispatch(getAllLocations({}));
  }, []);
  const handleFileUpload = async (uploadedFile: File) => {
    console.log("handleFileUpload", uploadedFile);
    // setFile(uploadedFile);
    try {
      const response = await UploadMedia("Devices", uploadedFile);
      if (response && response.Result.Id) {
        setMediaId(response.Result.Id);
      }
      console.log("handleFileUpload -> response:", response);
    } catch (error) {
      console.error("handleFileUpload Error:", error);
    }
  };
  const handleFileRemove = async () => {
    if (mediaId) {
      try {
        const response = await DeleteMedia(mediaId);
        if (response && !response.IsError) {
          setMediaId(null);
        }
        console.log("handleFileUpload -> response:", response);
      } catch (error) {
        console.error("handleFileUpload Error:", error);
      }
    }
  };
  /**
   * Handle form submission
   */
  const handelSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    if (locationId) {
      try {
        const deviceDeployPayload: DeviceDeployPayload = {
          DeviceId: device?.Id || "",
          Name: values.name,
          MediaId: mediaId || 0,
          LocationId: locationId,
        };
        const response = await devicePatchService.patchDeviceDeployed(
          deviceDeployPayload,
        );
        console.log(response);
        if (response && response.Result) {
          OpenSuccessNotification(response.Result);
          onClose();
          navigate(`/devices`);
        }
      } catch (error: any) {
        OpenErrorNotification(error.message);
      }
    } else {
      OpenErrorNotification("Please Select Location with Device");
    }
    actions.setSubmitting(false);
  };
  const handleDropdownChange = (e: number) => {
    setLocationId(e);
  };
  const cardHeader = () => {
    return (
      <Typography
        variant="lg"
        direction="flex-start"
        style={{ padding: "0.725rem", fontWeight: "700" }}
      >
        Deploy Device
      </Typography>
    );
  };
  const cardFooter = () => {
    return (
      <ScaledButtonWrapper
        buttonMargin="0.5rem"
        style={{ justifyContent: "end" }}
      >
        <button type="submit" form="deploy-device">
          Deploy
        </button>
      </ScaledButtonWrapper>
    );
  };
  const onClickCloseIcon = () => {
    handleFileRemove();
    onClose();
  };
  return (
    <ModalProvider>
      <CustomModal
        isOpen={isOpenModel}
        onClose={onClickCloseIcon}
        header={cardHeader()}
        footer={cardFooter()}
      >
        <DeployDeviceContent $customColumns="50% 50%">
          <UploadWrapper>
            <DragUpload
              color="white"
              iconColor={DefaultThemeColor["primary"]}
              accept="image/*"
              onFileUpload={handleFileUpload}
              onFileRemove={handleFileRemove}
              fileType={["image"]}
              labelText={"Choose a thumbnail image or drop it here"}
            />
          </UploadWrapper>
          <DeviceInfoWrapper>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handelSubmit}
            >
              {({
                isSubmitting,
                values,
                handleChange,
                handleBlur,
                submitForm,
              }) => (
                <StyledFormikForm id={"deploy-device"}>
                  <FormControl>
                    <div style={{ width: "100%" }}>
                      <FilterDropdown
                        title="Select Location"
                        options={locationDropdown?.options}
                        isFullSize
                        onChange={({ label, value }: FilterOption) => {
                          handleDropdownChange(Number(value));
                        }}
                      />
                    </div>
                  </FormControl>
                  <FormControl>
                    <Input
                      name="name"
                      placeHolder="Device Name"
                      label={"Device Name"}
                      width="100%"
                      inputWrapperWidth={"100%"}
                      value={values.name} // Now it will recognize values.name
                      onChange={handleChange}
                    />
                  </FormControl>
                </StyledFormikForm>
              )}
            </Formik>
          </DeviceInfoWrapper>
        </DeployDeviceContent>
      </CustomModal>
    </ModalProvider>
  );
}
