import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  Capture,
  CaptureWithGroups,
} from "../../../Interfaces/captures/Capture";
import {
  archiveCapture,
  getAllCaptures,
  searchCaptures,
  softDeleteCapture,
} from "../../../Services/Captures/capture.service";
import { RootState } from "../../../Store/store";
import deviceImage2 from "../../../Assets/device2.png";
import { ICaptureImageCard } from "../../../Types/captures/captures";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../Components/notification/Notification";
import { awsUtils } from "../../../Utils/awsUtils";

interface CaptureState {
  data: CaptureWithGroups[];
  combinedCaptures: ICaptureImageCard[];
  isLoading: boolean;
  isSucceeded: boolean;
  errorMessage: string;
  archiveStatus: "idle" | "succeeded" | "loading" | "failed";
  archiveStatusMessage: string;
  deleteStatus: "idle" | "succeeded" | "loading" | "failed";
}

const initialState: CaptureState = {
  data: [] as CaptureWithGroups[],
  combinedCaptures: [] as ICaptureImageCard[],
  isLoading: false,
  isSucceeded: false,
  errorMessage: "",
  archiveStatus: "idle",
  archiveStatusMessage: "",
  deleteStatus: "idle",
};

export const captureSlice = createSlice({
  name: "capture",
  initialState,
  reducers: {
    setAllCaptures: (state, action: PayloadAction<CaptureWithGroups[]>) => {
      state.data = action.payload;
    },
    resetAllCheckedStatus: (state) => {
      const updatedCombinedCaptures = state.combinedCaptures.map((capture) => ({
        ...capture,
        checked: false,
      }));

      state.combinedCaptures = updatedCombinedCaptures;
    },
    setCheckedStatus: (
      state,
      action: PayloadAction<{ captureId: number; checked: boolean }>,
    ) => {
      // Update checked status in combainedCaptures state
      const { captureId, checked } = action.payload;
      const selectedCapture = state.combinedCaptures.find(
        (capture) => capture.id === captureId,
      );

      if (selectedCapture) selectedCapture.checked = checked;
    },
  },
  extraReducers: (build) => {
    build.addCase(getAllCaptures.pending, (state) => {
      state.isLoading = true;
    });
    build.addCase(getAllCaptures.fulfilled, (state, action) => {
      state.isSucceeded = true;
      state.isLoading = false;
      state.data = action.payload.Result;
      const formattedCaptures = setCaptures(action.payload.Result);
      state.combinedCaptures = formattedCaptures;
    });
    build.addCase(getAllCaptures.rejected, (state, action) => {
      if (action.error.message) state.errorMessage = action.error.message;
      //Open error notification when archived is failed.
      OpenErrorNotification(state.errorMessage);
      state.isLoading = false;
    });
    build.addCase(archiveCapture.pending, (state) => {
      state.archiveStatus = "loading";
    });
    build.addCase(archiveCapture.fulfilled, (state, action) => {
      state.archiveStatus = "succeeded";
      state.archiveStatusMessage = action.payload.Result;

      //Open success notification when archive is done successfully.
      OpenSuccessNotification(state.archiveStatusMessage);

      //Update capture list new archived and non-archived captures
      const captureId = action.meta.arg.Id;
      state.combinedCaptures = state.combinedCaptures.filter(
        (capture) => capture.id !== captureId,
      );
    });
    build.addCase(archiveCapture.rejected, (state, action) => {
      state.archiveStatus = "failed";
      if (action.error.message)
        state.archiveStatusMessage = action.error.message;
      //Open error notification when archived is failed.
      OpenErrorNotification(state.archiveStatusMessage);
    });
    build.addCase(searchCaptures.pending, (state) => {
      state.isLoading = true;
    });
    build.addCase(searchCaptures.fulfilled, (state, action) => {
      state.isSucceeded = true;
      state.isLoading = false;
      state.data = action.payload.Result;
      const formattedCaptures = setCaptures(action.payload.Result);
      state.combinedCaptures = formattedCaptures;
    });
    build.addCase(searchCaptures.rejected, (state, action) => {
      if (action.error.message) state.errorMessage = action.error.message;
      //Open error notification when archived is failed.
      OpenErrorNotification(state.errorMessage);
    });
    build.addCase(softDeleteCapture.pending, (state) => {
      state.deleteStatus = "loading";
    });
    build.addCase(softDeleteCapture.fulfilled, (state, action) => {
      state.deleteStatus = "succeeded";
      //Open success notification when soft delete is done successfully.
      OpenSuccessNotification("The capture has been deleted successfully.");

      //Update capture list with all non-deleted captures
      const deletedCaptureId = action.meta.arg.Id;
      state.combinedCaptures = state.combinedCaptures.filter(
        (capture) => capture.id !== deletedCaptureId,
      );
    });
    build.addCase(softDeleteCapture.rejected, (state, action) => {
      state.archiveStatus = "failed";
      if (action.error.message)
        //Open error notification when deleting is failed.
        OpenErrorNotification(action.error.message);
    });
  },
});

const setCaptures = (data: CaptureWithGroups[]): ICaptureImageCard[] => {
  const captures: ICaptureImageCard[] = [];
  for (let i = 0; i < data?.length; i++) {
    captures.push({
      id: data[i].Captures.Id,
      deviceId: data[i].Captures.DeviceId,
      modelId: data[i].Captures.ModelId,
      checked: false,
      thumbnail: awsUtils.getSignedImageURL(
        data[i].Captures.Media.ThumbnailUrl,
      ),
      mediaPath: data[i].Captures.Media.Url,
      fullMedia: awsUtils.getSignedImageURL(data[i].Captures.Media.Url),
      device: {
        index: i.toString(),
        dataId: data[i].Captures.DeviceId,
        image: data[i].Captures.Device.Thumbnail || deviceImage2,
        header: data[i].Captures.Device.TagName || "Unnamed Device",
        upToDate: data[i].Captures.Device.UpToDate,
        online: data[i].Captures.Device.Online,
        connectionStatus:
          data[i].Captures.Device.UpToDate && data[i].Captures.Device.Online
            ? "Connected"
            : !data[i].Captures.Device.UpToDate &&
              data[i].Captures.Device.Online
            ? "Out of Date"
            : "Disconnected",
      },
      model: data[i].Captures?.AIModel?.Name || "No Model",
      modelDeleted: data[i].Captures?.AIModel?.Deleted || false,
      modelArchived: data[i].Captures?.AIModel?.Archived || false,
      snapshortTakenTime: data[i].Captures.TakenAt,
      pairedGroup: data[i].GroupNames,
      gpuUsage: data[i].Captures.GPUUsage,
      cpuUsage: data[i].Captures.CPUUsage,
      aiConfidence: data[i].Captures.Confidence,
      temperature: data[i].Captures.Temperature,
      frame: data[i].Captures.MediaType === "image" ? "Image" : "Video",
      deleted: data[i].Captures.Deleted,
      archived: data[i].Captures.Archive,
    });
  }

  return captures;
};

export const { setAllCaptures, resetAllCheckedStatus, setCheckedStatus } =
  captureSlice.actions;
export const selectedCaptureData = (state: RootState) => state.captures;
export const selectedCheckedCaptures = (state: RootState) =>
  state.captures.combinedCaptures.filter(
    (capture: ICaptureImageCard) => capture.checked === true,
  );

export default captureSlice.reducer;
