import { useState } from "react";
import Input from "../../input/Input";
import {
  FormWrapper,
  ScaledButtonWrapper,
} from "../../modals/CustomModal.style";
import TextEditor from "../../rich-text-editor/TextEditor";
import { Typography } from "../../typrography/Typography";
import {
  EditorWrapper,
  ErrorText,
  StyledFormikForm,
  UploadWrapper,
} from "./Upload.style";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AppDispatch, RootState } from "../../../Store/store";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import AIModelService, {
  aiModelEditParams,
} from "../../../Services/AIModels/aimodel.service";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../notification/Notification";
import { ResponseObject } from "../../../Interfaces/Response";
import { AIModel } from "../../../Interfaces/AIModel/AIModel";
import DragUpload from "../../input/upload-input/DragUpload";
import {
  FileActions,
  fileAction,
  resetAllFiles,
} from "../../../Features/Slice/ai-models/aiModelSlice";
import Loader from "../../loader/Loader";

export interface EditFormProps {
  item: any;
  onCancel: () => void;
  onSubmitCloseEditMode: () => void;
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Name must be at least 2 characters")
    .max(50, "Name must not exceed 50 characters")
    .required("Name is required")
    .matches(/^[A-Za-z_\s]+$/g, "No numbers or special characters besides _"),
  description: Yup.string()
    .min(2, "Description must be at least 2 characters")
    .max(50, "Description must not exceed 50 characters")
    .required("Description is required"),
});

const EditForm = ({ item, onCancel, onSubmitCloseEditMode }: EditFormProps) => {
  const dispatch: AppDispatch = useAppDispatch();
  const aiModels = useAppSelector((state: RootState) => state.aiModels);
  const [updateStatus, setUpdateStatus] = useState("");
  const [keyToDropzone, setKeyToDropzone] = useState(0);

  const initialValues: aiModelEditParams = {
    id: item?.id || 0,
    aiModelFile: null,
    name: item?.model[1] || "",
    /* Commented out for the time being, will re-implement if need be */
    // labelFiles: [],
    imageFile: null,
    description: item?.model[2] || "",
  };

  const handleAIModelFileUpload = (uploadedFile: File) => {
    const fileUploadObj: FileActions = {
      file: uploadedFile,
      type: "AI_MODEL",
      action: "UPLOAD",
    };

    dispatch(fileAction(fileUploadObj));
  };

  const handleThumbnailFileUpload = (uploadedFile: File) => {
    const fileUploadObj: FileActions = {
      file: uploadedFile,
      type: "THUMBNAIL",
      action: "UPLOAD",
    };

    dispatch(fileAction(fileUploadObj));
  };

  const handleAIModelFileRemove = () => {
    const fileRemoveObj: FileActions = {
      file: null,
      type: "AI_MODEL",
      action: "REMOVE",
    };

    dispatch(fileAction(fileRemoveObj));
  };

  const handleThumbnailFileRemove = () => {
    const fileRemoveObj: FileActions = {
      file: null,
      type: "THUMBNAIL",
      action: "REMOVE",
    };

    dispatch(fileAction(fileRemoveObj));
  };

  const handleSubmit = async (
    values: aiModelEditParams,
    { setSubmitting, resetForm }: any,
  ) => {
    setUpdateStatus("pending");
    values.aiModelFile = aiModels.aiModelFile!;
    values.imageFile = aiModels.thumbnailFile!;
    /* Commented out for the time being, will re-implement if need be */
    // values.labelFiles = aiModels.labelFiles!;

    const response: ResponseObject<AIModel> = await AIModelService.editAIModel(
      values,
    );

    setUpdateStatus("");
    if (response.ErrorCode !== 200) {
      OpenErrorNotification(response.ErrorMessage);
    } else {
      OpenSuccessNotification("AI Model updated successfully");
      setSubmitting(false);
      setKeyToDropzone(keyToDropzone + 1);
      resetForm();
      dispatch(resetAllFiles());
      onSubmitCloseEditMode();
    }
  };

  return (
    <>
      {updateStatus === "pending" && (
        <Loader loadingText="Updating AI Model, please wait" isLoadingScreen />
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          isValid,
          handleChange,
          handleBlur,
          setFieldTouched,
          values,
        }) => (
          <StyledFormikForm>
            <UploadWrapper>
              <DragUpload
                key={`aiModelUploadFile_${keyToDropzone}`}
                onFileUpload={handleAIModelFileUpload}
                onFileRemove={handleAIModelFileRemove}
                labelText="Choose an AI model file or drag it here"
                height="13rem"
                flex={2}
                id="aiModelFile"
              />
              <DragUpload
                key={`thumbnailUploadFile_${keyToDropzone}`}
                onFileUpload={handleThumbnailFileUpload}
                onFileRemove={handleThumbnailFileRemove}
                labelText="Choose a thumbnail file or drag it here"
                height="13rem"
                flex={1}
                id="imageFile"
              />
            </UploadWrapper>
            <FormWrapper>
              <Input
                name={"name"}
                placeHolder={"Insert Name"}
                label={"Name your model"}
                width={"23vw"}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {/*
                Label files commented out for the time being
              */}
              {/* <MultipleUploadInput
                name={"labelFiles"}
                variant="input"
                placeHolder={"Insert Label Files"}
                label={"Label files"}
                width={"23vw"}
              />
              <Input
                name={"manualLabel"}
                placeHolder={"Insert Labels Manually"}
                label={"Manually input labels"}
                subLabel={'(Separate with space or ",")'}
                width={"23vw"}
                onChange={handleChange}
                onBlur={handleBlur}
              /> */}
            </FormWrapper>
            <EditorWrapper>
              <Typography
                variant="lg"
                direction="flex-start"
                style={{ fontWeight: "700", padding: "10px 0" }}
              >
                {"Describe your model"}
              </Typography>
              <Field name="description">
                {({ field, form }: any) => (
                  <TextEditor
                    initialValue={initialValues.description}
                    field={field}
                    form={form}
                    onBlur={() => setFieldTouched("description", true)}
                  />
                )}
              </Field>
              <ErrorMessage name="description" component={ErrorText} />
            </EditorWrapper>
            <ScaledButtonWrapper buttonPadding="1rem">
              <button type="submit">Save Changes</button>
              <button className="danger" onClick={onCancel}>
                Cancel
              </button>
            </ScaledButtonWrapper>
          </StyledFormikForm>
        )}
      </Formik>
    </>
  );
};

export default EditForm;
