import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { UptimeData } from "./ApiUptimeBarChartList";
import { DefaultThemeColor } from "../../app/Theme";

interface Props {
  data: UptimeData[];
}

const UptimeBarChart = ({ data }: Props) => {
  return (
    <div style={{ width: "100%", height: "60px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          {/* <XAxis dataKey="day"/> */}
          {/* <YAxis domain={[0, 100]} /> */}
          <Tooltip />
          <Bar dataKey="value" fill={DefaultThemeColor.secondary} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default UptimeBarChart;
