import React from "react";
import HelpDropdownList from "../../dropdown/help-dropdown-list/HelpDropdownList";
import helpDataList from "../../../data/faqAndHelpDropdowns";

type Props = {};

const General = (props: Props) => {
  const data = helpDataList.filter((item) => item.menuType === "general");
  return <HelpDropdownList data={data} width="80%" />;
};

export default General;
