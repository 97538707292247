import styled from "styled-components";
import { IAccordionProps } from "./Accordion";

export const AccordionContainer = styled.div<{}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  width: auto;
`;

export const AccordionContent = styled.div`
  display: none; // Start off not visible
  &.expanded {
    display: block; // Show content when expanded
  }
`;

export const AccordionList = styled.div<
  Pick<IAccordionProps, "backgroundColor" | "borderTheme">
>`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  color: #ffffff;
  justify-content: space-between;
  width: 100%;
  min-width: 108px;
  height: auto;
  border: ${({ borderTheme, theme }) =>
    borderTheme ? borderTheme : theme.border["primary-sm"]};
  border-radius: 5px;
  font-size: 1.25vw;
  margin: 1rem 1rem 1rem 0;
  padding: 12px;
  background: #020a01;

  &:hover {
    color: ${(props) => props.theme.colors["primary"]};
  }
`;

export const AccordionDescription = styled.div`
  font-size: 1vw;
  line-height: 1.5vw;
  color: #ffffff;

  a {
    color: ${(props) => props.theme.colors["primary"]};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    list-style: none;

    li {
      // Add styles for li elements here if needed
    }
  }
`;

export const AccordionHeader = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
`;

export const AccordionToggle = styled.div<{ isExpanded: boolean }>`
  margin-left: auto;
  font-size: 2vw;
  cursor: pointer;
  transform: ${({ isExpanded }) =>
    isExpanded ? "rotate(180deg)" : "rotate(0deg)"};
  transition: transform 0.3s ease;

  &:before {
    display: block;
  }
`;

export const VerticalLineWrapper = styled.div`
  height: 100%;
  display: flex;
  gap: 10px;
`;

export const VerticalLine = styled.span<Pick<IAccordionProps, "borderTheme">>`
  border: ${({ borderTheme, theme }) =>
    borderTheme ? borderTheme : theme.border["primary-sm"]};

  &:hover {
    border: 1px solid #ffffff;
  }
`;

export const HorizontalLine = styled.div<{ isExpanded: boolean }>`
  height: 2px;
  background-color: ${(props) =>
    props.theme.colors["primary"]}; // You can customize the color
  margin: ${({ isExpanded }) =>
    isExpanded ? "10px 0" : "0"}; // Adjust based on your needs
  display: ${({ isExpanded }) =>
    isExpanded ? "block" : "none"}; // Show only when expanded
`;
