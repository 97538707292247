import React from "react";
import { IconWrapper, StyledMasterIcon } from "./MastercardIcon.style";
import icon from "../../Assets/diners-club.png";

type Props = {};

const DinersClubIcon = (props: Props) => {
  return (
    <IconWrapper>
      <StyledMasterIcon src={icon} alt="diner's club icon" />
    </IconWrapper>
  );
};

export default DinersClubIcon;
