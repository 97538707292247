import { useEffect, useState } from "react";
import { Container } from "../Components/container/Container";
import Loader from "../Components/loader/Loader";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../Store/hooks";
import { activeNavLink } from "../Components/nav/side-nav/sideNavBarSlice";
import { useNavigate } from "react-router-dom";
import authService from "../Services/Auth/auth.service";
import { Auth0User } from "../Interfaces/UserFromToken";
import { useAuth0 } from "@auth0/auth0-react";
import { ResponseObject } from "../Interfaces/Response";
import { OpenErrorNotification } from "../Components/notification/Notification";
import { setRouteOption } from "../Features/Slice/routes/routesSlice";
import { CompanyReturnPayload } from "../Services/Company/company.service";
import {
  setAccessToken,
  setCompany,
  setHasSignedInPreviously,
  setIsSigningUp,
} from "../Features/Slice/auth/authSlice";
import { RootState } from "../Store/store";
import { integrationService } from "../Services/Integration/integration.service";
import { IntegrationCompany } from "../Features/Slice/integration/integrationSlice";

const RouterLoadingScreen = () => {
  // State variables
  const [authProcessDone, setAuthProcessDone] = useState(false);

  // Hooks
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const tokens = useAppSelector(
    (state: RootState) => state.persisted.auth.tokens,
  );
  const navigate = useNavigate();
  const { user } = useAuth0();
  const isActiveSession = sessionStorage.getItem("isActiveSession");

  // Function to check authentication setup
  const checkAuthentication = async () => {
    if (tokens?.accessToken) {
      processAuthentication(tokens.accessToken);
    } else {
      getAccessTokenSilently().then((token) => {
        authService.setAuthTokenToHeader(token);
        dispatch(setAccessToken(token));
        processAuthentication(token);
      });
    }
  };

  // Function to start user authentication process
  const processAuthentication = async (accessToken: string) => {
    const verifyUserResponse: ResponseObject<string> = await verifyUser(
      accessToken,
    );
    // Check if user needs to be registered
    if (verifyUserResponse.ErrorCode === 404) {
      const registerUserResponse: ResponseObject<string> = await registerUser(
        accessToken,
      );
      if (registerUserResponse.IsError == false) authenticateUser(accessToken);
      return;
    }

    if (!verifyUserResponse.IsError) authenticateUser(accessToken);
  };

  // Function to verify user
  const verifyUser = async (accessToken: string) => {
    const verifyResponse: ResponseObject<string> =
      await authService.verifyInSystem(user?.sub!, accessToken);

    return verifyResponse;
  };

  // Function to register user
  const registerUser = async (accessToken: string) => {
    // Extract user data from Auth0 user object
    const auth0User: Auth0User = {
      Sub: user?.sub!,
      Email: user?.email!,
      Nickname: user?.nickname!,
      Picture: user?.picture!,
    };

    const registerResponse: ResponseObject<string> =
      await authService.registerToSystem(auth0User, accessToken);

    return registerResponse;
  };

  // Function to authenticate user
  const authenticateUser = async (accessToken: string) => {
    try {
      // Extract user data from Auth0 user object
      const auth0User: Auth0User = {
        Sub: user?.sub!,
        Email: user?.email!,
        Nickname: user?.nickname!,
        Picture: user?.picture!,
      };

      // Authenticate user using authService
      const authResponse: ResponseObject<CompanyReturnPayload> =
        await authService.authenticateToSystem(auth0User, accessToken);

      // Handle authentication response
      if (authResponse.ErrorMessage === "NO_USER_COMPANY") {
        // Redirect user to get started if no role or user company
        redirectUser(true);
        setAuthProcessDone(true);
      } else if (authResponse.IsError) {
        // Display error notification and redirect user
        OpenErrorNotification(authResponse.ErrorMessage);
        redirectUser(false);
        setAuthProcessDone(false);
      } else {
        // Set company in Redux store and redirect user
        let integrationData: IntegrationCompany[] = [];

        // Fetch Integrations
        if (
          authResponse.Result.Company.IntegratedWith !== "" &&
          authResponse.Result.Company.IntegratedWith !== null
        ) {
          const response = await dispatch(integrationService.getIntegrations());
          integrationData.push(response.payload.Result);
        }

        dispatch(setCompany(authResponse.Result));
        redirectUser(
          false,
          authResponse.Result.Company.IntegratedWith,
          integrationData,
        );
        setAuthProcessDone(true);
      }
    } catch (e: any) {
      console.log("authenticateUser Error:", e.message);
      redirectUser(false);
      setAuthProcessDone(false);
    }
  };

  // Function to redirect user to Get Started page
  const redirectUser = async (
    redirectToGetStarted: boolean,
    integratedWith?: string,
    integrations?: IntegrationCompany[],
  ) => {
    // Set sign-in previously status and session
    dispatch(setHasSignedInPreviously(true));
    sessionStorage.setItem("isActiveSession", "true");

    // Redirect user based on status
    if (redirectToGetStarted) {
      // Redirect to get started page
      dispatch(setIsSigningUp(true));
      dispatch(setRouteOption(1));
      navigate("/getStarted");
    } else {
      // Redirect to dashboard after checking headers
      const headersCheck = setTimeout(() => {
        if (axios.defaults.headers.common["BSD-Company"] !== undefined) {
          clearTimeout(headersCheck);

          if (
            integratedWith !== "" &&
            integratedWith !== null &&
            integrations &&
            integrations[0].Enabled
          ) {
            navigate("/integrationLogin");
          } else {
            dispatch(setRouteOption(2));
            dispatch(activeNavLink(1));
            navigate("/dashboard");
          }
        }
      }, 2000);
    }
  };

  // useEffect hook to trigger authentication process
  useEffect(() => {
    // Check local and session storage for authentication status
    const persistRoot = JSON.parse(localStorage.getItem("persist:root")!);
    const auth = JSON.parse(persistRoot.auth);
    // Authenticate user if necessary
    if (
      (!authProcessDone &&
        !auth.hasSignedInPreviously &&
        isActiveSession === null) ||
      auth.isSigningUp
    ) {
      checkAuthentication();
    }
  }, []);

  return (
    <Container>
      {/* Display loader while authentication is in progress */}
      <Loader loadingText="Loading..." isLoadingScreen />
    </Container>
  );
};

export default RouterLoadingScreen;
