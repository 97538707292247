import { SwrmDevices } from "../../../Interfaces/Device/SwrmDevice";

const updateSensorData = (
  device: SwrmDevices,
  sensorId: number,
  updateFunc: Function,
) => {
  const sensorIndex = device.Sensors.findIndex((s: any) => s.Id === sensorId);
  if (sensorIndex !== -1) {
    updateFunc(device.Sensors[sensorIndex]);
  }
};

const updateChildDeviceSensor = (
  childDevice: SwrmDevices,
  sensorId: number,
  updateFunc: Function,
) => {
  const sensorIndex = childDevice.Sensors.findIndex(
    (s: any) => s.Id === sensorId,
  );
  if (sensorIndex !== -1) {
    updateFunc(childDevice.Sensors[sensorIndex]);
  }
};
const updateRelayData = (
  device: SwrmDevices,
  relayId: number,
  updateFunc: Function,
) => {
  if (device.RelayWithValues) {
    device.RelayWithValues.forEach((relay: any) => {
      if (relay.Id === relayId) {
        updateFunc(relay);
      }
    });
  }
};

const updateChildDeviceRelay = (
  childDevice: SwrmDevices,
  relayId: number,
  updateFunc: Function,
) => {
  const relayIndex = childDevice.RelayWithValues.findIndex(
    (s: any) => s.Id === relayId,
  );
  if (relayIndex !== -1) {
    updateFunc(childDevice.RelayWithValues[relayIndex]);
  }
};
const findChildDeviceById = (deviceId: string, device: any) => {
  return device.ChildDevices.find((cd: any) => cd.Id === deviceId);
};

const deviceUtilFunc = {
  updateSensorData,
  updateChildDeviceSensor,
  updateRelayData,
  updateChildDeviceRelay,
  findChildDeviceById,
};
export default deviceUtilFunc;
