import React, { useState } from "react";
import { GroupBox, GroupTitle } from "./Grouping.style";
import { CustomDropdown } from "../../../../Components/form-field/drop-down/CustomDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "../../../../Components/typrography/Typography";
import { postDeviceGroup } from "../../../../Services/DeviceManagement/deviceGroups.service";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { OpenSuccessNotification } from "../../../../Components/notification/Notification";
import { setDeviceGroup } from "../../../Slice/devices/devicesSlice";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Group } from "../../../../Interfaces/Device/Group";
import {
  FilterDropdown,
  FilterOption,
} from "../../../../Components/dropdown/filter-dropdown/FilterDropdown";

interface Props {
  locationGroups: Group[];
}
export default function AddDeviceGroup({ locationGroups }: Props) {
  const dispatch = useAppDispatch();
  const selectedDeviceId = useAppSelector(
    (state) => state.devices.selectedDevice.Id,
  );
  const [isEdit, setIsEdit] = useState(false);

  const handleEdit = () => {
    setIsEdit(true);
  };
  const cancelEdit = () => {
    setIsEdit(false);
  };
  const handleGroupDropdownChange = async (value: number) => {
    try {
      const response = await postDeviceGroup({
        id: 0,
        deviceId: selectedDeviceId,
        groupId: value,
      });
      if (response && !response.IsError) {
        OpenSuccessNotification(
          "The device has been added to DeviceGroups successfully",
        );
        cancelEdit();
        dispatch(setDeviceGroup(response.Result));
      }
    } catch (e) {
      console.log("handleGroupDropdownChange", value);
    }
  };
  return (
    <GroupBox>
      <div>
        <GroupTitle>Add Group</GroupTitle>
        {isEdit ? (
          <FilterDropdown
            title="Select Group"
            options={locationGroups.map((option: Group) => ({
              label: option.Name,
              value: option.Id,
            }))}
            onChange={({ label, value }: FilterOption) => {
              handleGroupDropdownChange(Number(value));
            }}
          />
        ) : (
          <div className="cursor-pointer" onClick={() => handleEdit()}>
            <FontAwesomeIcon
              icon={faPlus}
              style={{ padding: "0.725rem 0 0 0" }}
            />
            <Typography
              variant="sm"
              weight="light"
              direction="center"
              padding="0.725rem 0"
            >
              Click Here!
            </Typography>
          </div>
        )}
      </div>
    </GroupBox>
  );
}
