import React, { useEffect, useRef } from "react";
import { useAppDispatch } from "../../../Store/hooks";
import { getAllDevices } from "../../../Services/DeviceManagement/deviceGet.service";
import { useSelector } from "react-redux";
import {
  setActiveDeviceImageCard,
  setSelectedDevice,
} from "../../../Features/Slice/devices/devicesSlice";
import DeviceCard from "../DeviceCard";
import { SwrmDevices } from "../../../Interfaces/Device/SwrmDevice";
import { ImageCardWrapper } from "../../../Components/image-card/ImageCard.style";

export interface ImageCardListProps {}

const DeviceCardList = ({}: ImageCardListProps) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const device = useSelector((state: any) => state.devices);
  const handleImageCardClick = (index: number, id: string) => {
    dispatch(setActiveDeviceImageCard(id));
    dispatch(setSelectedDevice(device.data[index]));
  };

  useEffect(() => {
    dispatch(getAllDevices()).then(() => console.log(device));
  }, []);

  return (
    <>
      <div style={{ fontSize: "20px", fontWeight: 900, padding: "10px" }}>
        Device List
      </div>
      <ImageCardWrapper id="deviceManageTabList" ref={carouselRef}>
        {device.data.map((d: SwrmDevices, index: number) => (
          <DeviceCard
            key={d.Id}
            device={d}
            onClick={() => handleImageCardClick(index, d.Id)}
          />
        ))}
      </ImageCardWrapper>
    </>
  );
};

export default DeviceCardList;
