// NoDataAvailable.tsx
import React, { useState } from "react";
import { Typography } from "../typrography/Typography";
type Props = {
  text?: string;
};
const defaultText = "No Data Available!";
const NoDataAvailable: React.FC = ({ text = defaultText }: Props) => {
  return (
    <Typography variant="md" style={{ margin: "1rem", padding: "1rem" }}>
      {text}
    </Typography>
  );
};

export default NoDataAvailable;
