import React from "react";
import { IoSettingsSharp } from "react-icons/io5";
import {
  RelayBoxBlock,
  RelayEdit,
  RelaySwitch,
  RelayTitle,
} from "./RelayBox.style";
interface Props {
  title: string;
  onEdit: () => void;
  children: React.ReactNode;
}
export default function RelayBox({ title, onEdit, children }: Props) {
  return (
    <RelayBoxBlock>
      <RelayEdit>
        <IoSettingsSharp onClick={onEdit} />
      </RelayEdit>
      <RelayTitle> {title}</RelayTitle>
      <RelaySwitch>{children}</RelaySwitch>
    </RelayBoxBlock>
  );
}
