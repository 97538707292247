import styled from "styled-components";
import { NotificationBarProps } from "./NotificationBar";
import {
  BsCheckCircleFill,
  BsFillExclamationCircleFill,
  BsFillExclamationTriangleFill,
} from "react-icons/bs";
import { IoClose } from "react-icons/io5";

export const NotificationListWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: block;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors["grey-800"]};
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors["grey-500"]};
  }
`;

export const NotificationBarWrapper = styled.div<
  Pick<NotificationBarProps, "notification">
>`
  display: flex;
  height: 10.8vh;
  color: white;
  background: ${({ notification, theme }) =>
    notification.priority === "high"
      ? "#D6455040"
      : notification.priority === "medium"
      ? `${theme.colors["primary"]}40`
      : `${theme.colors["secondary"]}40`};
  margin: 0 5px;
  border-radius: 5px;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors["violet-500"]};
  }
`;
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledBsFillExclamationCircleFill = styled(
  BsFillExclamationCircleFill,
)`
  color: ${(props) => props.theme.colors["red-300"]};
  font-size: 2.22vw;
`;
export const StyledBsFillExclamationTriangleFill = styled(
  BsFillExclamationTriangleFill,
)`
  color: ${(props) => props.theme.colors["primary"]};
  font-size: 2.22vw;
`;
export const StyledBsCheckCircleFill = styled(BsCheckCircleFill)`
  color: ${(props) => props.theme.colors["green-100"]};
  font-size: 2.22vw;
`;
export const StyledIoClose = styled(IoClose)`
  color: ${(props) => props.theme.colors.white};
  font-size: 2.22vw;
  cursor: pointer;
`;
