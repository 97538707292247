import { useState, FC } from "react";
import { CardType } from "../../Types/device-manager/card-types";
import { CardAction } from "../device-manager-card/card-action/CardAction";
import { CardContent } from "../device-manager-card/card-content/CardContent";
import { CardHeader } from "../device-manager-card/card-header/CardHeader";
import { DeviceManagerCard } from "../device-manager-card/DeviceManagerCard";
import CardOutline from "../card/card-outline/CardOutline";
interface Props {
  config: CardType;
}

export const DeviceManagerElement: FC<Props> = ({ config }) => {
  const [isEditClicked, setIsEditClicked] = useState(false);

  return (
    <DeviceManagerCard key={config.index}>
      <CardOutline border={config.border}>
        <CardContent>
          <CardHeader
            cardType={config}
            key={config.index}
            onClick={() => setIsEditClicked((p) => !p)}
          />
          {isEditClicked &&
            config.action &&
            config.action.map((actionData) => (
              <CardAction action={actionData} key={actionData.index} />
            ))}
        </CardContent>
      </CardOutline>
    </DeviceManagerCard>
  );
};
