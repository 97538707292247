import React from "react";
import { CustomImage } from "./Image.style";

export interface ImageProps {
  src: any;
  alt: string;
  height?: number | string;
  width?: number | string;
  margin?: string;
  objectFit?: "cover" | "contain" | "fill";
}
export const Image: React.FC<ImageProps> = ({
  src,
  alt,
  height,
  width,
  margin,
  objectFit,
}) => {
  return (
    <CustomImage
      src={src}
      alt={alt}
      height={height}
      width={width}
      margin={margin}
      objectFit={objectFit}
    />
  );
};
