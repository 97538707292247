import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../../Store/store";
import {
  filterDevices,
  getAllDevices,
  getDevice,
} from "../../../Services/DeviceManagement/deviceGet.service";
import { DeviceName } from "../../../Interfaces/Device/DeviceName";
import { patchDeviceLocation } from "../../../Services/DeviceManagement/devicePatch.service";
import { DeviceModel } from "../../../Interfaces/Device/DeviceModel";
import {
  Relay,
  RelayValues,
  RelayWithValues,
} from "../../../Interfaces/Device/Relay";
import deviceUtilFunc from "./deviceSliceUtil";
import { Sensor } from "../../../Interfaces/Device/Sensor";
import { SwrmDevices } from "../../../Interfaces/Device/SwrmDevice";
import { defaultLocation } from "../locations/locationsSlice";
import { DeviceGroup } from "../../../Interfaces/Device/DeviceGroup";
import { BoardType } from "../../../Interfaces/Device/BoardTypes";
import { getAllBoardTypes } from "../../../Services/DeviceManagement/deviceBoardType.service";
import { awsUtils } from "../../../Utils/awsUtils";
import { syncRelays } from "../../../Services/DeviceManagement/deviceRelays.service";
import { OpenSuccessNotification } from "../../../Components/notification/Notification";
import { Routine } from "../../../Interfaces/Device/Routine";
import {
  deleteRoutine,
  getRoutines,
  getRoutineStepRelays,
  getRoutineSteps,
} from "../../../Services/DeviceManagement/deviceRoutines.service";
import { Step } from "../../../Interfaces/Device/Step";

//Define a type for the slice state
interface DeviceState {
  data: SwrmDevices[];
  allDevicesForModal: SwrmDevices[] | [];
  selectedDevice: SwrmDevices;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string;
  activeDeviceId: string;
  boardTypes: BoardType[];
}
export const defaultSelectedSwrmDevice: SwrmDevices = {
  Id: "",
  PiSerial: "123123",
  Name: "Device Name",
  UpToDate: false,
  Deployed: false,
  WifiStatus: false,
  ConnectionStatus: false,
  Enabled: false,
  LastContact: "",
  Location: defaultLocation,
  Groups: [],
  GPSLongitude: null,
  GPSLatitude: null,
  GPSElevation: null,
  Sensors: [],
  DeviceModel: null,
  Thumbnail: null,
  MediaId: 0,
  RelayWithValues: [],
  Routines: [],
  Steps: [],
  StepRelayIds: [],
  ChildDevices: [],
};
const initialState: DeviceState = {
  data: [],
  allDevicesForModal: [],
  selectedDevice: defaultSelectedSwrmDevice,
  isLoading: false,
  isSuccess: false,
  errorMessage: "",
  activeDeviceId: "",
  boardTypes: [],
};

export const devicesSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    setAllDevices: (state, action: PayloadAction<SwrmDevices[]>) => {
      state.data = { ...action.payload };
    },
    setActiveDeviceImageCard: (state, action: PayloadAction<string>) => {
      state.activeDeviceId = action.payload;
    },
    resetDeviceImageCard: (state) => {
      state.activeDeviceId = "";
    },
    changeDevicesOrder: (state) => {
      state.allDevicesForModal = state.allDevicesForModal.reverse();
    },
    updateSensorsInDevice: (
      state: any,
      action: PayloadAction<{ sensor: Sensor; masterDeviceId: string }>,
    ) => {
      const { sensor, masterDeviceId } = action.payload;
      const updateFunc = (sensor: any) => {
        sensor.Active = action.payload.sensor.Active;
      };

      state.data.forEach((device: any) => {
        if (device.Id === sensor.DeviceId) {
          deviceUtilFunc.updateSensorData(device, sensor.Id, updateFunc);
        } else if (device.Id === masterDeviceId) {
          const childDevice = deviceUtilFunc.findChildDeviceById(
            sensor.DeviceId,
            device,
          );
          if (childDevice) {
            deviceUtilFunc.updateChildDeviceSensor(
              childDevice,
              sensor.Id,
              updateFunc,
            );
          }
        }
      });

      if (state.selectedDevice.Id === sensor.DeviceId) {
        deviceUtilFunc.updateSensorData(
          state.selectedDevice,
          sensor.Id,
          updateFunc,
        );
      } else if (state.selectedDevice.Id === masterDeviceId) {
        const childDevice = deviceUtilFunc.findChildDeviceById(
          sensor.DeviceId,
          state.selectedDevice,
        );
        if (childDevice) {
          deviceUtilFunc.updateChildDeviceSensor(
            childDevice,
            sensor.Id,
            updateFunc,
          );
        }
      }
    },
    updateRelayInDevice: (
      state: any,
      action: PayloadAction<{ relay: RelayValues; masterDeviceId: string }>,
    ) => {
      const { relay, masterDeviceId } = action.payload;
      const updateFunc = (relay: any) => {
        relay.RelayValues = [action.payload.relay];
      };

      state.data.forEach((device: any) => {
        if (device.Id === relay.Relay?.DeviceId) {
          deviceUtilFunc.updateRelayData(device, relay.RelayId, updateFunc);
        } else if (device.Id === masterDeviceId) {
          const childDevice = deviceUtilFunc.findChildDeviceById(
            relay.Relay?.DeviceId || "",
            device,
          );
          if (childDevice) {
            deviceUtilFunc.updateChildDeviceRelay(
              childDevice,
              relay.RelayId,
              updateFunc,
            );
          }
        }
      });

      if (state.selectedDevice.Id === relay.Relay?.DeviceId) {
        deviceUtilFunc.updateRelayData(
          state.selectedDevice,
          relay.RelayId,
          updateFunc,
        );
      } else if (state.selectedDevice.Id === masterDeviceId) {
        const childDevice = deviceUtilFunc.findChildDeviceById(
          relay.Relay?.DeviceId || "",
          state.selectedDevice,
        );
        if (childDevice) {
          deviceUtilFunc.updateChildDeviceRelay(
            childDevice,
            relay.RelayId,
            updateFunc,
          );
        }
      }
    },

    updateRelay: (
      state: any,
      action: PayloadAction<{ relay: Relay; masterDeviceId: string }>,
    ) => {
      const { relay, masterDeviceId } = action.payload;
      const updateFunc = (r: any) => {
        r.Name = relay.Name;
      };

      state.data.forEach((device: any) => {
        if (device.Id === relay.DeviceId) {
          deviceUtilFunc.updateRelayData(device, relay.Id, updateFunc);
        } else if (device.Id === masterDeviceId) {
          const childDevice = deviceUtilFunc.findChildDeviceById(
            relay.DeviceId || "",
            device,
          );
          if (childDevice) {
            deviceUtilFunc.updateChildDeviceRelay(
              childDevice,
              relay.Id,
              updateFunc,
            );
          }
        }
      });

      if (state.selectedDevice.Id === relay.DeviceId) {
        deviceUtilFunc.updateRelayData(
          state.selectedDevice,
          relay.Id,
          updateFunc,
        );
      } else if (state.selectedDevice.Id === masterDeviceId) {
        const childDevice = deviceUtilFunc.findChildDeviceById(
          relay.DeviceId || "",
          state.selectedDevice,
        );
        if (childDevice) {
          deviceUtilFunc.updateChildDeviceRelay(
            childDevice,
            relay.Id,
            updateFunc,
          );
        }
      }
    },
    updateDeviceModelInDevice: (state, action: PayloadAction<DeviceModel>) => {
      state.data.forEach((device) => {
        if (device.Id === action.payload.DeviceId) {
          device.DeviceModel = action.payload;
        }
      });
      state.selectedDevice.DeviceModel = action.payload;
    },
    updateDeviceName: (state, action: PayloadAction<DeviceName>) => {
      state.data.forEach((device) => {
        if (device.Id === action.payload.deviceId) {
          device.Name = action.payload.deviceName || "";
        } else if (device.Id === action.payload.masterDeviceId) {
          const childDevice = deviceUtilFunc.findChildDeviceById(
            action.payload.deviceId || "",
            device,
          );
          if (childDevice) {
            childDevice.Name = action.payload.deviceName;
          }
        }
      });
      if (state.selectedDevice.Id === action.payload.deviceId) {
        state.selectedDevice.Name = action.payload.deviceName || "";
      } else if (state.selectedDevice.Name === action.payload.masterDeviceId) {
        const childDevice = deviceUtilFunc.findChildDeviceById(
          action.payload.deviceId || "",
          state.selectedDevice,
        );
        if (childDevice) {
          childDevice.Name = action.payload.deviceName;
        }
      }
    },
    removeArchiveDevice: (state, action: PayloadAction<string>) => {
      const dataIndex = state.data.findIndex((d) => d.Id === action.payload);
      if (dataIndex !== -1) {
        state.data.splice(dataIndex, 1);
      }
      const allDeviceIndex = state.allDevicesForModal.findIndex(
        (d) => d.Id === action.payload,
      );
      if (allDeviceIndex !== -1) {
        state.allDevicesForModal.splice(allDeviceIndex, 1);
      }
      state.activeDeviceId = "";
      state.selectedDevice = defaultSelectedSwrmDevice;
    },
    setSelectedDevice: (state, action: PayloadAction<SwrmDevices>) => {
      state.selectedDevice = { ...action.payload };
    },
    setDeviceGroup: (state, action: PayloadAction<DeviceGroup>) => {
      if (action.payload) {
        state.selectedDevice.Groups.push(action.payload);
        const index = state.data.findIndex(
          (x) => x.Id === state.selectedDevice.Id,
        );
        if (index !== -1) {
          state.data[index].Groups = state.selectedDevice.Groups;
        }
      }
    },
    removeDeviceGroup: (state, action: PayloadAction<number>) => {
      if (action.payload) {
        const groupIndex = state.selectedDevice.Groups.findIndex(
          (g) => g.Id === action.payload,
        );
        state.selectedDevice.Groups.splice(groupIndex, 1);
        const index = state.data.findIndex(
          (x) => x.Id === state.selectedDevice.Id,
        );
        if (index !== -1) {
          state.data[index].Groups = state.selectedDevice.Groups;
        }
      }
    },
    resetSelectedDevice: (state) => {
      state.selectedDevice = defaultSelectedSwrmDevice;
    },
    setDeviceWifiStatusConnected: (state, action: PayloadAction<string>) => {
      state.selectedDevice.WifiStatus = true;
      state.data.map((d: SwrmDevices) => {
        if (d.Id === action.payload) {
          d.WifiStatus = true;
        }
      });
    },
  },
  extraReducers: (build) => {
    // GET DEVICE SLICES
    build.addCase(getAllDevices.pending, (state, action) => {
      state.isLoading = true;
    });
    build.addCase(getAllDevices.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.Result && Array.isArray(action.payload.Result)) {
        const devicesWithThumbnails = buildDevicesThumbnails(
          action.payload.Result,
        );
        state.data = [...devicesWithThumbnails];
      }
    });
    build.addCase(getAllBoardTypes.fulfilled, (state, action) => {
      if (action.payload.Result && Array.isArray(action.payload.Result)) {
        state.boardTypes = [...action.payload.Result];
      }
    });
    build.addCase(getAllDevices.rejected, (state, action) => {
      state.isLoading = false;
    });
    build.addCase(getDevice.fulfilled, (state, action) => {
      // if (action.payload.Result && Array.isArray(action.payload.Result)) {
      //   const formattedDevices = deviceUtilFunc.setupSingleDevice(
      //     action.payload.Result,
      //   );
      //   state.allDevicesWithComponents = [...formattedDevices!];
      // }
    });
    build.addCase(filterDevices.fulfilled, (state, action) => {
      if (action.payload.Result === undefined) {
        state.allDevicesForModal.splice(0, state.allDevicesForModal.length);
      } else {
        const devicesWithThumbnails = buildDevicesThumbnails(
          action.payload.Result,
        );
        state.allDevicesForModal = [...devicesWithThumbnails];
      }
    });
    build.addCase(patchDeviceLocation.fulfilled, (state, action) => {
      const index = state.data.findIndex(
        (x) => x.Id === action.meta.arg.deviceId,
      );
      if (index !== -1) {
        state.data[index].Location = action.payload.Result;
        state.data[index].Groups = [];
      }
      state.selectedDevice.Location = action.payload.Result;
      state.selectedDevice.Groups = [];
    });
    build.addCase(syncRelays.fulfilled, (state, action) => {
      const relayWithValues: RelayWithValues[] = [...action.payload.Result];

      state.selectedDevice.RelayWithValues = relayWithValues;
      OpenSuccessNotification("Sync Successful");
    });
    build.addCase(getRoutines.fulfilled, (state, action) => {
      const routines: Routine[] = [...action.payload.Result];

      state.selectedDevice.Routines = routines;
    });
    build.addCase(getRoutineSteps.fulfilled, (state, action) => {
      const routineSteps: Step[] = [...action.payload.Result];

      state.selectedDevice.Steps = routineSteps;
    });
    build.addCase(getRoutineStepRelays.fulfilled, (state, action) => {
      const stepRelayIds: number[][] = [...action.payload.Result];

      state.selectedDevice.StepRelayIds = stepRelayIds;
    });
    build.addCase(deleteRoutine.fulfilled, (state, action) => {
      if (!action.payload.IsError) {
        state.selectedDevice.Routines = [];
        state.selectedDevice.Steps = [];
        state.selectedDevice.StepRelayIds = [];
      }
    });
  },
});

const buildDevicesThumbnails = (payload: SwrmDevices[]) => {
  payload.map((device) => {
    device.Thumbnail =
      device.Media?.ThumbnailUrl &&
      awsUtils.getSignedImageURL(device.Media?.ThumbnailUrl);
  });

  return payload;
};

export const {
  setAllDevices,
  setSelectedDevice,
  resetSelectedDevice,
  changeDevicesOrder,
  updateSensorsInDevice,
  updateDeviceName,
  updateDeviceModelInDevice,
  updateRelayInDevice,
  updateRelay,
  setActiveDeviceImageCard,
  setDeviceGroup,
  removeDeviceGroup,
  resetDeviceImageCard,
  removeArchiveDevice,
  setDeviceWifiStatusConnected,
} = devicesSlice.actions;

export const selectDevices = (state: RootState) => state.devices;
export const isDeviceLoading = (state: RootState) => state.devices.isLoading;

export default devicesSlice.reducer;
