import React, { useState } from "react";
import { AIModelControl } from "../../Features/tabs/device-manager/aimodel-control/AIModelControl";
import { CurrentData } from "../../Features/tabs/device-manager/current-data/CurrentData";
import { Sensors } from "../../Features/tabs/device-manager/sensors/Sensors";
import { Tabs } from "../../Components/tab/tabs/Tabs";
import { Relays } from "../../Features/tabs/device-manager/relays/Relays";
import { HistoricalData } from "../../Features/tabs/device-manager/historical-data/HistoricalData";
import { Boards } from "../../Features/tabs/device-manager/boards/Boards";
import { Grouping } from "../../Features/tabs/device-manager/grouping/Grouping";
type TabsType = {
  label: string;
  index: number;
  Component: React.FC<{}>;
}[];

// Tabs Array
const tabs: TabsType = [
  {
    label: "Current Data",
    index: 1,
    Component: CurrentData,
  },
  {
    label: "Historical Data",
    index: 2,
    Component: HistoricalData,
  },
  {
    label: "Boards",
    index: 3,
    Component: Boards,
  },
  {
    label: "Grouping",
    index: 4,
    Component: Grouping,
  },
  {
    label: "Relays",
    index: 5,
    Component: Relays,
  },
  {
    label: "Sensors",
    index: 6,
    Component: Sensors,
  },
  {
    label: "AI Model Control",
    index: 7,
    Component: AIModelControl,
  },
];

export default function DeviceManagerTabs() {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index);
  const handleEvent = (e: number) => {
    setSelectedTab(e);
  };

  return (
    <Tabs
      selectedTab={selectedTab}
      onClick={handleEvent}
      tabs={tabs}
      variant="subPageTab"
      padding="0"
    />
  );
}
