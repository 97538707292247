import styled from "styled-components";

export const CustomImageCardComponent = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  white-space: nowrap;
  display: flex;
  padding-right: 10px;
  gap: 1rem;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;

    &:hover {
      background: #555;
    }
  }
`;
