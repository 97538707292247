import styled from "styled-components";

export const StyledPaymentForm = styled.form`
  max-width: calc(100% - 20vw);
  width: 100%;
  padding: 1rem 0;
  border-radius: 5px;

  .card-element {
    border: 1px solid ${(props) => props.theme.colors.primary};
    padding: 10px;
    border-radius: 5px;
    margin-top: 0.5rem;
  }

  .pay-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;
export const PaymentFormGroup = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;
export const PaymentCVCExpiryInputGroup = styled(PaymentFormGroup)`
  padding-right: 1rem;
  &:last-child {
    padding-right: 0rem;
    padding-left: 1rem;
  }
`;
export const PaymentInputLabel = styled.label`
  font-size: 1vw;
  font-weight: bold;
  padding: 10px 0px;
`;
export const PaymentFormWrapper = styled.div`
  width: calc(100% - 20vw);
  padding: 0 2rem;
`;
