import { BsCreditCardFill } from "react-icons/bs";
import { RiMastercardFill, RiVisaLine } from "react-icons/ri";
import { SiAmericanexpress, SiGooglepay } from "react-icons/si";
import styled from "styled-components";
import { PaymentCardProps } from "./PaymentCard";
import { GrPaypal } from "react-icons/gr";

export const PaymentMethodWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const CurrentPaymentMethodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-right: ${(props) => props.theme.border["primary-sm"]};
  padding-right: 3vw;
  width: 20vw;
`;

export const CurrentCardWrapper = styled.div`
  display: flex;
  width: 15vw;
  justify-content: space-between;
  align-items: center;
`;

export const PaymentCardWrapper = styled.div<
  Pick<PaymentCardProps, "isActive" | "isCardList">
>`
  display: flex;
  width: 7.6vw;
  height: 7.8vh;
  border-radius: 7px;
  background: ${({ isActive, theme }) =>
    isActive ? theme.colors["primary"] : ""};
  flex-direction: column;
  justify-content: center;
  padding: 0 1vw;
  gap: 5px;
  border: ${(props) => props.theme.border["primary-sm"]};

  &:hover {
    border: ${(props) => props.theme.border["secondary-sm"]};
  }

  & svg {
    color: ${({ isActive }) => (isActive ? "black" : "white")};
  }
`;

export const VisaCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  width: 3.3vw;
  height: 3.4vh;
  border: 1px solid #e6e7e8;
  border-radius: 5%;
`;
export const CardInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2vw 0;
`;
export const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1vw;
`;
export const CardTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledBsCreditCardFill = styled(
  BsCreditCardFill,
)<PaymentCardProps>`
  color: ${(props) =>
    props.isActive
      ? props.theme.colors["black-200"]
      : props.theme.colors.white};
  font-size: 1.5vw;
`;
export const StyledGrPaypal = styled(GrPaypal)<PaymentCardProps>`
  color: ${(props) =>
    props.isActive
      ? props.theme.colors["black-200"]
      : props.theme.colors.white};
  font-size: 1.5vw;
`;

export const StyledBRiVisaLine = styled(RiVisaLine)`
  color: ${(props) => props.theme.colors["blue-100"]};
  font-size: 2vw;
`;

export const StyledRiMastercardFill = styled(RiMastercardFill)`
  color: ${(props) => props.theme.colors["blue-100"]};
  font-size: 2vw;
`;
export const StyledSiAmericanexpress = styled(SiAmericanexpress)`
  color: ${(props) => props.theme.colors["blue-100"]};
  font-size: 2vw;
`;
export const StyledSiGooglepay = styled(SiGooglepay)`
  color: ${(props) => props.theme.colors["blue-100"]};
  font-size: 2vw;
`;
export const CardIconsWrapper = styled.div`
  display: flex;
  gap: 1vw;
  position: absolute;
  bottom: 10px;
  right: 4px;
`;
