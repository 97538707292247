import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../Store/store";

export interface RadioState {
  id: number;
  value: string;
  isChecked: boolean;
  label: string;
}

const initialState: RadioState[] = [
  { id: 1, value: "Enabled", isChecked: true, label: "Device Status" },
  { id: 2, value: "Disabled", isChecked: false, label: "Device Status" },
  { id: 3, value: "No Access", isChecked: false, label: "Access Status" },
  { id: 4, value: "Partial Access", isChecked: false, label: "Access Status" },
  { id: 5, value: "Full Access", isChecked: false, label: "Access Status" },
  { id: 6, value: "High Confidence", isChecked: true, label: "AI Confidence" },
  { id: 7, value: "Low Confidence", isChecked: false, label: "AI Confidence" },
];

export const radioSlice = createSlice({
  name: "radio",
  initialState,
  reducers: {
    checkRadio: (state, action: PayloadAction<number>) => {
      const checkedRadioId = action.payload;
      state.map((radioItem) => {
        if (radioItem.id === checkedRadioId)
          return (radioItem.isChecked = true);
        else return (radioItem.isChecked = false);
      });
    },
    resetDeviceStatusRadio: (state) => {
      state
        .filter((radioItem) => radioItem.label === "Device Status")
        .map((radioItem) => {
          if (radioItem.id === 1) return (radioItem.isChecked = true);
          else if (radioItem.id === 2) return (radioItem.isChecked = false);
          else return radioItem;
        });
    },
  },
});

export const { checkRadio, resetDeviceStatusRadio } = radioSlice.actions;

export const selectAllRadioButtons = (state: RootState) => state.radio;

export default radioSlice.reducer;
