import React, { useState } from "react";
import { Theme } from "../../app/Theme";
import SideNavBar from "../../Components/nav/side-nav/SideNavBar";
import { ContentWrapper } from "../../Components/content-wrapper/ContentWrapper";
import TopNavBar from "../../Components/nav/top-nav/TopNavBar";
import Titles from "../../Components/title/Titles";
import { Tabs } from "../../Components/tab/tabs/Tabs";
import Documentation from "../../Components/tab/integration/Documentation";
import Status from "../../Components/tab/integration/Status";
import TokenGeneration from "../../Components/tab/integration/TokenGeneration";
import { ITab } from "../../Types/page-tabs/pageTabs";

interface IntegrationProps {}

const tabs: ITab[] = [
  {
    label: "Documentation",
    index: 1,
    Component: Documentation,
  },
  {
    label: "Status",
    index: 2,
    Component: Status,
  },
  {
    label: "Token Generation",
    index: 3,
    Component: TokenGeneration,
  },
];

const Integration: React.FC<IntegrationProps> = (props) => {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index);
  const handleEvent = (e: number) => {
    setSelectedTab(e);
  };

  return (
    <>
      <Theme>
        <SideNavBar />
        <ContentWrapper>
          <TopNavBar />
          <Titles
            heading="Integration"
            subheading="Integrate our API's into your pipeline"
          />
          <Tabs selectedTab={selectedTab} onClick={handleEvent} tabs={tabs} />
        </ContentWrapper>
      </Theme>
    </>
  );
};

export default Integration;
