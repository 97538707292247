import styled from "styled-components";
import { HelperTextProps } from "./HelperText";

// styles for helper text
export const CustomHelperText = styled.p<Pick<HelperTextProps, "error">>`
  font-size: ${(props) => props.theme.fontSizes["xs"]};
  color: ${({ error }) => {
    if (error) return "red-300";
    return "#fff";
  }};
`;
