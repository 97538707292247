import React from "react";
import { CustomTabLinks } from "./TabLinks.style";
import { Typography } from "../../../typrography/Typography";
import { DefaultThemeColor, DefaultThemeFontSize } from "../../../../app/Theme";

export interface TabLinksProps {
  className: string;
  onClick: (index: number) => void;
  tabIndex: number;
  children: string;
  active: boolean;
  style?: any;
  borderDirection?: string;
  tabWidth?: string;
  fontSize?: string;
  disabled?: boolean;
}
export const TabLinks: React.FC<TabLinksProps> = ({
  children,
  className,
  onClick,
  tabIndex,
  active,
  style,
  borderDirection,
  tabWidth,
  fontSize,
  disabled = false,
}) => {
  return (
    <CustomTabLinks
      onClick={() => onClick(tabIndex)}
      className={className}
      tabIndex={tabIndex}
      active={active}
      style={style}
      borderDirection={borderDirection}
      tabWidth={tabWidth}
      fontSize={fontSize}
      disabled={disabled}
    >
      <Typography
        disabled={disabled}
        style={{
          width: tabWidth,
          fontSize: fontSize ? fontSize : DefaultThemeFontSize["2xl"],
          textAlign: borderDirection === "row" ? "right" : "center",
          paddingRight: borderDirection === "row" ? "1vw" : "0",
          color: disabled
            ? "#444444"
            : className === "active"
            ? DefaultThemeColor["primary"]
            : "white",
        }}
      >
        {children}
      </Typography>
    </CustomTabLinks>
  );
};
