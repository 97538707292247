import { useEffect, useState } from "react";
import { FilterOption } from "../filter-dropdown/FilterDropdown";
import { FilterDropdownWrapper } from "../filter-dropdown/FilterDropdown.style";
import {
  multiSelectDropdownStyle,
  multiSelectDropdownStyleDisabled,
} from "./MultiSelectDropdown.style";
import Select, { ActionMeta, MenuPlacement, MultiValue } from "react-select";

export interface MultiSelectDropdownProps {
  defaultValue?: FilterOption[] | undefined;
  options: FilterOption[] | undefined;
  title: string;
  isMulti?: boolean;
  menuPlacement?: MenuPlacement;
  isFullSize?: boolean;
  onChange?: (newValue: FilterOption[] | null) => void;
  clearValues?: boolean;
}

const MultiSelectDropdown = ({
  defaultValue,
  options,
  title,
  menuPlacement,
  isFullSize,
  onChange,
  clearValues,
}: MultiSelectDropdownProps) => {
  const [selectedOptions, setSelectedOptions] = useState(defaultValue);
  const handleOptionClick = (
    newValue: MultiValue<FilterOption> | null,
    actionMeta: ActionMeta<FilterOption>,
  ) => {
    const selectedRelayOptions: FilterOption[] = (
      newValue as FilterOption[]
    ).map((option) => ({
      label: option.label,
      value: option.value,
    }));

    if (onChange) onChange(selectedRelayOptions);
    setSelectedOptions(selectedRelayOptions);
  };

  useEffect(() => {
    if (clearValues) setSelectedOptions([]);
  }, [clearValues]);

  return (
    <FilterDropdownWrapper>
      <Select
        id="multiselect-dropdown"
        defaultValue={defaultValue}
        value={selectedOptions}
        name={title}
        options={options}
        onChange={handleOptionClick}
        isMulti={true}
        closeMenuOnScroll={true}
        closeMenuOnSelect={false}
        placeholder={title}
        menuPlacement={menuPlacement || "bottom"}
        menuPosition="fixed"
        maxMenuHeight={250}
        styles={
          clearValues
            ? multiSelectDropdownStyleDisabled
            : multiSelectDropdownStyle
        }
        isDisabled={clearValues && true}
      />
    </FilterDropdownWrapper>
  );
};

export default MultiSelectDropdown;
