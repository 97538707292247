import React, { useEffect, useState } from "react";
import { Typography } from "../../../Components/typrography/Typography";
import LocationCreateModal from "../../../Components/modals/location-create-modal/LocationCreateModal";
import {
  ResponsiveFlexContainer,
  FlexContainer,
  FilterButtonWrapper,
  StyledWrapper,
  StyledDiv,
} from "../../../Components/modals/Modals.style";

export const LocationCreate = () => {
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);

  const handleOpenLocationModal = () => setIsLocationModalOpen(true);
  const handleOpenGroupModal = () => setIsGroupModalOpen(true);
  const handleCloseLocationModal = () => setIsLocationModalOpen(false);
  const handleCloseGroupModal = () => setIsGroupModalOpen(false);

  return (
    <StyledWrapper>
      <FlexContainer justifyContent="space-between" width="100%">
        <ResponsiveFlexContainer>
          <StyledDiv>
            <div>
              <Typography
                style={{
                  fontSize: "3.0rem",
                  textAlign: "left",
                  fontWeight: "bold",
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                Add & Create Locations
              </Typography>
              <Typography
                direction="flex-start"
                style={{ marginBottom: "30px", fontWeight: "normal" }}
              >
                Upload a Thumbnail, Name and Describe your Location and add it
                to your library
              </Typography>

              <FilterButtonWrapper>
                <button onClick={handleOpenLocationModal}>
                  Create Location
                </button>
              </FilterButtonWrapper>
            </div>
          </StyledDiv>
          <LocationCreateModal
            isOpen={isLocationModalOpen}
            onClose={handleCloseLocationModal}
            title="Create a Location"
            inputLabel="Name the Location"
            dropdownLabel="Select Location Type"
            buttonText="Create New Location"
            editorLabel="Describe your Location"
          />
        </ResponsiveFlexContainer>
        <ResponsiveFlexContainer>
          <StyledDiv>
            <Typography
              style={{
                fontSize: "3.0rem",
                textAlign: "left",
                fontWeight: "bold",
                width: "100%",
                marginBottom: "20px",
              }}
            >
              Add & Create Groups
            </Typography>

            <Typography direction="flex-start" style={{ marginBottom: "30px" }}>
              Create and categories groups to assist in the management of
              devices
            </Typography>

            <FilterButtonWrapper>
              <button onClick={handleOpenGroupModal}>Create Group</button>
            </FilterButtonWrapper>
          </StyledDiv>
          <LocationCreateModal
            isOpen={isGroupModalOpen}
            onClose={handleCloseGroupModal}
            title="Create a Group"
            inputLabel="Name the Group"
            dropdownLabel="Select Group Type"
            buttonText="Create New Group"
            editorLabel="Describe your Group"
          />
        </ResponsiveFlexContainer>
      </FlexContainer>
    </StyledWrapper>
  );
};

export default LocationCreate;
