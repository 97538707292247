import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { DefaultThemeColor } from "../../../app/Theme";

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  &:hover {
    color: ${(props) => props.theme.colors["primary"]};
  }
`;

export const StyledFontAwesomeIconButton = styled(FontAwesomeIcon)`
  padding: 6px;
  border-radius: 6px;
  color: #000;
  background-color: ${DefaultThemeColor["primary"]};
`;
