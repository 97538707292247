import styled from "styled-components";

export const IntegrationWrapper = styled.div`
  display: flex;
  padding: 0 2vw;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
`;

export const IntegrationItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const IntegrationItemBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 33.3%;
  padding-inline: 1rem;
  border: ${(props) => props.theme.border["border-sm-yellow"]};
  border-radius: ${(props) => props.theme.borderRadius["rounded-md"]};
`;
