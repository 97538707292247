// Loader.style.ts
import styled from "styled-components";

export const LoaderWrapper = styled.div<{ $isLoadingScreen: boolean }>`
  ${(props) =>
    props.$isLoadingScreen &&
    `
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000; /* Adjust the z-index as needed */

        .overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6); /* Adjust the opacity as needed */
            z-index: -1;
        }
    `}

  .loader-container {
    text-align: center;
    z-index: 1;
  }
`;
