import React, { useEffect, useRef, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { StyledFormikForm } from "../../../../Components/tab/ai-model-manager/Upload.style";
import * as Yup from "yup";
import { FlexContainer } from "../../../../Components/flex-container/FlexContainer";
import Input from "../../../../Components/input/Input";
import { ScaledButtonWrapper } from "../../../../Components/modals/CustomModal.style";
import { Typography } from "../../../../Components/typrography/Typography";
import { FilterOption } from "../../../../Components/dropdown/filter-dropdown/FilterDropdown";
import { useAppDispatch } from "../../../../Store/hooks";
import { RelayAddRoutineBoxHeader } from "./RelayBox.style";
import RelayRoutineForm from "./RelayRoutineForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import Checkbox from "../../../../Components/checkbox/Checkbox";
import { CheckboxWrapper } from "../../../../Components/tables/table-button/TableButton.style";
import {
  RelayAddRoutineWrapper,
  RoutineErrorMessage,
  RoutineStepActionButton,
  RoutineStepsFormsWrapper,
} from "./RelayRoutine.style";
import { CreateRoutinePayload } from "../../../../Interfaces/Device/Routine";
import routineService, {
  getRoutines,
  getRoutineStepRelays,
  getRoutineSteps,
} from "../../../../Services/DeviceManagement/deviceRoutines.service";
import { ResponseObject } from "../../../../Interfaces/Response";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../../Components/notification/Notification";
import { AppDispatch } from "../../../../Store/store";

interface Props {
  relayOptions: FilterOption[];
  onCancel: () => void;
  selectedDeviceId: string;
}

interface RelayRoutineFormsProps {
  key: number;
  component: JSX.Element;
}

export interface RelayRoutineFormValues {
  Turn: number;
  Relays: number[];
  Value: number;
}

interface FormValues {
  interval: number;
}

const defaultValues: FormValues = {
  interval: 0,
};

const validationSchema = Yup.object({
  interval: Yup.number().required("Value is required").min(0, "Value invalid"),
});

const RelayRoutineSetup = ({
  relayOptions,
  onCancel,
  selectedDeviceId,
}: Props) => {
  const dispatch: AppDispatch = useAppDispatch();
  const [stepNumber, setStepNumber] = useState<number>(1);
  const [componentKey, setComponentKey] = useState<number>(0);
  const [loopIndefinitelyStatus, setLoopIndefinitelyStatus] =
    useState<boolean>(true);
  const [routineFormFieldValues, setRoutineFormFieldValues] = useState<
    RelayRoutineFormValues[]
  >([]);
  const scrollToRef = useRef<HTMLDivElement>(null);
  const updateStates = async (incrementStates: boolean) => {
    setComponentKey((prevComponentKey) =>
      incrementStates ? prevComponentKey + 1 : prevComponentKey - 1,
    );
    setStepNumber((prevStepNumber) =>
      incrementStates ? prevStepNumber + 1 : prevStepNumber - 1,
    );
  };

  const handleAddRoutine = () => {
    return null;
  };

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    try {
      // Check if Relays were passed to all steps
      const formsWithNoRelays: RelayRoutineFormValues[] =
        routineFormFieldValues.filter((form) => {
          return form.Turn == 1 && form.Relays.length == 0;
        });

      const firstFormHasNoRelays: boolean =
        routineFormFieldValues[0].Relays.length == 0;

      if (firstFormHasNoRelays) {
        return OpenErrorNotification("First step must have selected relays");
      }

      if (formsWithNoRelays.length !== 0) {
        return OpenErrorNotification("Please select relays for your routine");
      }

      const payload: CreateRoutinePayload = {
        DeviceId: selectedDeviceId,
        RepeatIndefinitely: loopIndefinitelyStatus,
        Interval: Number(values.interval),
        RoutineSteps: routineFormFieldValues,
      };

      const response: ResponseObject<string> =
        await routineService.createRoutine(payload);
      if (!response.IsError) {
        OpenSuccessNotification(response.Result);
        dispatch(getRoutines(selectedDeviceId));
        dispatch(getRoutineSteps(selectedDeviceId));
        dispatch(getRoutineStepRelays(selectedDeviceId));
        onCancel();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddNewStep = async () => {
    await updateStates(true);
    setRoutineStepForms([
      ...routineStepForms,
      {
        key: componentKey + 1,
        component: (
          <RelayRoutineForm
            index={componentKey + 1}
            stepNumber={stepNumber + 1}
            relayOptions={relayOptions}
            onEditRoutine={handleRoutineStepChanges}
            onSaveRoutine={handleAddRoutine}
          />
        ),
      },
    ]);
  };

  const handleRemoveStep = () => {
    const copiedRoutineStepForms: RelayRoutineFormsProps[] = [
      ...routineStepForms,
    ];
    const copiedRoutineFormValues: RelayRoutineFormValues[] = [
      ...routineFormFieldValues,
    ];
    copiedRoutineStepForms.pop();
    copiedRoutineFormValues.pop();
    setRoutineStepForms(copiedRoutineStepForms);
    setRoutineFormFieldValues(copiedRoutineFormValues);
    updateStates(false);
  };

  const handleRoutineStepChanges = (
    index: number,
    fieldName: string,
    value: number | number[],
  ) => {
    setRoutineFormFieldValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = {
        ...newValues[index],
        [fieldName]: value,
      };
      return newValues;
    });
  };

  const [routineStepForms, setRoutineStepForms] = useState<
    RelayRoutineFormsProps[]
  >([
    {
      key: componentKey,
      component: (
        <RelayRoutineForm
          index={componentKey}
          stepNumber={stepNumber}
          relayOptions={relayOptions}
          onEditRoutine={handleRoutineStepChanges}
          onSaveRoutine={handleAddRoutine}
        />
      ),
    },
  ]);

  useEffect(() => {
    scrollToRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [stepNumber]);

  return (
    <>
      {/* Action buttons */}
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, errors, handleChange }: any) => (
          <StyledFormikForm id="create-routine-form">
            <RelayAddRoutineWrapper>
              <RelayAddRoutineBoxHeader>
                <CheckboxWrapper gap="0.5rem">
                  <Typography variant="md">Loop indefinitely</Typography>
                  <Checkbox
                    isChecked={loopIndefinitelyStatus}
                    onChange={() => {
                      setLoopIndefinitelyStatus((currentStatus) => {
                        if (currentStatus) {
                          setFieldValue("interval", 5);
                        } else {
                          setFieldValue("interval", 0);
                        }

                        return !currentStatus;
                      });
                    }}
                    width="1rem"
                    height="1rem"
                  />
                </CheckboxWrapper>
                <FlexContainer gap="1rem" alignItems="center">
                  <Typography variant="md" color="grey-500">
                    Loop interval
                  </Typography>

                  <div style={{ position: "relative" }}>
                    <Input
                      type="number"
                      name="interval"
                      width="4rem"
                      height="1.7rem"
                      padding="0"
                      margin="0"
                      value={defaultValues.interval.toString()}
                      onChange={handleChange}
                      alignCenter
                      disabled={loopIndefinitelyStatus}
                      hasCustomErrorMessage
                    />

                    <RoutineErrorMessage right="-75%">
                      {errors.interval}
                    </RoutineErrorMessage>
                  </div>
                </FlexContainer>
              </RelayAddRoutineBoxHeader>

              <RoutineStepsFormsWrapper>
                {routineStepForms.map(({ key, component }) => (
                  <React.Fragment key={key}>{component}</React.Fragment>
                ))}
              </RoutineStepsFormsWrapper>

              <FlexContainer gap="1.3rem" justifyContent="start">
                <Tooltip id="add-step-tooltip" />
                <RoutineStepActionButton
                  data-tooltip-id="add-step-tooltip"
                  data-tooltip-content="Add Step"
                  data-tooltip-place="top"
                  type="button"
                  onClick={handleAddNewStep}
                >
                  <FontAwesomeIcon
                    size="lg"
                    icon={faPlus}
                    data-tooltip-id="add-step-tooltip"
                    data-tooltip-content="Add Step"
                    data-tooltip-place="top"
                  />

                  <Typography variant="xs" color="grey-500">
                    Add new step
                  </Typography>
                </RoutineStepActionButton>

                {routineStepForms.length > 1 && (
                  <>
                    <Tooltip id="remove-step-tooltip" />
                    <RoutineStepActionButton
                      data-tooltip-id="remove-step-tooltip"
                      data-tooltip-content="Remove Step"
                      data-tooltip-place="top"
                      type="button"
                      onClick={handleRemoveStep}
                    >
                      <FontAwesomeIcon
                        size="lg"
                        icon={faTrash}
                        data-tooltip-id="remove-step-tooltip"
                        data-tooltip-content="Remove Step"
                        data-tooltip-place="top"
                      />
                      <Typography variant="xs" color="grey-500">
                        Delete last step
                      </Typography>
                    </RoutineStepActionButton>
                  </>
                )}
              </FlexContainer>

              <FlexContainer
                gap="1rem"
                justifyContent="center"
                alignItems="center"
              >
                <ScaledButtonWrapper buttonPadding={"10px"}>
                  <button type="submit" form="create-routine-form">
                    Create
                  </button>
                  <button type="button" className="danger" onClick={onCancel}>
                    Cancel
                  </button>
                </ScaledButtonWrapper>
              </FlexContainer>
            </RelayAddRoutineWrapper>
          </StyledFormikForm>
        )}
      </Formik>
      {/* Scroll To Anchor */}
      <div ref={scrollToRef}></div>
    </>
  );
};

export default RelayRoutineSetup;
