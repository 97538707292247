import styled from "styled-components";

export const NavContainer = styled.div<{ size?: string }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 15vh;
  background: #020a01;
  text-align: center;
  color: #ffffff;
  position: fixed;
  width: -webkit-fill-available;
  z-index: 10;
  width: ${(props) => (props.size ? props.size : "85%")};

  & a {
    font-size: ${(props) => props.theme.fontSizes["lg"]};
    color: #ffffff;
    text-decoration: none;
    padding: 1rem 2rem;
  }

  & a:hover {
    color: ${(props) => props.theme.colors["primary"]};
  }
`;

export const DropdownWrapper = styled.div`
  display: flex;
  padding: 1rem;

  & div {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s ease-in-out;
  }

  &:hover div.arrow-icon {
    transform: rotate(180deg);
  }
`;

export const ProfileDropDown = styled.div`
  position: relative;
  cursor: pointer;
  /* width: 250px; */
  /* width: 15rem; */
  & nav {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    right: 0;
    top: 75px;
    background-color: ${(props) => props.theme.colors["black-200"]};
    width: 100%;
    z-index: 1;
  }

  & a,
  button {
    display: flex;
    font-size: ${(props) => props.theme.fontSizes["sm"]};
    color: #ffffff;
    text-decoration: none;
    padding: 5px 10px;
    min-height: 2.5rem;
    line-height: 1.5;
    align-items: center;
  }

  & button {
    width: 100%;
    background: ${(props) => props.theme.colors["black-200"]};
    border: none;
    cursor: pointer;
  }

  & button:hover {
    color: ${(props) => props.theme.colors["primary"]};
  }

  & nav > a,
  & nav > button {
    width: 100%;
  }

  & nav > a > span,
  & nav > button > span {
    padding-left: 10px;
  }

  &:hover nav {
    display: flex;
  }
`;

export const TextWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 5px;
  text-align: left;
  & span {
    font-size: ${(props) => props.theme.fontSizes["sm"]};
  }
`;

export const AvatarWrapper = styled.div`
  position: relative;
  display: flex;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  img {
    object-fit: cover;
    border-radius: 50px;
  }
  & div {
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 20px;
    background: ${(props) => props.theme.colors["red-300"]};
    top: 0;
    right: 0;
    font-size: ${(props) => props.theme.fontSizes["xs"]};
  }
`;
