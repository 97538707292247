import React from "react";
import HelpDropdownList from "../../dropdown/help-dropdown-list/HelpDropdownList";
import helpDataList from "../../../data/faqAndHelpDropdowns";

const DeviceIssues = () => {
  const data = helpDataList.filter((item) => item.menuType === "deviceIssues");
  return <HelpDropdownList data={data} width="80%" />;
};

export default DeviceIssues;
