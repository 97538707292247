import { Editor } from "@tinymce/tinymce-react";

interface Props {
  apiKey?: string;
  initialValue?: string;
  init?: object;
  editorHeight?: number;
  field?: any;
  form?: any;
  fieldName?: string;
  currentContent?: string;
  onUpdate?: (data: string) => void;
  onBlur?: () => void;
}

const TextEditor = ({ initialValue, form, onBlur }: Props) => {
  return (
    <>
      <Editor
        initialValue={initialValue || ""}
        apiKey="sxeojllxpeozglayvlabrhbo7ndezfo2yqy72vynqgnc0ok2"
        textareaName="description"
        onEditorChange={(stringifiedHtmlValue) => {
          form.setFieldValue("description", stringifiedHtmlValue);
        }}
        onBlur={onBlur}
        init={{
          branding: false,
          height: "10rem",
          menubar: false,
          statusbar: false,
          skin: "tinymce-5-dark",
          content_css: "dark",
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px; background-color:#020A01; margin:0 20px;}",

          /*
            Previous implementation. Keeping this for the time being just in case.
          */
          // // add a setup function to the init object that listens for changes to the editor
          // // and updates the value of the Field component using the setFieldValue function from Formik.
          // setup: (editor: any) => {
          //   editor.on("change", () => {
          //     const description = editor.getContent();
          //     if (form && form.setFieldValue) {
          //       form.setFieldValue(
          //         fieldName !== null ? fieldName : "description",
          //         description,
          //       );
          //     }
          //     if (onUpdate) {
          //       onUpdate(description); // Notify parent of the change
          //     }
          //   });
          // },
        }}
      />
    </>
  );
};

export default TextEditor;
