import React, { useEffect, useState } from "react";
import { Typography } from "../../../../Components/typrography/Typography";
import { ScaledButtonWrapper } from "../../../../Components/modals/CustomModal.style";
import CustomModal from "../../../../Components/modals/CustomModal";
import {
  Relay,
  RelayRule,
  RelayRuleAction,
  RelayWithValues,
  RuleComparison,
} from "../../../../Interfaces/Device/Relay";
import { Formik } from "formik";
import { StyledFormikForm } from "../../../../Components/tab/ai-model-manager/Upload.style";
import * as Yup from "yup";
import Input from "../../../../Components/input/Input";
import { FormControl } from "../../../../Components/form-field/form-control/FormControl";
import relayService from "../../../../Services/DeviceManagement/deviceRelays.service";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../../Components/notification/Notification";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { updateRelay } from "../../../Slice/devices/devicesSlice";
import { FlexContainer } from "../../../../Components/flex-container/FlexContainer";
import SetupRelayRules from "./SetupRelayRules";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SetupAddRuleTimerHeading } from "./RelayBox.style";
import RelayRuleItem from "./RelayRuleItem";
import ConfirmationModal from "../../../../Components/modals/confirmation-modal/ConfirmationModal";
import { Sensor } from "../../../../Interfaces/Device/Sensor";
import { SwrmDevices } from "../../../../Interfaces/Device/SwrmDevice";
import { IOption } from "../../../../Components/form-field/drop-down/CustomDropdown";
import EditRelayRules from "./EditRelayRules";
import { FilterOption } from "../../../../Components/dropdown/filter-dropdown/FilterDropdown";
interface Props {
  relay: RelayWithValues;
  onClose: () => void;
}

interface FormValues {
  name: string;
}

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .min(1, "Name must be at least 1 character"),
});

const initialRelayRule: RelayRule = {
  Id: 0,
  Enable: true,
  DeviceId: "",
  RelayId: 0,
  Action: RelayRuleAction.On,
  At: 0,
  SensorId: 0,
  Comparison: RuleComparison.LessThan,
  Value: 0,
};

export default function SetupRelayDialog({ relay, onClose }: Props) {
  const dispatch = useAppDispatch();
  const device = useAppSelector((state) => state.devices);
  const selectedDevice = device.selectedDevice;
  const [displayAddRuleForm, setDisplayAddRuleForm] = useState<boolean>(false);
  const [displayEditRuleForm, setDisplayEditRuleForm] =
    useState<boolean>(false);
  const [relayRules, setRelayRules] = useState<RelayRule[]>([]);
  const [currentRuleToEdit, setCurrentRuleToEdit] =
    useState<RelayRule>(initialRelayRule);
  const [currentRuleToDelete, setCurrentRuleToDelete] = useState<number>(0);
  const [confirmMsg, setConfirmMsg] = useState<string>("");
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [allSensors, setAllSensors] = useState([]);
  useEffect(() => {
    let sensors: any = [];
    if (selectedDevice?.Sensors) {
      sensors = selectedDevice?.Sensors.map((x) => ({
        id: x.Id,
        value: x.Name,
        deviceId: x.DeviceId,
      }));
    }
    if (selectedDevice?.ChildDevices) {
      selectedDevice?.ChildDevices.forEach((d) => {
        d.Sensors.forEach((s) => {
          sensors.push({ id: s.Id, value: s.Name, deviceId: s.DeviceId });
        });
      });
    }
    setAllSensors(sensors);
  }, []);
  const initialValues = {
    name: relay?.Name || "",
  };
  // device options for dropdown
  const [deviceDropdownOptions, setDeviceDropdownOptions] = useState<
    FilterOption[]
  >([]);
  useEffect(() => {
    const deviceDropdown: FilterOption[] = [];
    if (selectedDevice?.Sensors?.length) {
      deviceDropdown.push({
        label: selectedDevice?.Name || "",
        value: selectedDevice?.Id || "",
      });
    }
    selectedDevice?.ChildDevices?.filter(
      (d: SwrmDevices) => d.Sensors?.length,
    ).forEach((d: SwrmDevices) => {
      deviceDropdown.push({
        label: d.Name,
        value: d.Id,
      });
    });
    setDeviceDropdownOptions(deviceDropdown);
  }, []);

  useEffect(() => {
    // load relay rules
    loadRelayRules();
  }, []);
  const cardHeader = () => {
    return (
      <Typography
        variant="lg"
        direction="flex-start"
        style={{ padding: "0 20px", fontWeight: "700" }}
      >
        Update Relay
      </Typography>
    );
  };
  const loadRelayRules = async () => {
    const response = await relayService.getRelayRuleByRelay(relay.Id);
    if (response && response.Result) {
      setRelayRules(response.Result);
    }
  };
  const handleSubmit = async (values: FormValues, { setSubmitting }: any) => {
    try {
      const payload: Relay = {
        Id: relay.Id,
        DeviceId: relay.DeviceId,
        Active: relay.Active,
        PinNumber: relay.PinNumber,
        Name: values.name,
      };
      const response = await relayService.updateRelay(payload);
      if (!response.IsError) {
        OpenSuccessNotification(`The Relay has been updated successfully`);
        dispatch(
          updateRelay({
            relay: response.Result,
            masterDeviceId: selectedDevice?.Id || "",
          }),
        );
        onClose();
      }
    } catch (error) {
      console.error("handelSubmit Error:", error);
    }
  };
  const onAddRule = () => {
    setDisplayAddRuleForm(true);
  };
  const onCancel = () => {
    setDisplayAddRuleForm(false);
    setDisplayEditRuleForm(false);
  };
  const handleRuleAdd = (newRelayRule: RelayRule) => {
    setRelayRules((prev) => [...prev, newRelayRule]);
    onCancel();
  };
  const handleRuleEdit = (editedRelayRule: RelayRule) => {
    setRelayRules((prev) =>
      prev.map((item) =>
        item.Id === editedRelayRule.Id ? editedRelayRule : item,
      ),
    );
    onCancel();
  };
  const handleEnableDisabled = async (rule: RelayRule, enabled: boolean) => {
    const response: any = await relayService.updateRelayRule({
      ...rule,
      Enable: enabled,
    });
    if (response.ErrorCode !== 200) {
      OpenErrorNotification(response.ErrorMessage);
    } else {
      const newRelayRules = relayRules.map((r) => {
        if (r.Id === rule.Id) {
          return response.Result;
        } else {
          return r;
        }
      });
      setRelayRules(newRelayRules);
    }
  };

  const handleDeleteClick = (ruleId: number) => {
    const msg = `Are you sure you'd like to delete the rule? This cannot be undone.`;
    setConfirmMsg(msg);
    setCurrentRuleToDelete(ruleId);
    setDeleteModalOpen(true);
  };

  const handleEditClick = (relayRule: RelayRule) => {
    setCurrentRuleToEdit(relayRule);
    setDisplayEditRuleForm(true);
  };

  const handleConfirmDelete = async () => {
    const response: any = await relayService.deleteRelayRule(
      currentRuleToDelete,
    );
    if (response.IsError) {
      OpenErrorNotification(response.ErrorMessage);
    } else {
      OpenSuccessNotification(response.Result);
      setRelayRules((prev) =>
        prev.filter((rule) => currentRuleToDelete !== rule.Id),
      );
    }
    handleConfirmModalClose();
  };

  const handleConfirmModalClose = () => {
    setDeleteModalOpen(false);
    setCurrentRuleToDelete(0);
  };
  return (
    <CustomModal
      isOpen={true}
      onClose={onClose}
      padding="1rem 0"
      width="60vw"
      height="65vh"
      header={cardHeader()}
    >
      <div style={{ padding: "0 1rem" }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, handleChange }: any) => (
            <StyledFormikForm id="rename-relay">
              <Typography color="primary">Edit Relay Name</Typography>
              <FlexContainer alignItems="end">
                <FormControl>
                  <Input
                    name="name"
                    placeHolder="Relay Name"
                    label={"Relay Name"}
                    width="100%"
                    inputWrapperWidth={"100%"}
                    value={values.name} // Now it will recognize values.name
                    onChange={handleChange}
                  />
                </FormControl>
                <ScaledButtonWrapper buttonPadding={"10px"}>
                  <button type="submit" form="rename-relay">
                    Rename
                  </button>
                </ScaledButtonWrapper>
              </FlexContainer>
            </StyledFormikForm>
          )}
        </Formik>
        <SetupAddRuleTimerHeading>
          <Typography color="primary" padding="1rem 0">
            Rules
          </Typography>
          <FontAwesomeIcon
            icon={faPlus}
            onClick={onAddRule}
            className={
              displayAddRuleForm || displayEditRuleForm ? "disabled" : ""
            }
          />
        </SetupAddRuleTimerHeading>
        {/* edit rule form */}
        {displayEditRuleForm && (
          <EditRelayRules
            relayRule={currentRuleToEdit}
            relayId={currentRuleToEdit?.RelayId!}
            deviceDropdownOptions={deviceDropdownOptions}
            onCancel={onCancel}
            onSaveRuleSuccess={handleRuleEdit}
            allSensor={allSensors}
          />
        )}
        {/* setup rules form */}
        {displayAddRuleForm && (
          <SetupRelayRules
            relayId={relay.Id}
            deviceDropdownOptions={deviceDropdownOptions}
            onCancel={onCancel}
            onSaveRuleSuccess={handleRuleAdd}
            allSensor={allSensors}
          />
        )}
        {/* relay rules list */}
        {!displayEditRuleForm && !displayAddRuleForm && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            {relayRules.map((rule) => {
              return (
                <RelayRuleItem
                  key={rule.Id}
                  item={rule}
                  handleEditClick={handleEditClick}
                  handleDeleteClick={handleDeleteClick}
                  handleEnableDisabled={handleEnableDisabled}
                  sensor={allSensors?.find((s: any) => s.id == rule.SensorId)}
                />
              );
            })}
          </div>
        )}
      </div>

      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setDeleteModalOpen}
        onConfirm={handleConfirmDelete}
        headerTitle={"Delete Rule"}
        confirmMsg={confirmMsg}
        onClose={handleConfirmModalClose}
        modalWidth="30vw"
        minWidth="450px"
      />
    </CustomModal>
  );
}
