import React from "react";
import { CustomContainer } from "./Container.style";

export interface ContainerProps {
  children: React.ReactNode | React.ReactNode[];
  noBackground?: boolean | null;
}

export const Container: React.FC<ContainerProps> = ({
  children,
  noBackground,
}) => {
  return <CustomContainer noBackground>{children}</CustomContainer>;
};
