import React, { forwardRef } from "react";
import { CustomImageCardComponent } from "./ImageCardContainer.style";

interface ImageCardContainerProps {
  id: string;
  children: any;
}

export const ImageCardContainer = forwardRef<
  HTMLDivElement,
  ImageCardContainerProps
>(({ id, children }, ref) => {
  return (
    <CustomImageCardComponent id={id} ref={ref}>
      {children}
    </CustomImageCardComponent>
  );
});
