import React, { useEffect, useState } from "react";
import Titles from "../../Components/title/Titles";
import SideNavBar from "./../../Components/nav/side-nav/SideNavBar";
import TopNavBar from "../../Components/nav/top-nav/TopNavBar";
import { Theme } from "../../app/Theme";
import { ContentWrapper } from "../../Components/content-wrapper/ContentWrapper";
import { ImageCarousel } from "../../Components/image-carousel/ImageCarousel";
import { InfoCardPermission } from "./info-card-permission/InfoCardPermission";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import { RootState } from "../../Store/store";
import { getCompanyUsers } from "../../Services/Company/company.service";
import { UserCompany } from "../../Interfaces/User";
import UserCard from "./UserCard";

const Permissions: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state: RootState) => state.users);

  useEffect(() => {
    dispatch(getCompanyUsers());
  }, []);
  const handleImageIndexChange = (index: number, Id: string) => {
    setCurrentIndex(index);
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      handleImageIndexChange(currentIndex - 1, "");
    }
  };

  const handleNext = () => {
    if (currentIndex < data.length - 1) {
      handleImageIndexChange(currentIndex + 1, "");
    }
  };
  return (
    <>
      <Theme>
        <SideNavBar />
        <ContentWrapper>
          <TopNavBar />
          <Titles
            heading="Permissions"
            subheading="Create and manage your locations and groups"
          />

          <ImageCarousel currentIndex={currentIndex} dataLength={data.length}>
            {data.map((user: UserCompany, index: number) => (
              <UserCard
                key={user.Id}
                user={user}
                onClick={() => handleImageIndexChange(index, `${user.Id}`)}
              />
            ))}
          </ImageCarousel>
          {data[currentIndex]?.Id ? (
            <>
              <InfoCardPermission
                key={data[currentIndex].Id}
                user={data[currentIndex]}
                next={handleNext}
                prev={handlePrev}
              />
            </>
          ) : null}
        </ContentWrapper>
      </Theme>
    </>
  );
};

export default Permissions;
