import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
// import authReducer from './auth';
import radioReducer from "../Components/radio-button/radioSlice";
import dropdownListReducer from "../Features/Slice/dropdownOptions/dropdownListSlice";
import sortByReducer from "../Components/sort-by/sortBySlice";
import sideNavBarReducer from "../Components/nav/side-nav/sideNavBarSlice";
import tabListReducer from "../Components/tabs/tabList/tabListSlice";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { combineReducers } from "redux";
import authReducer from "../Features/Slice/auth/authSlice";
import ThumbnailSizeReducer from "../Components/thumbnail-size/thumbnailSizeSlice";
import devicesSliceReducer from "../Features/Slice/devices/devicesSlice";
import fetchParametersReducer from "../Features/Slice/fetchParameters/fetchParametersSlice";
import locationsReducer from "../Features/Slice/locations/locationsSlice";
import captureReducer from "../Features/Slice/captures/captureSlice";
import aiModelsReducer from "../Features/Slice/ai-models/aiModelSlice";
import subscriptionReducer from "../Features/Slice/subscriptions/subscriptionsSlice";
import loaderReducer from "../Features/Slice/loader/loaderSlice";
import routesReducer from "../Features/Slice/routes/routesSlice";
import usersReducer from "../Features/Slice/users/usersSlice";
import integrationReducer from "../Features/Slice/integration/integrationSlice";

const rootReducer = combineReducers({
  sideNavBar: sideNavBarReducer,
  auth: authReducer, //setup the auth state to redux persist
  routes: routesReducer,
});

const persistConfig = {
  key: "root",
  storage: storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: {
    // routes: routesReducer,
    loader: loaderReducer,
    dropdownList: dropdownListReducer,
    radio: radioReducer,
    sortBy: sortByReducer,
    tabList: tabListReducer,
    persisted: persistedReducer,
    thumbnailSize: ThumbnailSizeReducer,
    devices: devicesSliceReducer,
    locations: locationsReducer,
    fetchParams: fetchParametersReducer,
    captures: captureReducer,
    aiModels: aiModelsReducer,
    subscriptions: subscriptionReducer,
    users: usersReducer,
    integration: integrationReducer,
  },
  /*
  we should ignore all the action types it dispatches when using Redux-Persist
  to avoid non-serializable value error
  */
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      //serializableCheck: {
      //  ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      //},
      serializableCheck: false,
    }),
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
