import styled from "styled-components";
import { ChipProps } from "./Chip";

export const CustomChip = styled.div<
  Pick<ChipProps, "color" | "backgroundColor">
>`
  background-color: ${({ backgroundColor, theme }) => {
    switch (backgroundColor) {
      case "grey":
        return theme.colors["grey-500"];
      case "green":
        return theme.colors["green-100"];
      case "green-100":
        return theme.colors["green-100"];
      case "blue":
        return theme.colors["blue-800"];
      case "pink":
        return theme.colors["pink-100"];
      case "indigo":
        return theme.colors["indigo-100"];
      case "light-blue":
        return theme.colors["blue-700"];
      default:
        return backgroundColor;
    }
  }};
  height: 1rem;
  // width: 58px;
  padding: 0.4rem;
  font-size: ${(props) => props.theme.fontSizes["xs"]};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${(props) => props.theme.borderRadius["rounded-sm"]};
`;
