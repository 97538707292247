import styled from "styled-components";

export const StyledCheckbox = styled.input.attrs((props) => ({
  type: "checkbox",
}))`
  appearance: none;
  background-color: transparent;
  font: inherit;
  width: ${(props) => props.width || "1.3em"};
  height: ${(props) => props.height || "1.3em"};
  border: ${(props) => props.theme.border["primary-sm"]};
  border-radius: 4px;
  display: grid;
  place-content: center;
  cursor: pointer;
  outline: none;

  &::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    background-color: ${(props) => props.theme.colors["primary"]};
  }

  &:hover::before {
    transform: scale(1);
    background-color: white;
  }

  &:checked::before {
    transform: scale(1);
    background-color: ${(props) => props.theme.colors["primary"]};
  }
`;

export const StyledAbsoluteCheckbox = styled(StyledCheckbox)`
  position: absolute;
  background-color: #010a01;
  top: 10px;
  left: 10px;
  width: 1em;
  height: 1em;

  &::before {
    width: 0.5em;
    height: 0.5em;
  }
`;
