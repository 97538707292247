import axios from "axios";
import { Auth0User } from "../../Interfaces/UserFromToken";
import { ResponseObject } from "../../Interfaces/Response";
import { CompanyReturnPayload } from "../Company/company.service";
import { decryptData } from "../../Utils/awsUtils";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

const verifyInSystem = async (id: string, accessToken: string) => {
  let response: ResponseObject<string> = await axios
    .get(`${API_URL}/Authentication/verify?id=${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then(async (response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};

const registerToSystem = async (auth0User: Auth0User, accessToken: string) => {
  let response: ResponseObject<string> = await axios
    .post(`${API_URL}/Authentication/register`, auth0User, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then(async (response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};

const authenticateToSystem = async (
  auth0User: Auth0User,
  accessToken: string,
) => {
  let response: ResponseObject<CompanyReturnPayload> = await axios
    .post(`${API_URL}/Authentication/authenticate`, auth0User, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then(async (response) => {
      if (!response.data.IsError) {
        // Decrypt Data
        const decryptDataResponse = await decryptData(
          response.data.Result,
        ).then((result) => {
          const companyPayload: CompanyReturnPayload = JSON.parse(
            window.atob(result!),
          );
          setCompanyTokenToHeader(companyPayload.CompanyToken);

          return companyPayload;
        });

        response.data.Result = decryptDataResponse;
        return response.data;
      }
    })
    .catch((error) => {
      return error.response.data;
    });

  return response;
};

// const login = async (email: string, password: string) => {
//   const loginInfo = { email: email, password: password };
//   try {
//     let response = await axios
//       .put(`${API_URL}/auth/login`, loginInfo)
//       .then(async (response) => {
//         await console.log(response.data);
//         const token = response.data;
//         if (response) {
//           localStorage.setItem("tokens", token);
//           setAuthTokenToHeader(token);
//         }
//         return response.data;
//       });
//     return response;
//   } catch (error: unknown) {
//     return error;
//   }
// };

// const loginUserPermsAll = async (callback: Function, data?: string) => {
//   if (data === undefined || data === null) {
//     data = "SWRM";
//   }

//   await axios
//     .post(`${API_URL}/auth/login-user-perms-all`, { service: data })
//     .then(async (response) => {
//       //do something
//       if (callback != null) {
//         await callback(response.data);
//       }
//     })
//     .catch(async (err) => {
//       // catch error
//       console.log(err);
//     });
// };

// const getUserTimeZone = async (callback: Function, errorcallback: Function) => {
//   await axios
//     .get(`${API_URL}/user-company/getUserTimeZone`)
//     .then((res) => {
//       //do something
//       if (callback != null) {
//         callback(res);
//       }
//     })
//     .catch((err) => {
//       // catch error
//       if (errorcallback != null) {
//         errorcallback(err);
//       }
//     });
// };

// const userPermission = async (
//   config: object,
//   callback: Function,
//   errCallback: Function,
// ) => {
//   await axios
//     .get(`${API_URL}/access-level/user/permissions`, { params: config })
//     .then(async (res) => {
//       //do something
//       if (callback != null) {
//         await callback(res.data);
//       }
//     })
//     .catch(async (err) => {
//       // catch error
//       if (errCallback != null) {
//         await errCallback(err);
//       }
//     });
// };

// const getComponentsAccess = (
//   id: string,
//   callback: Function,
//   errorcallback: Function,
// ) => {
//   axios
//     .get(`${API_URL}/userComponentAccess/userId/` + id)
//     .then((res) => {
//       //do something
//       if (callback != null) {
//         callback(res);
//       }
//     })
//     .catch((err) => {
//       // catch error
//       if (errorcallback != null) {
//         errorcallback(err);
//       }
//     });
// };

const setAuthTokenToHeader = (Token: any) => {
  if (Token) {
    axios.defaults.headers.Authorization = `Bearer ${Token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

const setIntegrationAuthTokenToHeader = (Token: string) => {
  if (Token) {
    axios.defaults.headers.common["Integration-Authorization"] = Token;
  } else {
    delete axios.defaults.headers.common["Integration-Authorization"];
  }
};

const setIntegrationRefreshTokenToHeader = (Token: string) => {
  if (Token) {
    axios.defaults.headers.common["Integration-Refresh"] = Token;
  } else {
    delete axios.defaults.headers.common["Integration-Refresh"];
  }
};

const setCompanyHeaderLogin = (company: any) => {
  if (company) {
    const CompanyString = JSON.stringify(company, null, 2);
    //localStorage.setItem('companyToken', btoa(CompanyString));
    localStorage.setItem("company", btoa(CompanyString));

    axios.defaults.headers.common["BSD-Company"] = btoa(CompanyString);
  } else {
    delete axios.defaults.headers.common["BSD-Company"];
  }
};
const setCompanyTokenToHeader = (Token: any) => {
  if (Token) {
    localStorage.setItem("company", Token);
    axios.defaults.headers.common["BSD-Company"] = Token;
  } else {
    delete axios.defaults.headers.common["BSD-Company"];
  }
};

const setCompanyHeaderLanding = (company: any) => {
  if (company) {
    //localStorage.setItem('companyToken', btoa(CompanyString));
    const parsedCompany = isJson(company);
    localStorage.setItem("company", parsedCompany);
    axios.defaults.headers.common["BSD-Company"] = parsedCompany;
  } else {
    delete axios.defaults.headers.common["BSD-Company"];
  }
};

function isJson(str: any) {
  try {
    JSON.parse(str);
  } catch (e) {
    return str;
  }
  return JSON.parse(str);
}

const authService = {
  // login,
  // loginUserPermsAll,
  // getUserTimeZone,
  verifyInSystem,
  registerToSystem,
  authenticateToSystem,
  setAuthTokenToHeader,
  setIntegrationAuthTokenToHeader,
  setIntegrationRefreshTokenToHeader,
  setCompanyHeaderLogin,
  setCompanyHeaderLanding,
  setCompanyTokenToHeader,
  // userPermission,
  // getComponentsAccess,
};

export default authService;
