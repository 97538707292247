import styled from "styled-components";

export const IntegrationLoginContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #020a01;
`;

export const IntegrationLoginFormContainer = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;
