import styled from "styled-components";
import { NavigationContainerProps } from "./NavigationContainer";

export const CustomNavigationContainer = styled.div<
  Pick<NavigationContainerProps, "direction">
>`
  position: sticky;
  z-index: 1;
  margin-left: ${(props) => (props.direction === "left" ? "24px" : "0")};
  margin-right: ${(props) => (props.direction === "right" ? "24px" : "0")};
`;
