import styled from "styled-components";
import { FormControlProps } from "./FormControl";

// styles for for form control
export const CustomFormControl = styled.div<Pick<FormControlProps, "variant">>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${({ variant }) => (variant === "small" ? "" : "100%")};
`;
