import { useEffect, useState } from "react";
import WeeklyChart from "./WeeklyChart";
import { getWeeklyScannedSensorData } from "../../Services/Dashboard/dashboard.service";
import { betterDateFormat } from "../../Utils/dateUtils";
import { WeeklyChartWrapper } from "./Dashboard.style";
import { sensorNameToReadableName } from "../../Utils/dashboardUtils";
import Loader from "../../Components/loader/Loader";
import NoDataAvailable from "../../Components/loader/NoDataAvailable";
import { integrationService } from "../../Services/Integration/integration.service";
import { useAppSelector } from "../../Store/hooks";
import { RootState } from "../../Store/store";

// Define the type for weekly chart data
type WeeklyChartData = {
  title: string;
  variant: "Temperature" | "Wind" | "Humidity" | "Current";
};

// Define the structure of the fetched data for the chart
export interface WeeklyData {
  date: string;
  average: number;
  type?: string;
  count?: number;
}

interface SensorWeeklyData {
  [key: string]: WeeklyData[];
}

const barChart = ["Door"];
// Function to format sensor data
const formatWeeklyData = (
  data: { Date: string; AggregatedValue: number }[],
  sensor: string,
): WeeklyData[] => {
  if (barChart.includes(sensor)) {
    return data.map((d) => ({
      date: betterDateFormat(d.Date),
      type: "Open",
      count:
        d.AggregatedValue !== null ?
          parseFloat(d.AggregatedValue.toFixed(2))
        : 0,
      average: 0,
    }));
  }
  return data.map((d) => ({
    date: betterDateFormat(d.Date),
    average:
      d.AggregatedValue !== null ? parseFloat(d.AggregatedValue.toFixed(2)) : 0,
  }));
};

interface Props {
  selectedLocation: number | null | undefined;
  selectedDevice: string | null | undefined;
  setIsDataAvailable?: (value: boolean) => void;
}

// Weekly Chart Section
const WeeklyChartSection = ({
  selectedLocation,
  selectedDevice,
  setIsDataAvailable,
}: Props) => {
  const auth = useAppSelector((state: RootState) => state.persisted.auth);
  const devices = useAppSelector((state: RootState) => state.devices.data);
  const integrations = useAppSelector(
    (state: RootState) => state.integration.integrations,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Define default sensor values
  const defaultChartData: SensorWeeklyData = {};
  const [weeklyData, setWeeklyData] =
    useState<SensorWeeklyData>(defaultChartData);
  const [sensors, setSensors] = useState<string[]>([]);
  const [orderedSensors, setOrderedSensors] = useState<string[]>([]);
  const specificOrder = [
    "Temperature",
    "Humidity",
    "AQI",
    "Current",
    "Voltage",
    "Wind",
    "CO2",
    "Moisture",
    "Conductivity",
    "DiElectricity",
    "Salinity",
    "Field Capacity",
    "Wilting Point",
    "Water Balance",
    "Oxygen",
  ];
  const hideSensor = ["Unknown"];

  // Function to fetch Integration Data
  const fetchIntegrationData = async () => {
    setIsLoading(true);
    const response = await integrationService.getWeeklySensorData(
      selectedDevice,
      selectedLocation,
    );

    if (response.Result !== null) {
      const sensorsList = Object.keys(response.Result).filter(
        (x) => !hideSensor.includes(x),
      );
      setIsDataAvailable && setIsDataAvailable(!!sensorsList.length);
      const formattedSensorData: SensorWeeklyData = {};

      sensorsList.forEach((sensor: string) => {
        formattedSensorData[sensor] = formatWeeklyData(
          response.Result[sensor],
          sensor,
        );
      });

      setSensors(sensorsList);
      setWeeklyData(formattedSensorData);
      setIsLoading(false);
    } else {
      // Handle the case where response.Result is null
      setIsLoading(false);
      console.log("No data available");
    }
  };

  // Function to fetch SWRM Data
  const fetchSWRMData = async () => {
    setIsLoading(true);
    try {
      const response = await getWeeklyScannedSensorData(
        selectedLocation,
        selectedDevice?.toLocaleString() || "",
      );

      if (response.Result !== null) {
        const sensorsList = Object.keys(response.Result).filter(
          (x) => !hideSensor.includes(x),
        );
        setIsDataAvailable && setIsDataAvailable(!!sensorsList.length);
        const formattedSensorData: SensorWeeklyData = {};

        sensorsList.forEach((sensor: string) => {
          formattedSensorData[sensor] = formatWeeklyData(
            response.Result[sensor],
            sensor,
          );
        });

        setSensors(sensorsList);
        setWeeklyData(formattedSensorData);
      } else {
        // Handle the case where response.Result is null
        console.log("No data available");
      }
    } catch (error) {
      console.error(`Error fetching data:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    if (
      auth.company.IntegratedWith !== "" &&
      auth.company.IntegratedWith !== null &&
      integrations[0].Enabled
    ) {
      // Fetch Integration Data
      await fetchIntegrationData();
    }

    // Fetch SWRM Data
    await fetchSWRMData();
  };

  useEffect(() => {
    fetchData();
  }, [selectedLocation, selectedDevice]);

  // Update orderesensors state changes
  useEffect(() => {
    const restSensors = sensors.filter(
      (sensor) => !specificOrder.includes(sensor),
    );
    const reorderedSensors = specificOrder.concat(restSensors);
    setOrderedSensors(reorderedSensors);
  }, [sensors]);

  return (
    <div className="position-relative">
      {
        isLoading ?
          <Loader />
          // /* Render GaugePanel components based on sensor data */
        : !Object.keys(weeklyData).length ?
          <NoDataAvailable />
        : <WeeklyChartWrapper>
            {/* Render charts components based on sensor data */}
            {orderedSensors.map(
              (sensor: string, index: number) =>
                weeklyData[sensor] && (
                  <WeeklyChart
                    key={index}
                    type={"Weekly"}
                    title={`${sensorNameToReadableName(sensor)} Per Day`}
                    data={weeklyData[sensor]}
                    chartType={barChart.includes(sensor) ? "Bar" : "Line"}
                    yFieldLabel={
                      barChart.includes(sensor) ? "count" : "average"
                    }
                  />
                ),
            )}
          </WeeklyChartWrapper>

      }
    </div>
  );
};

export default WeeklyChartSection;
