import React, { useEffect, useState } from "react";
import CapturesFilterOptions from "../../filter-options/CapturesFilterOptions";
import TableButton from "../../tables/table-button/TableButton";
import CaptureImageVideoCardList from "../../image-card/capture-image-video-card/CaptureImageVideoCardList";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import {
  selectActiveThumbnailSizeItem,
  toggleThumbnailSize,
} from "../../thumbnail-size/thumbnailSizeSlice";
import CaptureImageVideoTable from "../../tables/CaptureImageVideoTable";
import { RootState } from "../../../Store/store";
import { ICaptureImageCard } from "../../../Types/captures/captures";
import { Typography } from "../../typrography/Typography";
import {
  resetAllCheckedStatus,
  setCheckedStatus,
} from "../../../Features/Slice/captures/captureSlice";
import Loader from "../../loader/Loader";
import { getAllCaptures } from "../../../Services/Captures/capture.service";
import { useParams } from "react-router-dom";
import { faL } from "@fortawesome/free-solid-svg-icons";

interface ImagesProps {}

const ImagesAndVideos = (props: ImagesProps) => {
  const dispatch = useAppDispatch();
  const captures = useAppSelector((state: RootState) => state.captures);
  const sortBys = useAppSelector((state: RootState) => state.sortBy);
  const searchParams = useAppSelector(
    (state: RootState) => state.fetchParams.CaptureSearchParams,
  );
  const status = captures.isSucceeded;
  const selectedSortByOrderOption = sortBys.find((item) => {
    return (item.id === 1 || item.id === 2) && item.isActive;
  });
  const allThumbnailSizeItems = useAppSelector((state) => state.thumbnailSize);
  const activeThumbnailSizeItem = useAppSelector(
    selectActiveThumbnailSizeItem,
  )[0];

  const { activeTab } = useParams<any>();
  const [data, setData] = useState<ICaptureImageCard[]>([]);
  const [checkedAll, setCheckedAll] = useState(false);

  useEffect(() => {
    if (status) {
      setData(captures.combinedCaptures as ICaptureImageCard[]);
    }
  }, [captures, status]);

  //setup pagination properties
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = activeThumbnailSizeItem.pageSize;

  const getCurrentPageData = (
    currentPage: number,
    pageSize: number,
    data: ICaptureImageCard[],
  ) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const currentPageData = data.slice(startIndex, endIndex);
    return currentPageData;
  };
  const pageData = getCurrentPageData(currentPage, pageSize, data);
  const startItemId = (currentPage - 1) * pageSize + 1;
  const endItemId = Math.min(currentPage * pageSize, data.length);
  const lastPage = Math.ceil(data.length / pageSize);

  const [paginatedData, setPaginatedData] = useState(pageData);
  const columns = [
    "Model",
    "",
    "Confidence",
    "CPU % Usage",
    "GPU % Usage",
    "Frame",
    "Date Taken",
  ];

  useEffect(() => {
    updateCheckAllCheckbox();
  }, [paginatedData]);

  const handlePageChange = (pageNum: number) => {
    setCurrentPage(pageNum);
    setCheckedAll(false);
    const updatedData = data.map((item) => ({ ...item, checked: false }));
    setData(updatedData);
    // reset all checked status for combinedCaptures state
    dispatch(resetAllCheckedStatus());
  };

  const rebindData = () => {
    dispatch(
      getAllCaptures({
        mediaType: activeTab == "videos" ? "video" : "image",
        ascending:
          selectedSortByOrderOption?.name == "ascending" ? true : false,
        archive: false,
      }),
    );
    handlePageChange(currentPage);
  };

  const handleCheckAllChange = () => {
    const selectedItems = pageData.map((selectItem) => selectItem.id);
    const updatedData = data.map((item) =>
      selectedItems.includes(item.id)
        ? { ...item, checked: !checkedAll }
        : item,
    );
    setData(updatedData);
    setCheckedAll(!pageData.some((item) => item.checked));
    // Update checked status for combinedCaptures state
    pageData.map((capture) =>
      dispatch(
        setCheckedStatus({
          captureId: capture.id,
          checked: !pageData.some((item) => item.checked),
        }),
      ),
    );
  };

  const handleItemCheckChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const id = parseInt(event.target.value);
    changeCheckStatus(id);
  };

  const handleThumbnailClick = (id: number) => {
    changeCheckStatus(id);
  };

  const updateCheckAllCheckbox = () => {
    const allChecked =
      paginatedData.length && paginatedData.some((item) => item.checked);
    setCheckedAll(!!allChecked);
  };

  const handleChangeThumbnailViewSize = (id: number) => {
    dispatch(toggleThumbnailSize(id));
  };

  const changeCheckStatus = (id: number) => {
    const selectedItem = data.find((item) => item.id === id);
    const selectedCheckedStatus = selectedItem?.checked!;

    // Update data and checked status for combinedCaputres in Redux state
    const updatedData = data.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item,
    );
    setData(updatedData);
    dispatch(
      setCheckedStatus({ captureId: id, checked: !selectedCheckedStatus }),
    );

    // Update paginatedData after the check status has been changed.
    const updatedPaginatedData = getCurrentPageData(
      currentPage,
      pageSize,
      updatedData,
    );
    setPaginatedData(updatedPaginatedData);
    // Update checkedAll status if the all items are checked or not
    setCheckedAll(!updatedPaginatedData.every((item) => item.checked));
  };

  return (
    <>
      <CapturesFilterOptions
        thumbnailSizeItems={allThumbnailSizeItems}
        handleChangeThumbnailViewSize={handleChangeThumbnailViewSize}
      />
      <TableButton
        isChecked={checkedAll}
        dataLength={data.length}
        handleCheckAllChange={handleCheckAllChange}
        handlePageChange={handlePageChange}
        handleRebindData={rebindData}
        currentPage={currentPage}
        lastPage={lastPage}
        startItemId={startItemId}
        endItemId={endItemId}
        page="Captures"
      />
      {captures.isLoading && <Loader isLoadingScreen />}
      {activeThumbnailSizeItem.name === "list" &&
      captures.isSucceeded &&
      pageData.length !== 0 ? (
        <CaptureImageVideoTable
          columns={columns}
          data={pageData}
          handleItemCheckChange={handleItemCheckChange}
        />
      ) : captures.isSucceeded && pageData.length > 0 ? (
        <CaptureImageVideoCardList
          data={pageData}
          handleItemCheckChange={handleItemCheckChange}
          handleThumbnailClick={handleThumbnailClick}
          cardSize={activeThumbnailSizeItem.name}
        />
      ) : (
        captures.isSucceeded &&
        pageData.length === 0 && (
          <Typography>Sorry, no capture thumbnail cards loaded!</Typography>
        )
      )}
    </>
  );
};

export default ImagesAndVideos;
