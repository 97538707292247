import styled from "styled-components";
import { FlexContainerProps } from "./FlexContainer";

export const CustomFlexContainer = styled.div<
  Pick<
    FlexContainerProps,
    | "justifyContent"
    | "alignItems"
    | "flexWrap"
    | "flexDirection"
    | "gap"
    | "height"
    | "width"
    | "margin"
    | "padding"
    | "hasBorder"
  >
>`
  display: flex;
  flex-wrap: ${(props) => props.flexWrap || "none"};
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: ${(props) => props.alignItems || "center"};
  flex-direction: ${(props) => props.flexDirection || "row"};
  gap: ${(props) => props.gap};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  border: ${(props) => props.hasBorder && "1px solid #EEE"};
  border-radius: ${(props) => props.hasBorder && "5px"};
`;
