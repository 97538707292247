import React, { useEffect, useState } from "react";
import {
  ButtonIconWrapper,
  CheckboxWrapper,
  HeaderWrapper,
  IconWrapper,
  LeftArrowIconWragger,
  PageIconWrapper,
  PageWrapper,
  RightArrowIconWragger,
  StyledAiFillDelete,
  StyledBiSolidArchiveIn,
  StyledBiSolidArchiveOut,
  StyledBsArrowLeftSquare,
  StyledBsArrowRightSquare,
  StyledIoMdDownload,
} from "./TableButton.style";
import Checkbox from "../../checkbox/Checkbox";
import { Typography } from "../../typrography/Typography";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import ConfirmationModal from "../../modals/confirmation-modal/ConfirmationModal";
import {
  selectedCaptureData,
  selectedCheckedCaptures,
} from "../../../Features/Slice/captures/captureSlice";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
  OpenWarningNotification,
} from "../../notification/Notification";
import { Tooltip } from "react-tooltip";
import {
  AiModelManagerData,
  selectCheckedAiModels,
} from "../../../Features/Slice/ai-models/aiModelSlice";
import AIModelService from "../../../Services/AIModels/aimodel.service";
import Loader from "../../loader/Loader";
import captureService, {
  bulkArchiveUpdate,
  bulkDownloadCaptures,
  bulkSoftDelete,
} from "../../../Services/Captures/capture.service";

export interface TableButtonProps {
  isChecked: boolean;
  isArchivedData?: boolean;
  dataLength?: number;
  handleCheckAllChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePageChange: (pageNum: number) => void;
  currentPage: number;
  lastPage: number;
  startItemId: number;
  endItemId: number;
  handleOpenRetrainModal?: () => void;
  handleOpenViewDevicesModal?: () => void;
  handleRebindData?: () => void;
  page: "AiModelManager" | "Captures";
}

const TableButton = ({
  isChecked,
  isArchivedData,
  dataLength,
  handleCheckAllChange,
  handlePageChange,
  handleRebindData,
  currentPage,
  lastPage,
  startItemId,
  endItemId,
  page,
}: TableButtonProps) => {
  const dispatch = useAppDispatch();
  const pageText = page === "AiModelManager" ? "AI models" : "Captures";
  const selectedCaptures = useAppSelector(selectedCheckedCaptures);
  const selectedAiModels = useAppSelector(selectCheckedAiModels);
  const numOfSelected =
    page === "Captures" ? selectedCaptures.length : selectedAiModels.length;

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [actionType, setActionType] = useState("");
  const [confirmTitle, setConfirmTitle] = useState<string>("");
  const [confirmMsg, setConfirmMsg] = useState<string>("");
  const [downloadStatus, setDownloadStatus] = useState("");
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  // AI Models bulk action handler methods
  const handleBulkArchiveAIModels = async () => {
    handleConfirmModalClose();
    const modelsToArchive: number[] = [];

    selectedAiModels?.map((model) => {
      modelsToArchive.push(model.id);
    });

    const response: any = await AIModelService.bulkArchiveAIModels(
      modelsToArchive,
    );

    if (response.ErrorCode !== 200) {
      OpenErrorNotification(response.ErrorMessage);
    } else {
      OpenSuccessNotification(response.Result);
    }

    handleRebindData && handleRebindData();
  };

  const handleBulkSoftDeleteAIModels = async () => {
    handleConfirmModalClose();
    const modelsToDeleteIds: number[] = [];

    selectedAiModels?.map((model) => {
      modelsToDeleteIds.push(model.id);
    });

    const response: any = await AIModelService.bulkDeleteAIModels(
      modelsToDeleteIds,
    );

    if (response.ErrorCode !== 200) {
      OpenErrorNotification(response.ErrorMessage);
    } else {
      OpenSuccessNotification(response.Result);
    }

    handleRebindData && handleRebindData();
  };

  const handleBulkDownloadAIModels = async () => {
    handleConfirmModalClose();
    setDownloadStatus("pending");
    const modelsToDeleteIds: number[] = [];

    selectedAiModels?.map((model) => {
      modelsToDeleteIds.push(model.id);
    });

    const response: any = await AIModelService.bulkDownloadAIModels(
      modelsToDeleteIds,
    );

    setDownloadStatus("");
    if (response.ErrorCode !== 200) {
      OpenErrorNotification(response.ErrorMessage);
    } else {
      OpenSuccessNotification(response.Result);
    }
  };

  // Captures bulk action handler methods
  const handleBulkArchiveUpdateCaptures = async (archiveStatus: boolean) => {
    handleConfirmModalClose();
    const capturesToArchive: number[] = [];

    selectedCaptures?.map((capture) => {
      capturesToArchive.push(capture.id);
    });

    const response: any = await dispatch(
      bulkArchiveUpdate({
        CaptureIds: capturesToArchive,
        ArchiveStatus: archiveStatus,
      }),
    );

    if (!response.payload.IsError) {
      OpenSuccessNotification(response.payload.Result);
    }

    handleRebindData && handleRebindData();
  };

  const handleBulkSoftDeleteCaptures = async () => {
    handleConfirmModalClose();
    const capturesToDelete: number[] = [];

    selectedCaptures?.map((capture) => {
      capturesToDelete.push(capture.id);
    });

    const response: any = await dispatch(bulkSoftDelete(capturesToDelete));

    if (!response.payload.IsError) {
      OpenSuccessNotification(response.payload.Result);
    }

    handleRebindData && handleRebindData();
  };

  const handleBulkDownloadCaptures = async () => {
    handleConfirmModalClose();
    setDownloadStatus("pending");
    const capturesToDeleteURLs: string[] = [];

    selectedCaptures?.map((capture) => {
      capturesToDeleteURLs.push(capture.mediaPath!);
    });

    await bulkDownloadCaptures(capturesToDeleteURLs);
    setDownloadStatus("");
  };

  const handleConfirmationModalOpen = (actionType: string) => {
    const msg = `
      Are you sure you'd like to ${actionType} the selected ${pageText}?
    `;
    setConfirmMsg(msg);
    setIsConfirmModalOpen(true);
    setActionType(actionType);

    switch (actionType) {
      case "archive":
        setConfirmTitle(`Archive ${pageText}`);
        break;
      case "unarchive":
        setConfirmTitle(`Unarchive ${pageText}`);
        break;
      case "delete":
        setConfirmTitle(`Delete ${pageText}`);
        break;
      case "download":
        setConfirmTitle(`Download ${pageText}`);
    }
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const handleArchiveIconClick = () => {
    if (numOfSelected > 0) handleConfirmationModalOpen("archive");
    else
      OpenWarningNotification(
        `Please select the ${pageText} you would like to archive first!`,
      );
  };

  const handleUnarchiveIconClick = () => {
    if (numOfSelected > 0) handleConfirmationModalOpen("unarchive");
    else
      OpenWarningNotification(
        `Please select the ${pageText} you would like to unarchive first!`,
      );
  };

  const handleDeleteIconClick = () => {
    if (numOfSelected > 0) handleConfirmationModalOpen("delete");
    else
      OpenWarningNotification(
        `Please select the ${pageText} you would like to delete first!`,
      );
  };

  const handleDownloadIconClick = () => {
    if (numOfSelected > 0) handleConfirmationModalOpen("download");
    else
      OpenWarningNotification(
        `Please select the ${pageText} you would like to download first!`,
      );
  };

  const selectBulkMethodHandler = () => {
    switch (actionType) {
      case "archive":
        pageText === "AI models"
          ? handleBulkArchiveAIModels()
          : handleBulkArchiveUpdateCaptures(true);
        break;
      case "unarchive":
        handleBulkArchiveUpdateCaptures(false);
        break;
      case "delete":
        pageText === "AI models"
          ? handleBulkSoftDeleteAIModels()
          : handleBulkSoftDeleteCaptures();
        break;
      case "download":
        pageText === "AI models"
          ? handleBulkDownloadAIModels()
          : handleBulkDownloadCaptures();
    }
  };

  useEffect(() => {
    // use setImageUrls to keep the imageUrls array in sync with the selectedCaptures array
    setImageUrls(selectedCaptures?.map((capture) => capture.mediaPath!));
  }, [selectedCaptures]);

  return (
    <>
      {downloadStatus === "pending" && (
        <Loader
          loadingText={`Downloading ${pageText}, please wait`}
          isLoadingScreen
        />
      )}
      <HeaderWrapper>
        <ButtonIconWrapper>
          <CheckboxWrapper>
            <Checkbox isChecked={isChecked} onChange={handleCheckAllChange} />
            <Typography variant="sm">
              {numOfSelected}
              {page === "AiModelManager" ? " Models" : " Frames"} Selected
            </Typography>
          </CheckboxWrapper>

          <IconWrapper>
            {isArchivedData ? (
              <>
                <StyledBiSolidArchiveOut
                  onClick={handleUnarchiveIconClick}
                  data-tooltip-id="bulk-unarchive-tooltip"
                  data-tooltip-content="Bulk Unarchive"
                />
                <Tooltip id="bulk-unarchive-tooltip" />
              </>
            ) : (
              <>
                <StyledBiSolidArchiveIn
                  onClick={handleArchiveIconClick}
                  data-tooltip-id="bulk-archive-tooltip"
                  data-tooltip-content="Bulk Archive"
                />
                <Tooltip id="bulk-archive-tooltip" />
              </>
            )}

            <StyledIoMdDownload
              onClick={handleDownloadIconClick}
              data-tooltip-id="bulk-download-tooltip"
              data-tooltip-content="Bulk Download"
            />
            <Tooltip id="bulk-download-tooltip" />

            <StyledAiFillDelete
              onClick={handleDeleteIconClick}
              data-tooltip-id="bulk-delete-tooltip"
              data-tooltip-content="Bulk Delete"
            />
            <Tooltip id="bulk-delete-tooltip" />
          </IconWrapper>
        </ButtonIconWrapper>

        <PageWrapper>
          <Typography variant="sm">
            {startItemId} - {endItemId} of page {currentPage}
          </Typography>

          <PageIconWrapper>
            <LeftArrowIconWragger currentpage={currentPage}>
              <StyledBsArrowLeftSquare
                currentpage={currentPage}
                onClick={() => handlePageChange(currentPage - 1)}
              />
            </LeftArrowIconWragger>
            <RightArrowIconWragger
              currentpage={currentPage}
              lastpage={lastPage}
              dataLength={dataLength}
            >
              <StyledBsArrowRightSquare
                currentpage={currentPage}
                lastpage={lastPage}
                dataLength={dataLength}
                onClick={() => handlePageChange(currentPage + 1)}
              />
            </RightArrowIconWragger>
          </PageIconWrapper>
        </PageWrapper>
      </HeaderWrapper>
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        onConfirm={selectBulkMethodHandler}
        headerTitle={confirmTitle}
        confirmMsg={confirmMsg}
        onClose={handleConfirmModalClose}
        modalWidth="30vw"
        minWidth="450px"
      />
    </>
  );
};

export default TableButton;
