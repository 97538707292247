import styled from "styled-components";

export const CustomContentWrapper = styled.div`
  width: 85vw;
  background: #020a01;
  color: white;
  margin-left: 15vw;
  height: auto;
  min-height: 100vh;
  overflow-y: auto;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 80vw;
    margin-left: 20vw;
  }

  *& > * {
    /* background: #020a01; */
  }

  /* Customize the scrollbar style */
  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(90, 90, 90); /* Color of the scrollbar thumb */
    border-radius: 5px; /* Roundness of the scrollbar thumb */
  }

  ::-webkit-scrollbar-track {
    background-color: #020a01; /* Color of the scrollbar track */
    border-radius: 5px; /* Roundness of the scrollbar track */
  }
`;
export const ScrollableWrapper = styled.div`
  height: 85vh;
  /* overflow-y: auto; */
  margin-top: 15vh;
`;
