import React, { useState } from "react";
import { ScaledButtonWrapper } from "../../modals/CustomModal.style";
import InviteUserModel from "./InviteUserModel";

export default function InviteUserBtn() {
  // State to track if data export is in progress
  const [isInviteModelOpen, setIsInviteModelOpen] = useState<boolean>(false);
  // Function to handle exporting sensor data
  const openDialog = () => {
    setIsInviteModelOpen(true);
  };

  const closeDialog = () => {
    setIsInviteModelOpen(false);
  };

  return (
    <>
      <ScaledButtonWrapper style={{ justifyContent: "flex-end" }}>
        <button onClick={openDialog}>Invite User</button>
      </ScaledButtonWrapper>

      {!!isInviteModelOpen && (
        <InviteUserModel isOpen={isInviteModelOpen} onClose={closeDialog} />
      )}
    </>
  );
}
