import styled from "styled-components";
import { DeviceManagerCardProps } from "./DeviceManagerCard";

export const CustomDeviceManagerCard = styled.div<
  Pick<
    DeviceManagerCardProps,
    "variant" | "border" | "minWidth" | "marginBottom"
  >
>`
  color: ${(props) => props.theme.colors.white};

  min-width: ${({ minWidth }) =>
    minWidth === "xs" ? "176px" : "small" ? "193px" : "300px"};

  display: inline;
  &:hover {
    border: ${({ border, theme }) =>
      border ? theme.border["primary-sm"] : "none"};
  }
  border-radius: ${(props) => props.theme.borderRadius["rounded-lg"]};
  height: ${({ variant }) =>
    variant === "xs" ? "200px" : "small" ? "214px" : "471.69px"};
  border: ${({ border, theme }) =>
    border ? theme.border["border-sm-white"] : "none"};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)};
`;
//margin-top: 10px;padding: 12px;
