import React from "react";
import ApiUptimeBarChart from "./ApiUptimeBarChart";
import { BarChartListWrapper } from "./ApiUptimeBarChartList.style";

interface Props {}
export interface UptimeData {
  day: number;
  value: number;
}
export interface ApiUptimeData {
  name: string;
  uptimeInLastNinetyDays: UptimeData[];
}

export const uptimeList: UptimeData[] = [
  {
    day: 1,
    value: 100,
  },
  {
    day: 2,
    value: 100,
  },
  {
    day: 3,
    value: 90,
  },
  {
    day: 4,
    value: 95,
  },
  {
    day: 5,
    value: 100,
  },
  {
    day: 6,
    value: 100,
  },
  {
    day: 7,
    value: 100,
  },
  {
    day: 8,
    value: 100,
  },
  {
    day: 9,
    value: 100,
  },
  {
    day: 10,
    value: 100,
  },
  {
    day: 11,
    value: 100,
  },
  {
    day: 12,
    value: 100,
  },
  {
    day: 13,
    value: 100,
  },
  {
    day: 14,
    value: 100,
  },
  {
    day: 15,
    value: 100,
  },
  {
    day: 16,
    value: 100,
  },
  {
    day: 17,
    value: 100,
  },
  {
    day: 18,
    value: 100,
  },
  {
    day: 19,
    value: 100,
  },
  {
    day: 20,
    value: 100,
  },
  {
    day: 21,
    value: 100,
  },
  {
    day: 22,
    value: 100,
  },
  {
    day: 23,
    value: 100,
  },
  {
    day: 24,
    value: 100,
  },
  {
    day: 25,
    value: 100,
  },
  {
    day: 26,
    value: 100,
  },
  {
    day: 27,
    value: 100,
  },
  {
    day: 28,
    value: 100,
  },
  {
    day: 29,
    value: 100,
  },
  {
    day: 30,
    value: 100,
  },
  {
    day: 31,
    value: 100,
  },
  {
    day: 32,
    value: 100,
  },
  {
    day: 33,
    value: 100,
  },
  {
    day: 34,
    value: 100,
  },
  {
    day: 35,
    value: 100,
  },
  {
    day: 36,
    value: 100,
  },
  {
    day: 37,
    value: 100,
  },
  {
    day: 38,
    value: 100,
  },
  {
    day: 39,
    value: 100,
  },
  {
    day: 40,
    value: 100,
  },
  {
    day: 41,
    value: 100,
  },
  {
    day: 42,
    value: 100,
  },
  {
    day: 43,
    value: 100,
  },
  {
    day: 44,
    value: 100,
  },
  {
    day: 45,
    value: 100,
  },
  {
    day: 46,
    value: 100,
  },
  {
    day: 47,
    value: 100,
  },
  {
    day: 48,
    value: 100,
  },
  {
    day: 49,
    value: 100,
  },
  {
    day: 50,
    value: 100,
  },
  {
    day: 51,
    value: 100,
  },
  {
    day: 52,
    value: 100,
  },
  {
    day: 53,
    value: 100,
  },
  {
    day: 54,
    value: 100,
  },
  {
    day: 55,
    value: 100,
  },
  {
    day: 56,
    value: 100,
  },
  {
    day: 57,
    value: 100,
  },
  {
    day: 58,
    value: 100,
  },
  {
    day: 59,
    value: 100,
  },
  {
    day: 60,
    value: 100,
  },
  {
    day: 61,
    value: 100,
  },
  {
    day: 62,
    value: 100,
  },
  {
    day: 63,
    value: 100,
  },
  {
    day: 64,
    value: 100,
  },
  {
    day: 65,
    value: 100,
  },
  {
    day: 66,
    value: 100,
  },
  {
    day: 67,
    value: 100,
  },
  {
    day: 68,
    value: 100,
  },
  {
    day: 69,
    value: 100,
  },
  {
    day: 70,
    value: 100,
  },
  {
    day: 71,
    value: 100,
  },
  {
    day: 72,
    value: 100,
  },
  {
    day: 73,
    value: 100,
  },
  {
    day: 14,
    value: 100,
  },
  {
    day: 75,
    value: 100,
  },
  {
    day: 76,
    value: 100,
  },
  {
    day: 77,
    value: 100,
  },
  {
    day: 78,
    value: 100,
  },
  {
    day: 79,
    value: 100,
  },
  {
    day: 80,
    value: 100,
  },
  {
    day: 81,
    value: 100,
  },
  {
    day: 82,
    value: 100,
  },
  {
    day: 83,
    value: 100,
  },
  {
    day: 84,
    value: 100,
  },
  {
    day: 85,
    value: 100,
  },
  {
    day: 86,
    value: 100,
  },
  {
    day: 87,
    value: 100,
  },
  {
    day: 88,
    value: 100,
  },
  {
    day: 89,
    value: 100,
  },
  {
    day: 90,
    value: 100,
  },
];

const apiUptimeList: ApiUptimeData[] = [
  { name: "Test API 1", uptimeInLastNinetyDays: uptimeList },
  { name: "Test API 2", uptimeInLastNinetyDays: uptimeList },
  { name: "Test API 3", uptimeInLastNinetyDays: uptimeList },
  { name: "Test API 4", uptimeInLastNinetyDays: uptimeList },
];

const ApiUptimeBarChartList = (props: Props) => {
  return (
    <BarChartListWrapper>
      {apiUptimeList.map((item, index) => (
        <ApiUptimeBarChart
          key={index}
          name={item.name}
          data={item.uptimeInLastNinetyDays}
        />
      ))}
    </BarChartListWrapper>
  );
};

export default ApiUptimeBarChartList;
