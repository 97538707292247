import React from "react";
import { ChipStyles } from "../../Interfaces/Components/ChipEnums";
import { CustomChip } from "./Chip.style";

export interface ChipProps {
  children: ChipStyles | string; //"Manager" | "Enabled" | "Disabled" | "Deployed" | "Undeployed"| "Assistant" | "Employee";
  color?: string;
  backgroundColor?: string;
}

export const Chip: React.FunctionComponent<ChipProps> = ({
  children,
  backgroundColor,
  color,
}) => {
  let bgColor = "";
  switch (children) {
    case ChipStyles.Manager:
      bgColor = "indigo";
      break;
    case ChipStyles.Viewer:
      bgColor = "pink";
      break;
    case ChipStyles.Enabled:
    case ChipStyles.Owner:
      bgColor = "green-100";
      break;
    case ChipStyles.Deployed:
      bgColor = "blue";
      break;
    case ChipStyles.Editor:
      bgColor = "light-blue";
      break;
    default:
      bgColor = backgroundColor || "grey";
      break;
  }

  return (
    <CustomChip backgroundColor={bgColor} color={color}>
      {children}
    </CustomChip>
  );
};
