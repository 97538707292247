import styled from "styled-components";
import { InputProps } from "./SearchInput";

export const InputWrapper = styled.div<Pick<InputProps, "searchIcon">>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 10vh;
  width: ${(searchIcon) => (searchIcon ? "100%" : "45%")};

  & .search {
    position: absolute;
    left: 30px;
    margin: auto 0;
  }
`;

export const CustomSearchInput = styled.input<
  Pick<InputProps, "background" | "maxLength">
>`
  border: 0;
  border-bottom: ${(props) => props.theme.border["primary-sm"]};
  background: ${({ background }) => (background ? background : "#020A01")};
  width: 100%;
  min-width: 108px;
  height: 3rem;
  font-size: ${(props) => props.theme.fontSizes["lg"]};
  color: #b5b5b5;
  padding-left: 4rem;

  &:hover {
    border-bottom: ${(props) => props.theme.border["secondary-sm"]};
  }

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors["secondary"]};
  }

  &::placeholder {
    color: #b5b5b5;
  }
`;
