import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import JSZip from "jszip";
import AWS from "aws-sdk";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
  OpenWarningNotification,
} from "../../Components/notification/Notification";

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";
interface getParamsType {
  mediaType?: string | null;
  archive?: boolean;
  ascending: boolean;
}

interface archiveParamsType {
  Id: number;
  ModelId: number;
  DeviceId: string;
  Archive: boolean;
}

interface bulkArchiveUpdateParamsType {
  CaptureIds: number[];
  ArchiveStatus: boolean;
}

interface bulkDeleteParamsType {
  CaptureIds: number[];
  ArchiveStatus: boolean;
}

interface deleteParamsType {
  Id: number;
  ModelId: number;
  DeviceId: string;
  Deleted: boolean;
}

interface searchParamsType {
  highConfidence: boolean | null;
  mediaType: string | null;
  archive: boolean;
  locationId: number;
  deviceId: string;
  modelId: number;
  ascending: boolean;
}

export const getAllCaptures = createAsyncThunk(
  "capture/getAllCaptures",
  async (params: getParamsType) => {
    try {
      const response = await axios
        .get(`${API_URL}/Captures/withGroupNames`, { params })
        .then(async (response) => {
          // await console.log(response.data);
          return response.data;
        });
      return response;
    } catch (error: unknown) {
      return error;
    }
  },
);

export const archiveCapture = createAsyncThunk(
  "capture/patchArchive",
  async (params: archiveParamsType) => {
    try {
      const response = await axios
        .patch(`${API_URL}/Captures/archive`, params) // Pass 'params' directly as the request body
        .then(async (response) => {
          // await console.log(response.data);
          return response.data;
        });
      return response;
    } catch (error: unknown) {
      return error;
    }
  },
);

export const softDeleteCapture = createAsyncThunk(
  "capture/softDelete",
  async (params: deleteParamsType) => {
    try {
      const response = await axios
        .patch(`${API_URL}/Captures/softDelete`, params) // Pass 'params' directly as the request body
        .then(async (response) => {
          // await console.log(response.data);
          return response.data;
        });
      return response;
    } catch (error: unknown) {
      return error;
    }
  },
);

export const bulkArchiveUpdate = createAsyncThunk(
  "capture/bulkArchiveUpdate",
  async (params: bulkArchiveUpdateParamsType) => {
    const response = await axios
      .patch(`${API_URL}/Captures/bulkArchiveUpdate`, params)
      .then(async (response) => {
        return response.data;
      })
      .catch((err: any) => {
        OpenErrorNotification(err.response.data.ErrorMessage);
        return err.response.data;
      });

    return response;
  },
);

export const bulkSoftDelete = createAsyncThunk(
  "capture/bulkSoftDelete",
  async (capturesIds: number[]) => {
    const response = await axios
      .patch(`${API_URL}/Captures/bulkDelete`, capturesIds)
      .then(async (response) => {
        return response.data;
      })
      .catch((err: any) => {
        OpenErrorNotification(err.response.data.ErrorMessage);
        return err.response.data;
      });

    return response;
  },
);

export const searchCaptures = createAsyncThunk(
  "capture/search",
  async (params: searchParamsType) => {
    try {
      const response = await axios
        .get(`${API_URL}/Captures/withGroupNames`, { params })
        .then(async (response) => {
          // await console.log(response.data);
          return response.data;
        });
      return response;
    } catch (error: unknown) {
      return error;
    }
  },
);

const getDownloadUrlFromS3 = (imageUrl: string) => {
  let downloadUrl = "";
  const params = {
    Bucket: process.env.REACT_APP_AWS_BUCKET!,
    Key: imageUrl,
  };
  s3.getObject(params, (err, data) => {
    if (err) {
      console.error(err);
    } else {
      if (data.Body) {
        const blob = new Blob([data.Body as Uint8Array]);
        downloadUrl = URL.createObjectURL(blob);
        console.log(downloadUrl);
      }
    }
  });
  return downloadUrl;
};

export const handleDownloadCapture = async (imageUrl: string) => {
  try {
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET!,
      Key: imageUrl,
    };
    s3.getObject(params, (err, data) => {
      if (err) {
        console.error(err);
      } else {
        if (data.Body) {
          const blob = new Blob([data.Body as Uint8Array]);
          // Get download URL from AWS S3 server
          const downloadUrl = URL.createObjectURL(blob);

          // Download the S3 object in the browser
          // 1.Create an anchor element to trigger the download
          const link = document.createElement("a");
          link.href = downloadUrl;
          const imageName = imageUrl.split("/").pop();
          link.download = imageName!;
          document.body.appendChild(link);
          link.click();
          // 2.Clean up resources
          document.body.removeChild(link);

          // Open success notification if the capture has benn downloaded successfully
          OpenSuccessNotification(
            "The selected capture has been downloaded successfully!",
          );
        }
      }
    });
  } catch (error) {
    console.error("Error downloading file:", error);
    // Open error notification if something goes wrong
    OpenErrorNotification("Oops! Something goes wrong.");
  }
};

export const bulkDownloadCaptures = async (imageUrls: string[]) => {
  if (imageUrls.length > 0) {
    try {
      const zip = new JSZip();

      // Use Promise.all to wait for all downloads to complete
      const downloadPromises = imageUrls.map(async (imageUrl) => {
        try {
          const params = {
            Bucket: process.env.REACT_APP_AWS_BUCKET!,
            Key: imageUrl,
          };

          const data = await s3.getObject(params).promise();

          if (data.Body) {
            const imageName = imageUrl.split("/").pop();
            zip.file(imageName!, data.Body as Uint8Array, { binary: true });
          }
        } catch (error) {
          console.error(`Error downloading ${imageUrl}:`, error);
        }
      });

      // Handle asynchronous operations in parallel, ensuring that all image downloads complete before proceeding to create the zip file.
      await Promise.all(downloadPromises);

      // Generate the zip file
      const content = await zip.generateAsync({ type: "blob" });

      // Create a blob URL for the zip file
      const blobUrl = URL.createObjectURL(content);

      // Create an anchor element to trigger the download
      const downloadLink = document.createElement("a");
      downloadLink.href = blobUrl;
      downloadLink.download = "captures.zip";
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Clean up resources
      URL.revokeObjectURL(blobUrl);
      document.body.removeChild(downloadLink);

      // Open success notification if bulk download successfully
      OpenSuccessNotification(
        "The selected captures have been downloaded successfully!",
      );
    } catch (error) {
      console.error("Error downloading files:", error);
      // Open error notification if something goes wrong
      OpenErrorNotification("Oops! Something goes wrong.");
    }
  } else {
    // Open warning notification if no captures selected
    OpenWarningNotification(
      "Please select the captures you would like to bulk download first!",
    );
  }
};

const captureService = {
  getAllCaptures,
  searchCaptures,
  archiveCapture,
};

export default captureService;
