import { useEffect, useState } from "react";
import { Theme } from "../../app/Theme";
import { ContentWrapper } from "../../Components/content-wrapper/ContentWrapper";
import SideNavBar from "../../Components/nav/side-nav/SideNavBar";
import TopNavBar from "../../Components/nav/top-nav/TopNavBar";
import { Tabs } from "../../Components/tab/tabs/Tabs";
import Titles from "../../Components/title/Titles";
import LocationCreate from "./create/LocationCreate";
import styled from "styled-components";

import { Manage } from "./manage/Manage";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import LocationImageCardList from "./location-list/LocationImageCardList";
import { ScrollableWrapper } from "../../Components/content-wrapper/ContentWrapper.style";
import { useNavigate, useParams } from "react-router-dom";
import { resetLocationImageCard } from "../../Features/Slice/locations/locationsSlice";

const StyledTitles = styled(Titles)`
  .subheading {
    font-weight: normal;
  }
`;

export type TabsType = {
  label: string;
  index: number;
  Component: React.FC<{}>;
  path?: string;
}[];

const locationManageMainTabs: TabsType = [
  {
    label: "Create",
    index: 0,
    Component: LocationCreate,
    path: "create",
  },
  {
    label: "Manage",
    index: 1,
    Component: LocationImageCardList,
    path: "manage",
  },
];

const locationManageCarouselTabs: TabsType = [
  {
    label: "Create",
    index: 0,
    Component: LocationCreate,
    path: "create",
  },
  {
    label: "Manage",
    index: 1,
    Component: Manage,
    path: "manage",
  },
];

export const Location = () => {
  const initialTabIndex = 0;
  const navigate = useNavigate();
  const { activeTab } = useParams<any>();
  const dispatch = useAppDispatch();
  const activeLocationId = useAppSelector(
    (state) => state.locations.activeLocationId,
  );
  const [selectedTab, setSelectedTab] = useState<number>(initialTabIndex);

  useEffect(() => {
    return () => {
      resetSelection();
    };
  }, []);

  useEffect(() => {
    if (!activeTab) {
      resetSelection();
      navigate(`/locations/${getPathByIndex()}`);
    } else {
      const tab =
        locationManageMainTabs?.find((t) => t.path === activeTab)?.index ||
        initialTabIndex;
      setSelectedTab(tab);
    }
  }, [activeTab]);

  const getPathByIndex = (index = 0) => {
    return locationManageMainTabs[index].path;
  };

  const handleTabEvent = (e: number) => {
    if (selectedTab === 1 && e === 0) {
      resetSelection();
    }
    setSelectedTab(e);
    // update navigation
    navigate(`/locations/${getPathByIndex(e)}`);
  };

  const resetSelection = () => {
    // reset selected location from image carousel slice
    dispatch(resetLocationImageCard());
  };
  return (
    <>
      <Theme>
        <SideNavBar />
        <ContentWrapper>
          <TopNavBar />
          <ScrollableWrapper>
            <StyledTitles
              heading="Locations"
              subheading="Create and manage your locations and groups"
              marginTop={"0"}
            />

            <Tabs
              selectedTab={selectedTab}
              onClick={handleTabEvent}
              tabs={
                activeLocationId !== 0
                  ? locationManageCarouselTabs
                  : locationManageMainTabs
              }
              variant="pageTab"
            />
          </ScrollableWrapper>
        </ContentWrapper>
      </Theme>
    </>
  );
};
