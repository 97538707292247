import styled from "styled-components";

export const GaugeChartBlock = styled.div`
  display: block;
  padding: 1rem 2rem;
`;

export const GaugeChartWrapper = styled.div`
  display: flex;
  padding: 1rem 0rem;
  flex-wrap: wrap;
  /* chart block */
  & > div {
    margin-bottom: 1rem;
    padding: 0 1rem 0 0;
  }
`;

export const WeeklyChartWrapper = styled(GaugeChartWrapper)`
  & > div {
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding: 0 2rem;
  }
`;

export const TopActionBarWrapper = styled.div`
  display: flex;
  & > div {
    width: 100%;
    &:last-child {
      width: auto;
    }
  }
`;
