import React, { useRef, useState } from "react";
import { useEffect } from "react";
import {
  HorizontalLine,
  ModalContent,
  ModalWrapper,
} from "./CustomModal.style";
import { IoClose } from "react-icons/io5";

export interface CustomModalProps {
  isOpen: boolean;
  onClose: (e?: React.MouseEvent) => void;
  closeBtnRightPosition?: string;
  closeBtnTopPosition?: string;
  children: React.ReactNode;
  width?: string;
  height?: string;
  minWidth?: string;
  minHeight?: string;
  padding?: string;
  gap?: string;
  justifyContent?: string;
  alignItems?: string;
  border?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}
const CustomModal: React.FC<CustomModalProps> = ({
  header,
  footer,
  isOpen,
  onClose,
  closeBtnRightPosition,
  closeBtnTopPosition,
  children,
  width,
  height,
  padding,
  gap,
  justifyContent,
  alignItems,
  border,
  minWidth,
  minHeight,
}) => {
  const [opacity, setOpacity] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      setOpacity(1);
      document.body.style.overflow = "hidden";
    } else {
      setOpacity(0);
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      modalRef.current &&
      modalRef.current.isEqualNode(event.target as Node)
    ) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {isOpen && (
        <ModalWrapper
          style={{ opacity }}
          ref={modalRef}
          justifyContent={justifyContent}
          alignItems={alignItems}
        >
          <ModalContent
            width={width}
            height={height}
            padding={padding}
            gap={gap}
            border={border}
            minWidth={minWidth}
            minHeight={minHeight}
          >
            {!!header && header}
            {!!header && <HorizontalLine />}
            <IoClose
              style={{
                position: "absolute",
                right: closeBtnRightPosition || "0.5rem",
                top: closeBtnTopPosition || "0.5rem",
                zIndex: 2,
                cursor: "pointer",
              }}
              size="2rem"
              color={isHovered ? "#FFF" : "#707070"}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => {
                setIsHovered(false);
                onClose();
              }}
            />
            <div className="content">{children}</div>
            {!!footer && footer}
          </ModalContent>
        </ModalWrapper>
      )}
    </>
  );
};

export default CustomModal;
