import React from "react";
import { Tooltip } from "react-tooltip";
import { StyledRetrainIcon } from "../tables/Table.style";

interface RetrainIconProps {
  handleRetrainOnClick: () => void;
}

const RetrainIcon: React.FC<RetrainIconProps> = ({ handleRetrainOnClick }) => {
  return (
    <>
      <StyledRetrainIcon
        data-tooltip-id="retrain-tooltip"
        data-tooltip-content="Retrain"
        data-tooltip-place="top"
        onClick={handleRetrainOnClick}
      />
      <Tooltip id="retrain-tooltip" />
    </>
  );
};

export default RetrainIcon;
