import React, { useState, useCallback, useEffect } from "react";
import { CustomToggleButton } from "./ToggleButton.style";

interface ToggleButtonProps {
  id?: string;
  onToggle?: (newState: boolean, revert: () => void) => void;
  isActive?: boolean;
  children?: React.ReactNode;
  margin?: string;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
  isActive = false,
  onToggle,
  id = "checkbox",
  margin = undefined,
}) => {
  const [switchState, setSwitchState] = useState(isActive);

  const handleOnChange = useCallback(() => {
    const newState = !switchState;
    setSwitchState(newState);

    if (onToggle) {
      const revert = () => setSwitchState(!newState);
      onToggle(newState, revert);
    }
  }, [onToggle, switchState]);

  useEffect(() => {
    setSwitchState(isActive);
  }, [isActive]);

  return (
    <CustomToggleButton margin={margin} checked={switchState}>
      <input
        id={id}
        type="checkbox"
        checked={switchState}
        onChange={handleOnChange}
      />
    </CustomToggleButton>
  );
};

export default ToggleButton;
