import React, { useState } from "react";
import { DeviceStatisticsWrapper } from "../../../../Components/device-manager-elements/DeviceManagerMain.style";
import { useAppSelector } from "../../../../Store/hooks";
import WeeklyChartSection from "../../../../Pages/dashboard/WeeklyChartSection";
import ExportDataBtn from "../../../../Pages/dashboard/ExportDataBtn";

export const HistoricalData = () => {
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const { selectedDevice } = useAppSelector((state) => state.devices);
  return (
    <>
      {isDataAvailable && (
        <ExportDataBtn locationId={null} deviceId={selectedDevice?.Id || ""} />
      )}
      <DeviceStatisticsWrapper>
        {selectedDevice.Id ? (
          <WeeklyChartSection
            selectedLocation={null}
            selectedDevice={selectedDevice?.Id}
            setIsDataAvailable={setIsDataAvailable}
          />
        ) : null}
      </DeviceStatisticsWrapper>
    </>
  );
};
