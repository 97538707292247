import React from "react";
import ImageCard from "../../Components/image-card/ImageCard";
import permissionpage1 from "../../Assets/permissionpage1.png";
import { ControlButtons } from "../../Components/device-manager-card/image-card/ImageCard.style";
import { Chip } from "../../Components/chip/Chip";
import { ChipStyles } from "../../Interfaces/Components/ChipEnums";
import { SecondaryHeader } from "../../Components/image-card/ImageCard.style";
import { UserCompany, UserRoles } from "../../Interfaces/User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { awsUtils } from "../../Utils/awsUtils";

// Define props interface
interface CardProps {
  user: UserCompany;
  className?: string;
  onClick?: any;
}

// Define functional component
const UserCard: React.FC<CardProps> = ({ className, user, onClick }) => {
  return (
    <ImageCard
      className={`user-card ${className || ""}`}
      image={
        awsUtils.getSignedImageURL(user.User?.Media?.Url || "") ||
        permissionpage1
      }
      title={`${user.User.FirstName || ""} ${user.User.LastName || ""}`}
      subtitle={""}
      onClick={onClick}
    >
      <ControlButtons>
        <Chip
          children={
            UserRoles[user.RoleId] == "Owner"
              ? ChipStyles.Owner
              : UserRoles[user.RoleId] == "Editor"
              ? ChipStyles.Editor
              : ChipStyles.Viewer
          }
        />
      </ControlButtons>
      <SecondaryHeader>
        {UserRoles[user.RoleId] === "Owner"
          ? "Full Access"
          : "Restricted Access"}{" "}
        &nbsp;
        {
          <FontAwesomeIcon
            icon={
              UserRoles[user.RoleId] === "Owner" ? faCheckCircle : faCircleXmark
            }
            color={UserRoles[user.RoleId] === "Owner" ? "#80D39B" : "#D64550"}
          />
        }
      </SecondaryHeader>
    </ImageCard>
  );
};
export default UserCard;
