export interface IApiData {
  id: number;
  name: string;
  description: string;
  getApi: { apiId: number; apiName: string }[];
  postApi: { apiId: number; apiName: string }[];
  patchApi: { apiId: number; apiName: string }[];
  putApi: { apiId: number; apiName: string }[];
  deleteApi: { apiId: number; apiName: string }[];
}

const integrationApiList: IApiData[] = [
  {
    id: 1,
    name: "Device API",
    description: "Description placeholder",
    getApi: [
      { apiId: 1, apiName: "/api/v1/Device" },
      { apiId: 2, apiName: "/api/v1/Device/getById" },
    ],
    postApi: [{ apiId: 1, apiName: "/api/v1/Device" }],
    patchApi: [
      { apiId: 1, apiName: "/api/v1/Device" },
      { apiId: 2, apiName: "/api/v1/Device/DeployDevice" },
    ],
    putApi: [{ apiId: 1, apiName: "/api/v2/Device" }],
    deleteApi: [{ apiId: 1, apiName: "/api/v1/Device" }],
  },
  {
    id: 2,
    name: "Location API",
    description: "Description placeholder",
    getApi: [
      { apiId: 1, apiName: "/api/v1/Location" },
      { apiId: 1, apiName: "/api/v1/Location/getById" },
    ],
    postApi: [{ apiId: 1, apiName: "/api/v1/Location" }],
    patchApi: [{ apiId: 1, apiName: "/api/v1/Location" }],
    putApi: [{ apiId: 1, apiName: "/api/v2/Location" }],
    deleteApi: [{ apiId: 1, apiName: "/api/v1/Location" }],
  },
];

export default integrationApiList;
