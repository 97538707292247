import React, { useState } from "react";
import Checkbox from "../checkbox/Checkbox";
import { StyledTable } from "./Table.style";
import { Image } from "../image/Image";
import { AiModelManagerData } from "../../Features/Slice/ai-models/aiModelSlice";
import TableRow from "./TableRow";

interface TableProps {
  columns: string[];
  data: AiModelManagerData[];
  rebindData: () => void;
  handleItemCheckChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleItemDownload: (aiModelId: number) => void;
  handleViewDevices: (aiModelId: number) => void;
  handleRetrainOnClick: (aiModelId: number) => void;
  handleDuplicateOnClick: (aiModelId: number) => void;
  handleDeleteOnClick: (aiModelId: number) => void;
  handleArchiveOnClick: (aiModelId: number) => void;
}

const Table = ({
  columns,
  data,
  rebindData,
  handleItemCheckChange,
  handleItemDownload,
  handleViewDevices,
  handleRetrainOnClick,
  handleDuplicateOnClick,
  handleDeleteOnClick,
  handleArchiveOnClick,
}: TableProps) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          <th></th>
          {columns.map((column, index) => {
            return <th key={index}>{column}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <TableRow
            key={item.id}
            item={item}
            rebindData={rebindData}
            handleItemCheckChange={handleItemCheckChange}
            handleItemDownload={handleItemDownload}
            handleViewDevices={handleViewDevices}
            handleRetrainOnClick={handleRetrainOnClick}
            handleDuplicateOnClick={handleDuplicateOnClick}
            handleDeleteOnClick={handleDeleteOnClick}
            handleArchiveOnClick={handleArchiveOnClick}
          />
        ))}
      </tbody>
    </StyledTable>
  );
};

export default Table;
