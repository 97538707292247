import styled from "styled-components";

export const SensorBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  & > div {
    width: calc(20% - 1rem);
  }
`;
