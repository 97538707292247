import styled from "styled-components";
import { CaptureImageCardListProps } from "./CaptureImageVideoCardList";

export const ImageCardListWrapper = styled.div<
  Pick<CaptureImageCardListProps, "cardSize">
>`
  display: grid;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  grid-template-columns: repeat(
    auto-fill,
    ${({ cardSize }) =>
      cardSize === "medium" ? "15%" : cardSize === "small" ? "10%" : "18%"}
  );
`;
