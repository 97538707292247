import styled from "styled-components";

export const BarChartWrapper = styled.div`
  border-bottom: ${(props) => props.theme.border["primary-md"]};
  width: 100%;
  padding: 1rem;

  &:last-child {
    border-bottom: none;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BarChartListWrapper = styled.div`
  width: 100%;
  border: ${(props) => props.theme.border["primary-md"]};
  border-radius: 9px;
`;
