import { Formik } from "formik";
import * as Yup from "yup";
import Input from "../../../../Components/input/Input";
import { Typography } from "../../../../Components/typrography/Typography";
import { FilterOption } from "../../../../Components/dropdown/filter-dropdown/FilterDropdown";
import { RelayRuleAction } from "../../../../Interfaces/Device/Relay";
import MultiSelectDropdown from "../../../../Components/dropdown/multi-select-dropdown/MultiSelectDropdown";
import { useEffect, useState } from "react";
import { CustomDropdown } from "../../../../Components/dropdown/custom-dropdown/CustomDropdown";
import { RoutineErrorMessage, RoutineStepsForm } from "./RelayRoutine.style";
import { Step } from "../../../../Interfaces/Device/Step";
import { useAppSelector } from "../../../../Store/hooks";

const validationSchema = Yup.object({
  value: Yup.number().required("Value is required").positive("Value invalid"),
});

const TurnOptions: FilterOption[] = [
  {
    label: "Turn On",
    value: RelayRuleAction.On,
  },
  {
    label: "Turn Off All",
    value: RelayRuleAction.Off,
  },
];

interface Props {
  index: number;
  stepNumber: number;
  relayOptions: FilterOption[];
  onEditRoutine: (
    index: number,
    fieldName: "Turn" | "Value" | "Relays",
    value: number | number[],
  ) => void;
  onSaveRoutine: () => void;
  step?: Step | null;
}

const RelayRoutineForm = ({
  index,
  stepNumber,
  relayOptions,
  onEditRoutine,
  onSaveRoutine,
  step,
}: Props) => {
  const devices = useAppSelector((state) => state.devices);
  const selectedDevice = devices.selectedDevice;
  const [clearValues, setClearValues] = useState<boolean>(false);

  const defaultValues = {
    value: step?.Timer ? step.Timer : 10,
  };

  const handleRelayOptionSelection = (
    selectedRelayOptions: FilterOption[] | null,
  ) => {
    if (selectedRelayOptions !== null) {
      const selectedRelayIds: number[] = selectedRelayOptions.map((option) => {
        return Number(option.value);
      });

      onEditRoutine(index, "Relays", selectedRelayIds);
    }
  };

  const setTurnOption = (): FilterOption => {
    if (step) {
      if (step?.RelayStatus) {
        return TurnOptions[0];
      } else {
        return TurnOptions[1];
      }
    }

    return TurnOptions[0];
  };

  const setStepDefaultRelayOptions = (): FilterOption[] => {
    const relayIdsList = selectedDevice.StepRelayIds[index];

    if (!step?.RelayStatus) return [];

    const stepRelayIds: FilterOption[] = relayIdsList.map((relayId) => {
      const option = relayOptions.find((option) => option.value === relayId);
      return {
        label: option!.label,
        value: option!.value,
      };
    });

    return stepRelayIds;
  };

  // Initialize Turn and Value on first render
  useEffect(() => {
    if (!step) {
      onEditRoutine(index, "Turn", 1);
      onEditRoutine(index, "Relays", []);
    } else {
      onEditRoutine(index, "Turn", step.RelayStatus ? 1 : 0);
      if (!step?.RelayStatus) setClearValues(true);
    }

    onEditRoutine(index, "Value", defaultValues.value);
  }, []);

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={onSaveRoutine}
    >
      {({ values, handleBlur, handleChange, errors }: any) => (
        <RoutineStepsForm>
          <Typography
            variant="sm"
            weight="bold"
            direction="none"
            color="grey-500"
            padding="0"
          >
            {stepNumber}
          </Typography>

          {/*1. Turn: Switch On/Off */}
          <div>
            <CustomDropdown
              defaultValue={setTurnOption()}
              title="Action"
              options={TurnOptions}
              onChange={({ label, value }: FilterOption) => {
                let status: number = label === "Turn On" ? 1 : 0;
                if (status == 0) {
                  onEditRoutine(index, "Relays", []);
                  setClearValues(true);
                } else {
                  setClearValues(false);
                }
                onEditRoutine(index, "Turn", status);
              }}
            />
          </div>

          {/*2. Relays */}
          <div>
            <MultiSelectDropdown
              defaultValue={setStepDefaultRelayOptions()}
              title="Relays"
              options={relayOptions}
              onChange={handleRelayOptionSelection}
              clearValues={clearValues}
            />
          </div>

          <Typography variant="sm" color="grey-500">
            for
          </Typography>

          {/*3. Time (in seconds)  */}
          <div style={{ position: "relative" }}>
            <Input
              type="number"
              name="value"
              placeHolder="Time"
              label={""}
              height="1.9rem"
              width="5rem"
              margin="0"
              padding="0"
              value={values.value}
              onChange={(e) => {
                onEditRoutine(index, "Value", Number(e.target.value));
                handleChange(e);
              }}
              alignCenter
              hasCustomErrorMessage
            />

            <RoutineErrorMessage>{errors.value}</RoutineErrorMessage>
          </div>

          <Typography variant="sm" color="grey-500">
            seconds
          </Typography>
        </RoutineStepsForm>
      )}
    </Formik>
  );
};

export default RelayRoutineForm;
