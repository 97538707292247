import React from "react";
import UptimeBarChart from "./UptimeBarChart";
import { BarChartWrapper, TitleWrapper } from "./ApiUptimeBarChartList.style";
import { Typography } from "../typrography/Typography";
import { TypographyWithLine } from "../typrography/Typography.styles";
import { UptimeData } from "./ApiUptimeBarChartList";

interface ApiUptimeBarChartProps {
  name: string;
  data: UptimeData[];
}

const ApiUptimeBarChart = ({ data, name }: ApiUptimeBarChartProps) => {
  return (
    <BarChartWrapper>
      <TitleWrapper>
        <Typography display="inline-block">{name}</Typography>
        <Typography display="inline-block" color="green-100">
          Operational
        </Typography>
      </TitleWrapper>
      <UptimeBarChart data={data} />
      <TitleWrapper>
        <Typography display="inline-block" variant="sm">
          90 days ago
        </Typography>
        <TypographyWithLine
          display="flex"
          variant="sm"
          style={{ width: "85%" }}
        >
          100% uptime
        </TypographyWithLine>
        <Typography display="inline-block" variant="sm">
          Today
        </Typography>
      </TitleWrapper>
    </BarChartWrapper>
  );
};

export default ApiUptimeBarChart;
