import React from "react";
import { PaymentCardWrapper } from "./PaymentCard.style";
import { Typography } from "../../typrography/Typography";

export interface PaymentCardProps {
  cardName: string;
  cardIcon: React.FC;
  isActive: boolean;
  isCardList: boolean;
}

const PaymentCard = (props: PaymentCardProps) => {
  return (
    <PaymentCardWrapper isActive={props.isActive} isCardList={props.isCardList}>
      <props.cardIcon />
      <Typography
        variant="md"
        color={props.isActive ? "grey-900" : "white"}
        direction="flex-start"
        weight="black"
      >
        {props.cardName}
      </Typography>
    </PaymentCardWrapper>
  );
};

export default PaymentCard;
