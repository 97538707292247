import React from "react";
import { CustomDeviceManagerCard } from "./DeviceManagerCard.style";

export interface DeviceManagerCardProps {
  children: React.ReactNode;
  variant?: "xs" | "small" | "large";
  border?: boolean;
  minWidth?: "xs" | "small" | "large";
  marginBottom?: string;
}

export const DeviceManagerCard: React.FC<DeviceManagerCardProps> = ({
  children,
  variant,
  border,
  minWidth,
  marginBottom,
}) => {
  return (
    <CustomDeviceManagerCard
      variant={variant}
      minWidth={minWidth}
      border={border}
      marginBottom={marginBottom}
    >
      {children}
    </CustomDeviceManagerCard>
  );
};
