export enum ChipStyles {
  Manager = "Manager",
  Enabled = "Enabled",
  Disabled = "Disabled",
  Deployed = "Deployed",
  Undeployed = "Undeployed",
  Owner = "Owner",
  Editor = "Editor",
  Viewer = "Viewer",
}
