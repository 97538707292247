import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { ImageCarousel } from "../../../Components/image-carousel/ImageCarousel";
import LocationCard from "../LocationCard";
import { Location } from "../../../Interfaces/Device/Location";
import { InfoCardLocation } from "../manage/info-card-location/InfoCardLocation";
import { setActiveLocationImageCard } from "../../../Features/Slice/locations/locationsSlice";
import { RootState } from "../../../Store/store";
import { ResponseObject } from "../../../Interfaces/Response";
import { IntegrationStats } from "../../../Interfaces/Integration/IntegrationStats";
import { integrationService } from "../../../Services/Integration/integration.service";
import { getLastScannedSensorData } from "../../../Services/Dashboard/dashboard.service";

export const LocationList = () => {
  /* Hooks */
  const dispatch = useAppDispatch();
  const locationList = useAppSelector((state: any) => state.locations.data);
  const devices = useAppSelector((state: RootState) => state.devices.data);
  const activeLocationId = useAppSelector(
    (state) => state.locations.activeLocationId,
  );

  /* States */
  const [currentIndex, setCurrentIndex] = useState<number>(
    locationList.findIndex((l: Location) => l.Id === activeLocationId),
  );

  /* Methods */
  const handleImageIndexChange = (index: number, id: number) => {
    setCurrentIndex(index);
    if (!id) {
      id = locationList[index].Id || "";
    }
    dispatch(setActiveLocationImageCard(id));
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      handleImageIndexChange(currentIndex - 1, 0);
    }
  };

  const handleNext = () => {
    if (currentIndex < locationList.length - 1) {
      handleImageIndexChange(currentIndex + 1, 0);
    }
  };

  return (
    <>
      <ImageCarousel
        currentIndex={currentIndex}
        dataLength={locationList.length}
      >
        {locationList.map((location: Location, index: number) => (
          <LocationCard
            key={location.Id}
            location={location}
            onClick={() => handleImageIndexChange(index, location.Id)}
          />
        ))}
      </ImageCarousel>
      {locationList[currentIndex]?.Id ?
        <InfoCardLocation
          key={locationList[currentIndex].Id}
          location={locationList[currentIndex]}
          devices={devices}
          next={handleNext}
          prev={handlePrev}
        />
      : null}
    </>
  );
};
