import React from "react";
import { CustomHelperText } from "./HelperText.style";

export interface HelperTextProps {
  children?: string;
  error?: boolean;
}

//Helper text component
export const HelperText: React.FC<HelperTextProps> = ({ children, error }) => {
  return <CustomHelperText error={error}>{children}</CustomHelperText>;
};
