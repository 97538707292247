import React from "react";
import { StyledIoMdDownload } from "../tables/Table.style";
import { Tooltip } from "react-tooltip";

interface DownloadIconProps {
  handleDownloadOnClick: () => void;
}

const DownloadIcon: React.FC<DownloadIconProps> = ({
  handleDownloadOnClick,
}) => {
  return (
    <>
      <StyledIoMdDownload
        onClick={handleDownloadOnClick}
        data-tooltip-id="download-tooltip"
        data-tooltip-content="Download"
        data-tooltip-place="top"
      />
      <Tooltip id="download-tooltip" />
    </>
  );
};

export default DownloadIcon;
