import axios from "axios";
import { DeviceConfiguration } from "../../Interfaces/Device/DeviceConfiguration";
import { DeviceConfigurationWifi } from "../../Interfaces/Device/DeviceConfigurationWifi";
import { OpenErrorNotification } from "../../Components/notification/Notification";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

const getDeviceConfig = async (
  id: number,
  deviceId: string,
  boardTypes: number,
) => {
  const searchString = `id${id > 0 ? "=" + id : ""}&deviceId${
    deviceId.length > 0 ? "=" + deviceId : ""
  }&boardTypes${boardTypes > 0 ? "=" + boardTypes : ""}`;
  try {
    let response = await axios
      .get(`${API_URL}/DeviceConfiguration?${searchString}`)
      .then(async (response) => {
        await console.log(response.data);
        return response.data;
      });
    return response;
  } catch (error: unknown) {
    return error;
  }
};

const getWifiConnectionResult = async (deviceId: string) => {
  try {
    let response = await axios
      .get(
        `${API_URL}/DeviceConfiguration/getWifiConnectionResult?deviceId=${deviceId}`,
      )
      .then((response) => {
        console.log(response.data);
        return response.data;
      });
    return response;
  } catch (error: unknown) {
    return error;
  }
};

const sendFirmwareUpdate = async (deviceId: string) => {
  try {
    let response = await axios
      .patch(
        `${API_URL}/DeviceConfiguration/sendFirmwareUpdate?deviceId=${deviceId}`,
      )
      .then((response) => {
        console.log(response.data);
        return response.data;
      });
    return response;
  } catch (error: unknown) {
    return error;
  }
};

const patchDeviceConfig = async (deviceConfig: DeviceConfiguration) => {
  try {
    let response = await axios
      .patch(`${API_URL}/DeviceConfiguration`)
      .then((response) => {
        console.log(response.data.Result);
        return response.data.Result;
      });
    return response;
  } catch (error: unknown) {
    return error;
  }
};

const patchDeviceConfigWifi = async (deviceConfig: DeviceConfigurationWifi) => {
  try {
    let response = await axios
      .patch(
        `${API_URL}/DeviceConfiguration/updateWifiConfiguration`,
        deviceConfig,
      )
      .then((response) => {
        console.log(response.data);
        return response.data;
      });
    return response;
  } catch (error: unknown) {
    return error;
  }
};

const patchDeviceConfigExposure = async (
  deviceId: string,
  exposure: number,
) => {
  try {
    let response = await axios
      .patch(`${API_URL}/DeviceConfiguration/Exposure`, {
        Item1: deviceId,
        Item2: exposure,
      })
      .then((response) => {
        console.log(response.data);
        return response.data;
      });
    return response;
  } catch (error: unknown) {
    return error;
  }
};
const patchDeviceConfigCoolDown = async (
  deviceId: string,
  coolDown: number,
) => {
  try {
    let response = await axios
      .patch(`${API_URL}/DeviceConfiguration/CoolDown`, {
        Item1: deviceId,
        Item2: coolDown,
      })
      .then((response) => {
        console.log(response.data);
        return response.data;
      });
    return response;
  } catch (error: unknown) {
    return error;
  }
};
const patchDeviceConfigLocation = async (
  deviceId: string,
  locationId: number,
) => {
  try {
    let response = await axios
      .patch(`${API_URL}/DeviceConfiguration/Location`, {
        Item1: deviceId,
        Item2: locationId,
      })
      .then((response) => {
        console.log(response.data);
        return response.data;
      });
    return response;
  } catch (error: unknown) {
    return error;
  }
};
const patchDeviceConfigName = async (
  deviceId: string,
  newDeviceName: string,
) => {
  let response = await axios
    .patch(`${API_URL}/device/updateTagName`, {
      DeviceId: deviceId,
      NewTagName: newDeviceName,
    })
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((err: any) => {
      OpenErrorNotification(err.response.data.ErrorMessage);
      return err.response.data;
    });
  return response;
};
const deviceConfigService = {
  getDeviceConfig,
  getWifiConnectionResult,
  sendFirmwareUpdate,
  patchDeviceConfig,
  patchDeviceConfigWifi,
  patchDeviceConfigExposure,
  patchDeviceConfigCoolDown,
  patchDeviceConfigLocation,
  patchDeviceConfigName,
};

export default deviceConfigService;
