import React, { useMemo } from "react";
import { Area, Column } from "@ant-design/charts";
import { FramesChartWrapper } from "../../Components/data-stats/DataStats.style";
import { Typography } from "../../Components/typrography/Typography";
import { WeeklyData } from "./WeeklyChartSection";
import { DefaultThemeColor } from "../../app/Theme";

// Define the expected props for the WeeklyChart component
interface FramesChartProps {
  type: string;
  title: string;
  data: WeeklyData[];
  chartType: "Bar" | "Line";
  yFieldLabel: string;
}

const WeeklyChart: React.FC<FramesChartProps> = ({
  type,
  title,
  data,
  chartType,
  yFieldLabel,
}: FramesChartProps) => {
  // Memoize the chart configuration to avoid unnecessary recalculations on re-renders
  const chartConfig = useMemo(
    () => ({
      data: data,
      xField: "date",
      yField: yFieldLabel || "average",
      xAxis: {
        range: [0, 1],
      },
      color: DefaultThemeColor["primary"],
    }),
    [data],
  );
  const barConfig = useMemo(
    () => ({
      data,
      xField: "date",
      yField: yFieldLabel || "count",
      seriesField: "type",
      isGroup: true,
      color: [DefaultThemeColor["primary"], "#41D8CE", "#D84187"],
      columnStyle: {
        radius: [0, 0, 0, 0],
      },
    }),
    [data],
  );
  return (
    <FramesChartWrapper>
      {/* Display chart type */}
      <Typography variant="xl" display="flex" direction="flex-start">
        {type}
      </Typography>
      {/* Display chart title */}
      <Typography
        variant="xl"
        weight="bold"
        color="primary"
        display="flex"
        direction="flex-start"
      >
        {title}
      </Typography>
      {/* Render Area chart with the memoized configuration */}
      {chartType === "Bar" ? (
        <Column {...barConfig} />
      ) : (
        <Area {...chartConfig} />
      )}
    </FramesChartWrapper>
  );
};

export default WeeklyChart;
