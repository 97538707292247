import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface RouteOption {
  RouteOption: number;
}

const initialState: RouteOption = {
  RouteOption: 0,
};

export const routesSlice = createSlice({
  name: "routes",
  initialState,
  reducers: {
    setRouteOption(state, action: PayloadAction<number>) {
      state.RouteOption = action.payload;
    },
  },
});

export const { setRouteOption } = routesSlice.actions;
export default routesSlice.reducer;
