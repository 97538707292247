import styled from "styled-components";
import { TabsProps } from "./Tabs";

export const CustomTabs = styled.div<
  Pick<
    TabsProps,
    "tabs" | "selectedTab" | "padding" | "customTabsDisplay" | "backgroundColor"
  >
>`
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  display: ${({ customTabsDisplay }) =>
    customTabsDisplay ? customTabsDisplay : "block"};
  padding: ${({ padding }) => (padding ? padding : "1rem 3rem 2rem")};
  outline: none;
  width: 100%;

  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#020a01"};
  color: ${(props) => props.theme.colors.white};
`;
