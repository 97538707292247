import React, { useState } from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import { InputWrapper, CustomSearchInput } from "./SearchInput.style";

export type inputParamsType = {
  label: string;
  placeHolder: string;
  searchIcon: boolean;
  background?: string;
};

export interface InputProps {
  label: string;
  placeHolder: string;
  searchIcon: boolean;
  background?: string;
  maxLength?: number;
  style?: React.CSSProperties;
  handleSearch: (inputValue: string) => void;
}

const SearchInput = (props: InputProps) => {
  const {
    label,
    placeHolder,
    searchIcon,
    background,
    maxLength,
    handleSearch,
  } = props;
  const [searchValue, setSearchValue] = useState("");
  const onClickSearch = () => {
    if (searchValue) handleSearch(searchValue);
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      handleSearch("");
    }
  };

  const handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch(searchValue);
    }
  };

  return (
    <InputWrapper searchIcon={searchIcon} style={props.style || {}}>
      {label ? <label htmlFor={label}></label> : ""}
      <CustomSearchInput
        type="text"
        placeholder={placeHolder}
        background={background}
        maxLength={maxLength ? maxLength : 50}
        value={searchValue}
        onChange={handleSearchInputChange}
        onKeyDown={handleEnterKey}
      />
      {searchIcon ? (
        <BiSearchAlt2
          size={"24px"}
          className="search"
          onClick={() => onClickSearch()}
        />
      ) : (
        ""
      )}
    </InputWrapper>
  );
};

export default SearchInput;
