import React from "react";
import { CustomFlexContainer } from "./FlexContainer.style";

export interface FlexContainerProps {
  justifyContent?: string;
  alignItems?: string;
  flexDirection?: string;
  flexWrap?: string;
  children?: string | React.ReactNode | React.ReactNode[];
  gap?: string;
  height?: string;
  width?: string;
  margin?: string;
  padding?: string;
  hasBorder?: boolean;
}

export const FlexContainer: React.FC<FlexContainerProps> = ({
  justifyContent,
  alignItems,
  flexDirection,
  flexWrap = "nowrap",
  children,
  gap,
  height,
  width,
  margin,
  padding,
  hasBorder,
}) => {
  return (
    <CustomFlexContainer
      justifyContent={justifyContent}
      alignItems={alignItems}
      flexDirection={flexDirection}
      flexWrap={flexWrap}
      gap={gap}
      height={height}
      width={width}
      margin={margin}
      padding={padding}
      hasBorder={hasBorder}
    >
      {children}
    </CustomFlexContainer>
  );
};
