import React from "react";
import {
  CurrentSubscriptionWrapper,
  SubscriptionWrapper,
  TextWrapper,
} from "../../../Components/card/subscription-card/SubscriptionCard.style";
import {
  CompanySubscription,
  StripeProduct,
} from "../../../Interfaces/Subscription";
import SubscriptionCard from "../../../Components/card/subscription-card/SubscriptionCard";
import { Typography } from "../../../Components/typrography/Typography";
import { formatDate } from "../../../Utils/dateUtils";

interface CurrentSubscriptionProps {
  isSubscriptionPurchased: () => boolean;
  companySubscription: CompanySubscription[];
  subscriptions: StripeProduct[] | undefined;
}

const CurrentSubscription: React.FC<CurrentSubscriptionProps> = ({
  isSubscriptionPurchased,
  companySubscription,
  subscriptions,
}: CurrentSubscriptionProps) => {
  const getActiveSubscription = () => {
    if (!isSubscriptionPurchased()) return null;

    const activeSub = subscriptions?.find(
      (sub) => sub.Id === companySubscription[0].StripeProductId,
    );

    if (!activeSub) return null;

    const lastPaymentDate = companySubscription[0].UpdatedById
      ? companySubscription[0].UpdatedAt
      : companySubscription[0].CreatedAt;

    let isExpired = false;
    if (new Date() > new Date(companySubscription[0].EndAt)) {
      isExpired = true;
    }
    return (
      <CurrentSubscriptionWrapper>
        <TextWrapper>
          <Typography
            direction="flex-start"
            variant="xl"
            style={{ padding: "0.5vw 0" }}
          >
            {isExpired ? "Last" : "Active"} Subscription
          </Typography>
        </TextWrapper>
        <SubscriptionCard
          key={activeSub.Id}
          title={activeSub.Name}
          price={`CAD $${activeSub.Metadata["monthly-price"]}/Mo`}
          descriptions={activeSub.Description?.split(",") || []}
        />
        <TextWrapper>
          <Typography
            direction="flex-start"
            variant="2xl"
            style={{ padding: "0.5vw 0" }}
          >
            Last Payment:
          </Typography>
          <Typography
            direction="flex-start"
            variant="lg"
            color="primary"
            style={{ padding: "0.5vw 0" }}
          >
            {formatDate(lastPaymentDate)}
          </Typography>
        </TextWrapper>
      </CurrentSubscriptionWrapper>
    );
  };
  return getActiveSubscription();
};

export default CurrentSubscription;
