import styled from "styled-components";
import { ToggleButtonProps } from "./VerticalToggleButton";

export const ToggleButtonWrapper = styled.div<
  Pick<ToggleButtonProps, "width" | "height">
>`
  display: flex;
  padding: 1rem;
  width: ${({ width }) => (width ? width : "12.1vw")};
  height: ${({ height }) => (height ? height : "7vh")};
  justify-content: space-between;
  align-items: center;
  border: 1px solid #707070;
  min-width: 180px;
  min-height: 75px;
`;

export const ToggleButtonLabel = styled.label`
  font-size: ${(props) => props.theme.fontSizes["2lg"]};
`;

export const CustomToggleButton = styled.label<{ checked: boolean }>`
  cursor: pointer;
  text-indent: -9999px;
  width: 19px;
  height: 38px;
  background: ${({ checked }) => (checked ? "#80D39B" : "#4B4B4B")};
  display: block;
  border-radius: 100px;
  position: relative;
  min-width: 19px;
  &:after {
    content: "";
    position: absolute;
    top: ${({ checked }) => (checked ? "0px" : "calc(57%)")};
    left: 2px;
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }
`;
