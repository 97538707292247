import React from "react";
import { StyledFiEdit } from "../tables/Table.style";
import { Tooltip } from "react-tooltip";
import { IconButton } from "../tables/table-button/TableButton.style";

interface EidtIconProps {
  handleEditOnClick: () => void;
  size?: string;
  tooltip?: string;
  disabled?: boolean;
}

const EditIcon: React.FC<EidtIconProps> = ({
  handleEditOnClick,
  size,
  tooltip,
  disabled,
}) => {
  return (
    <IconButton onClick={handleEditOnClick} disabled={disabled}>
      <StyledFiEdit
        size={size}
        data-tooltip-id="edit-tooltip"
        data-tooltip-content={tooltip || "Edit"}
        data-tooltip-place="top"
        className={disabled ? "disabled" : ""}
      />
      <Tooltip id="edit-tooltip" />
    </IconButton>
  );
};

export default EditIcon;
