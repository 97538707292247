import React, { useEffect, useState } from "react";
import { Location } from "../../../../Interfaces/Device/Location";
import { FlexContainer } from "../../../../Components/flex-container/FlexContainer";
import { Typography } from "../../../../Components/typrography/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditIcon from "../../../../Components/icons/EditIcon";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import {
  DropdownItem,
  getLocationsDropdown,
  setDropdownOptions,
} from "../../../../Features/Slice/dropdownOptions/dropdownListSlice";
import { RootState } from "../../../../Store/store";
import { getAllLocations } from "../../../../Services/Locations/Location.service";
import SaveIcon from "../../../../Components/icons/SaveIcon";
import { patchDeviceLocation } from "../../../../Services/DeviceManagement/devicePatch.service";
import {
  FilterDropdown,
  FilterOption,
} from "../../../../Components/dropdown/filter-dropdown/FilterDropdown";

type Props = {
  location: Location | null | undefined;
  deviceId: string;
  deployed: boolean;
};

export default function DeviceLocation({
  location,
  deviceId,
  deployed = false,
}: Props) {
  const dispatch = useAppDispatch();
  const locationDropdown = useAppSelector(getLocationsDropdown);
  const defaultValue: FilterOption = {
    label: location?.Name!,
    value: Number(location?.Id),
  };

  // Fetch all locations, devices, and AI models on initial load of the page
  useEffect(() => {
    if (
      !locationDropdown?.options.length ||
      !locationDropdown?.options.filter((x) => x.value).length
    ) {
      dispatch(getAllLocations({}));
    }
  }, []);
  const handleDropdownChange = ({ label, value }: FilterOption) => {
    if (value && value !== location?.Id) {
      dispatch(
        patchDeviceLocation({ deviceId: deviceId, locationId: Number(value) }),
      );
    }
  };
  return (
    <FlexContainer gap="8px">
      <Typography variant="md">
        <FontAwesomeIcon icon={faTruck} />
      </Typography>
      <Typography variant="md" weight="bold">
        Location:
      </Typography>
      {!deployed ? (
        <>
          <Typography variant="md" color={"green-100"}>
            {location?.Name || "-"}
          </Typography>
        </>
      ) : (
        <FilterDropdown
          defaultValue={defaultValue}
          title="Locations"
          options={locationDropdown?.options || []}
          onChange={({ label, value }: FilterOption) => {
            handleDropdownChange({ label, value });
          }}
        />
      )}
    </FlexContainer>
  );
}
