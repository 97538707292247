export interface INotificationData {
  id: number;
  description: string;
  createdTime: Date;
  priority: "high" | "medium" | "low";
}

const notificationList: INotificationData[] = [
  {
    id: 1,
    description: "Notification Description",
    createdTime: new Date("2023-01-03T15:29"),
    priority: "high",
  },
  {
    id: 2,
    description: "Notification Description",
    createdTime: new Date("2023-01-03T15:29"),
    priority: "low",
  },
  {
    id: 3,
    description: "Notification Description",
    createdTime: new Date("2023-01-03T15:29"),
    priority: "medium",
  },
  {
    id: 4,
    description: "Notification Description",
    createdTime: new Date("2023-01-03T15:29"),
    priority: "low",
  },
  {
    id: 5,
    description: "Notification Description",
    createdTime: new Date("2023-01-03T15:29"),
    priority: "high",
  },
  {
    id: 6,
    description: "Notification Description",
    createdTime: new Date("2023-01-03T15:29"),
    priority: "medium",
  },
  {
    id: 7,
    description: "Notification Description",
    createdTime: new Date("2023-01-03T15:29"),
    priority: "high",
  },
  {
    id: 8,
    description: "Notification Description",
    createdTime: new Date("2023-01-03T15:29"),
    priority: "low",
  },
  {
    id: 9,
    description: "Notification Description",
    createdTime: new Date("2023-01-03T15:29"),
    priority: "low",
  },
  {
    id: 10,
    description: "Notification Description",
    createdTime: new Date("2023-01-03T15:29"),
    priority: "high",
  },
  {
    id: 11,
    description: "Notification Description",
    createdTime: new Date("2023-01-03T15:29"),
    priority: "medium",
  },
];

export default notificationList;
