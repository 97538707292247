import React, { useState } from "react";
import CaptureImageVideoCard from "./CaptureImageVideoCard";
import { ImageCardListWrapper } from "./CaptureImageVideoCardList.style";
import ThumbnailModal from "../../modals/thumbnail-modal/ThumbnailModal";
import { ICaptureImageCard } from "../../../Types/captures/captures";

export interface CaptureImageCardListProps {
  data: ICaptureImageCard[];
  isArchivedData?: boolean;
  handleItemCheckChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleThumbnailClick: (id: number) => void;
  cardSize: string;
}

const CaptureImageVideoCardList: React.FC<CaptureImageCardListProps> = ({
  data,
  isArchivedData,
  handleItemCheckChange,
  handleThumbnailClick,
  cardSize,
}) => {
  const [isThumbnailModalOpen, setIsThumbnailModalOpen] = useState(false);
  const [activeCard, setActiveCard] = useState<ICaptureImageCard>(data[0]);

  const handleOpenThumbnailModal = (id: number) => {
    setIsThumbnailModalOpen(true);
    const card = data.find((item) => item.id === id);
    if (card) {
      setActiveCard(card);
    }
  };

  const handleCloseThumbnailModal = () => {
    setIsThumbnailModalOpen(false);
  };

  return (
    <>
      <ImageCardListWrapper cardSize={cardSize}>
        {data.map((item) => (
          <CaptureImageVideoCard
            key={item.id}
            image={item.thumbnail}
            isChecked={item.checked}
            isArchivedData={isArchivedData}
            id={item.id}
            imageCardInfo={item}
            handleItemCheckChange={handleItemCheckChange}
            handleOpenThumbnailModal={handleOpenThumbnailModal}
            handleThumbnailClick={handleThumbnailClick}
          />
        ))}
      </ImageCardListWrapper>
      <ThumbnailModal
        isOpen={isThumbnailModalOpen}
        onClose={handleCloseThumbnailModal}
        isArchivedData={isArchivedData}
        modalWidth="70vw"
        modalHeight="80vh"
        activeCard={activeCard}
      />
    </>
  );
};

export default CaptureImageVideoCardList;
