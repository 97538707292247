import React, { useState } from "react";
import { Typography } from "../../typrography/Typography";
import CustomModal from "../CustomModal";
import { ScaledButtonWrapper } from "../CustomModal.style";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  StyledFormikForm,
  UploadWrapper,
} from "../../tab/ai-model-manager/Upload.style";
import DragUpload from "../../input/upload-input/DragUpload";
import accountService from "../../../Services/Account/account.services";
import { OpenWarningNotification } from "../../notification/Notification";
import { useAppDispatch } from "../../../Store/hooks";
import { DefaultThemeColor } from "../../../app/Theme";
import { UploadMedia } from "../../../Services/Media/Media.Upload";
import { User, UserInfo, UserUpdatePayload } from "../../../Interfaces/User";

export interface AvatarUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: UserUpdatePayload;
  title: string;
  buttonText: string;
  buttonPadding?: string;
  editorHeight?: number;
  formPadding?: string;
}

interface FormValues {}

const validationSchema = Yup.object().shape({});

const AvatarUploadModal = (props: AvatarUploadModalProps) => {
  const dispatch = useAppDispatch();
  const { isOpen, onClose, title, buttonText, buttonPadding } = props;
  const [file, setFile] = useState<File | null>(null);

  const handleSubmit = async (values: FormValues, { setSubmitting }: any) => {
    try {
      if (file) {
        const response = await UploadMedia("Users", file);
        if (response.Result.Id) {
          dispatch(
            accountService.updateUserProfile({
              ...props.user,
              MediaId: response.Result.Id,
            }),
          )
            .then(() => {
              onClose();
            })
            .catch((e: any) => {
              console.error("updateUserProfile Error:", e);
            });
        }
        console.log("handleFileUpload -> response:", response);
      } else {
        OpenWarningNotification("Please add image");
      }
    } catch (error) {
      console.error("handleFileUpload Error:", error);
    }
  };

  const handleFileUpload = (uploadedFile: File) => {
    setFile(uploadedFile);
  };
  const cardHeader = () => {
    return (
      <Typography
        variant="lg"
        direction="flex-start"
        style={{ padding: "0 20px", fontWeight: "700" }}
      >
        {title}
      </Typography>
    );
  };
  const cardFooter = () => {
    return (
      <ScaledButtonWrapper buttonPadding={buttonPadding}>
        <button type="submit" form="avatar-upload">
          {buttonText}
        </button>
      </ScaledButtonWrapper>
    );
  };
  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      padding="1rem 0"
      width="auto"
      height="auto"
      header={cardHeader()}
      footer={cardFooter()}
    >
      <div style={{ padding: "1rem" }}>
        <Formik
          initialValues={{}}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }: any) => (
            <StyledFormikForm id="avatar-upload">
              <UploadWrapper width="21vw" height="21vw">
                <DragUpload
                  onFileUpload={handleFileUpload}
                  color="white"
                  iconColor={DefaultThemeColor["primary"]}
                  accept="image/jpg,image/jpeg,image/png"
                />
              </UploadWrapper>
            </StyledFormikForm>
          )}
        </Formik>
      </div>
    </CustomModal>
  );
};

export default AvatarUploadModal;
