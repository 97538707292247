import React from "react";
import mastercard from "../../Assets/Mastercard.svg";
import { IconWrapper, StyledMasterIcon } from "./MastercardIcon.style";

type Props = {};

const MastercardIcon = (props: Props) => {
  return (
    <IconWrapper>
      <StyledMasterIcon src={mastercard} alt="master card icon" />
    </IconWrapper>
  );
};

export default MastercardIcon;
