import React, { Fragment, useEffect, useState } from "react";
import { DeviceManagerMain } from "../../../../Components/device-manager-elements/deviceManagerMain";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import ToggleButton from "../../../../Components/button/toggle-button/ToggleButton";
import { SensorBlock } from "../sensors/Sensors.style";
import { Typography } from "../../../../Components/typrography/Typography";
import { Relay, RelayWithValues } from "../../../../Interfaces/Device/Relay";
import relayService, {
  syncRelays,
} from "../../../../Services/DeviceManagement/deviceRelays.service";
import { updateRelayInDevice } from "../../../Slice/devices/devicesSlice";
import RelayBox from "./RelayBox";
import SetupRelayDialog from "./SetupRelayDialog";
import { SwrmDevices } from "../../../../Interfaces/Device/SwrmDevice";
import { DeviceRelayWrapper, RelayButton } from "./RelayBox.style";
import RelayRoutineDialog from "./RelayRoutineDialog";
import SyncIcon from "../../../../Components/icons/SyncIcon";
export const Relays = () => {
  const [isModelOpen, setIsModelOpen] = useState<boolean>(false);
  const [isRoutineModalOpen, setIsRoutineModelOpen] = useState<boolean>(false);
  const [relayForSetup, setRelayForSetup] = useState<RelayWithValues | null>(
    null,
  );
  const dispatch = useAppDispatch();
  const device = useAppSelector((state) => state.devices);
  const selectedDevice = device.selectedDevice;
  const relays = selectedDevice.RelayWithValues;
  const childDevice = selectedDevice.ChildDevices;
  const updateRelayStatus = async (
    relay: Relay,
    value: boolean,
    revert: () => void,
  ) => {
    const response = await relayService.addRelayValue(relay.Id, value);

    if (!response.IsError) {
      dispatch(
        updateRelayInDevice({
          relay: response.Result,
          masterDeviceId: selectedDevice.Id || "",
        }),
      );
    } else {
      revert();
    }
  };
  const handleRelayEdit = (relay: RelayWithValues) => {
    console.log("handleRelayEdit", relay);
    setRelayForSetup(relay);
    setIsModelOpen(true);
  };

  const handleRelaySync = async () => {
    await dispatch(syncRelays(selectedDevice.Id));
  };

  const onClose = () => {
    setIsModelOpen(false);
    setIsRoutineModelOpen(false);
    setRelayForSetup(null);
  };

  return (
    <DeviceManagerMain data={[]}>
      {relays &&
      !relays?.length &&
      childDevice &&
      (!childDevice.length ||
        childDevice.every((d: SwrmDevices) => !d.RelayWithValues.length)) ? (
        <Typography color="red-100" variant="lg">
          No relays connected
        </Typography>
      ) : null}
      {relays && relays?.length > 0 ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <DeviceRelayWrapper>
            <Typography>{selectedDevice.Name}</Typography>
            <RelayButton onClick={() => setIsRoutineModelOpen(true)}>
              Create Routine
            </RelayButton>
            {/* Commented out for the time being. Will re-implement when starting the task */}
            {/* <RelayButton onClick={() => null}>Create Schedules</RelayButton> */}
          </DeviceRelayWrapper>

          <SyncIcon
            size="32px"
            handleSyncOnClick={handleRelaySync}
            tooltipContent="Sync Relays"
          />
        </div>
      ) : null}
      <SensorBlock>
        {!!relays &&
          !!relays?.length &&
          relays?.map((r: RelayWithValues, index: number) => (
            <div key={r.Id} style={{}}>
              <RelayBox title={`${r.Name}`} onEdit={() => handleRelayEdit(r)}>
                <ToggleButton
                  id={r.Id.toString()}
                  isActive={r.Active || false}
                  onToggle={(status, revert) =>
                    updateRelayStatus(r, status, revert)
                  }
                />
              </RelayBox>
            </div>
          ))}
      </SensorBlock>
      {!!childDevice &&
        !!childDevice.length &&
        childDevice.map((d, index) =>
          !!d.RelayWithValues && !!d.RelayWithValues.length ? (
            <Fragment key={d.Id}>
              <Typography>{d.Name}</Typography>
              <SensorBlock>
                {!!d.RelayWithValues &&
                  d.RelayWithValues &&
                  !!d.RelayWithValues &&
                  d.RelayWithValues?.length &&
                  d.RelayWithValues &&
                  d.RelayWithValues?.map(
                    (r: RelayWithValues, index: number) => (
                      <div key={r.Id} style={{}}>
                        <RelayBox
                          title={`${r.Name}`}
                          onEdit={() => handleRelayEdit(r)}
                        >
                          <ToggleButton
                            id={r.Id.toString()}
                            isActive={r.Active || false}
                            onToggle={(status, revert) =>
                              updateRelayStatus(r, status, revert)
                            }
                          />
                        </RelayBox>
                      </div>
                    ),
                  )}
              </SensorBlock>
            </Fragment>
          ) : null,
        )}
      {isModelOpen && relayForSetup && (
        <SetupRelayDialog relay={relayForSetup} onClose={onClose} />
      )}
      {isRoutineModalOpen && <RelayRoutineDialog onClose={onClose} />}
    </DeviceManagerMain>
  );
};
