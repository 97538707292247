import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import Loader from "../../../Components/loader/Loader";
import {
  setActiveLocationImageCard,
  isLocationLoading,
} from "../../../Features/Slice/locations/locationsSlice";
import LocationCard from "../LocationCard";
import { getAllLocations } from "../../../Services/Locations/Location.service";
import { ImageCardWrapper } from "../../../Components/image-card/ImageCard.style";

export interface ImageCardListProps {}

const ImageCardList = ({}: ImageCardListProps) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(isLocationLoading);
  const carouselRef = useRef<HTMLDivElement>(null);
  const locationList = useSelector((state: any) => state.locations);

  useEffect(() => {
    dispatch(getAllLocations({})).catch((error) => {
      console.error("Failed to fetch locations:", error);
    });
  }, [dispatch]);

  const handleImageCardClick = (index: number, id: number) => {
    dispatch(setActiveLocationImageCard(id));
  };

  return (
    <>
      {isLoading && <Loader isLoadingScreen />}
      <div style={{ fontSize: "20px", fontWeight: 900, padding: "10px" }}>
        Location List
      </div>
      <ImageCardWrapper>
        {locationList.data.map((location: any, index: number) => (
          <LocationCard
            key={location.Id}
            location={location}
            onClick={() => handleImageCardClick(index, location.Id)}
          />
        ))}
      </ImageCardWrapper>
    </>
  );
};

export default ImageCardList;
