import styled from "styled-components";

export const InputSection = styled.div`
  display: flex;
  padding: 5px;
  cursor: pointer;
  align-items: center;

  label {
    cursor: pointer;
  }
`;

export const RadioButton = styled.input`
  border-radius: 1.25rem;
  appearance: none;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid #ffffff;
  outline: none;
  margin-right: 0.5rem;
  cursor: pointer;

  &:hover {
    border: 1px solid #707070;
  }

  &:checked {
    &::after {
      appearance: none;
      content: "";
      position: absolute;
      width: 0.725rem;
      height: 0.725rem;
      display: block;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border-radius: 0.725rem;
      background: ${(props) => props.theme.colors["primary"]};
    }
  }
`;
