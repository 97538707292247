import styled from "styled-components";

const LoginPageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  background-color: #020a01;
`;

const PageTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #fff;
  background: #020a01;

  @media only screen and (max-width: 450px) {
    display: none;
  }
`;

const PageTitleHeading = styled.p`
  font-size: ${(props) => props.theme.fontSizes["3xl"]};
  font-weight: bold;
  letter-spacing: 0.1rem;
  align-self: center;
`;
const PageTitleDescription = styled.p`
  font-size: ${(props) => props.theme.fontSizes["2xl"]};
  align-self: center;
  letter-spacing: 0.5px;
  color: #fff;
`;

const ForgotPassword = styled.button`
  display: block;
  font-size: ${(props) => props.theme.fontSizes.sm};
  text-decoration: underline ${(props) => props.theme.colors};
  color: ${(props) => props.theme.colors["primary"]};
  border: none;
  background-color: transparent;
  padding-bottom: 8px;
  &:hover {
    color: ${(props) => props.theme.colors["secondary"]};
    text-decoration: underline ${(props) => props.theme.colors["secondary"]};
  }
`;

const CheckBoxLabel = styled.label`
  font-size: ${(props) => props.theme.fontSizes.xs};
  padding-left: 5px;
`;

const CheckBox = styled.input.attrs({ type: "checkbox" })`
  padding: 5px;
  background-color: transparent;
  border: 1px solid #fff;
  &:hover {
    color: #4454ea;
  }
`;

export {
  LoginPageContainer,
  PageTitle,
  PageTitleHeading,
  PageTitleDescription,
  ForgotPassword,
  CheckBoxLabel,
  CheckBox,
};
