import React from "react";
import { CustomCardContent } from "./CardContent.style";

export interface CardContentProps {
  children: React.ReactNode | React.ReactNode[];
}

export const CardContent: React.FC<CardContentProps> = ({ children }) => {
  return <CustomCardContent>{children}</CustomCardContent>;
};
