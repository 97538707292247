import React from "react";
import { CardContent, CustomImageCard, Header } from "./ImageCard.style";
import { Typography } from "../typrography/Typography";
import { LazyLoadImage } from "react-lazy-load-image-component";

// Define props interface
interface CardProps {
  className: string;
  image: string;
  title: string;
  subtitle: string;
  children?: any;
  onClick?: any;
}

// Define functional component
const ImageCard: React.FC<CardProps> = ({
  className,
  image,
  title,
  subtitle,
  children,
  onClick,
}) => {
  return (
    <CustomImageCard onClick={onClick} className={className}>
      <LazyLoadImage
        key={image}
        src={image}
        alt={title}
        effect="blur"
        width="100%"
        height="60%"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
        }}
      />
      <CardContent>
        <Header title={title}>{title}</Header>
        {subtitle && <Typography variant="xs">{subtitle}</Typography>}
        {children}
      </CardContent>
    </CustomImageCard>
  );
};
export default ImageCard;
