import Select, { MenuPlacement } from "react-select";
import {
  FilterDropdownWrapper,
  filterDropdownStyle,
  filterDropdownStyleFull,
} from "./FilterDropdown.style";
import { useEffect } from "react";

export interface FilterOption {
  label: string;
  value: string | number;
}
export interface FilterDropdownProps {
  defaultValue?: FilterOption | undefined;
  value?: FilterOption | null;
  options: FilterOption[] | undefined;
  title: string;
  isMulti?: boolean;
  menuPlacement?: MenuPlacement;
  isFullSize?: boolean;
  onChange?: ({ label, value }: FilterOption) => void;
}

export const FilterDropdown = ({
  defaultValue,
  value,
  options,
  title,
  menuPlacement,
  isFullSize,
  onChange,
}: FilterDropdownProps) => {
  const handleOptionClick = (option: FilterOption | null) => {
    const selectedOption: FilterOption = {
      label: option?.label!,
      value: option?.value!,
    };

    if (onChange) onChange(selectedOption);
  };

  const validateDefaultValue =
    defaultValue == null ||
    defaultValue.label == undefined ||
    defaultValue.label == ""
      ? null
      : defaultValue;
  return (
    <FilterDropdownWrapper>
      <Select
        name={title}
        defaultValue={validateDefaultValue}
        {...(value === null && { value: null })}
        options={options}
        onChange={(option: FilterOption | null) => handleOptionClick(option)}
        placeholder={title}
        menuPlacement={menuPlacement || "bottom"}
        menuPosition="fixed"
        maxMenuHeight={250}
        styles={isFullSize ? filterDropdownStyleFull : filterDropdownStyle}
      />
    </FilterDropdownWrapper>
  );
};
