import styled from "styled-components";
import { setupGuideProps } from "./SetupGuide";

export const SetupGuideListWrapper = styled.div<
  Pick<setupGuideProps, "padding">
>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ padding }) => (padding ? padding : "0 2vw")};
`;
