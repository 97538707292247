import React, { useState } from "react";
import { SetupDropdown } from "../../../data/setupGuide";
import { SetupGuideListWrapper } from "./SetGuide.style";
import { Accordion } from "../../../Components/accordion/Accordion";

export interface setupGuideProps {
  data: SetupDropdown[];
  width?: string;
  padding?: string;
  margin?: string;
}

const SetupGuideList = ({ data, padding, width, margin }: setupGuideProps) => {
  const [activeId, setActiveId] = useState<number | null>(null);

  const handleToggle = (id: number) => {
    if (activeId === id) {
      setActiveId(null); // Close the accordion if it is already open
    } else {
      setActiveId(id); // Open the clicked accordion and close others
    }
  };
  return (
    <SetupGuideListWrapper padding={padding}>
      {data.map((item, index) => (
        <Accordion
          key={index}
          id={item.id}
          title={item.question}
          description={item.answer}
          width={width || ""}
          alignItems="flex-start"
          margin={margin || ""}
          label=""
          color=""
          display=""
          verticalLine={false}
          backgroundColor=""
          isActive={activeId === item.id}
          handleToggle={() => handleToggle(item.id)}
        ></Accordion>
      ))}
    </SetupGuideListWrapper>
  );
};
export default SetupGuideList;
