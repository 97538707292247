import { Buffer } from "buffer";
import AWS from "aws-sdk";
import {
  KmsKeyringBrowser,
  KMS,
  getClient,
  buildClient,
  CommitmentPolicy,
  buildDecrypt,
} from "@aws-crypto/client-browser";
window.Buffer = window.Buffer || require("buffer").Buffer;

// Utility Interfaces
interface AWSParams {
  Bucket: string;
  Key: string;
  Expires: number;
}

// Utility Variables
const s3Bucket = process.env.REACT_APP_AWS_BUCKET!;
const s3AccessKey = process.env.REACT_APP_AWS_ACCESS_KEY!;
const s3SecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY!;
const s3Region = process.env.REACT_APP_AWS_REGION!;
const kmsKeyARN = process.env.REACT_APP_AWS_KMS_KEY_ARN!;

// AWS Credentials Config
AWS.config.update({
  accessKeyId: s3AccessKey,
  secretAccessKey: s3SecretAccessKey,
  region: s3Region,
});

// AWS Clients and dependencies
const { decrypt } = buildDecrypt();
const s3Client = new AWS.S3();
const kmsClient = getClient(KMS, {
  credentials: {
    accessKeyId: s3AccessKey,
    secretAccessKey: s3SecretAccessKey,
  },
});

// Utility Methods
const getSignedImageURL = (imageURL: string) => {
  if (!imageURL) return null;
  if (imageURL.includes("http://") || imageURL.includes("https://")) {
    return imageURL;
  }
  const params: AWSParams = {
    Bucket: s3Bucket,
    Key: imageURL,
    Expires: 3600,
  };

  const signedUrl = s3Client.getSignedUrl("getObject", params);
  return signedUrl;
};

export const awsUtils = {
  getSignedImageURL,
};

export const decryptData = async (data: string) => {
  try {
    const keyIds = [kmsKeyARN];
    const keyring = new KmsKeyringBrowser({
      clientProvider: kmsClient,
      keyIds,
    });

    const { plaintext } = await decrypt(keyring, Buffer.from(data, "base64"));

    const tokenByteArray = Array.from(plaintext);
    const tokenString = btoa(String.fromCharCode.apply(null, tokenByteArray));

    return tokenString;
  } catch (error: any) {
    console.log(error.messageHeader);
    console.log(error.stack);
  }
};
