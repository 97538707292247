import React from "react";
import { FormControl } from "../form-control/FormControl";
import { FormLabel } from "../form-label/FormLabel";
import { HelperText } from "../helper-text/HelperText";
import { CustomTextField } from "./TextField.style";

export interface TextFieldProps {
  id: string;
  label: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  helpText?: string;
  name?: string;
  type?: React.HTMLInputTypeAttribute;
  placeholder?: string;
  error?: boolean;
  variant?: string;
  dataPlaceholder?: string;
  children?: React.ReactNode;
  color?: string;
  style?: React.CSSProperties;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

// text field component
export const TextField: React.FC<TextFieldProps> = ({
  id,
  label,
  value,
  onChange,
  helpText = "",
  name = "",
  type = "text",
  placeholder,
  error = false,
  variant,
  dataPlaceholder = "",
  color,
  onBlur,
}) => {
  return (
    <FormControl>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <CustomTextField
        type={type}
        name={name}
        value={value}
        id={id}
        onChange={onChange}
        placeholder={placeholder}
        variant={variant}
        color={color}
        onBlur={onBlur}
      ></CustomTextField>
      {helpText && <HelperText error={error}>{helpText}</HelperText>}
    </FormControl>
  );
};
