import { useEffect, useState } from "react";
import { getLastScannedSensorData } from "../../Services/Dashboard/dashboard.service";
import GaugePanel from "../../Components/data-stats/GaugePanel";
import { isTodayDate } from "../../Utils/dateUtils";
import { GaugeChartBlock, GaugeChartWrapper } from "./Dashboard.style";
import { Typography } from "../../Components/typrography/Typography";
import { RootState } from "../../Store/store";
import { useAppSelector } from "../../Store/hooks";
import Loader from "../../Components/loader/Loader";
import NoDataAvailable from "../../Components/loader/NoDataAvailable";
import {
  booleanSensor,
  formatIntegratedSensorValues,
} from "../../Utils/dashboardUtils";
import { integrationService } from "../../Services/Integration/integration.service";
import { ResponseObject } from "../../Interfaces/Response";
import { IntegrationStats } from "../../Interfaces/Integration/IntegrationStats";
import { Device } from "../../Interfaces/Device/Device";
import { SwrmDevices } from "../../Interfaces/Device/SwrmDevice";

// Define interfaces for SensorData and GaugeValue
interface SensorData {
  title: string;
  value: number | string;
}

interface Props {
  selectedLocation: number | null | undefined;
  selectedDevice: string | null | undefined;
}

const GaugeChartSection = ({ selectedLocation, selectedDevice }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const devices = useAppSelector((state: RootState) => state.devices.data);
  const auth = useAppSelector((state: RootState) => state.persisted.auth);
  const integrations = useAppSelector(
    (state: RootState) => state.integration.integrations,
  );
  // State to manage sensor data
  const [deviceWithSensor, setDeviceWithSensor] = useState<any>([]);
  const [integrationSensors, setIntegrationSensors] = useState<any>([]);
  const specificOrder = [
    "Temperature",
    "Humidity",
    "AQI",
    "Current",
    "Voltage",
    "Wind",
    "CO2",
    "Moisture",
    "Conductivity",
    "DiElectricity",
    "Salinity",
    "Field Capacity",
    "Wilting Point",
    "Water Balance",
    "Oxygen",
  ];
  const hideSensor = ["Unknown", "Door"];

  // Fetch data initially when the component mounts
  useEffect(() => {
    fetchData();
    // Fetch data every 10 minutes
    const interval = setInterval(
      () => {
        fetchData();
      },
      10 * 60 * 1000,
    ); // 10 minutes in milliseconds

    // Clean up the interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, [selectedLocation, selectedDevice]);

  // Function to format date for integrated sensors
  const formatDate = (timeTaken: string) => {
    const currentTime = new Date(timeTaken || "");

    // Convert UTC time to local time
    const localTime = new Date(currentTime + "UTC");

    const time =
      !timeTaken ? "No Data"
      : isTodayDate(currentTime) ?
        `Today at ${localTime.toLocaleTimeString("en-US", {
          timeStyle: "short",
        })}`
      : localTime.toLocaleString("en-US", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        });

    return time;
  };

  // Function to format sensor data
  const formatSensorData = (
    sensor: string,
    sensorValue: string,
    timeTaken?: string,
    valueTypeId?: number,
  ): SensorData => {
    const currentTime = new Date(timeTaken || "");

    // Convert UTC time to local time
    const localTime = new Date(currentTime + "UTC");

    const title =
      !timeTaken ? "No Data"
      : isTodayDate(currentTime) ?
        `Today at ${localTime.toLocaleTimeString("en-US", {
          timeStyle: "short",
        })}`
      : localTime.toLocaleString("en-US", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        });

    // value type id 1 = int
    if (booleanSensor.includes(sensor)) {
      //this line of code is causing a mismatch on the gauge
      //let roundedValue = Number(sensorValue) === 0 ? "Close" : "Open";

      return { value: sensorValue, title };
    } else if (valueTypeId !== 1) {
      const parsedValue = parseFloat(sensorValue) || 0;
      let roundedValue = parseFloat(parsedValue.toFixed(2)); // Round to two decimal places
      return { value: roundedValue, title };
    } else {
      let roundedValue = Math.round(Number(sensorValue) || 0);
      return { value: roundedValue, title };
    }
  };

  // Function to fetch sensor data
  const fetchData = async () => {
    setIsLoading(true);
    try {
      // Fetch Integration Devices
      if (
        auth.company.IntegratedWith !== "" &&
        auth.company.IntegratedWith !== null &&
        integrations[0].Enabled
      ) {
        const integrationResponse: ResponseObject<IntegrationStats> =
          await integrationService.getLatestSensorData(
            selectedDevice,
            selectedLocation,
          );
        setIntegrationSensors(integrationResponse.Result);
      }

      // Fetch SWRM Devices
      const response = await getLastScannedSensorData(
        selectedLocation,
        selectedDevice?.toLocaleString(),
      );

      if (response.Result !== null) {
        const deviceList = Object.keys(response.Result);
        const deviceWithSensor = deviceList.map((device) => {
          const sensors = Object.keys(response.Result[device]).filter(
            (x) => !hideSensor.includes(x),
          );
          const sensorsWithCustomUI = Object.keys(
            response.Result[device],
          ).filter((x) => booleanSensor.includes(x));
          const restSensors = [...sensors, ...sensorsWithCustomUI].filter(
            (sensor) => !specificOrder.includes(sensor),
          );
          const reorderedSensors = specificOrder.concat(restSensors);
          const deviceSensors = reorderedSensors.filter(
            (s) => sensors.includes(s) || restSensors.includes(s),
          );
          const formattedSensorData = deviceSensors.map((sensor) =>
            formatSensorData(
              sensor,
              response.Result[device][sensor]?.Value,
              response.Result[device][sensor]?.TimeTaken,
              response.Result[device][sensor]?.ValueTypeId,
            ),
          );
          return {
            deviceId: device,
            sensors: deviceSensors,
            formattedSensorData,
          };
        });
        console.log(deviceWithSensor);
        setDeviceWithSensor(deviceWithSensor);
      } else {
        console.log("No data available");
      }
    } catch (error) {
      console.error(`Error fetching data:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDeviceName = (deviceId: string) => {
    const device = devices.find((d) => d.Id === deviceId);
    if (device) {
      return device?.Name || device?.PiSerial || deviceId;
    } else {
      const deviceWithChildDevice = devices.find(
        (d) => d?.ChildDevices?.findIndex((c) => c.Id === deviceId) !== -1,
      );
      if (deviceWithChildDevice) {
        const childDevice = deviceWithChildDevice.ChildDevices?.find(
          (c) => c.Id === deviceId,
        );
        return `${deviceWithChildDevice?.Name} (${
          childDevice?.Name || childDevice?.PiSerial || deviceId
        })`;
      } else {
        return deviceId;
      }
    }
  };
  const renderSensorPanels = (data: any) => {
    return data.sensors.map((sensor: any, index: number) => {
      if (sensor && data.formattedSensorData) {
        return (
          <GaugePanel
            key={sensor}
            percent={data.formattedSensorData[index]?.value || 0}
            title={data.formattedSensorData[index]?.title || "No Data"}
            type={sensor}
            variant={sensor}
            titleVariant="lg"
            indicator={!booleanSensor.includes(sensor)}
          />
        );
      }
      return null;
    });
  };

  const isValidKey = (key: string): boolean => {
    const excludedKeys = ["device", "timestamp", "site"];
    return !excludedKeys.includes(key);
  };

  const renderIntegratedSensorPanels = (sensors: IntegrationStats[]) => {
    if (sensors.length > 0) {
      return sensors.map((sensor) => (
        <GaugeChartBlock key={sensor.DeviceId}>
          <Typography
            variant="xl"
            display="flex"
            direction="flex-start"
            weight="bold"
          >
            {sensor.TagName} (Soil Scout Sensor)
          </Typography>
          <GaugeChartWrapper>
            {Object.entries(sensor.Measurement).map(([key, value]) => {
              if (value !== undefined && isValidKey(key)) {
                return (
                  <GaugePanel
                    key={`${sensor.DeviceId}-${key}`}
                    percent={formatIntegratedSensorValues(key, value)}
                    title={
                      formatDate(sensor.Measurement.timestamp?.toString()) ||
                      "No Data"
                    }
                    type={key}
                    variant={key}
                    titleVariant="lg"
                  />
                );
              }
              return null;
            })}
          </GaugeChartWrapper>
        </GaugeChartBlock>
      ));
    }
  };

  return (
    <div className="position-relative">
      {isLoading ?
        <Loader />
      : (
        !deviceWithSensor ||
        (!deviceWithSensor.length && !integrationSensors.length)
      ) ?
        <NoDataAvailable />
      : <>
          {/* Integration Specific Devices */}
          {auth.company.IntegratedWith !== "" && (
            <>{renderIntegratedSensorPanels(integrationSensors)}</>
          )}

          {/* SWRM Specific Devices */}
          {deviceWithSensor?.map((data: any) => (
            <GaugeChartBlock key={data.deviceId}>
              <Typography
                variant="xl"
                display="flex"
                direction="flex-start"
                weight="bold"
              >
                {getDeviceName(data.deviceId)}
              </Typography>
              <GaugeChartWrapper>{renderSensorPanels(data)}</GaugeChartWrapper>
            </GaugeChartBlock>
          ))}
        </>
      }
    </div>
  );
};

export default GaugeChartSection;
