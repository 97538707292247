import { Group } from "../../../../../Interfaces/Device/Group";
import { GroupBox } from "../../../../../Features/tabs/device-manager/grouping/Grouping.style";
import { RelayTitle } from "../../../../../Features/tabs/device-manager/relays/RelayBox.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tooltip";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { DeviceManagerMain } from "../../../../../Components/device-manager-elements/deviceManagerMain";
import { SensorBlock } from "../../../../../Features/tabs/device-manager/sensors/Sensors.style";
import LocationGroupCard from "./LocationGroupCard";
import {
  FilterDropdown,
  FilterOption,
} from "../../../../../Components/dropdown/filter-dropdown/FilterDropdown";
interface Props {
  locationGroups: Group[];
  groups: Group[];
  selectedOptions: number[];
  handleGroupDropdownChange: any;
}
export default function LocationGroups({
  locationGroups,
  groups,
  selectedOptions,
  handleGroupDropdownChange,
}: Props) {
  return (
    <DeviceManagerMain data={[]}>
      <SensorBlock>
        {!!locationGroups.length &&
          locationGroups.map((group: Group) => (
            <LocationGroupCard group={group} key={group.Id} />
          ))}
        {Array.isArray(locationGroups) &&
          [...Array(5 - locationGroups.length)].map((_, index) => {
            return (
              <GroupBox key={index}>
                <div>
                  <RelayTitle>Add Group</RelayTitle>
                  {groups.length ? (
                    // display group selection if groups available
                    <FilterDropdown
                      key={`dropdown-${index}`}
                      value={null}
                      title="Select Group"
                      options={groups.map((option: Group) => ({
                        label: option.Name,
                        value: option.Id,
                      }))}
                      onChange={({ label, value }: FilterOption) => {
                        handleGroupDropdownChange(Number(value));
                      }}
                    />
                  ) : (
                    <>
                      <FontAwesomeIcon
                        icon={faPlus}
                        data-tooltip-id="add-group-tooltip"
                        data-tooltip-content="No groups available. Please add more groups!"
                      />
                      <Tooltip id="add-group-tooltip" />
                    </>
                  )}
                </div>
              </GroupBox>
            );
          })}
      </SensorBlock>
    </DeviceManagerMain>
  );
}
