import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../../Store/store";
import {
  Company,
  CompanyReturnPayload,
} from "../../../Services/Company/company.service";
import { UserInfo, UserUpdatedReturnPayload } from "../../../Interfaces/User";
import accountService from "../../../Services/Account/account.services";
import { integrationService } from "../../../Services/Integration/integration.service";
import { IntegrationTokens } from "../../../Interfaces/Integration/IntegrationTokens";

//Define a type for the slice state
interface AuthState {
  userInfo: UserInfo;
  company: Company;
  userRole: string;
  timeZoneOffset: string;
  hasSignedInPreviously: boolean;
  isSigningUp: boolean;
  tokens: {
    accessToken: string;
    integrationAccessToken: string;
    integrationRefreshToken: string;
  };
}

// interface Tokens {
//   AuthToken: string;
//   CompanyToken: string;
// }
// // Company Interface
// interface Company {
//   id: string;
//   logo: string;
//   banner: string;
//   name: string;
//   legalEntityName: string;
// }
// User Interface
// interface User {
//   nameid: string;
//   display_name: string;
//   given_name: string;
//   family_name: string;
//   name: string;
//   email: string;
//   avatar: string;
//   type: string;
//   nbf: number;
//   exp: number;
//   iat: number;
// }

//api call for the login
// export const login = createAsyncThunk(
//   "auth/login",
//   async ({ email, password }: any, thunkApi) => {
//     try {
//       const userToken = await authService.login(email, password);
//       return userToken;
//     } catch (error: any) {
//       console.log(error);
//       return error;
//     }
//   },
// );
// initial State
const initialState: AuthState = {
  userInfo: {
    MediaId: 0,
    Email: "",
    FirstName: "",
    LastName: "",
    MediaURL: "",
    JobTitle: "",
  },
  company: {
    UID: "",
    Name: "",
    CompanyOwnerId: "",
    CompanyEmail: "",
    IntegratedWith: "",
  },
  userRole: "",
  timeZoneOffset: "",
  hasSignedInPreviously: false,
  isSigningUp: false,
  tokens: {
    accessToken: "",
    integrationAccessToken: "",
    integrationRefreshToken: "",
  },
};
// slice
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
    //   state.isAuthenticated = action.payload;
    // },
    // setCurrentUser: (state, action: PayloadAction<AuthState>) => {
    //   state.isAuthenticated = !isEmpty(action.payload.user);
    //   state.user = action.payload.user;
    // },
    setCompany: (state, action: PayloadAction<CompanyReturnPayload>) => {
      state.company = action.payload.Company;
      state.userRole = action.payload.Role;
      state.userInfo = action.payload.UserInfo;
    },
    setTimeZone: (state, action: PayloadAction<AuthState>) => {
      state.timeZoneOffset = action.payload.timeZoneOffset;
    },
    // setTokens: (state, action: PayloadAction<Tokens>) => {
    //   state.tokens = action.payload;
    // },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.tokens.accessToken = action.payload;
    },
    clearIntegrationTokens: (state) => {
      state.tokens.integrationAccessToken = "";
      state.tokens.integrationRefreshToken = "";
    },
    // setCompanyTokens: (state, action: PayloadAction<string>) => {
    //   state.tokens.CompanyToken = action.payload;
    // },
    // setSelectCompany: (state) => {
    //   state.selectCompany = true;
    // },
    // setCompanyOptions: (state, action: PayloadAction<[]>) => {
    //   state.companyOptions = action.payload;
    // },
    setHasSignedInPreviously: (state, action: PayloadAction<boolean>) => {
      state.hasSignedInPreviously = action.payload;
    },
    setIsSigningUp: (state, action: PayloadAction<boolean>) => {
      state.isSigningUp = action.payload;
    },
    // setUserId: (state, action: PayloadAction<string>) => {
    //   state.userId = action.payload;
    // },
    userLogout: (state) => {
      localStorage.removeItem("auth");
      localStorage.removeItem("tokens");
      localStorage.removeItem("company");
      sessionStorage.clear();
      localStorage.clear();
      return initialState;
    },
  },
  extraReducers: (build) => {
    build.addCase(
      integrationService.authenticate.fulfilled,
      (state, action) => {
        console.log("Integration Authentication Payload: ", action.payload);
        if (!action.payload.IsError) {
          const { refresh, access }: IntegrationTokens = action.payload.Result;
          state.tokens.integrationRefreshToken = refresh;
          state.tokens.integrationAccessToken = access;
        }
      },
    );
    build.addCase(
      accountService.updateUserProfile.fulfilled,
      (state, action) => {
        if (
          action.payload.code != "ERR_BAD_REQUEST" &&
          state.userInfo &&
          action.payload.Result
        ) {
          state.userInfo = action.payload.Result.UserReturnPayload;
          state.company.Name = action.payload.Result.CompanyName;
        }
      },
    );
  },
});

export const {
  // setCurrentUser,
  setCompany,
  setTimeZone,
  // setTokens,
  setAccessToken,
  clearIntegrationTokens,
  // setCompanyTokens,
  // setIsAuthenticated,
  // setSelectCompany,
  // setCompanyOptions,
  setHasSignedInPreviously,
  setIsSigningUp,
  // setUserId,
  userLogout,
} = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAuth = (state: RootState) => state.persisted.auth;

export default authSlice.reducer;
