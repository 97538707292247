import React from "react";
import { CustomFormControl } from "./FormControl.style";

export interface FormControlProps {
  children: React.ReactNode[] | React.ReactNode;
  variant?: "small";
}

export const FormControl: React.FC<FormControlProps> = ({
  children,
  variant,
}) => {
  return <CustomFormControl variant={variant}>{children}</CustomFormControl>;
};
