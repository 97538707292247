import React, { useState } from "react";
import { FormControl } from "../../../../Components/form-field/form-control/FormControl";
import { FormLabel } from "../../../../Components/form-field/form-label/FormLabel";
import { StyledLabel } from "../../../../Components/modals/Modals.style";
import { Location } from "../../../../Interfaces/Device/Location";
import { OpenWarningNotification } from "../../../../Components/notification/Notification";
import { useAppDispatch } from "../../../../Store/hooks";
import { DEFAULT_MAX_FILE_SIZE_IN_BYTES } from "../../../../Components/input/upload-input/DragUpload";
import { formatBytes } from "../../../../Utils";
import { patchLocationMedia } from "../../../../Services/Locations/Location.service";
import { UploadMedia } from "../../../../Services/Media/Media.Upload";
import { Media } from "../../../../Interfaces/Device/Media";

interface Props {
  locationId: number;
  media: Media | undefined;
}

export default function LocationImageUpload({ locationId, media }: Props) {
  const dispatch = useAppDispatch();
  const [selectedFileName, setSelectedFileName] = useState(
    media?.FileName ? media.FileName : "",
  );

  const validateFileType = (file: File, fileType?: string[]) => {
    if (!fileType) return true;
    const inputFileType: string = file?.type?.split("/")[0];
    if (inputFileType && fileType.includes(inputFileType)) {
      return true;
    } else {
      const msg = fileType.join(", ");
      OpenWarningNotification(`Only ${msg} file allowed!`);
      return false;
    }
  };
  const handleFileUpload = async (file: File) => {
    try {
      const response = await UploadMedia("Locations", file);
      if (response.Result.Id) {
        try {
          dispatch(
            patchLocationMedia({
              Id: locationId,
              MediaId: response.Result.Id,
            }),
          );
          setSelectedFileName(file.name);
        } catch (error) {
          console.error("handleFileUpload Error:", error);
        }
      }
      console.log("handleFileUpload -> response:", response);
    } catch (error) {
      console.error("handleFileUpload Error:", error);
    }
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0].toString().split("/")[2];
      if (validateFileType(e.target.files[0], ["image"])) {
        if (e.target.files[0].size <= DEFAULT_MAX_FILE_SIZE_IN_BYTES) {
          setSelectedFileName(file);
          handleFileUpload(e.target.files[0]);
        } else {
          OpenWarningNotification(
            `File must smaller than ${formatBytes(
              DEFAULT_MAX_FILE_SIZE_IN_BYTES,
            )}`,
          );
        }
      }
    }
  };
  return (
    <FormControl>
      <FormLabel htmlFor="" style={{ paddingTop: "10px" }}>
        Thumbnail Image
      </FormLabel>
      <input
        type="file"
        id={`fileInput`}
        name="thumbnail_img"
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept="image/*"
      />
      <StyledLabel
        style={{
          marginTop: "12px",
          marginBottom: "19px",
        }}
        htmlFor={`fileInput`}
      >
        {selectedFileName || "Choose File"}
      </StyledLabel>
    </FormControl>
  );
}
