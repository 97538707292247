import { useState } from "react";
import { CustomToggleSwitch } from "./ToggleSwitch.style";

interface ToggleSwitchProps {
  onToggle?: (newToggleState: boolean) => Promise<void>;
  isActive?: boolean;
  margin?: string;
}

const ToggleSwitch = ({
  onToggle,
  isActive = false,
  margin,
}: ToggleSwitchProps) => {
  const [toggleState, setToggleState] = useState<boolean>(isActive);
  const [isPending, setIsPending] = useState<boolean>(false);

  const handleToggleSwitch = async () => {
    // Set new Toggle and Pending states
    const newToggleState = !toggleState;
    setToggleState(newToggleState);
    setIsPending(true);

    try {
      // Call the API to update the toggle state
      if (onToggle) await onToggle(newToggleState);
    } catch (error) {
      // Revert the switch if the API call fails
      setToggleState(!newToggleState);
    } finally {
      // Reset pending state
      setIsPending(false);
    }
  };

  return (
    <CustomToggleSwitch
      checked={toggleState}
      isPending={isPending}
      margin={margin}
    >
      <input
        type="checkbox"
        checked={toggleState}
        onChange={handleToggleSwitch}
        disabled={isPending}
      />
    </CustomToggleSwitch>
  );
};

export default ToggleSwitch;
