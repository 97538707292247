import React from "react";
import {
  LoginPageContainer,
  PageTitle,
  PageTitleDescription,
} from "./LoginForm.style";
import SwrmNavLogo from "../../Assets/SWRM_Logo_512x80_Yellow.png";
import { Typography } from "../../Components/typrography/Typography";
import Link from "../../Components/link/Link";
import { Button } from "../../Components/button/Button";
import { useAuth0 } from "@auth0/auth0-react";

const PageDescription = () => {
  const { loginWithRedirect } = useAuth0();
  const links = [
    {
      text: "disclaimer",
      url: "https://wiki.swrm.ai/en/dashboard/legal/SWRMDisclaimer",
    },
    {
      text: "terms and conditions",
      url: "https://wiki.swrm.ai/en/dashboard/legal/SWRMTermsandConditions",
    },
    {
      text: "privacy policy",
      url: "https://wiki.swrm.ai/en/dashboard/legal/PrivacyPolicy",
    },
  ];

  return (
    <LoginPageContainer>
      <PageTitle>
        <div style={{ textAlign: "center" }}>
          <img
            src={SwrmNavLogo}
            alt="SWRM Logo"
            style={{ width: "28vw" }}
          ></img>
        </div>
        <PageTitleDescription>
          Be Organized. Be Smart. Be SWRM.
        </PageTitleDescription>
      </PageTitle>
      <Button
        fullWidth={false}
        color="primary"
        variant="filled"
        size="medium"
        scaled
        onClick={loginWithRedirect}
      >
        Get Started
      </Button>
      <Typography>
        By logging in to SWRM, you hereby agree to the{" "}
        {links.map((link, index) => (
          <React.Fragment key={index}>
            {index > 0 && ", "}
            <Link href={link.url} target="_blank">
              {link.text}
            </Link>
          </React.Fragment>
        ))}
        .
      </Typography>
    </LoginPageContainer>
  );
};

export default PageDescription;
