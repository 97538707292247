import React from "react";
import {
  TriangleTagWrapper,
  CardWrapper,
  TextWrapper,
} from "./SubscriptionCard.style";
import { Typography } from "../../typrography/Typography";
import { BsDot } from "react-icons/bs";
import { DefaultThemeColor } from "../../../app/Theme";

export interface SubscriptionCardProps {
  displayTriangleTag?: "flex" | "none";
  title?: string;
  price?: string;
  descriptions: string[];
  onClick?: Function;
  isSelected?: boolean;
}

const SubscriptionCard = ({
  isSelected = false,
  displayTriangleTag,
  title,
  price,
  descriptions,
  onClick,
}: SubscriptionCardProps) => {
  return (
    <CardWrapper
      onClick={() => onClick && onClick()}
      className={isSelected ? "selected" : ""}
    >
      <TriangleTagWrapper displayTriangleTag={displayTriangleTag}>
        <Typography
          variant="xs"
          color="grey-900"
          direction="flex-end"
          weight="black"
        >
          Best
          <br />
          Value
        </Typography>
      </TriangleTagWrapper>
      <TextWrapper>
        <Typography variant="xl" weight="black" direction="flex-start">
          {title}
        </Typography>
        <Typography
          variant="2lg"
          direction="flex-start"
          color="primary"
          style={{ padding: "9px 0" }}
        >
          {price}
        </Typography>
      </TextWrapper>
      <TextWrapper>
        {descriptions?.map((desc: string, index: number) => (
          <Typography
            variant="sm"
            direction="flex-start"
            key={`${desc}_${index}`}
          >
            <BsDot color={DefaultThemeColor["primary"]} />
            {desc.trim()}
          </Typography>
        ))}
      </TextWrapper>
    </CardWrapper>
  );
};

export default SubscriptionCard;
