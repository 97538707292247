import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

export const getAllGroupType = createAsyncThunk(
  "GroupType",
  async ({
    id,
    name,
    locationId,
  }: {
    id?: number;
    name?: string;
    locationId?: number;
  } = {}) => {
    const idString = id ? `id=${id}` : "";
    const nameString = name ? `&name=${name}` : "";
    const groupIdString = locationId ? `&locationId=${locationId}` : "";

    try {
      let response = await axios
        .get(
          `${API_URL}/GroupCategoryTypes?${idString}${nameString}${groupIdString}`,
        )
        .then(async (response) => {
          await console.log("response.data", response.data);
          return response.data;
        });
      return response;
    } catch (error: unknown) {
      return error;
    }
  },
);

export const getAllGroups = createAsyncThunk(
  "groups/getAllGroups",
  async (thunkapi) => {
    try {
      const response = await axios.get(`${API_URL}/Group`);
      return response.data;
    } catch (error: unknown) {
      return error;
    }
  },
);

const GroupTypeService = {
  getAllGroupType,
  getAllGroups,
};

export default GroupTypeService;
