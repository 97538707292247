import styled from "styled-components";
import { CardOutlineProps } from "./CardOutline";

// styles for card
export const CustomCardOutline = styled.div<Pick<CardOutlineProps, "border">>`
  height: 100%;
  border-radius: 5px;
  &:hover {
    border: ${({ border, theme }) =>
      border ? theme.border["primary-sm"] : "none"};
  }
  border: ${({ border, theme }) =>
    border ? theme.border["border-sm-white"] : "none"};
  display: ${({ border }) => (border ? "flex" : "inherit")};
  align-items: center;
  justify-content: center;
  cursor: ${({ border }) => (border ? "pointer" : "inherit")};
`;
