import Layout from "./Layout";
import AppRouter from "../Router";
import TokenRefresh from "../Components/token-refresh/TokenRefresh";

const MainApp = () => {
  return (
    <>
      <TokenRefresh />
      <Layout>
        <AppRouter />
      </Layout>
    </>
  );
};

export default MainApp;
