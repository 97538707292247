import React, { Fragment, useEffect, useState } from "react";
import {
  DropdownSection,
  DropdownWrapper,
  FilterOptionContainer,
  SortBySection,
  StatusAndSortContainer,
  VerticalLine,
} from "./FilterOptions.style";
import { SubTitle } from "../title/Titles.styles";
import DeviceStatus from "../status-radio/StatusRadio";
import SortBy from "../sort-by/SortBy";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import { RootState } from "../../Store/store";
import {
  FetchParamsForLocations,
  setLocationParameters,
} from "../../Features/Slice/fetchParameters/fetchParametersSlice";
import { searchLocationsFilter } from "../../Services/Locations/Location.service";
import { resetDeviceStatusRadio } from "../radio-button/radioSlice";
import { resetSortByState, switchSortBy } from "../sort-by/sortBySlice";
import {
  DropdownItem,
  getLocationTypeDropdown,
} from "../../Features/Slice/dropdownOptions/dropdownListSlice";
import { CustomDropdown } from "../form-field/drop-down/CustomDropdown";
import { Button } from "../button/Button";
import SearchInput from "../input/SearchInput";
import {
  FilterDropdown,
  FilterOption,
} from "../dropdown/filter-dropdown/FilterDropdown";

interface FilterOptionContainerProps {
  children?: React.ReactNode[] | React.ReactNode;
  background?: string;
}

const LocationFilterOptions: React.FC<FilterOptionContainerProps> = ({
  children,
  background,
}) => {
  const dispatch = useAppDispatch();
  const LocationSearchParams = useAppSelector(
    (state: RootState) => state.fetchParams.LocationSearchParams,
  );
  const deployedRadioBtn = useAppSelector((state: RootState) =>
    state.radio.find((x: any) => x.value == "Enabled"),
  );
  const locationTypeDropdown = useAppSelector(getLocationTypeDropdown);
  const [keyToResetDropdown, setKeyToResetDropdown] = useState<number>(0); // to reset dropdown selection on clear

  const onStatusChange = () => {
    //location
    const payload = {
      ...LocationSearchParams,
      enabled: !deployedRadioBtn?.isChecked,
    };
    refreshData(payload);
  };
  const onSortByChange = (sortByItem: any) => {
    const payload = {
      ...LocationSearchParams,
      orderbydesc: sortByItem.name === "descending",
    };
    refreshData(payload);
  };
  const refreshData = (payload: FetchParamsForLocations) => {
    dispatch(setLocationParameters(payload));
    dispatch(searchLocationsFilter(payload));
  };
  const resetFilteredLocationData = () => {
    // Reset device status radio and sortby states
    dispatch(resetDeviceStatusRadio());
    dispatch(resetSortByState());
    //Reset the location search data
    const initialLocationSearchParams = {
      ...LocationSearchParams,
      locationTypeId: 0,
      enabled: true,
      orderbydesc: false,
    };
    refreshData(initialLocationSearchParams);
    setKeyToResetDropdown((prev) => prev + 1);
  };
  useEffect(() => {
    resetFilteredLocationData();

    /*
      Switching Sort By because Locations are being ordered Alphabetically
    */
    dispatch(switchSortBy(1));
  }, []);

  const handleDropdownOptionClick = (
    dropdownId: DropdownItem,
    optionValue: number,
  ) => {
    if (dropdownId === DropdownItem.LocationTypes) {
      const payload = {
        ...LocationSearchParams,
        locationTypeId: optionValue,
      };
      refreshData(payload);
    }
  };

  const handleSearch = (inputValue: string) => {
    const payload = {
      ...LocationSearchParams,
      name: inputValue,
    };
    refreshData(payload);
  };

  return (
    <>
      <SearchInput
        label={""}
        placeHolder={"Search Location"}
        searchIcon={true}
        handleSearch={handleSearch}
      />
      <FilterOptionContainer background={background}>
        <DropdownSection>
          <SubTitle>FILTER OPTIONS</SubTitle>
          <DropdownWrapper>
            <Fragment key={keyToResetDropdown}>
              {locationTypeDropdown ? (
                <FilterDropdown
                  title="Location Types"
                  options={locationTypeDropdown.options}
                  onChange={({ label, value }: FilterOption) => {
                    handleDropdownOptionClick(
                      locationTypeDropdown.id,
                      Number(value),
                    );
                  }}
                />
              ) : null}
            </Fragment>
            <Button
              color="primary"
              variant="filled"
              onClick={resetFilteredLocationData}
            >
              Clear Filters
            </Button>
          </DropdownWrapper>
        </DropdownSection>
        <StatusAndSortContainer>
          <DropdownSection>
            <DeviceStatus label={"Device Status"} onChange={onStatusChange} />
          </DropdownSection>
          <VerticalLine />
          <SortBySection>
            <SortBy onChange={onSortByChange} />
          </SortBySection>
        </StatusAndSortContainer>
      </FilterOptionContainer>
    </>
  );
};

export default LocationFilterOptions;
