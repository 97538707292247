import {
  IntegrationItemBox,
  IntegrationItemsWrapper,
  IntegrationWrapper,
} from "./Integration.style";
import { Typography } from "../../typrography/Typography";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { RootState } from "../../../Store/store";
import { IntegrationCompany } from "../../../Features/Slice/integration/integrationSlice";
import IntegrationLoginModal from "./IntegrationLoginModal";
import { useEffect, useState } from "react";
import {
  integrationService,
  UpdateIntegrationStatusPayload,
} from "../../../Services/Integration/integration.service";
import { clearIntegrationTokens } from "../../../Features/Slice/auth/authSlice";
import authService from "../../../Services/Auth/auth.service";
import ToggleSwitch from "../../button/toggle-switch/ToggleSwitch";
import Loader from "../../loader/Loader";

const Integration = () => {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedIntegrationId, setSelectedIntegrationId] = useState<number>(0);
  const [integrationStatus, setIntegrationStatus] = useState<boolean>(false);
  const [selectedIntegrationName, setSelectedIntegrationName] =
    useState<string>("");
  const integrations = useAppSelector(
    (state: RootState) => state.integration.integrations,
  );
  const integrationsIsLoading = useAppSelector(
    (state: RootState) => state.integration.integrationsIsLoading,
  );
  const companyId = useAppSelector(
    (state: RootState) => state.persisted.auth.company.UID,
  );

  const onClose = async () => {
    setIsModalOpen(false);
    await dispatch(integrationService.getIntegrations());
  };

  const handleToggleDisable = async (
    integrationId: number,
    newToggleState: boolean,
  ) => {
    try {
      const payload: UpdateIntegrationStatusPayload = {
        companyId: companyId,
        integrationId: integrationId,
        status: newToggleState,
      };

      const response = await dispatch(
        integrationService.updateIntegrationStatus(payload),
      );

      if (!response.payload.IsError) {
        dispatch(clearIntegrationTokens());
        authService.setIntegrationAuthTokenToHeader("");
        authService.setIntegrationRefreshTokenToHeader("");
      } else {
        throw new Error("Failed to disable integration");
      }
    } catch (error) {
      throw error;
    }
  };

  const handleToggleUpdate = async (
    integration: IntegrationCompany,
    status: boolean,
  ) => {
    try {
      // State Setting
      setSelectedIntegrationId(integration.Integration.Id);
      setSelectedIntegrationName(integration.Integration.Name);
      setIntegrationStatus(status);

      // Execute based off toggle status
      if (!status) {
        await handleToggleDisable(integration.Integration.Id, status);
        await dispatch(integrationService.getIntegrations());
      } else {
        setIsModalOpen(true);
      }
    } catch (error) {
      throw error;
    }
  };

  const handleToggleChange =
    (integration: IntegrationCompany) => async (newToggleState: boolean) => {
      try {
        await handleToggleUpdate(integration, newToggleState);
      } catch (error) {
        throw error;
      }
    };

  useEffect(() => {
    dispatch(integrationService.getIntegrations());
  }, []);

  return (
    <>
      {integrationsIsLoading ?
        <Loader loadingText="Fetching integrations" isLoadingScreen />
      : <IntegrationWrapper>
          <div>
            <Typography
              direction="flex-start"
              variant="2xl"
              style={{ padding: "0.5vw 0" }}
            >
              Integrations
            </Typography>
            <Typography
              direction="flex-start"
              variant="lg"
              color="primary"
              style={{ padding: "0.5vw 0" }}
            >
              Manage your company's integrations here.
            </Typography>
          </div>

          <IntegrationItemsWrapper>
            {integrations.map((integration: IntegrationCompany) => (
              <IntegrationItemBox key={integration.Integration.Id}>
                <Typography color="white" variant="lg">
                  {integration.Integration.Name}
                </Typography>

                <ToggleSwitch
                  key={integration.Integration.Id}
                  isActive={integration.Enabled}
                  onToggle={handleToggleChange(integration)}
                />
              </IntegrationItemBox>
            ))}
          </IntegrationItemsWrapper>
          <IntegrationLoginModal
            isOpen={isModalOpen}
            onClose={onClose}
            integrationId={selectedIntegrationId}
            integrationStatus={integrationStatus}
            integrationName={selectedIntegrationName}
          />
        </IntegrationWrapper>
      }
    </>
  );
};

export default Integration;
