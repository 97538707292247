import Select from "react-select";
import { Country, State, City } from "country-state-city";
import { Option } from "../../Interfaces/Country";
import {
  SelectErrorMsg,
  SelectLabel,
  SelectWrapper,
  selectStyle,
} from "./SelectDropdown.style";
import { useEffect, useState } from "react";
import { ErrorMsg } from "../input/Input.style";
import { FieldHelperProps, useField } from "formik";

export interface SelectProps {
  name: string;
}

interface ProvinceStateSelectProps {
  selectedCountryValue: Option | null;
  selectProps: SelectProps;
}

interface CitySelectProps {
  selectedCountryValue: Option | null;
  selectedStateValue: Option | null;
  selectProps: SelectProps;
}

const handleOptionChange = (
  helpers: FieldHelperProps<Option | null>,
  option: Option | null,
) => {
  helpers.setValue(option);
};

const handleOptionsBlur = (
  helpers: FieldHelperProps<Option | null>,
  value: Option | null,
  fieldName: string,
) => {
  if (!value) {
    helpers.setTouched(true);
    helpers.setError(`${fieldName} is required`);
  }
};

export const CountrySelectDropdown = ({ name }: SelectProps) => {
  const [field, meta, helpers] = useField(name);

  return (
    <SelectWrapper>
      <SelectLabel>Country</SelectLabel>
      <Select
        name={name}
        // Returning CA and US countries for now
        options={Country.getAllCountries()
          .filter((country) => ["US", "CA"].includes(country.isoCode))
          .map((country) => ({
            label: country.name,
            value: country.isoCode,
          }))}
        value={field.value}
        onChange={(item: Option | null) => handleOptionChange(helpers, item)}
        onBlur={() => handleOptionsBlur(helpers, field.value, "Country")}
        placeholder="Insert Country"
        menuPosition="fixed"
        styles={selectStyle}
      />
      {meta.touched && meta.error && (
        <SelectErrorMsg>{meta.error}</SelectErrorMsg>
      )}
    </SelectWrapper>
  );
};

export const ProvinceStateSelectDropdown = ({
  selectedCountryValue,
  selectProps: { name },
}: ProvinceStateSelectProps) => {
  const [field, meta, helpers] = useField(name);

  useEffect(() => {
    helpers.setValue(null);
  }, [selectedCountryValue]);

  return (
    <SelectWrapper>
      <SelectLabel>Province/State</SelectLabel>
      <Select
        name={name}
        options={
          State?.getStatesOfCountry(selectedCountryValue?.value)?.map(
            (state) => ({
              label: state.name,
              value: state.isoCode,
            }),
          ) || []
        }
        value={field.value}
        onChange={(item: Option | null) => handleOptionChange(helpers, item)}
        onBlur={() => handleOptionsBlur(helpers, field.value, "Province/State")}
        placeholder="Insert Province/State"
        menuPosition="fixed"
        styles={selectStyle}
      />
      {meta.touched && meta.error && (
        <SelectErrorMsg>{meta.error}</SelectErrorMsg>
      )}
    </SelectWrapper>
  );
};

export const CitySelectDropdown = ({
  selectedCountryValue,
  selectedStateValue,
  selectProps: { name },
}: CitySelectProps) => {
  const [field, meta, helpers] = useField(name);

  useEffect(() => {
    helpers.setValue(null);
  }, [selectedCountryValue, selectedStateValue]);

  return (
    <SelectWrapper>
      <SelectLabel>City</SelectLabel>
      <Select
        name={name}
        options={City.getCitiesOfState(
          selectedCountryValue?.value!,
          selectedStateValue?.value!,
        ).map((city) => ({
          label: city.name,
          value: city.name,
        }))}
        value={field.value}
        onChange={(item: Option | null) => handleOptionChange(helpers, item)}
        onBlur={() => handleOptionsBlur(helpers, field.value, "City")}
        placeholder="Insert City"
        styles={selectStyle}
        menuPosition="fixed"
      />
      {meta.touched && meta.error && (
        <SelectErrorMsg>{meta.error}</SelectErrorMsg>
      )}
    </SelectWrapper>
  );
};
