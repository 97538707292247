import { Formik, FormikHelpers } from "formik";
import CustomModal from "../../modals/CustomModal";
import { Typography } from "../../typrography/Typography";
import * as Yup from "yup";
import { StyledFormikForm } from "../ai-model-manager/Upload.style";
import { RoleOptions, UserRoles } from "../../../Interfaces/User";
import { useState } from "react";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
  OpenWarningNotification,
} from "../../notification/Notification";
import { ResponseObject } from "../../../Interfaces/Response";
import {
  LocalModalFormWrapper,
  ScaledButtonWrapper,
} from "../../modals/CustomModal.style";
import Input from "../../input/Input";
import { companyService } from "../../../Services/Company/company.service";
import {
  CustomDropdown,
  IOption,
} from "../../form-field/drop-down/CustomDropdown";
import { FormControl } from "../../form-field/form-control/FormControl";
import { FormLabel } from "../../form-field/form-label/FormLabel";
import { DropdownMenuContainer } from "../../form-field/drop-down/Dropdown.style";
import {
  FilterDropdown,
  FilterOption,
} from "../../dropdown/filter-dropdown/FilterDropdown";
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

interface FormValues {
  email: string;
}

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const initialValues: FormValues = {
  email: "",
};
const InviteUserModel = (props: Props) => {
  const { isOpen, onClose } = props;
  const [isWaitingForResponse, setIsWaitingForResponse] =
    useState<boolean>(false);
  const [userRole, setUserRole] = useState<FilterOption>(RoleOptions[0]); //default role
  const cardHeader = () => {
    return (
      <Typography
        variant="lg"
        direction="flex-start"
        style={{ padding: "0 20px", fontWeight: "700" }}
      >
        Invite User
      </Typography>
    );
  };
  const cardFooter = () => {
    return (
      <ScaledButtonWrapper>
        <button type="submit" form="invite-user">
          {isWaitingForResponse ? "Send Invitation..." : "Send Invitation"}
        </button>
      </ScaledButtonWrapper>
    );
  };

  const handleRoleChange = (value: any) => {
    setUserRole(value);
  };

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    try {
      if (!userRole) {
        OpenWarningNotification("Please select user role!");
        return;
      }
      // If currently inviting user, prevent multiple attempt to invite user
      if (isWaitingForResponse) return;

      setIsWaitingForResponse(true);

      // service call
      const response: ResponseObject<string> = await companyService.inviteUser({
        email: values.email,
        roleId: userRole,
      });

      setIsWaitingForResponse(false);

      if (response.IsError) {
        OpenErrorNotification(response.ErrorMessage);
        return;
      }

      OpenSuccessNotification(response.Result);
      onClose();
    } catch (error) {
      setIsWaitingForResponse(false);
      console.error("Error inviting user:", error);
    }

    actions.setSubmitting(false);
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      closeBtnTopPosition="0.188rem"
      padding="10px 0"
      width={""}
      height={""}
      header={cardHeader()}
      footer={cardFooter()}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, handleBlur }) => (
          <StyledFormikForm id={"invite-user"}>
            <LocalModalFormWrapper formPadding="0 1rem">
              <Input
                name="email"
                placeHolder="Insert Email"
                label={"Email"}
                width="100%"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormControl>
                <FormLabel
                  htmlFor="Role"
                  fontSize="1.25vw"
                  fontWeight="700"
                  style={{ color: "white" }}
                >
                  Role
                </FormLabel>
                <div style={{ width: "100%" }}>
                  <FilterDropdown
                    defaultValue={userRole}
                    title="Role"
                    options={RoleOptions}
                    isFullSize
                    onChange={({ label, value }: FilterOption) => {
                      handleRoleChange(value);
                    }}
                  />
                </div>
              </FormControl>
            </LocalModalFormWrapper>
          </StyledFormikForm>
        )}
      </Formik>
    </CustomModal>
  );
};

export default InviteUserModel;
