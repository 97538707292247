import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { User, UserUpdatePayload } from "../../Interfaces/User";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2/UserCompany";

const updateUserProfile = createAsyncThunk(
  "auth/updateUserProfile",
  async (payload: UserUpdatePayload, thunkApi) => {
    try {
      let response = await axios
        .patch(`${API_URL}/user`, payload)
        .then(async (response) => {
          return response.data;
        })
        .catch(async (error: unknown) => {
          return error;
        });
      return response;
    } catch (error: unknown) {
      return error;
    }
  },
);
// export interface IUpdateAccountEmailPayload {
//   Email: string;
//   NewEmail: string;
//   Service: string;
// }

// export const updateAccountEmail = async (
//   payload: IUpdateAccountEmailPayload,
// ) => {
//   try {
//     const response = await axios.post(`${API_URL}/change-email`, payload);
//     return response.data;
//   } catch (error: any) {
//     return error?.response?.data || error;
//   }
// };

const accountService = {
  updateUserProfile,
  // updateAccountEmail,
};
export default accountService;
