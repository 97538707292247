import styled from "styled-components";

export const NavBarTileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  height: 100%;
`;

export const TitleWrapper = styled.div`
  font-size: ${(props) => props.theme.fontSizes["4xl"]};
`;

export const SubTitleWrapper = styled.div`
  font-size: ${(props) => props.theme.fontSizes["lg"]};
`;
