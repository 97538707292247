import React from "react";

import {
  NavBarTileWrapper,
  SubTitleWrapper,
  TitleWrapper,
} from "./NavBarTitle.styles";

interface NavBarTitleProps {
  title: string;
  subTitle: string;
}

const NavBarTitle = ({ title, subTitle }: NavBarTitleProps) => {
  return (
    <NavBarTileWrapper>
      <TitleWrapper>{title}</TitleWrapper>
      <SubTitleWrapper>{subTitle}</SubTitleWrapper>
    </NavBarTileWrapper>
  );
};

export default NavBarTitle;
