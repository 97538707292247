import React from "react";
import Login from "../../Pages/login/Login";
import DeviceManager from "../../Pages/deviceManager/DeviceManager";
import Permissions from "../../Pages/permissions/Permissions";
import { Location } from "../../Pages/location";
import AiModelManager from "../../Pages/aiModelManager/AiModelManager";
import Captures from "../../Pages/captures/Captures";
import Integration from "../../Pages/integration/Integration";
import Help from "../../Pages/help/Help";
import AccountSettings from "../../Pages/accountSettings/AccountSettings";
import Dashboard from "../../Pages/dashboard/Dashboard";
import RouterLoadingScreen from "../RouterLoadingScreen";
import CompanyOptions from "../../Pages/companyOptions/CompanyOptions";
import AcceptInvitation from "../../Pages/signup/AcceptInvitation";
import IntegrationLogin from "../../Pages/integrationLogin/IntegrationLogin";

export interface PathComponent {
  path: string;
  component: any;
}

export const setupRoutes: PathComponent[] = [
  { path: "/accept-invitation", component: <AcceptInvitation /> },
  { path: "/integrationLogin", component: <IntegrationLogin /> },
  { path: "/", component: <RouterLoadingScreen /> },
];

export const newUserRoutes: PathComponent[] = [
  { path: "/accept-invitation", component: <AcceptInvitation /> },
  { path: "/getStarted", component: <CompanyOptions /> },
  { path: "/", component: <CompanyOptions /> },
];

export const loggedInRoutes: PathComponent[] = [
  { path: "/", component: <Dashboard /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/login", component: <Login /> },
  { path: "/devices", component: <DeviceManager /> },
  { path: "/devices/:activeTab", component: <DeviceManager /> },
  { path: "/captures", component: <Captures /> },
  { path: "/captures/:activeTab", component: <Captures /> },
  { path: "/permissions", component: <Permissions /> },
  { path: "/locations", component: <Location /> },
  { path: "/locations/:activeTab", component: <Location /> },
  { path: "/ai-model-manager", component: <AiModelManager /> },
  { path: "/ai-model-manager/:activeTab", component: <AiModelManager /> },
  { path: "/integration", component: <Integration /> },
  { path: "/help", component: <Help /> },
  { path: "/account-settings", component: <AccountSettings /> },
  // { path: "/notifications", component: <Notifications /> },
  // { path: "/reportBug", component: <ReportBug /> },
  // { path: "/oldDashboardStats", component: <DashboardStats /> }, /* old dashboard with dummy data and charts*/
];
export const viewerRoutes: PathComponent[] = [
  { path: "/", component: <Dashboard /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/account-settings", component: <AccountSettings /> },
];
export const editorRoutes: PathComponent[] = [
  { path: "/", component: <Dashboard /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/devices", component: <DeviceManager /> },
  { path: "/devices/:activeTab", component: <DeviceManager /> },
  { path: "/captures", component: <Captures /> },
  { path: "/captures/:activeTab", component: <Captures /> },
  { path: "/locations", component: <Location /> },
  { path: "/locations/:activeTab", component: <Location /> },
  { path: "/ai-model-manager", component: <AiModelManager /> },
  { path: "/ai-model-manager/:activeTab", component: <AiModelManager /> },
  { path: "/integration", component: <Integration /> },
  { path: "/help", component: <Help /> },
  { path: "/account-settings", component: <AccountSettings /> },
];
export const notLoggedInRoutes: PathComponent[] = [
  { path: "/accept-invitation", component: <AcceptInvitation /> },
  { path: "/", component: <Login /> },
  { path: "/login", component: <Login /> },
];
