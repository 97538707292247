import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

export const getAllLocationsType = createAsyncThunk(
  "LocationType",
  async ({ id, name }: { id?: number; name?: string } = {}) => {
    const searchString = `id${id && id > 0 ? "=" + id : ""}&name${
      name && name.length > 0 ? "=" + name : ""
    }`;
    try {
      let response = await axios
        .get(`${API_URL}/LocationType?${searchString}`)
        .then(async (response) => {
          await console.log(response.data);
          return response.data;
        });
      return response;
    } catch (error: unknown) {
      return error;
    }
  },
);

const LocationTypeService = {
  getAllLocationsType,
};

export default LocationTypeService;
