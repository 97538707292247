import styled from "styled-components";

interface ErrorMessageProps {
  right?: string;
}

export const RelayAddRoutineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 2rem;
`;

export const RoutineStepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
`;

export const RoutineStepsHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

export const RoutineStepsFormsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const RoutineStepsForm = styled.div`
  display: grid;
  grid-template-columns: auto 3fr 12fr auto auto auto;
  gap: 1rem;
  align-items: center;
  max-width: 100%;
`;

export const RoutineStepActionButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  border: none;
  font-weight: ${({ theme }) => theme.fontWeight["bold"]};
  color: ${({ theme }) => theme.colors["grey-500"]};
  background-color: ${({ theme }) => theme.colors["black-100"]};
  cursor: pointer;
  transition: 0.1s ease-in-out;
`;

export const RoutineErrorMessage = styled.span<ErrorMessageProps>`
  width: 8rem;
  position: absolute;
  right: ${(props) => (props.right ? props.right : "-40%")};
  padding-top: 0.2rem;
  color: #d64550;
`;
