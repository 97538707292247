import { createSlice } from "@reduxjs/toolkit";
import { integrationService } from "../../../Services/Integration/integration.service";

interface Integration {
  Id: number;
  Name: string;
  URL: string;
  APIToken: string | null;
}

export interface IntegrationCompany {
  Integration: Integration;
  Enabled: boolean;
}
interface IntegrationState {
  integrationsIsLoading: boolean;
  integrations: IntegrationCompany[];
}

const initialState: IntegrationState = {
  integrationsIsLoading: false,
  integrations: [],
};

export const integrationSlice = createSlice({
  name: "integration",
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build.addCase(
      integrationService.getIntegrations.pending,
      (state, action) => {
        state.integrationsIsLoading = true;
      },
    );
    build.addCase(
      integrationService.getIntegrations.fulfilled,
      (state, action) => {
        state.integrations = [action.payload.Result];
        state.integrationsIsLoading = false;
      },
    );
  },
});

export default integrationSlice.reducer;
