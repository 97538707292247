import React from "react";
import { NotificationListWrapper } from "./NotificationBar.style";
import { INotificationData } from "../../data/notificationList";
import NotificationBar from "./NotificationBar";

export interface NotificationListProps {
  data: INotificationData[];
}

const NotificationList = ({ data }: NotificationListProps) => {
  return (
    <NotificationListWrapper>
      {data.map((item) => (
        <NotificationBar key={item.id} notification={item} />
      ))}
    </NotificationListWrapper>
  );
};

export default NotificationList;
