import styled from "styled-components";
import { TypographyProps } from "./Typography";

export const CustomTypography = styled.div<
  Pick<
    TypographyProps,
    | "variant"
    | "color"
    | "weight"
    | "display"
    | "direction"
    | "padding"
    | "onMouseOver"
    | "disabled"
  >
>`
  font-size: ${({ variant, theme }) => {
    switch (variant) {
      case "xs":
        return theme.fontSizes.xs;
      case "sm":
        return theme.fontSizes.sm;
      case "md":
        return theme.fontSizes.md;
      case "lg":
        return theme.fontSizes.lg;
      case "xl":
        return theme.fontSizes.xl;
      case "2xl":
        return theme.fontSizes["2xl"];
      case "3xl":
        return theme.fontSizes["3xl"];
      case "4xl":
        return theme.fontSizes["4xl"];
      case "6xl":
        return theme.fontSizes["6xl"];
      case "7xl":
        return theme.fontSizes["7xl"];
      default:
        return theme.fontSizes.md;
    }
  }};

  color: ${({ color, theme }) => {
    switch (color) {
      case "violet-100":
        return theme.colors["violet-100"];
      case "violet-200":
        return theme.colors["violet-200"];
      case "violet-300":
        return theme.colors["violet-300"];
      case "violet-400":
        return theme.colors["violet-400"];
      case "violet-500":
        return theme.colors["violet-500"];
      case "violet-600":
        return theme.colors["violet-600"];
      case "violet-700":
        return theme.colors["violet-700"];
      case "violet-800":
        return theme.colors["violet-800"];
      case "violet-900":
        return theme.colors["violet-900"];
      case "grey-100":
        return theme.colors["grey-100"];
      case "grey-200":
        return theme.colors["grey-200"];
      case "grey-300":
        return theme.colors["grey-300"];
      case "grey-400":
        return theme.colors["grey-400"];
      case "grey-500":
        return theme.colors["grey-500"];
      case "grey-600":
        return theme.colors["grey-600"];
      case "grey-700":
        return theme.colors["grey-700"];
      case "grey-800":
        return theme.colors["grey-800"];
      case "grey-900":
        return theme.colors["grey-900"];
      case "red-100":
        return theme.colors["red-100"];
      case "red-200":
        return theme.colors["red-200"];
      case "red-300":
        return theme.colors["red-300"];
      case "green-100":
        return theme.colors["green-100"];
      case "green-200":
        return theme.colors["green-200"];
      case "green-300":
        return theme.colors["green-300"];
      case "primary":
        return theme.colors["primary"];

      case "none":
        return "none";
      default:
        return theme.colors.white;
    }
  }};

  font-weight: ${({ weight, theme }) => {
    switch (weight) {
      case "light":
        return theme.fontWeight.light;
      case "medium":
        return theme.fontWeight.medium;
      case "semiBold":
        return theme.fontWeight.semiBold;
      case "bold":
        return theme.fontWeight.bold;
      case "extraBold":
        return theme.fontWeight.extraBold;
      case "black":
        return theme.fontWeight.black;
      default:
        return theme.fontWeight.normal;
    }
  }};

  display: ${({ display, theme }) => {
    switch (display) {
      case "inline":
        return "inline";
      case "inline-block":
        return "inline-block";
      case "flex":
        return "flex";
      case "none":
        return "none";
      default:
        return "block";
    }
  }};
  padding: ${({ padding }) => {
    return padding;
  }};
  flex-direction: ${({ direction }) =>
    direction === "column" ? "column" : "row"};

  align-self: ${({ direction }) => {
    switch (direction) {
      case "flex-start":
        return "flex-start";
      case "flex-end":
        return "flex-end";
      case "center":
        return "center";
      case "baseline":
        return "baseline";
      case "stretch":
        return "stretch";
      case "none":
        return "none";

      default:
        return "center";
    }
  }};
  cursor: ${({ disabled }) => {
    return disabled ? "not-allowed" : "inherit";
  }};
`;

export const TypographyWithLine = styled(CustomTypography)`
  &:before,
  &:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid white;
    margin: auto 10px;
  }
`;
