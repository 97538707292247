export interface SetupDropdown {
  id: number;
  question: string;
  answer: JSX.Element | string;
}

const setupGuideDataList: SetupDropdown[] = [
  {
    id: 1,
    question: "Step 1",
    answer:
      "Download the firmware for your IoT (Internet of Things) device. The firmware for your device can be found by clicking device manager on the left, selecting the setup tab and then selecting download firmware.",
  },
  {
    id: 2,
    question: "Step 2",
    answer: "Install the firmware onto the IoT device.",
  },
  {
    id: 3,
    question: "Step 3",
    answer: "Boot up the device with the new firware.",
  },
  {
    id: 4,
    question: "Step 4",
    answer:
      "Log back into SWRM. Navigate to the device manager, click the setup tab and then select deploy devices. From the deploy devices menu, you can select the new device and finish configuring it.",
  },
];

export default setupGuideDataList;
