import React from "react";
import { FlexContainer } from "../../../../Components/flex-container/FlexContainer";
import { Typography } from "../../../../Components/typrography/Typography";
import LocationEditToolTip from "./LocationEditToolTip";
interface Props {
  address: string;
  locationId: number;
}
export default function LocationAddress({ address, locationId }: Props) {
  return (
    <FlexContainer justifyContent="flex-start" gap="30px">
      <Typography variant="xl" weight="light">
        {address}
      </Typography>
      {address !== null && (
        <LocationEditToolTip
          locationName={address}
          locationId={locationId}
          editLocationAddress
        />
      )}
    </FlexContainer>
  );
}
