import styled from "styled-components";
import { TabListProps } from "./TabList";

export const CustomTabList = styled.div<
  Pick<
    TabListProps,
    "children" | "variant" | "justifyContent" | "tabsDirection"
  >
>`
  display: flex;
  border-bottom: ${({ variant, theme }) =>
    variant === "subPageTab" ? `1px solid ${theme.colors["primary"]}` : ""};
  gap: 2rem;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "flex-start"};
  flex-direction: ${({ tabsDirection }) =>
    tabsDirection ? tabsDirection : "row"};
  border-right: ${({ tabsDirection, theme }) =>
    tabsDirection === "column" ? theme.border["primary-sm"] : "none"};

  @media (max-width: 1028px) {
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
  }
`;
