export interface TokenGenerationTableData {
  id: number | string;
  secureKey: string;
  created: Date;
  lastUsed: Date | string;
}
const aiModelManagerTableData: TokenGenerationTableData[] = [
  {
    id: 1,
    secureKey: "testkeydfdfdedsf1",
    created: new Date("2022-05-14"),
    lastUsed: "never",
  },
  {
    id: 2,
    secureKey: "testkeydfdfdedsf2",
    created: new Date("2022-05-17"),
    lastUsed: "never",
  },
  {
    id: 3,
    secureKey: "testkeydfdfdedsf3",
    created: new Date("2022-05-22"),
    lastUsed: "never",
  },
];

export default aiModelManagerTableData;
