import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import { DefaultThemeColor, DefaultThemeBorder } from "../../app/Theme";

interface SystemMetricsLineChartProps {}

const data = [
  {
    hour: "06:00",
    APIResponseTime: 100,
  },
  {
    hour: "07:00",
    APIResponseTime: 90,
  },
  {
    hour: "08:00",
    APIResponseTime: 130,
  },
  {
    name: "09:00",
    APIResponseTime: 134,
  },
  {
    name: "10:00",
    APIResponseTime: 230,
  },
  {
    name: "11:00",
    APIResponseTime: 189,
  },
  {
    name: "12:00",
    APIResponseTime: 200,
  },
  {
    name: "13:00",
    APIResponseTime: 86,
  },
  {
    name: "14:00",
    APIResponseTime: 27,
  },
  {
    name: "15:00",
    APIResponseTime: 234,
  },
  {
    name: "16:00",
    APIResponseTime: 178,
  },
  {
    name: "17:00",
    APIResponseTime: 278,
  },
  {
    name: "18:00",
    APIResponseTime: 53,
  },
  {
    name: "19:00",
    APIResponseTime: 18,
  },
  {
    name: "20:00",
    APIResponseTime: 189,
  },
  {
    name: "21:00",
    APIResponseTime: 168,
  },
  {
    name: "22:00",
    APIResponseTime: 139,
  },
  {
    name: "23:00",
    APIResponseTime: 58,
  },
  {
    name: "00:00",
    APIResponseTime: 49,
  },
  {
    name: "01:00",
    APIResponseTime: 174,
  },
  {
    name: "02:00",
    APIResponseTime: 36,
  },
  {
    name: "03:00",
    APIResponseTime: 24,
  },
  {
    name: "04:00",
    APIResponseTime: 32,
  },
  {
    name: "05:00",
    APIResponseTime: 74,
  },
];

const SystemMetricsLineChart = (props: SystemMetricsLineChartProps) => {
  return (
    <div
      style={{
        width: "100%",
        height: "300px",
        border: DefaultThemeBorder["primary-md"],
        borderRadius: "9px",
        padding: "20px",
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 10,
            left: 10,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            axisLine={{ stroke: DefaultThemeColor["primary"] }}
            interval={2}
          />
          <YAxis
            domain={[0, 400]}
            axisLine={{ stroke: DefaultThemeColor["primary"] }}
            orientation="right"
            tickLine={false}
          >
            <Label
              value="Response Time(ms)"
              angle={90}
              position="insideBottomRight"
            />
          </YAxis>
          <Tooltip />
          <Legend />
          <Line
            type="linear"
            dataKey="APIResponseTime"
            stroke={DefaultThemeColor["primary"]}
            strokeWidth={3}
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SystemMetricsLineChart;
