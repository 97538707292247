import { format } from "date-fns";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const shortMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
const daysOfTheWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function formatDate(dateTime: Date): string {
  const dateObject = new Date(dateTime);
  const dayName = daysOfTheWeek[dateObject.getDay()];
  const day = dateObject.getDate().toString().padStart(2, "0");
  const monthIndex = dateObject.getMonth();
  const year = dateObject.getFullYear();

  const monthName = shortMonths[monthIndex];

  return `${dayName}, ${monthName} ${day}, ${year}`;
}

function twoDigits(number: number) {
  if (0 <= number && number < 10) {
    return `0${number.toString()}`;
  }
  if (-10 < number && number < 0) {
    return `-0${(-1 * number).toString()}`;
  }
  return number.toString();
}

function getCurrentDate() {
  const today = new Date();
  const date = `${today.getFullYear()}-${twoDigits(
    today.getMonth() + 1,
  )}-${twoDigits(today.getDate())}`;
  const time = `${twoDigits(today.getHours())}:${twoDigits(
    today.getMinutes(),
  )}:${twoDigits(today.getSeconds())}`;
  const dateTime = `${date}T${time}`;
  return dateTime;
}

function betterDateFormat(dataDate: string) {
  let date: any = dataDate.split("T")[0].split("-");
  date = `${date[0]}-${date[1]}-${date[2]}`;
  return date;
}

function getMonthAndYear(dataDate: string) {
  const date = new Date(dataDate);
  return months[date.getMonth()] + " " + date.getFullYear();
}

function mysqlToJsDate(date: string) {
  const newDate = date.replace(/:/g, "-").replace("T", "-");
  const dateParts = newDate
    .split("-")
    .map((item: string) => parseInt(item, 10));
  return new Date(
    dateParts[0],
    dateParts[1] - 1,
    dateParts[2],
    dateParts[3],
    dateParts[4],
    dateParts[5],
  );
}

function formatTimeShow(hours: number, minutes: number) {
  const h = hours % 12;
  return (
    (hours < 10 ? "0" : "") + h + `:${minutes} ${hours < 12 ? "am" : "pm"}`
  );
}

function canadaDateFormat(dataDate: string) {
  const date = mysqlToJsDate(dataDate);
  return `${date.getDate()} ${
    months[date.getMonth()]
  }, ${date.getFullYear()} - ${formatTimeShow(
    date.getHours(),
    date.getMinutes(),
  )}`;
}

function createMySqlDate(dataDate: string) {
  const today = new Date(dataDate);
  return (
    today.getUTCFullYear() +
    "-" +
    twoDigits(1 + today.getUTCMonth()) +
    "-" +
    twoDigits(today.getUTCDate()) +
    " " +
    twoDigits(today.getUTCHours()) +
    ":" +
    twoDigits(today.getUTCMinutes()) +
    ":" +
    twoDigits(today.getUTCSeconds())
  );
}

export function formatHHMMSS(seconds: number) {
  return new Date(seconds * 1000).toISOString().substr(11, 8);
}

/**
 *  Get formatted datetime
 * @param value
 * @param formatString
 * @returns formatted date string
 */
export function dateTimeConversion(value: string, formatString = "MM/dd/yyyy") {
  return format(new Date(value), formatString);
}

/**
 *  Get formatted datetime
 * @param value
 * @param formatString
 * @returns formatted date string
 */
export function militaryDateTimeConversion(
  value: string,
  formatString = "HHmm dd MMM yy",
) {
  // Military formates
  // 1. Date only: "dd MMM yy"
  // 2. Date Time : "HHmm dd MMM yy"
  return format(new Date(value), formatString);
}

function time12hDate(dateString: string) {
  let date = new Date(dateString);
  let ampm = date.getHours() % 12 > 1 ? " AM" : " PM";
  return (
    date.getHours() +
    ":" +
    date.getMinutes() +
    ampm +
    " - " +
    shortMonths[date.getMonth()] +
    " " +
    date.getDate() +
    " " +
    date.getFullYear()
  );
}

// Function to check if the date is today
const isTodayDate = (date: Date): boolean => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

// Sort dates based on string e.g "10 Jan 2024"
const sortStringDates = (dates: string[]) => {
  let dateObjects = dates.map((dateString) => new Date(dateString));

  dateObjects.sort((a: any, b: any) => a - b);
  const sortedDatesArray = dateObjects.map((date) => date.toDateString());

  return sortedDatesArray;
};

export {
  formatDate,
  getCurrentDate,
  betterDateFormat,
  getMonthAndYear,
  canadaDateFormat,
  createMySqlDate,
  time12hDate,
  isTodayDate,
  sortStringDates,
};
