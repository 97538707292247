export interface toggleButton {
  id: number;
  label: string;
  isActive: boolean;
  tabName: string;
}

export const toggleButtonList: toggleButton[] = [
  { id: 1, label: "Doug's Truck", isActive: true, tabName: "Locations" },
  { id: 2, label: "Warehouse 1", isActive: true, tabName: "Locations" },
  { id: 3, label: "Worksite 1", isActive: true, tabName: "Locations" },
  { id: 4, label: "Loc. Creation", isActive: true, tabName: "Locations" },
  { id: 5, label: "Loc. Deletion", isActive: true, tabName: "Locations" },
  { id: 6, label: "Group Creation", isActive: true, tabName: "Locations" },
  { id: 7, label: "Loc. Editing", isActive: true, tabName: "Locations" },
  { id: 8, label: "Group Editing", isActive: true, tabName: "Locations" },
  { id: 9, label: "Enable Pairing", isActive: true, tabName: "Pairing" },
  { id: 10, label: "Disable Pairing", isActive: true, tabName: "Pairing" },
  { id: 11, label: "Edit Pairing", isActive: true, tabName: "Pairing" },
  { id: 12, label: "Apply Group", isActive: true, tabName: "Pairing" },
  { id: 13, label: "Remove Group", isActive: true, tabName: "Pairing" },
  { id: 14, label: "Edit Pair Timer", isActive: true, tabName: "Pairing" },
  { id: 15, label: "Pairing Relation", isActive: true, tabName: "Pairing" },
  { id: 16, label: "View Scans", isActive: true, tabName: "Devices" },
  { id: 17, label: "Deploy Device", isActive: true, tabName: "Devices" },
  { id: 18, label: "Disable Device", isActive: true, tabName: "Devices" },
  { id: 19, label: "Edit Device Loc.", isActive: true, tabName: "Devices" },
  { id: 20, label: "Enable Sensor", isActive: true, tabName: "Devices" },
  { id: 21, label: "Disable Sensor", isActive: true, tabName: "Devices" },
  { id: 22, label: "Assign AI Model", isActive: true, tabName: "Devices" },
  { id: 23, label: "Edit Conf. %", isActive: true, tabName: "Devices" },
  { id: 24, label: "Escalate Alerts", isActive: true, tabName: "Devices" },
  { id: 25, label: "View Gallery", isActive: true, tabName: "AI Model" },
  { id: 26, label: "Download Fr.", isActive: true, tabName: "AI Model" },
  { id: 27, label: "Archive Fr.", isActive: true, tabName: "AI Model" },
  { id: 28, label: "Delete Fr.", isActive: true, tabName: "AI Model" },
  { id: 29, label: "Upload Fr.", isActive: true, tabName: "AI Model" },
  { id: 30, label: "Edit Fr.", isActive: true, tabName: "AI Model" },
  {
    id: 31,
    label: "Receive SMS Notifications",
    isActive: true,
    tabName: "General Notifications",
  },
  {
    id: 32,
    label: "Receive Email Notifications",
    isActive: true,
    tabName: "General Notifications",
  },
  {
    id: 33,
    label: "Receive Daily Device Report",
    isActive: true,
    tabName: "General Notifications",
  },
  {
    id: 34,
    label: "Receive Storage Notifications",
    isActive: true,
    tabName: "General Notifications",
  },
  {
    id: 35,
    label: "Receive Billing Notifications",
    isActive: true,
    tabName: "General Notifications",
  },
  {
    id: 36,
    label: "Receive Deployment Notifications",
    isActive: true,
    tabName: "Device Notifications",
  },
  {
    id: 37,
    label: "Receive Connection Notifications",
    isActive: true,
    tabName: "Device Notifications",
  },
  {
    id: 38,
    label: "Receive Device Health Notifications",
    isActive: true,
    tabName: "Device Notifications",
  },
  {
    id: 39,
    label: "Receive Extreme Data Alerts",
    isActive: true,
    tabName: "Device Notifications",
  },
  {
    id: 40,
    label: "Receive Confidence Notifications",
    isActive: true,
    tabName: "AI Notifications",
  },
  {
    id: 41,
    label: "Receive AI Update Notifications",
    isActive: true,
    tabName: "AI Notifications",
  },
  {
    id: 42,
    label: "Receive AI Capture Notifications",
    isActive: true,
    tabName: "AI Notifications",
  },
  {
    id: 43,
    label: "Receive AI Device Notifications",
    isActive: true,
    tabName: "AI Notifications",
  },
  {
    id: 44,
    label: "Receive Capture Expiration Alerts",
    isActive: true,
    tabName: "AI Notifications",
  },
  {
    id: 45,
    label: "Receive Location Name Notifications",
    isActive: true,
    tabName: "Location Notifications",
  },
  {
    id: 46,
    label: "Receive GPS Notifications",
    isActive: true,
    tabName: "Location Notifications",
  },
  {
    id: 47,
    label: "Receive Location Status Notifications",
    isActive: true,
    tabName: "Location Notifications",
  },
  {
    id: 48,
    label: "Receive Group Status Notifications",
    isActive: true,
    tabName: "Location Notifications",
  },
];
