import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import CustomModal from "../CustomModal";
import { Typography } from "../../typrography/Typography";
import {
  ContentWrapper,
  HorizontalLine,
  ScaledButtonWrapper,
} from "../CustomModal.style";
import Input from "../../input/Input";
import { Formik } from "formik";
import * as Yup from "yup";
import { StyledFormikForm } from "../../tab/ai-model-manager/Upload.style";
import DragUpload from "../../input/upload-input/DragUpload";

export interface RetrainModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  subTitle: string;
  inputLabel: string;
  inputPlaceholder: string;
  buttonText: string;
  buttonPadding?: string;
  editorHeight?: number;
}

interface FormValues {
  url: string;
}

const validationSchema = Yup.object().shape({
  url: Yup.string()
    .min(2, "Name must be at least 2 characters")
    .max(50, "Name must be up to 50 characters")
    .required("Name is required"),
});

const RetrainModal = (props: RetrainModalProps) => {
  const {
    isOpen,
    onClose,
    title,
    subTitle,
    inputLabel,
    inputPlaceholder,
    buttonText,
    buttonPadding,
  } = props;
  const initialValues = {
    url: "",
  };
  const [file, setFile] = useState<File | null>(null);

  const handelSubmit = (values: FormValues, { setSubmitting }: any) => {};
  const handleFileUpload = (uploadedFile: File) => {
    setFile(uploadedFile);
  };
  const cardHeader = () => {
    return (
      <Typography
        variant="lg"
        direction="flex-start"
        style={{ padding: "0 20px", fontWeight: "700" }}
      >
        {title}
      </Typography>
    );
  };
  const cardFooter = () => {
    return (
      <ScaledButtonWrapper buttonPadding={buttonPadding}>
        <button type="submit" form="retrain-model-form">
          {buttonText}
        </button>
      </ScaledButtonWrapper>
    );
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      padding="10px 0"
      header={cardHeader()}
      footer={cardFooter()}
    >
      <ContentWrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handelSubmit}
        >
          {({ isSubmitting }: any) => (
            <StyledFormikForm id="retrain-model-form">
              <Typography variant="sm" display="flex" direction="flex-start">
                {subTitle}
              </Typography>
              <Input
                placeHolder={inputPlaceholder}
                label={inputLabel}
                name={inputLabel}
                width="100%"
              />
              <DragUpload onFileUpload={handleFileUpload} width="100%" />
            </StyledFormikForm>
          )}
        </Formik>
      </ContentWrapper>
    </CustomModal>
  );
};

export default RetrainModal;
