import styled from "styled-components";

export const ResponsiveComponent = styled.div`
  /* Full width of the viewport */
  width: 100vw;
  /* Full height of the viewport */
  height: 100vh;

  /* Font size scales with viewport width */
  /* font-size: 1.075vw; */

  // paddingTop: '10vh',
  // height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
`;
