import React from "react";
import { CustomCardOutline } from "./CardOutline.style";

export interface CardOutlineProps {
  children: React.ReactNode;
  border: boolean;
}

//card
export const CardOutline: React.FC<CardOutlineProps> = ({
  children,
  border,
}) => {
  return <CustomCardOutline border={border}>{children}</CustomCardOutline>;
};

export default CardOutline;
