import React from "react";
import NavBarTitle from "../../title/nav-bar-title/NavBarTitle";
import { IconWraper, SetupNavBarWraper } from "./SetupNavBar.style";

interface SetupNavBarProps {
  title: string;
  subTitle: string;
  barIcon: JSX.Element;
  onClick: (value: string) => void;
}

const SetupNavBar: React.FC<SetupNavBarProps> = ({
  title,
  subTitle,
  barIcon,
  onClick,
}) => {
  return (
    <SetupNavBarWraper onClick={() => onClick(title)}>
      <IconWraper>{barIcon}</IconWraper>
      <NavBarTitle title={title} subTitle={subTitle}></NavBarTitle>
    </SetupNavBarWraper>
  );
};

export default SetupNavBar;
