import styled from "styled-components";

export const DeviceTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: ${(props) => props.theme.fontSizes["lg"]};
  font-weight: 900;
  padding: 0.725rem;
`;
