import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

export const postDeviceGroup = async (payload: {
  id: number;
  deviceId: string;
  groupId: number;
}) => {
  try {
    const response = await axios.post(`${API_URL}/DeviceGroup`, payload);
    return response.data;
  } catch (error: unknown) {
    return error;
  }
};
export const deleteDeviceGroup = async (id: number) => {
  try {
    const response = await axios.delete(`${API_URL}/DeviceGroup?id=${id}`);
    return response.data;
  } catch (error: unknown) {
    return error;
  }
};
