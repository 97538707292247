import React from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DeviceManagerElement } from "../device-manager-elements/deviceManagerElement";
import { AddDeviceModelImageBox } from "./DeviceModelBox.style";
interface Props {
  index: number;
  title: string;
  onClick: (index: number) => void;
  hideIcon?: boolean;
  helperText?: string;
}
export default function DeviceModelBox({
  index,
  title,
  hideIcon,
  helperText,
  onClick,
}: Props) {
  const defaultStyles = {
    height: "100%",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    hover: { borderColor: "yellow" },
  };
  const defaultConfig = {
    header: title,
    index: `${index + 1}`,
    mainLabel: !hideIcon ? <FontAwesomeIcon icon={faPlus} /> : null,
    helperLabel: helperText != undefined ? helperText : "Click Here!",
    padding: "0.725rem 1rem",
    styling: defaultStyles,
    border: true,
  };
  return (
    <AddDeviceModelImageBox onClick={() => onClick(index)}>
      <DeviceManagerElement config={defaultConfig} />
    </AddDeviceModelImageBox>
  );
}
