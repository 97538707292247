import React from "react";
import { SubTitle } from "../title/Titles.styles";
import { IconSection } from "../sort-by/SortBy.style";
import { ThumbnailSizeItem } from "./thumbnailSizeSlice";
import { ThumbnailSizeIconWraper } from "./ThumbnailSize.style";
import { DefaultThemeColor } from "../../app/Theme";

export interface ThumbnailSizeProps {
  thumbnailSizeItems: ThumbnailSizeItem[];
  handleChangeThumbnailViewSize: (id: number) => void;
}

const ThumbnailSize: React.FC<ThumbnailSizeProps> = ({
  thumbnailSizeItems,
  handleChangeThumbnailViewSize,
}) => {
  return (
    <>
      <SubTitle>Thumbnail Size</SubTitle>
      <IconSection>
        {thumbnailSizeItems.map((item) => (
          <ThumbnailSizeIconWraper
            key={item.id}
            isActive={item.isActive}
            onClick={() => handleChangeThumbnailViewSize(item.id)}
          >
            <item.icon
              size="1.25rem"
              color={item.isActive ? DefaultThemeColor.primary : ""}
            />
          </ThumbnailSizeIconWraper>
        ))}
      </IconSection>
    </>
  );
};

export default ThumbnailSize;
