import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { StyledFormikForm } from "../../../../Components/tab/ai-model-manager/Upload.style";
import * as Yup from "yup";
import {
  CustomDropdown,
  IOption,
} from "../../../../Components/form-field/drop-down/CustomDropdown";
import { FlexContainer } from "../../../../Components/flex-container/FlexContainer";
import { FormLabel } from "../../../../Components/form-field/form-label/FormLabel";
import { FormControl } from "../../../../Components/form-field/form-control/FormControl";
import Input from "../../../../Components/input/Input";
import { ScaledButtonWrapper } from "../../../../Components/modals/CustomModal.style";
import {
  RelayRule,
  RelayRuleAction,
  RuleComparison,
} from "../../../../Interfaces/Device/Relay";
import relayService from "../../../../Services/DeviceManagement/deviceRelays.service";
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from "../../../../Components/notification/Notification";
import { Sensor } from "../../../../Interfaces/Device/Sensor";
import Checkbox from "../../../../Components/checkbox/Checkbox";
import { initial } from "lodash";
import { Typography } from "../../../../Components/typrography/Typography";
import {
  FilterDropdown,
  FilterOption,
} from "../../../../Components/dropdown/filter-dropdown/FilterDropdown";
import { setSelectedDevice } from "../../../Slice/devices/devicesSlice";
interface Props {
  relayRule: RelayRule;
  relayId: number;
  allSensor: any;
  deviceDropdownOptions: any;
  onCancel: () => void;
  onSaveRuleSuccess: (newRule: RelayRule) => void;
}
interface FormValues {
  value: number;
}

const validationSchema = Yup.object({
  value: Yup.number()
    .required("Value is required")
    .integer("Only integer allowed"),
});

export default function EditRelayRules({
  relayRule,
  relayId,
  allSensor,
  deviceDropdownOptions,
  onCancel,
  onSaveRuleSuccess,
}: Props) {
  const TurnOptions: FilterOption[] = [
    {
      label: "On",
      value: RelayRuleAction.On,
    },
    {
      label: "Off",
      value: RelayRuleAction.Off,
    },
  ];
  const IsOptions: FilterOption[] = [
    {
      label: "<",
      value: RuleComparison.LessThan,
    },
    {
      label: "<=",
      value: RuleComparison.LessThanEqual,
    },
    {
      label: ">",
      value: RuleComparison.GreaterThan,
    },
    {
      label: ">=",
      value: RuleComparison.GreaterThanEqual,
    },
  ];

  const initialValues = {
    Id: relayRule.Id,
    Enable: true,
    DeviceId: relayRule.DeviceId,
    Action: relayRule.Action,
    At: relayRule.DeviceId,
    SensorId: relayRule.SensorId,
    Comparison: relayRule.Comparison,
    value: relayRule.Value,
  };

  const [relayRulePayload, setRelayRulePayload] = useState(initialValues);
  const [sensors, setSensors] = useState<IOption[]>([]);
  const currentDevice: FilterOption = deviceDropdownOptions.find(
    (item: FilterOption) => {
      return item.value === initialValues.DeviceId;
    },
  );
  const currentSensor = allSensor.find(
    (sensor: any) => sensor.id == initialValues.SensorId,
  );
  const currentSensorFilterOption: FilterOption = {
    label: currentSensor?.value!,
    value: currentSensor?.id!,
  };

  const returnComparison = (comparisonIndex: number) => {
    switch (comparisonIndex) {
      case 0:
        return IsOptions[0];
      case 1:
        return IsOptions[1];
      case 2:
        return IsOptions[2];
      case 3:
        return IsOptions[3];
    }
  };

  const handleSubmit = async (values: FormValues, { setSubmitting }: any) => {
    try {
      if (!relayRulePayload.SensorId) {
        OpenErrorNotification("Please select sensor");
        return;
      }
      const payload: RelayRule = {
        RelayId: relayId,
        Id: relayRulePayload.Id,
        DeviceId: relayRulePayload.DeviceId,
        Enable: true,
        At: relayRulePayload.SensorId,
        Action: relayRulePayload.Action,
        SensorId: relayRulePayload.SensorId,
        Comparison: relayRulePayload.Comparison,
        Value: values.value,
      };
      const response = await relayService.updateRelayRule(payload);
      if (!response.IsError) {
        OpenSuccessNotification(`The Relay Rule has been updated successfully`);
        onSaveRuleSuccess(response.Result);
      }
    } catch (error) {
      console.error("handelSubmit Error:", error);
    }
  };

  const seedSensorOptions = async (deviceId: string) => {
    setSensors(allSensor.filter((s: any) => s.deviceId === deviceId));
  };

  const handleDropdownChange = (
    e: number | string | boolean,
    field: keyof RelayRule,
  ) => {
    if (field === "DeviceId") {
      setRelayRulePayload((prev: any) => ({
        ...prev,
        DeviceId: e.toString(),
        SensorId: 0,
      }));
      setSensors(allSensor.filter((s: any) => s.deviceId === e));
    } else {
      setRelayRulePayload((prev: any) => ({
        ...prev,
        [field]: e,
      }));
    }
  };

  useEffect(() => {
    seedSensorOptions(initialValues.DeviceId);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, handleChange }: any) => (
        <StyledFormikForm id="edit-relay-rule">
          {/*
            Comment out enable disable relay functionality.
            May or may not re-implement, but it is here
            if needed.
          */}
          {/* <div style={{ display: "flex", marginBottom: "1rem" }}>
            <Checkbox
              isChecked={relayRulePayload.Enable}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleDropdownChange(e.target.checked, "Enable")
              }
            />
            <span style={{ marginLeft: "0.725rem" }}>Rule is Enabled?</span>
          </div> */}
          <Typography variant="2xl" weight="bold">
            Edit Relay
          </Typography>
          <FlexContainer gap="1rem" justifyContent="center" alignItems="center">
            {/* setup rules  */}
            {/* enable/disable rule */}
            {/*1. Turn: Switch On/Off */}
            <FormControl>
              <FormLabel htmlFor="">Turn</FormLabel>
              <FilterDropdown
                defaultValue={
                  initialValues.Action == 0 ? TurnOptions[0] : TurnOptions[1]
                }
                title="Action"
                options={TurnOptions}
                onChange={({ label, value }: FilterOption) => {
                  handleDropdownChange(value, "Action");
                }}
              />
            </FormControl>

            {/*2. At: Select Device Location */}
            <FormControl>
              <FormLabel htmlFor="">At</FormLabel>
              <FilterDropdown
                defaultValue={currentDevice}
                title="Select Device"
                options={deviceDropdownOptions}
                onChange={({ label, value }: FilterOption) => {
                  handleDropdownChange(value, "DeviceId");
                }}
              />
            </FormControl>

            {/*3. When:  Select Sensor */}
            <FormControl key={relayRulePayload.DeviceId}>
              <FormLabel htmlFor="">When</FormLabel>
              <FilterDropdown
                defaultValue={currentSensorFilterOption}
                title="Select Sensor"
                options={
                  sensors?.map((sensor) => ({
                    label: sensor.value,
                    value: sensor.id,
                  })) || []
                }
                onChange={({ label, value }: FilterOption) => {
                  handleDropdownChange(value, "SensorId");
                }}
              />
            </FormControl>

            {/*4. Is : Value >,>=, <. <=  */}
            <FormControl>
              <FormLabel htmlFor="">Is</FormLabel>
              <FilterDropdown
                defaultValue={returnComparison(initialValues.Comparison)}
                title="Is"
                options={IsOptions}
                onChange={({ label, value }: FilterOption) => {
                  handleDropdownChange(value, "Comparison");
                }}
              />
            </FormControl>

            {/*5. Value  */}
            <FormControl>
              <FormLabel htmlFor="">Value</FormLabel>
              <Input
                type="number"
                name="value"
                placeHolder="Value"
                label={""}
                width="100%"
                inputWrapperWidth={"100%"}
                value={initialValues.value.toString()} // Now it will recognize values.name
                onChange={handleChange}
              />
            </FormControl>
          </FlexContainer>

          {/* Action buttons */}
          <FlexContainer gap="1rem" justifyContent="center" alignItems="center">
            <ScaledButtonWrapper buttonPadding={"10px"}>
              <button type="submit" form="edit-relay-rule">
                Save
              </button>
              <button type="button" className="danger" onClick={onCancel}>
                Cancel
              </button>
            </ScaledButtonWrapper>
          </FlexContainer>
        </StyledFormikForm>
      )}
    </Formik>
  );
}
