import React from "react";
import { useAppDispatch } from "../../../../../Store/hooks";
import { Group } from "../../../../../Interfaces/Device/Group";
import DeleteIcon from "../../../../../Components/icons/DeleteIcon";
import { OpenSuccessNotification } from "../../../../../Components/notification/Notification";
import {
  GroupBox,
  GroupDelete,
  GroupTitle,
} from "../../../../../Features/tabs/device-manager/grouping/Grouping.style";
import { DefaultThemeFontSize } from "../../../../../app/Theme";
import GroupService, {
  removeLocationFromGroup,
} from "../../../../../Services/Locations/Group.service";
import {
  removeGroupFromLocation,
  setAllGroups,
} from "../../../../../Features/Slice/locations/locationsSlice";
interface Props {
  group: Group;
}
export default function LocationGroupCard({ group }: Props) {
  const dispatch = useAppDispatch();
  const onDelete = async () => {
    try {
      const response = await removeLocationFromGroup(group.Id);
      console.log("On remove Response", response);
      if (response && !response.IsError) {
        OpenSuccessNotification(
          "The Group has been removed successfully from Location",
        );
        dispatch(removeGroupFromLocation(response.Result));
        GroupService.getGroups({}).then((response) => {
          if (response?.Result) {
            dispatch(
              setAllGroups(
                response?.Result.filter((g: Group) => !g.LocationId),
              ),
            );
          }
        });
      }
    } catch (e: any) {
      console.log("onDelete Error", e.message);
    }
  };
  return (
    <GroupBox>
      <GroupDelete className="deleteIcon">
        <DeleteIcon
          size={DefaultThemeFontSize.md}
          handleDeleteOnClick={onDelete}
        />
      </GroupDelete>
      <GroupTitle>{group.Name}</GroupTitle>
    </GroupBox>
  );
}
