import React from "react";
import { GroupBox, GroupDelete, GroupTitle } from "./Grouping.style";
import DeleteIcon from "../../../../Components/icons/DeleteIcon";
import { DefaultThemeFontSize } from "../../../../app/Theme";
import { DeviceGroup } from "../../../../Interfaces/Device/DeviceGroup";
import { deleteDeviceGroup } from "../../../../Services/DeviceManagement/deviceGroups.service";
import { OpenSuccessNotification } from "../../../../Components/notification/Notification";
import { useAppDispatch } from "../../../../Store/hooks";
import { removeDeviceGroup } from "../../../Slice/devices/devicesSlice";
interface Props {
  group: DeviceGroup;
}
export default function DeviceGroupCard({ group }: Props) {
  const dispatch = useAppDispatch();
  const onDelete = async () => {
    try {
      const response = await deleteDeviceGroup(group.Id);
      console.log("On delete Response", response);
      if (response && !response.IsError) {
        OpenSuccessNotification(response.Result);
        dispatch(removeDeviceGroup(group.Id));
      }
    } catch (e: any) {
      console.log("onDelete Error", e.message);
    }
  };
  return (
    <GroupBox>
      <GroupDelete className="deleteIcon">
        <DeleteIcon
          size={DefaultThemeFontSize.md}
          handleDeleteOnClick={onDelete}
        />
      </GroupDelete>
      <GroupTitle>{group.Group.Name}</GroupTitle>
    </GroupBox>
  );
}
