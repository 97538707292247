import React, { useEffect } from "react";
import {
  CardInfoWrapper,
  CardTextWrapper,
  TextWrapper,
  CurrentPaymentMethodWrapper,
  CurrentCardWrapper,
  StyledBsCreditCardFill,
} from "../../../Components/card/payment-card/PaymentCard.style";
import { Typography } from "../../../Components/typrography/Typography";
import PaymentCard from "../../../Components/card/payment-card/PaymentCard";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { RootState } from "../../../Store/store";
import { getLastPaymentCardDetails } from "../../../Services/Payment/payment.service";
import CardBrand from "./CardBrand";

type Props = {};

const LastPaymentCardInfo = (props: Props) => {
  const dispatch = useAppDispatch();
  const { lastPaymentCard, companySubscription } = useAppSelector(
    (state: RootState) => state.subscriptions,
  );
  useEffect(() => {
    if (
      companySubscription &&
      companySubscription.length &&
      companySubscription[0].PaymentMethodId
    ) {
      dispatch(
        getLastPaymentCardDetails(companySubscription[0].PaymentMethodId),
      );
    }
  }, [companySubscription && companySubscription.length]);

  return lastPaymentCard ? (
    <CurrentPaymentMethodWrapper>
      <CurrentCardWrapper>
        <PaymentCard
          cardName={"Card"}
          cardIcon={StyledBsCreditCardFill}
          isActive={true}
          isCardList={false}
        />
        <CardBrand Brand={lastPaymentCard.Brand} />
      </CurrentCardWrapper>
      <CardInfoWrapper>
        <TextWrapper>
          <CardTextWrapper>
            <Typography
              direction="flex-start"
              variant="lg"
              style={{ padding: "0.5vw 0" }}
            >
              Card Number
            </Typography>
            <Typography
              direction="flex-start"
              variant="md"
              color="primary"
              style={{ padding: "0.5vw 0" }}
            >
              **** **** **** {lastPaymentCard?.Last4}
            </Typography>
          </CardTextWrapper>
          <CardTextWrapper>
            <Typography
              direction="flex-start"
              variant="lg"
              style={{ padding: "0.5vw 0" }}
            >
              Expiration
            </Typography>
            <Typography
              direction="flex-start"
              variant="md"
              color="primary"
              style={{ padding: "0.5vw 0" }}
            >
              {lastPaymentCard?.ExpMonth}/{lastPaymentCard?.ExpYear}
            </Typography>
          </CardTextWrapper>
        </TextWrapper>
      </CardInfoWrapper>
    </CurrentPaymentMethodWrapper>
  ) : null;
};

export default LastPaymentCardInfo;
