import styled from "styled-components";

export const CustomInfoCard = styled.div`
  max-width: 100%;
  width: 100%;
  display: grid;
  background-color: #020a01;
  color: white;
  @media (min-width: 1028px) {
    grid-template-columns: 228px auto;
  }

  @media (max-width: 600px) {
    grid-template-rows: 1fr 1fr;
  }
  padding: 20px;
  gap: 16px;
`;

export const CustomInfoCardSingleColumn = styled(CustomInfoCard)`
  grid-template-columns: 100%;
  @media (min-width: 1028px) {
    grid-template-columns: 100%;
  }
  @media (max-width: 600px) {
    grid-template-rows: 1fr 1fr;
  }
`;
