import React, { useEffect, useState } from "react";
import {
  DropdownContainer,
  DropdownMenu,
  IconButtonWrapper,
  OptionsContainer,
  VerticalLine,
  VerticalLineWrapper,
} from "./Dropdown.style";
import ConfigIcon from "../../react-icons/ConfigIcon";
import { MdKeyboardArrowDown } from "react-icons/md";
export interface IOption {
  id: number;
  value: string;
}
interface IOptionList extends IOption {
  isOptionSelected: boolean;
}
export interface DropdownProps {
  isOpen?: boolean;
  onToggle?: () => void;
  label: string;
  options: IOption[];
  variant?: "xs" | "small" | "large";
  color?: string;
  width?: string;
  justifyContent?: string;
  alignItems?: string;
  display?: string;
  verticalLine?: boolean;
  backgroundColor?: string;
  borderTheme?: string;
  iconColor?: string;
  margin?: string;
  defaultValue?: number | string;
  onChange?: (selectedValue: number, selectedOption?: any) => void;
}

export const CustomDropdown = (props: DropdownProps) => {
  const {
    defaultValue,
    variant,
    width,
    color,
    display,
    justifyContent,
    alignItems,
    verticalLine,
    backgroundColor,
    borderTheme,
    iconColor,
    margin,
  } = props;

  const [isActiveState, setIsActiveState] = useState(
    props.isOpen !== undefined ? props.isOpen : false,
  );
  const [value, setValue] = useState(defaultValue || 0);
  const [optionsList, setOptionsList] = useState<IOptionList[]>([]);
  const [dropdownTitle, setDropdownTitle] = useState(props.label || "");
  useEffect(() => {
    const newOptions = props.options.map((option: IOption) => ({
      ...option,
      isOptionSelected: option.id === value ? true : false,
    }));
    setOptionsList(newOptions);
    const selected = newOptions.find((item) => item.isOptionSelected);
    if (selected) {
      setDropdownTitle(selected.value);
    }
  }, [props.options, value]);

  // clicks the dropdown
  const onDropdownClick = () => {
    if (props.onToggle) {
      props.onToggle();
    } else {
      setIsActiveState(!isActiveState);
    }
  };

  // Click event that sets isOptionSelected to true when an option <li> is clicked
  const handleOptionClick = (optionId: number, optionValue: string) => {
    const selectedOption = props.options.find((opt) => opt.id === optionId);
    selectedOption?.value && setDropdownTitle(selectedOption?.value);
    setValue(optionId); // set value
    if (props.onChange) {
      props.onChange(optionId, selectedOption);
    }
    onDropdownClick(); // unclick the dropdown
  };
  return (
    <DropdownContainer alignItems={alignItems} width={width}>
      <DropdownMenu
        onClick={onDropdownClick}
        variant={variant}
        width={width}
        backgroundColor={backgroundColor}
        borderTheme={borderTheme}
        margin={margin}
      >
        <ConfigIcon color={iconColor}>
          <span
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {dropdownTitle}
          </span>
          <VerticalLineWrapper>
            {verticalLine ? <VerticalLine borderTheme={borderTheme} /> : ""}
            <IconButtonWrapper isActive={isActiveState}>
              <MdKeyboardArrowDown />
            </IconButtonWrapper>
          </VerticalLineWrapper>
        </ConfigIcon>
      </DropdownMenu>
      {isActiveState && (
        <OptionsContainer variant={variant} width={width}>
          <ul>
            {optionsList.map((option, index) => (
              <li
                key={index}
                onClick={() => {
                  handleOptionClick(option.id, option.value);
                }}
                className={option.isOptionSelected ? "selected" : ""}
              >
                {option.value}
              </li>
            ))}
          </ul>
        </OptionsContainer>
      )}
    </DropdownContainer>
  );
};
