import React from "react";
import { DeviceManagerMain } from "../../../../Components/device-manager-elements/deviceManagerMain";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import ToggleButton from "../../../../Components/button/toggle-button/ToggleButton";
import { DeviceManagerElement } from "../../../../Components/device-manager-elements/deviceManagerElement";
import { SensorBlock } from "./Sensors.style";
import sensorService from "../../../../Services/DeviceManagement/deviceSensors.service";
import { Sensor } from "../../../../Interfaces/Device/Sensor";
import { updateSensorsInDevice } from "../../../Slice/devices/devicesSlice";
import { Typography } from "../../../../Components/typrography/Typography";
import { SwrmDevices } from "../../../../Interfaces/Device/SwrmDevice";
import { DefaultThemeBorder } from "../../../../app/Theme";
export const Sensors = () => {
  const dispatch = useAppDispatch();
  const device = useAppSelector((state) => state.devices);
  const selectedDevice = device.selectedDevice;
  const sensors = selectedDevice.Sensors;
  const childDevice = selectedDevice.ChildDevices;
  const updateSensorStatus = async (
    sensor: Sensor,
    active: boolean,
    revert: () => void,
  ) => {
    const updatedSensor = { ...sensor, Active: active };
    const response = await sensorService.patchSensor(updatedSensor);
    if (!response.IsError) {
      dispatch(
        updateSensorsInDevice({
          sensor: response.Result,
          masterDeviceId: selectedDevice.Id || "",
        }),
      );
    } else {
      revert();
    }
  };
  return (
    <DeviceManagerMain data={[]}>
      {sensors &&
      !sensors?.length &&
      childDevice &&
      (!childDevice.length ||
        childDevice.every((d: SwrmDevices) => !d.Sensors.length)) ? (
        <Typography color="red-100" variant="lg">
          No sensors connected
        </Typography>
      ) : null}
      {sensors && sensors?.length > 0 ? (
        <Typography>{selectedDevice.Name}</Typography>
      ) : null}
      <SensorBlock>
        {!!sensors &&
          !!sensors?.length &&
          sensors?.map((s: Sensor, index: number) => (
            <div
              key={s.Id}
              style={{
                paddingBottom: "1.5rem",
                border: DefaultThemeBorder["border-sm-white"],
                borderRadius: "5px",
              }}
            >
              <DeviceManagerElement
                config={{
                  header: `Sensor ${index + 1}`,
                  index: s.Id.toString(),
                  mainLabel: (
                    <ToggleButton
                      id={s.Id.toString()}
                      isActive={s.Active}
                      onToggle={(status, revert) =>
                        updateSensorStatus(s, status, revert)
                      }
                    />
                  ),
                  helperLabel: s.Name,
                }}
              />
            </div>
          ))}
      </SensorBlock>
      {!!childDevice &&
        !!childDevice.length &&
        childDevice.map((d: SwrmDevices, index: number) =>
          !!d.Sensors && !!d.Sensors.length ? (
            <>
              <Typography>{d.Name}</Typography>
              <SensorBlock>
                {d.Sensors && d.Sensors?.length > 0 ? (
                  d.Sensors?.map((s: Sensor, index: number) => (
                    <div
                      key={s.Id}
                      style={{
                        paddingBottom: "1.5rem",
                        border: DefaultThemeBorder["border-sm-white"],
                        borderRadius: "5px",
                      }}
                    >
                      <DeviceManagerElement
                        config={{
                          header: `Sensor ${index + 1}`,
                          index: s.Id.toString(),
                          mainLabel: (
                            <ToggleButton
                              id={s.Id.toString()}
                              isActive={s.Active}
                              onToggle={(status, revert) =>
                                updateSensorStatus(s, status, revert)
                              }
                            />
                          ),
                          helperLabel: s.Name,
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <Typography color="red-100" variant="lg">
                    No sensors connected
                  </Typography>
                )}
              </SensorBlock>
            </>
          ) : null,
        )}
    </DeviceManagerMain>
  );
};
