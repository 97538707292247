import React from "react";
import { Tooltip } from "react-tooltip";
import { StyledHiOutlineDuplicate } from "../tables/Table.style";

interface DuplicateIconProps {
  handleDuplicateOnClick: () => void;
}

const DuplicateIcon: React.FC<DuplicateIconProps> = (props) => {
  return (
    <>
      <StyledHiOutlineDuplicate
        data-tooltip-id="duplicate-tooltip"
        data-tooltip-content="Duplicate"
        data-tooltip-place="top"
        onClick={props.handleDuplicateOnClick}
      />
      <Tooltip id="duplicate-tooltip" />
    </>
  );
};

export default DuplicateIcon;
