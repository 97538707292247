import axios from "axios";
import { DeviceModel } from "../../Interfaces/Device/DeviceModel";
import { ModelDownloadToDeviceData } from "../SSE/deviceSSE.service";
import { OpenErrorNotification } from "../../Components/notification/Notification";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";
const getAllDeviceModels = async () => {
  let response = await axios
    .get(`${API_URL}/DeviceModel`)
    .then(async (response) => {
      await console.log(response.data);
      return response.data;
    });
  return response;
};

const searchDeviceModels = async (
  searchedName: string,
  deviceName: string,
  deviceType: string,
  deployed: boolean,
  groupName: string,
  orderByDesc: boolean,
) => {
  const searchString = `?SearchedName${
    searchedName.length > 0 ? "=" + searchedName : ""
  }&DeviceName${deviceName.length > 0 ? "=" + deviceName : ""}&DeviceType${
    deviceType.length > 0 ? "=" + deviceType : ""
  }&Deployed${deployed ? "=" + deployed : ""}&GroupName${
    groupName.length > 0 ? "=" + groupName : ""
  }&OrderByDesc${orderByDesc ? "=" + orderByDesc : ""}`;
  let response = await axios
    .get(`${API_URL}/Device/search?${searchString}`)
    .then(async (response) => {
      await console.log(response.data);
      return response.data;
    });
  return response;
};

const postDeviceModel = async (deviceModel: DeviceModel) => {
  let response = await axios
    .post(`${API_URL}/DeviceModel`, deviceModel, {
      headers: {
        "Model-Hash-Code": deviceModel.ModelHashCode,
      },
    })
    .then(async (response) => {
      await console.log(response.data);
      return response.data;
    })
    .catch((err: any) => {
      OpenErrorNotification(err.response.data.ErrorMessage);
      return err.response.data;
    });
  return response;
};

const patchDeviceModel = async (deviceModel: DeviceModel) => {
  let response = await axios
    .patch(`${API_URL}/DeviceModel`, deviceModel, {
      headers: {
        "Model-Hash-Code": deviceModel.ModelHashCode,
      },
    })
    .then(async (response) => {
      await console.log(response.data);
      return response.data;
    })
    .catch((err: any) => {
      OpenErrorNotification(err.response.data.ErrorMessage);
      return err.response.data;
    });
  return response;
};

const deleteDeviceModel = async (deviceModel: DeviceModel) => {
  let response = await axios
    .delete(`${API_URL}/DeviceModel`, { data: deviceModel })
    .then(async (response) => {
      await console.log(response.data);
      return response.data;
    });
  return response;
};

const sendDetectionUploadLimit = async (deviceId: string, limit: number) => {
  let response = await axios
    .patch(
      `${API_URL}/DeviceModel/sendDetectionUploadLimit?deviceId=${deviceId}&limit=${limit}`,
    )
    .then(async (response) => {
      await console.log(response.data);
      return response.data;
    });
  return response;
};

const deviceModelService = {
  getAllDeviceModels,
  searchDeviceModels,
  postDeviceModel,
  patchDeviceModel,
  deleteDeviceModel,
  sendDetectionUploadLimit,
};

export default deviceModelService;
