import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

export const getAllBoardTypes = createAsyncThunk(
  "boardTypes/getAllBoardTypes",
  async (thunkapi) => {
    try {
      const response = await axios
        .get(`${API_URL}/BoardTypes`)
        .then(async (response) => {
          return response.data;
        });
      return response;
    } catch (error: unknown) {
      return error;
    }
  },
);
