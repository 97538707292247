import axios from "axios";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2/MediaController";

export const UploadMedia = async (bucketName: string, file: File) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data;",
      },
    };
    const bodyFormData = new FormData();
    bodyFormData.append("file", file);
    const response = await axios.post(
      `${API_URL}/upload?bucketName=${bucketName}`,
      bodyFormData,
      config,
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const DeleteMedia = async (mediaId: number) => {
  try {
    const response = await axios.delete(`${API_URL}/${mediaId}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
