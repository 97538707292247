import React from "react";
import { useSelector } from "react-redux";
import { ModalProvider } from "styled-react-modal";
import Modal from "../../../Components/modals/Modals";
import { DeviceList } from "../../../Features/tabs/device-manager/device-list/DeviceList";
import DeviceManagerFilterOptions from "../../../Components/filter-options/DeviceManagerFilterOptions";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { RootState } from "../../../Store/store";
import DeviceCard from "../DeviceCard";
import { SwrmDevices } from "../../../Interfaces/Device/SwrmDevice";
import { ImageCardsWrapper } from "../../../Components/modals/Modals.style";
import {
  FetchParamsForDevices,
  resetSearchParams,
  setDeviceParameters,
} from "../../../Features/Slice/fetchParameters/fetchParametersSlice";
import { resetSortByState } from "../../../Components/sort-by/sortBySlice";
import { resetDeviceStatusRadio } from "../../../Components/radio-button/radioSlice";
import { filterDevices } from "../../../Services/DeviceManagement/deviceGet.service";

const DeviceCarouselList = () => {
  const dispatch = useAppDispatch();
  const searchParams = useAppSelector(
    (state: RootState) => state.fetchParams.DeviceSearchParams,
  );
  const device = useSelector((state: any) => state.devices);

  const filterData = (searchParams: FetchParamsForDevices) => {
    dispatch(setDeviceParameters(searchParams));
    dispatch(filterDevices(searchParams));
  };

  const onClose = () => {
    // Reset queried data
    const searchDeviceParams = {
      ...searchParams,
      SearchedName: "",
    };

    filterData(searchDeviceParams);
    dispatch(resetDeviceStatusRadio());
    dispatch(resetSearchParams({ page: "DeviceManager" }));
    dispatch(resetSortByState());
  };
  return (
    <>
      <ModalProvider>
        <Modal
          buttonLabel={"Filter Devices"}
          listTitle="Device List"
          onClose={onClose}
        >
          <>
            <DeviceManagerFilterOptions />
            <ImageCardsWrapper>
              {device.allDevicesForModal.map(
                (d: SwrmDevices, index: number) => (
                  <DeviceCard key={d.Id} device={d} onClick={() => undefined} />
                ),
              )}
            </ImageCardsWrapper>
          </>
        </Modal>
      </ModalProvider>
      <DeviceList deviceList={device.data} />
    </>
  );
};

export default DeviceCarouselList;
