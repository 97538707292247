import styled from "styled-components";
import { TextFieldProps } from "./TextField";

export const CustomTextField = styled.input<
  Pick<TextFieldProps, "variant" | "dataPlaceholder">
>`
  outline: none;
  font-size: ${(props) => props.theme.fontSizes["xs"]};
  background-color: #020a01;

  //set the background color and font color for browser autofill feature.
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px #020a01 inset !important;
    -webkit-text-fill-color: white !important;
  }
  font-weight: bold;
  padding: 8px;
  padding-left: 12px;
  margin: 4px 0;
  border-radius: 4px;
  width: 100%;
  // height: 40px;
  height: 2.25rem;
  &::placeholder {
    color: ${(props) => props.theme.colors["grey-800"]};
  }

  &:hover {
    border: ${(props) => props.theme.border["secondary-md"]};
  }
  background-color: ${({ color, variant }) => {
    if (variant !== "filled") return "transparent";
    switch (color) {
      case "secondary":
        return "#020A01";
      case "danger":
        return "red-300";
      case "success":
        return "green-100";
      default:
        return "#020A01";
    }
  }};

  border: ${({ variant, theme }) => {
    if (variant !== "outlined") return theme.border["primary-sm"];
    return "none";
  }};

  color: ${({ variant, theme }) => {
    if (variant !== "outlined") return theme.colors["grey-500"];
    return "none";
  }};
`;
