import React, { useEffect, useState } from "react";
import Titles from "../../Components/title/Titles";
import DashboardDropdowns from "./DashboardDropdowns";
import WeeklyChartSection from "./WeeklyChartSection";
import GaugeChartSection from "./GaugeChartSection";
import { Typography } from "../../Components/typrography/Typography";

// DashboardContent component for rendering main content
const DashboardContent: React.FC = () => {
  const [selectedDeviceOpt, setSelectedDeviceOpt] = useState<string>("");
  const [selectedLocationOpt, setSelectedLocationOpt] = useState<number>(
    Number(null),
  );

  return (
    <>
      {/* Dashboard Title and Subtitle */}
      <Titles
        heading="Dashboard Stats"
        subheading="Track and process Data within the Data Centre"
      />

      {/* Dropdowns for Dashboard */}
      <DashboardDropdowns
        setDeviceId={setSelectedDeviceOpt}
        setLocationId={setSelectedLocationOpt}
      />

      {/* Chart Section for Gauges */}
      <div>
        <Typography
          variant="3xl"
          color="primary"
          weight="bold"
          padding="0 2rem"
        >
          Recent Data
        </Typography>
        <GaugeChartSection
          selectedLocation={selectedLocationOpt}
          selectedDevice={selectedDeviceOpt?.toString()}
        />
      </div>

      {selectedDeviceOpt ? (
        /*
          Weekly Chart Section for selected device only.

          Will however be passing the selected location
          in order to determine if the selected location and device
          share a relationship in the database.
        */
        <div>
          <Typography
            variant="3xl"
            color="primary"
            weight="bold"
            padding="0 2rem"
          >
            Weekly Data
          </Typography>
          <WeeklyChartSection
            selectedLocation={selectedLocationOpt}
            selectedDevice={selectedDeviceOpt?.toString()}
          />
        </div>
      ) : null}
    </>
  );
};
export default DashboardContent;
