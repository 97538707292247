import {
  IntegrationLoginContainer,
  IntegrationLoginFormContainer,
} from "./styles/IntegrationLogin.style";
import SwrmNavLogo from "../../Assets/SWRM_Logo_512x80_Yellow.png";
import { Typography } from "../../Components/typrography/Typography";
import { Formik } from "formik";
import * as Yup from "yup";
import Input from "../../Components/input/Input";
import { FlexContainer } from "../../Components/flex-container/FlexContainer";
import { ScaledButtonWrapper } from "../../Components/modals/CustomModal.style";
import { RootState } from "../../Store/store";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import {
  AuthenticatePayload,
  integrationService,
} from "../../Services/Integration/integration.service";
import { OpenInfoNotification } from "../../Components/notification/Notification";
import { StyledFormikForm } from "../../Components/tab/ai-model-manager/Upload.style";
import { useEffect, useState } from "react";
import { setRouteOption } from "../../Features/Slice/routes/routesSlice";
import {
  activeNavLink,
  resetNavLink,
} from "../../Components/nav/side-nav/sideNavBarSlice";
import { useNavigate } from "react-router-dom";
import {
  AvatarWrapper,
  NavContainer,
  ProfileDropDown,
  TextWrapper,
} from "../../Components/nav/top-nav/TopNavBar.style";
import { DropdownWrapper } from "../../Components/filter-options/FilterOptions.style";
import { Image } from "../../Components/image/Image";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoMdLogOut } from "react-icons/io";
import { userLogout } from "../../Features/Slice/auth/authSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { DefaultThemeFontSize } from "../../app/Theme";
import { awsUtils } from "../../Utils/awsUtils";

interface FormValues {
  username: string;
  password: string;
}

const initialValues: FormValues = {
  username: "",
  password: "",
};

const validationSchema = Yup.object({
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
});

const IntegrationLogin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user, logout } = useAuth0();
  const auth = useAppSelector((state: RootState) => state.persisted.auth);
  const { integrationAccessToken, integrationRefreshToken } = auth.tokens;

  const handleSubmit = async (values: FormValues) => {
    try {
      setIsLoading(true);
      const payload: AuthenticatePayload = {
        Username: values.username,
        Password: values.password,
        IntegratedWith: auth.company.IntegratedWith,
      };

      await dispatch(integrationService.authenticate(payload));
      setIsLoading(false);
    } catch (error) {
      console.error("handelSubmit Error:", error);
    }
  };

  const handleLogout = () => {
    logout();
    dispatch(userLogout());
    dispatch(resetNavLink());
    dispatch(setRouteOption(0));
  };

  // Complete Authentication
  useEffect(() => {
    if (integrationAccessToken !== "" && integrationRefreshToken !== "") {
      dispatch(setRouteOption(2));
      dispatch(activeNavLink(1));
      navigate("/dashboard");
    }
  }, [integrationRefreshToken, integrationAccessToken]);

  return (
    <>
      <NavContainer size="90%">
        <ProfileDropDown>
          <DropdownWrapper>
            <AvatarWrapper>
              <Image
                src={
                  awsUtils.getSignedImageURL(
                    auth?.userInfo?.MediaURL || user?.picture || "",
                  ) || "default-image-url.jpg"
                }
                alt="User Image"
                height="50px"
                width="50px"
              />
            </AvatarWrapper>
            <div className="arrow-icon">
              <MdKeyboardArrowDown size={"36px"} />
            </div>
          </DropdownWrapper>
          <nav style={{ minWidth: "15vw", top: "53px" }}>
            <a href="/" onClick={handleLogout} style={{ width: "100%" }}>
              <IoMdLogOut
                size={DefaultThemeFontSize.lg}
                style={{ marginRight: "5px" }}
              />
              <Typography variant="sm">LOG OUT</Typography>
            </a>
          </nav>
        </ProfileDropDown>
      </NavContainer>
      <IntegrationLoginContainer>
        <IntegrationLoginFormContainer>
          <div>
            <img
              src={SwrmNavLogo}
              alt="SWRM Logo"
              style={{ width: "14vw" }}
            ></img>
          </div>
          <div style={{ textAlign: "center" }}>
            <Typography>
              Enter your {auth.company.IntegratedWith} integration credentials
            </Typography>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleBlur, handleChange, errors }: any) => (
              <StyledFormikForm id="integration-authentication">
                <FlexContainer flexDirection="column" gap="0.5rem">
                  <div style={{ position: "relative" }}>
                    <Input
                      type="text"
                      name="username"
                      placeHolder="Username"
                      label={""}
                      width="18rem"
                      margin="0 auto 22px"
                      padding="0 10px 0"
                      value={values.username}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>

                  <div style={{ position: "relative" }}>
                    <Input
                      type="password"
                      name="password"
                      placeHolder="Password"
                      label={""}
                      width="18rem"
                      margin="0 auto 22px"
                      padding="0 10px 0"
                      value={values.password}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>

                  <FlexContainer
                    gap="1rem"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ScaledButtonWrapper buttonPadding={"10px"}>
                      <button type="submit" form="integration-authentication">
                        {isLoading ? "Logging in..." : "Login"}
                      </button>
                    </ScaledButtonWrapper>
                  </FlexContainer>
                </FlexContainer>
              </StyledFormikForm>
            )}
          </Formik>
        </IntegrationLoginFormContainer>
      </IntegrationLoginContainer>
    </>
  );
};

export default IntegrationLogin;
