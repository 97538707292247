import React, { useState } from "react";
import { Button } from "../../button/Button";
import { CustomDropdown } from "../../form-field/drop-down/CustomDropdown";
import { FormControl } from "../../form-field/form-control/FormControl";
import { TextField } from "../../form-field/text-field/TextField";
import { Typography } from "../../typrography/Typography";
import { CustomCardAction } from "./CardAction.style";
import { CardActionType } from "../../../Types/device-manager/card-types";
import { defaultOptions } from "../../../data/dropdown";

export interface CardActionProps {
  action: CardActionType;
}

export const CardAction: React.FC<CardActionProps> = ({ action }) => {
  const {
    dropdown,
    noOfDropdown,
    placeholder,
    placeholderHelper,
    noOfTextField,
    textfield,
    button,
    noOfButton,
    index,
  } = action;
  return (
    <CustomCardAction key={index}>
      {dropdown &&
        [...Array(noOfDropdown)].map((_, index) => {
          return (
            <div style={{ minWidth: "100%" }} key={index}>
              <CustomDropdown
                alignItems="start"
                label="some label"
                color="secondary"
                options={defaultOptions}
              />
            </div>
          );
        })}

      {textfield &&
        [...Array(noOfTextField)].map((_, index) => (
          <div style={{ position: "relative" }}>
            <FormControl>
              <TextField
                key={index}
                id={_}
                label=""
                placeholder={
                  placeholder && placeholder[index % placeholder.length]
                }
              />
            </FormControl>
            <div
              style={{
                position: "absolute",
                top: 16,
                right: 8,
                color: "white",
              }}
            >
              <Typography color="white" weight="light" variant="xs">
                {placeholderHelper &&
                  placeholderHelper[index % placeholderHelper.length]}
              </Typography>
            </div>
          </div>
        ))}

      {button &&
        [...Array(noOfButton)].map((_, index) => (
          <Typography key={index} direction="center" style={{ width: "120px" }}>
            <Button fullWidth>Alerts</Button>
          </Typography>
        ))}
    </CustomCardAction>
  );
};
