import React from "react";
import { Tooltip } from "react-tooltip";
import { StyledViewDevicesIcon } from "../tables/Table.style";

interface ViewDevicesIconProps {
  handleViewDevicesOnClick: () => void;
}

const ViewDevicesIcon: React.FC<ViewDevicesIconProps> = ({
  handleViewDevicesOnClick,
}) => {
  return (
    <>
      <StyledViewDevicesIcon
        data-tooltip-id="view-devices-tooltip"
        data-tooltip-content="View Devices"
        data-tooltip-place="top"
        onClick={handleViewDevicesOnClick}
      />
      <Tooltip id="view-devices-tooltip" />
    </>
  );
};

export default ViewDevicesIcon;
