import axios from "axios";
import { OpenErrorNotification } from "../../Components/notification/Notification";
import { CreateRoutinePayload } from "../../Interfaces/Device/Routine";
import { createAsyncThunk } from "@reduxjs/toolkit";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

export const getRoutines = createAsyncThunk(
  "device/getDeviceRoutines",
  async (deviceId: string) => {
    let response = await axios
      .get(`${API_URL}/Routine?deviceId=${deviceId}`)
      .then(async (response) => {
        await console.log(response.data);
        return response.data;
      })
      .catch((err: any) => {
        OpenErrorNotification(err.response.data.ErrorMessage);
        return err.response.data;
      });
    return response;
  },
);

export const getRoutineSteps = createAsyncThunk(
  "device/getDeviceRoutineSteps",
  async (deviceId: string) => {
    let response = await axios
      .get(`${API_URL}/Routine/getSteps?deviceId=${deviceId}`)
      .then(async (response) => {
        await console.log(response.data);
        return response.data;
      })
      .catch((err: any) => {
        OpenErrorNotification(err.response.data.ErrorMessage);
        return err.response.data;
      });
    return response;
  },
);

export const getRoutineStepRelays = createAsyncThunk(
  "device/getDeviceRoutineStepRelays",
  async (deviceId: string) => {
    let response = await axios
      .get(`${API_URL}/Routine/getRoutineStepRelays?deviceId=${deviceId}`)
      .then(async (response) => {
        await console.log(response.data);
        return response.data;
      })
      .catch((err: any) => {
        OpenErrorNotification(err.response.data.ErrorMessage);
        return err.response.data;
      });
    return response;
  },
);

const createRoutine = async (payload: CreateRoutinePayload) => {
  let response = await axios
    .post(`${API_URL}/Routine`, payload)
    .then(async (response) => {
      await console.log(response.data);
      return response.data;
    })
    .catch((err: any) => {
      OpenErrorNotification(err.response.data.ErrorMessage);
      return err.response.data;
    });
  return response;
};

const alterRoutine = async (payload: CreateRoutinePayload) => {
  let response = await axios
    .post(`${API_URL}/Routine/alter`, payload)
    .then(async (response) => {
      await console.log(response.data);
      return response.data;
    })
    .catch((err: any) => {
      OpenErrorNotification(err.response.data.ErrorMessage);
      return err.response.data;
    });
  return response;
};

const updateRoutineStatus = async (deviceId: string, status: boolean) => {
  let response = await axios
    .patch(
      `${API_URL}/Routine/updateStatus?deviceId=${deviceId}&status=${status}`,
    )
    .then(async (response) => {
      await console.log(response.data);
      return response.data;
    })
    .catch((err: any) => {
      OpenErrorNotification(err.response.data.ErrorMessage);
      return err.response.data;
    });
  return response;
};

export const deleteRoutine = createAsyncThunk(
  "device/deleteDeviceRoutine",
  async (deviceId: string) => {
    let response = await axios
      .delete(`${API_URL}/Routine/?deviceId=${deviceId}`)
      .then(async (response) => {
        await console.log(response.data);
        return response.data;
      })
      .catch((err: any) => {
        OpenErrorNotification(err.response.data.ErrorMessage);
        return err.response.data;
      });
    return response;
  },
);

const routineService = {
  createRoutine,
  alterRoutine,
  updateRoutineStatus,
};

export default routineService;
