import styled from "styled-components";

interface CustomToggleButtonProps {
  margin?: string;
  checked?: boolean;
}

export const CustomToggleButton = styled.label<CustomToggleButtonProps>`
  cursor: pointer;
  text-indent: -9999px;
  width: 38px;
  height: 19px;
  background: ${({ checked }) => (checked ? "#80D39B" : "#4B4B4B")};
  display: block;
  border-radius: 100px;
  position: relative;
  margin: ${({ margin }) => (margin ? margin : "20px")};
  &:after {
    content: "";
    position: absolute;
    left: ${({ checked }) => (checked ? "calc(57%)" : "0px")};
    top: 2px;
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }
`;
