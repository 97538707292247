import React from "react";
import ImageCard from "../../Components/image-card/ImageCard";
import deviceImage2 from "../../Assets/device2.png";
import { SwrmDevices } from "../../Interfaces/Device/SwrmDevice";
import { ControlButtons } from "../../Components/device-manager-card/image-card/ImageCard.style";
import { Chip } from "../../Components/chip/Chip";
import { ChipStyles } from "../../Interfaces/Components/ChipEnums";
import { FlexContainer } from "../../Components/flex-container/FlexContainer";
import { SecondaryHeader } from "../../Components/image-card/ImageCard.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleXmark,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { DefaultThemeColor } from "../../app/Theme";

// Define props interface
interface CardProps {
  device: SwrmDevices;
  className?: string;
  onClick?: any;
}
export const getDeviceStatus = (upToDate: any, connectionStatus: any) => {
  return (
    upToDate && connectionStatus ? "Online"
    : !upToDate && connectionStatus ? "Out of Date"
    : "Offline"
  );
};

export const getDeviceWifiStatus = (wifiStatus: boolean) => {
  return wifiStatus ? "Connected" : "Disconnected";
};

// Define functional component
const DeviceCard: React.FC<CardProps> = ({ className, device, onClick }) => {
  const renderDeviceStatus = () => {
    let status = getDeviceStatus(device.UpToDate, device.ConnectionStatus);
    return (
      <SecondaryHeader>
        {status}{" "}
        {
          <FontAwesomeIcon
            icon={
              status === "Online" ? faCheckCircle
              : status === "Out of Date" ?
                faExclamationCircle
              : faCircleXmark
            }
            color={
              status === "Online" ? "#80D39B"
              : status === "Out of Date" ?
                DefaultThemeColor["primary"]
              : "#D64550"
            }
          />
        }
      </SecondaryHeader>
    );
  };
  return (
    <ImageCard
      className={`device-card ${className || ""}`}
      image={device.Thumbnail || deviceImage2}
      title={device.Name || ""}
      subtitle={device.PiSerial}
      onClick={onClick}
    >
      <ControlButtons>
        <Chip
          children={
            device.Deployed ? ChipStyles.Deployed : ChipStyles.Undeployed
          }
          backgroundColor={device.Deployed ? "blue" : "grey"}
        />
      </ControlButtons>

      {/* card footer */}
      <FlexContainer width="100%" justifyContent="space-between">
        {renderDeviceStatus()}
      </FlexContainer>
    </ImageCard>
  );
};
export default DeviceCard;
