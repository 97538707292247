import { IDropdown } from "./faqAndHelpDropdowns";

const securitySettingDropdowns: IDropdown[] = [
  {
    id: 1,
    menuType: "securitySettings",
    question: "Enable Two-Factor Authentication",
    answer: "",
  },
  {
    id: 2,
    menuType: "securitySettings",
    question: "Receive SMS Log In PIN",
    answer: "",
  },
  {
    id: 3,
    menuType: "securitySettings",
    question: "Use Security Questions",
    answer: "",
  },
  {
    id: 4,
    menuType: "recoverySettings",
    question: "Set Security Questions",
    answer: "",
  },
];

export default securitySettingDropdowns;
