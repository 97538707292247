import React from "react";
import { CustomTabList } from "./TabList.style";

export interface TabListProps {
  children: React.ReactNode;
  variant?: "pageTab" | "subPageTab" | "chartTab";
  justifyContent?: string;
  tabsDirection?: string;
}
export const TabList: React.FC<TabListProps> = ({
  children,
  variant,
  justifyContent,
  tabsDirection,
}) => {
  return (
    <CustomTabList
      variant={variant}
      justifyContent={justifyContent}
      tabsDirection={tabsDirection}
    >
      {children}
    </CustomTabList>
  );
};
