import styled from "styled-components";
import { navigationBackgroundProps } from "./NavigationBackground";

export const CustomNavigationBackground = styled.div<
  Pick<navigationBackgroundProps, "direction">
>`
  position: absolute;
  z-index: 1;
  top: 0;

  background: transparent;
  height: 100%;
  width: 7rem;
  display: flex;
  justify-content: ${(props) =>
    props.direction === "left" ? "flex-start" : "flex-end"};
  align-items: center;
  left: ${(props) => props.direction === "left" && "0"};
  right: ${(props) => props.direction === "right" && "0"};

  &:hover {
    background: ${(props) =>
      props.direction === "left"
        ? `linear-gradient(to right, black, transparent)`
        : `linear-gradient(to left, black, transparent)`};
  }
`;
