import axios from "axios";
import { Relay, RelayRule } from "../../Interfaces/Device/Relay";
import { OpenErrorNotification } from "../../Components/notification/Notification";
import { createAsyncThunk } from "@reduxjs/toolkit";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

const addRelayValue = async (relayId: number, value: boolean) => {
  let response = await axios
    .post(`${API_URL}/RelayValue`, { relayId, value })
    .then(async (response) => {
      await console.log(response.data);
      return response.data;
    })
    .catch((err: any) => {
      OpenErrorNotification(err.response.data.ErrorMessage);
      return err.response.data;
    });
  return response;
};

const updateRelay = async (payload: Relay) => {
  let response = await axios
    .patch(`${API_URL}/Relay`, payload)
    .then(async (response) => {
      await console.log(response.data);
      return response.data;
    })
    .catch((err: any) => {
      OpenErrorNotification(err.response.data.ErrorMessage);
      return err.response.data;
    });
  return response;
};
const getRelayRuleByRelay = async (relayId: number) => {
  let response = await axios
    .get(`${API_URL}/RelayRules/by?relayId=${relayId}`)
    .then(async (response) => {
      await console.log(response.data);
      return response.data;
    });
  return response;
};
const addRelayRule = async (payload: RelayRule) => {
  let response = await axios
    .post(`${API_URL}/RelayRules`, payload)
    .then(async (response) => {
      await console.log(response.data);
      return response.data;
    })
    .catch((err: any) => {
      OpenErrorNotification(err.response.data.ErrorMessage);
      return err.response.data;
    });
  return response;
};
const updateRelayRule = async (payload: RelayRule) => {
  let response = await axios
    .patch(`${API_URL}/RelayRules`, payload)
    .then(async (response) => {
      await console.log(response.data);
      return response.data;
    })
    .catch((err: any) => {
      OpenErrorNotification(err.response.data.ErrorMessage);
      return err.response.data;
    });
  return response;
};
const deleteRelayRule = async (ruleId: number) => {
  let response = await axios
    .delete(`${API_URL}/RelayRules?id=${ruleId}`)
    .then(async (response) => {
      await console.log(response.data);
      return response.data;
    })
    .catch((err: any) => {
      OpenErrorNotification(err.response.data.ErrorMessage);
      return err.response.data;
    });
  return response;
};

export const syncRelays = createAsyncThunk(
  "device/syncRelays",
  async (deviceId: string) => {
    try {
      let response = await axios
        .get(`${API_URL}/Relay/sync?deviceId=${deviceId}`)
        .then(async (response) => {
          await console.log(response.data);
          return response.data;
        })
        .catch((err: any) => {
          OpenErrorNotification(err.response.data.ErrorMessage);
          return err.response.data;
        });
      return response;
    } catch (error: unknown) {
      return error;
    }
  },
);

const relayService = {
  addRelayValue,
  updateRelay,
  getRelayRuleByRelay,
  addRelayRule,
  updateRelayRule,
  deleteRelayRule,
  syncRelays,
};

export default relayService;
