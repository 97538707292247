import { IoMdCloseCircle } from "react-icons/io";
import styled from "styled-components";
import { DragUploadProps } from "./DragUpload";

export const DropZone = styled.div<
  Pick<DragUploadProps, "width" | "height" | "color" | "flex">
>`
  width: ${({ width }) => (width ? width : "90%")};
  height: ${({ height }) => (height ? height : "90%")};
  flex: ${({ flex }) => (flex ? flex : "auto")};
  display: flex;
  border: 2px dashed ${(props) => props.theme.colors["primary"]};
  padding: 20px;
  color: ${({ color, theme }) => (color ? color : theme.colors["primary"])};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

export const FileIconWrapper = styled.div`
  display: flex;
  border: 1px solid #ffffff;
  border-radius: 7px;
  margin: 10px;
  padding: 10px;
  position: relative;
`;

export const StyledIoMdCloseCircle = styled(IoMdCloseCircle)`
  font-size: ${(props) => props.theme.fontSizes["2xl"]};
  position: absolute;
  right: -20px;
  top: -10px;
  color: #d64550;
`;
export const FileName = styled.p`
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: inherit;
  word-break: break-all;
`;
