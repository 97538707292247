import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { PhoneInputLabel, PhoneInputWrapper } from "./PhoneNumberInput.style";
import "./PhoneNumberInput.css";
import { DefaultThemeBorder, DefaultThemeFontSize } from "../../app/Theme";
import { useField } from "formik";
import { ErrorMsg } from "../input/Input.style";

interface PhoneNumberInputProps {
  name: string;
  label: string;
  onChange?: (value: string) => void;
}

const PhoneNumberInput = ({
  name,
  label,
  onChange,
  ...props
}: PhoneNumberInputProps) => {
  const [field, meta] = useField(name);

  const handleChange = (value: string) => {
    field.onChange({ target: { name, value } });
    if (onChange) onChange(value);
  };

  const handleBlur = () => {
    field.onBlur({ target: { name } });
  };

  return (
    <PhoneInputWrapper id="phone-input-wrapper">
      <PhoneInputLabel htmlFor={name}>{label}</PhoneInputLabel>
      <PhoneInput
        {...field}
        {...props}
        onChange={(value) => handleChange(value)}
        onBlur={handleBlur}
        country={"ca"}
        onlyCountries={["ca", "us"]}
        dropdownClass="dropdown"
        dropdownStyle={{
          backgroundColor: "#020A01",
          color: "#FFF",
          border: DefaultThemeBorder["border-sm-yellow"],
          borderRadius: "5px",
          padding: "0.188rem 0",
          fontSize: DefaultThemeFontSize.sm,
        }}
        buttonStyle={{
          backgroundColor: "#020A01",
          border: DefaultThemeBorder["border-sm-yellow"],
        }}
        inputStyle={{
          backgroundColor: "#020A01",
          height: "2.25rem",
          width: "100%",
          fontSize: DefaultThemeFontSize.sm,
          color: "#FFF",
          outline: "none",
          border: DefaultThemeBorder["border-sm-yellow"],
          borderRadius: "5px",
        }}
      />
      {meta.touched && meta.error && <ErrorMsg>{meta.error}</ErrorMsg>}
    </PhoneInputWrapper>
  );
};

export default PhoneNumberInput;
