import styled from "styled-components";
import { CustomGrid } from "../CustomModal.style";

export const DeployDeviceContent = styled(CustomGrid)`
  padding: 0 20px;
  width: 100%;
`;

export const DeviceInfoWrapper = styled.div`
  padding: 1rem;
`;
// export const SensorWrapper = styled.div`
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
//   grid-gap: 2rem 1.25rem;
//   width: 100%;
//   padding: 1rem;
// `;
