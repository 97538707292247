import styled from "styled-components";

export const CustomImageCarousel = styled.div`
  display: flex;
  gap: 8px;
  padding: 20px;
  white-space: nowrap;
  position: relative;
  background-color: #020a01;
  width: 100%;
`;
