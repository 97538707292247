import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import JSZip from "jszip";
import { ResponseObject } from "../../Interfaces/Response";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";
var qs = require("qs");
export interface LabelFile {
  labelFile: File;
}

export interface aiModelUploadParams {
  aiModelFile: File | null;
  name: string;
  /* Commented out for the time being, will re-implement if need be */
  // labelFiles: File[];
  imageFile: File | null;
  description: string;
  manualLabel: string;
}

export interface aiModelEditParams {
  id: number;
  aiModelFile: File | null;
  name: string;
  /* Commented out for the time being, will re-implement if need be */
  // labelFiles: File[];
  imageFile: File | null;
  description: string;
}

export interface aiModelFilterParams {
  dateCreated?: string;
  deviceId?: string | number;
  orderByDesc?: boolean;
}

export const getAllAIModels = createAsyncThunk(
  "device/getAllAIModels",
  async () => {
    try {
      let response = await axios.get(`${API_URL}/AIModels`);
      // await console.log("Get All AI Models API:", response.data);
      return response.data;
    } catch (error: unknown) {
      return error;
    }
  },
);

export const filterAIModels = createAsyncThunk(
  "aiModels/filterAIModels",
  async (params: aiModelFilterParams) => {
    try {
      let response = await axios
        .get(
          `${API_URL}/AIModels/fordropdown?dateCreated=${params.dateCreated}&deviceId=${params.deviceId}&orderByDesc=${params.orderByDesc}`,
        )
        .then(async (response) => {
          return await response.data;
        })
        .catch(async (error) => {
          return error.response.data;
        });

      return response;
    } catch (error) {
      return error;
    }
  },
);

export const downloadAIModel = async (aiModelId: number) => {
  try {
    /*
      Response is expected to be an arraybuffer. But we are building 
      the default ResponseObject here for rendering error messages 
      and providing an easier validation flow
    */
    const response = await axios
      .get(`${API_URL}/AIModels/download?aiModelId=${aiModelId}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const zip = new JSZip();
        return zip.loadAsync(response.data);
      })
      .then(async (zip) => {
        const newZip = new JSZip();
        const promises: any = [];
        zip.forEach((relativePath, zipEntry) => {
          const promise = zipEntry.async("arraybuffer").then((fileContent) => {
            newZip.file(relativePath, fileContent, { binary: true });
          });

          promises.push(promise);
        });

        return Promise.all(promises).then(() => newZip);
      })
      .then(async (newZip) => {
        return newZip.generateAsync({ type: "arraybuffer" });
      })
      .then(async (newZipContent) => {
        const blob = new Blob([newZipContent]);

        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = "model.zip";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        const response: ResponseObject<string> = {
          IsError: false,
          ErrorCode: 200,
          ErrorMessage: "",
          Result: "AI Model downloaded successfully",
        };

        return response;
      })
      .catch((error) => {
        const errorResponse: ResponseObject<string> = {
          IsError: true,
          ErrorCode: 0,
          ErrorMessage: "",
          Result: "",
        };

        switch (error.response.status) {
          case 404:
            errorResponse.ErrorCode = 404;
            errorResponse.ErrorMessage =
              "Model not found. Please check your database and AWS S3 bucket";
            break;
          case 500:
            errorResponse.ErrorCode = 500;
            errorResponse.ErrorMessage = "There was an internal server error";
            break;
        }

        return errorResponse;
      });

    return response;
  } catch (error) {
    return error;
  }
};

export const getAIModelDownloadURL = async (aiModelId: number) => {
  try {
    const response = await axios
      .get(`${API_URL}/AIModels/downloadToDevice?aiModelId=${aiModelId}`)
      .then(async (response) => {
        return await response.data;
      })
      .catch(async (error) => {
        return error.response.data;
      });

    return response;
  } catch (error) {
    return error;
  }
};

export const uploadAIModel = async (params: aiModelUploadParams) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data;",
      },
    };

    const formData = new FormData();
    formData.append("Name", params.name.trim());
    formData.append("ManualLabel", params.manualLabel.trim());
    formData.append("Description", params.description.trim()!);
    formData.append("AIModelFile", params.aiModelFile!);
    formData.append("ImageFile", params.imageFile!);

    /* Commented out for the time being, will re-implement if need be */
    // params.labelFiles.forEach((file: File) => {
    //   formData.append("LabelFiles", file);
    // });

    const response = await axios
      .post(`${API_URL}/AIModels/upload`, formData, config)
      .then(async (response) => {
        return await response.data;
      })
      .catch(async (error) => {
        return error.response.data;
      });

    return response;
  } catch (error) {
    return error;
  }
};

export const editAIModel = async (payload: aiModelEditParams) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data;",
      },
    };

    const formData = new FormData();
    formData.append("Name", payload.name.trim());
    formData.append("Description", payload.description.trim()!);
    formData.append("AIModelFile", payload.aiModelFile!);
    formData.append("ImageFile", payload.imageFile!);

    /* Commented out for the time being, will re-implement if need be */
    // payload.labelFiles.forEach((file: File) => {
    //   formData.append("LabelFiles", file);
    // });

    const response = await axios
      .patch(`${API_URL}/AIModels`, payload, config)
      .then(async (response) => {
        return await response.data;
      })
      .catch(async (error) => {
        return error.response.data;
      });

    return response;
  } catch (error) {
    return error;
  }
};

export const duplicateAIModel = async (id: number) => {
  try {
    const response = await axios
      .post(`${API_URL}/AIModels/duplicate?id=${id}`)
      .then(async (response) => {
        return await response.data;
      })
      .catch(async (error) => {
        return error.response.data;
      });

    return response;
  } catch (error) {
    return error;
  }
};

export const bulkDownloadAIModels = async (ids: number[]) => {
  try {
    /*
      Response is expected to be an arraybuffer. But we are building 
      the default ResponseObject here for rendering error messages 
      and providing an easier validation flow
    */
    const queryParams = ids.join(",");
    console.log(queryParams);
    const response = await axios
      .get(`${API_URL}/AIModels/bulkDownload?aiModelIds=${queryParams}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data]);

        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = "models.zip";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        const successResponse: ResponseObject<string> = {
          IsError: false,
          ErrorCode: 200,
          ErrorMessage: "",
          Result: "AI Models downloaded successfully",
        };

        return successResponse;
      })
      .catch((error) => {
        const errorResponse: ResponseObject<string> = {
          IsError: true,
          ErrorCode: 0,
          ErrorMessage: "",
          Result: "",
        };

        switch (error.response.status) {
          case 404:
            errorResponse.ErrorCode = 404;
            errorResponse.ErrorMessage =
              "Models not found. Please check your database and AWS S3 bucket";
            break;
          case 500:
            errorResponse.ErrorCode = 500;
            errorResponse.ErrorMessage = "There was an internal server error";
            break;
        }

        return errorResponse;
      });

    return response;
  } catch (error) {
    return error;
  }
};

export const bulkDeleteAIModels = async (ids: number[]) => {
  try {
    const response = await axios
      .patch(`${API_URL}/AIModels/bulkDelete`, ids, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        return response.data;
      })
      .catch(async (error) => {
        return error.response.data;
      });

    return response;
  } catch (error) {
    return error;
  }
};

export const bulkArchiveAIModels = async (ids: number[]) => {
  try {
    const response = await axios
      .patch(`${API_URL}/AIModels/bulkArchive`, ids, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        return response.data;
      })
      .catch(async (error) => {
        return error.response.data;
      });

    return response;
  } catch (error) {
    return error;
  }
};

export const deleteAIModel = async (id: number) => {
  try {
    const response = await axios
      .patch(`${API_URL}/AIModels/delete?id=${id}`)
      .then(async (response) => {
        return await response.data;
      })
      .catch(async (error) => {
        return error.response.data;
      });

    return response;
  } catch (error) {
    return error;
  }
};

export const archiveAIModel = async (id: number) => {
  try {
    const response = await axios
      .patch(`${API_URL}/AIModels/archive?id=${id}`)
      .then(async (response) => {
        return await response.data;
      })
      .catch(async (error) => {
        return error.response.data;
      });

    return response;
  } catch (error) {
    return error;
  }
};

const AIModelService = {
  getAllAIModels,
  filterAIModels,
  downloadAIModel,
  archiveAIModel,
  getAIModelDownloadURL,
  uploadAIModel,
  duplicateAIModel,
  editAIModel,
  bulkDeleteAIModels,
  bulkArchiveAIModels,
  bulkDownloadAIModels,
  deleteAIModel,
};

export default AIModelService;
