import React from "react";
import { StyledFiArchive } from "../tables/Table.style";
import { Tooltip } from "react-tooltip";

interface ArchiveIconProps {
  handleArchiveOnClick: () => void;
  size?: string;
}

const ArchiveIcon: React.FC<ArchiveIconProps> = ({
  handleArchiveOnClick,
  size,
}) => {
  return (
    <>
      <StyledFiArchive
        onClick={handleArchiveOnClick}
        size={size}
        data-tooltip-id="archive-tooltip"
        data-tooltip-content="Archive"
        data-tooltip-place="top"
      />
      <Tooltip id="archive-tooltip" />
    </>
  );
};

export default ArchiveIcon;
