import React from "react";
import { CustomTabPanel } from "./TabPanel.style";

export interface TabPanelProps {
  children: React.ReactNode;
  tabPanelPadding?: string;
}
export const TabPanel: React.FC<TabPanelProps> = ({
  children,
  tabPanelPadding,
}) => {
  return (
    <CustomTabPanel tabPanelPadding={tabPanelPadding}>
      {children}
    </CustomTabPanel>
  );
};
