import React from "react";
import { HeadingStyle, SubheadingStyle, TitleWrapper } from "./Titles.styles";

// For props to be passed into <Titles /> component
export interface TitlesProps {
  heading: string;
  subheading: string;
  variant?: "normal";
  marginTop?: string;
  flex?: number;
}

//Title component that renders a Title with a subheading
const Titles: React.FC<TitlesProps> = (props) => {
  const { heading, subheading, variant, marginTop, flex } = props;
  return (
    <TitleWrapper variant={variant} marginTop={marginTop} flex={flex}>
      <HeadingStyle variant={variant}> {heading} </HeadingStyle>
      <SubheadingStyle variant={variant}> {subheading} </SubheadingStyle>
    </TitleWrapper>
  );
};

export default Titles;
