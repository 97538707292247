import styled from "styled-components";

export const CompanyOptionsContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #020a01;
`;

export const CompanyOptionsCardContainer = styled.div`
  width: 100%;
  padding-inline: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.875rem;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const CompanyOptionsTextContainer = styled.article`
  & > * {
    margin-bottom: 5px;
  }
`;
