import styled from "styled-components";

export const IconWrapper = styled.div`
  display: flex;
  background: white;
  width: 3.3vw;
  height: 3.4vh;
  border: 1px solid #e6e7e8;
  border-radius: 5%;
  align-items: center;
  justify-content: center;
`;
export const StyledMasterIcon = styled.img`
  width: 2vw;
`;
export const AmericanExpressWrapper = styled(IconWrapper)`
  width: 3.3vw;
  height: 3.4vh;
  background: #306fc5;
  border: 1px solid #306fc5;
`;
export const StyledAmericanExpressIcon = styled.img`
  width: 3.3vw;
  height: 4.4vh;
`;

export const InteracIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const StyledInteracIcon = styled.img`
  height: 3vh;
`;
