import styled from "styled-components";
import { LinkProps } from "./Link";

const StyledLink = styled.a<Pick<LinkProps, "color" | "hoverColor">>`
  color: ${({ color, theme }) => (color ? color : theme.colors["primary"])};

  text-decoration: underline;
  font-weight: bold;

  &:hover {
    color: ${({ color, theme }) => (color ? color : theme.colors["green-300"])};
  }
`;

export default StyledLink;
