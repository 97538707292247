import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentForm";
import { PaymentFormWrapper } from "./PaymentForm.style";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

const Payment: React.FC = () => {
  return (
    <PaymentFormWrapper>
      <Elements stripe={stripePromise}>
        <PaymentForm />
      </Elements>
    </PaymentFormWrapper>
  );
};

export default Payment;
