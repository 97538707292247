export const getId = () => Math.random().toString(16).slice(2);

/**
@description This function takes a URL as input and returns the URL with the AWS URL prepended to it.
@param {string} url - The URL to be modified.
@returns {string} - The modified URL with the AWS URL prepended to it. 
*/
export const getMediaURL = (url: string) => {
  const AWS_URL = process.env.REACT_APP_AWS_URL;
  if (!url) return "";
  return `${AWS_URL}${url}`;
};

/**\n * Takes in a number of bytes and returns a formatted string of its equivalent value in different units of storage size
 *
 bytes The number of bytes to convert

 decimals Optional. Number of decimals to round off the results to. Defaults to 2

  returns {string} A formatted string of the equivalent storage size of the provided number of bytes
*/
export function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
// Logs a storybook event to the console
export function logStoryBookEvent(functionName: string, e: any) {
  console.log(functionName, e);
}
