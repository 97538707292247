import styled from "styled-components";
import { DragUploadProps } from "./UploadInput";

export const UploadWrapper = styled.div<
  Pick<DragUploadProps, "padding" | "fontSize">
>`
  display: flex;
  flex-direction: column;
  font-size: ${({ fontSize }) =>
    fontSize ? fontSize : (props) => props.theme.fontSizes["lg"]};
  font-weight: bold;
  padding: ${({ padding }) => (padding ? padding : "0")};
`;

export const DropZone = styled.div<Pick<DragUploadProps, "width" | "height">>`
  width: ${({ width }) => (width ? width : "90%")};
  height: ${({ height }) => (height ? height : "90%")};
  display: flex;
  border: ${(props) => props.theme.border["primary-sm"]};
  padding: 10px;
  color: #b5b5b5;
  border-radius: 5px;
  margin: 5px 0;
  font-size: ${(props) => props.theme.fontSizes["sm"]};
  cursor: pointer;

  &:hover {
    border: ${(props) => props.theme.border["secondary-sm"]};
  }
`;

export const SelectedFileNameWrapper = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
`;
