import React, { useState } from "react";
import {
  RelayRule,
  RelayRuleAction,
  RuleComparisonToDisplay,
} from "../../../../Interfaces/Device/Relay";
import { Sensor } from "../../../../Interfaces/Device/Sensor";
import { Typography } from "../../../../Components/typrography/Typography";
import DeleteIcon from "../../../../Components/icons/DeleteIcon";
import { FlexContainer } from "../../../../Components/flex-container/FlexContainer";
import Checkbox from "../../../../Components/checkbox/Checkbox";
import EditIcon from "../../../../Components/icons/EditIcon";
import { DefaultThemeBorder } from "../../../../app/Theme";
import { defaultTheme } from "react-select";
interface Props {
  item: RelayRule;
  sensor: any;
  handleEditClick: (relayRule: RelayRule) => void;
  handleDeleteClick: (ruleId: number) => void;
  handleEnableDisabled: (rule: RelayRule, checked: boolean) => void;
}
export default function RelayRuleItem({
  item,
  sensor,
  handleEditClick,
  handleDeleteClick,
  handleEnableDisabled,
}: Props) {
  const onDeleteIconClick = () => {
    handleDeleteClick(item.Id);
  };
  const onEditIconClick = () => {
    handleEditClick(item);
  };
  const onCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleEnableDisabled(item, e.target.checked);
  };
  return (
    <div
      style={{
        padding: "0.5rem",
        border: DefaultThemeBorder["border-md-yellow"],
        borderRadius: "4px",
      }}
    >
      <FlexContainer justifyContent="space-between">
        {/*
          Comment out enable disable relay functionality.
          May or may not re-implement, but it is here
          if needed.
        */}
        {/* <div style={{ display: "flex" }}>
          <Checkbox isChecked={item.Enable} onChange={onCheckboxClick} />
        </div> */}
        <Typography>
          {`Turn ${RelayRuleAction[item.Action]} when ${
            sensor?.value || ""
          } is ${RuleComparisonToDisplay[item.Comparison]} ${item.Value}`}
        </Typography>
        <Typography variant="md" color="white" weight="bold">
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <EditIcon handleEditOnClick={onEditIconClick} size="26px" />
            <DeleteIcon handleDeleteOnClick={onDeleteIconClick} size="26px" />
          </div>
        </Typography>
      </FlexContainer>
    </div>
  );
}
