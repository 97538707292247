import axios from "axios";
import { Device } from "../../Interfaces/Device/Device";
import { createAsyncThunk } from "@reduxjs/toolkit";

const API_URL = process.env.REACT_APP_SWRMBE_URL + "api/v2";

const patchDevice = async (device: Device) => {
  try {
    let response = await axios
      .patch(`${API_URL}/Device`, device)
      .then(async (response) => {
        await console.log(response.data);
        return response.data;
      });
    return response;
  } catch (error: unknown) {
    return error;
  }
};
export interface DeviceDeployPayload {
  DeviceId: string;
  Name: string;
  MediaId: number;
  LocationId: number;
}
const patchDeviceDeployed = async (payload: DeviceDeployPayload) => {
  try {
    let response = await axios
      .patch(`${API_URL}/Device/deployDevice`, payload)
      .then(async (response) => {
        await console.log(response.data);
        return response.data;
      });
    return response;
  } catch (error: unknown) {
    return error;
  }
};
const patchDeviceUndeploy = async (deviceId: string) => {
  try {
    let response = await axios
      .patch(`${API_URL}/Device/undeployDevice`, { deviceId })
      .then(async (response) => {
        await console.log(response.data);
        return response.data;
      });
    return response;
  } catch (error: unknown) {
    return error;
  }
};

const patchDeviceEnabled = async (deviceId: string, enabled: boolean) => {
  try {
    let response = await axios
      .patch(`${API_URL}/Device/toggleEnabled`, {
        DeviceId: deviceId,
        Enabled: enabled,
      })
      .then(async (response) => {
        await console.log(response.data);
        return response.data;
      });
    return response;
  } catch (error: unknown) {
    return error;
  }
};

export const patchDeviceLocation = createAsyncThunk(
  "device/updateLocation",
  async (payload: { deviceId: string; locationId: number }) => {
    try {
      let response = await axios.patch(
        `${API_URL}/Device/updateLocation`,
        payload,
      );

      return response.data;
    } catch (error: unknown) {
      return error;
    }
  },
);

export const patchDeviceArchive = async (payload: {
  id: string;
  archive: boolean;
}) => {
  try {
    let response = await axios.patch(`${API_URL}/Device/archive`, payload);

    return response.data;
  } catch (error: unknown) {
    return error;
  }
};
const devicePatchService = {
  patchDevice,
  patchDeviceDeployed,
  patchDeviceEnabled,
  patchDeviceUndeploy,
  patchDeviceArchive,
};

export default devicePatchService;
