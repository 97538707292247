import styled from "styled-components";

export const RelayBoxBlock = styled.div`
  padding: 1rem;
  text-align: center;
  border-radius: 5px;
  border: ${({ theme }) => theme.border["border-sm-white"]};
  &:hover {
    border: ${({ theme }) => theme.border["primary-sm"]};
  }
`;

export const RelayTitle = styled.div`
  padding: 0.225rem;
  font-size: 1.125rem;
`;

export const RelayEdit = styled.div`
  display: flex;
  justify-content: end;
  padding: 0.225rem;
  svg {
    cursor: pointer;
  }
`;

export const RelaySwitch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SetupAddRuleTimerHeading = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 1rem;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
      color: ${({ theme }) => theme.colors["grey-800"]};
    }
  }
`;

export const DeviceRelayWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;
`;

export const RelayAddRoutineBoxHeader = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem 0 0;
  width: 100%;
`;

export const RelayAddRoutineBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const RelayButton = styled.button`
  padding: 0.2rem 1rem;
  border: ${({ theme }) => theme.border["border-sm-yellow"]};
  border-radius: ${({ theme }) => theme.borderRadius["rounded-full"]};
  font-weight: ${({ theme }) => theme.fontWeight["bold"]};
  color: ${({ theme }) => theme.colors["white"]};
  background-color: ${({ theme }) => theme.colors["black-100"]};
  cursor: pointer;
  transition: 0.1s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colors["black-100"]};
    background-color: ${({ theme }) => theme.colors["primary"]};
  }
`;
