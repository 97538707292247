import React from "react";
import { DeviceStatisticsWrapper } from "../../../../Components/device-manager-elements/DeviceManagerMain.style";
import { useAppSelector } from "../../../../Store/hooks";
import GaugeChartSection from "../../../../Pages/dashboard/GaugeChartSection";

export const CurrentData = () => {
  const { selectedDevice } = useAppSelector((state) => state.devices);
  return (
    <DeviceStatisticsWrapper>
      {selectedDevice.Id ? (
        <GaugeChartSection
          selectedLocation={null}
          selectedDevice={selectedDevice?.Id}
        />
      ) : null}
    </DeviceStatisticsWrapper>
  );
};
